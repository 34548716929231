import * as Yup from "yup";

export const requiredMessage = (field) => `Please input ${field}`;
export const invalidMessage = (field) => `Invalid ${field}`;
export const incorrectMessage = (field) => `Incorrect ${field}`;
export const alreadyExistsMessage = (field) =>
  `${field} already exists in the system. ${field} must be unique`;
export const maxSizeMessage = (size) =>
  `File size must be less than ${size} MB`;
export const cannotBeNullMessage = (field) => `${field} cannot be null`;

// Email regex pattern
export const emailRegex =
  /^[A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Phone number regex pattern
export const phoneRegex = /^\+965[1-9]\d{7}$/;

// Maximum file size for images
export const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB

// Supported image formats
export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
];

// Dosya doğrulama şeması
export const fileSchema = Yup.mixed()
  .nullable()
  .notRequired()
  .test(
    "fileFormat",
    invalidMessage("file format. Supported formats are JPG, JPEG, PNG."),
    (value) => {
      if (!value) return true;
      if (typeof value === "string") return true; // URL ise kontrol etme
      return SUPPORTED_FORMATS.includes(value.type);
    }
  )
  .test("fileSize", maxSizeMessage(3), (value) => {
    if (!value) return true;
    if (typeof value === "string") return true; // URL ise kontrol etme
    return value.size <= MAX_FILE_SIZE;
  })
  .test("notNull", cannotBeNullMessage("Image"), (value) => value !== null);
