import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { showError } from "utils/helpers";

const {
  default: locationService,
} = require("services/location/locationService");

const useLocations = () => {
  const userBusinessId = useSelector(selectBusinessId);
  const [locations, setLocations] = useState([]);
  const [superadminLocations, setSuperadminLocations] = useState([]);
  const [vendorLocations, setVendorLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLocationName = async (locationId) => {
    try {
      const response = await locationService.getLocation(locationId);
      switch (response.statusCode) {
        case 200:
          return response.location.name;
        default:
          showError(response.message);
          return "";
      }
    } catch (error) {
      showError("Failed to fetch location name. Please try again.");
      return "";
    }
  };

  const fetchVendorLocations = async (businessId) => {
    try {
      const response = await locationService.getLocationWQuery(
        `businessId=${businessId}`
      );
      switch (response.statusCode) {
        case 200:
          return response.locations;
        default:
          showError(response.message);
          return [];
      }
    } catch (error) {
      showError("Failed to fetch locations. Please try again.");
      return [];
    }
  };

  const fetchLocation = async (locationId) => {
    try {
      const response = await locationService.getLocation(locationId);
      switch (response.statusCode) {
        case 200:
          return response.location;
        default:
          showError(response.message);
          return null;
      }
    } catch (error) {
      showError("Failed to fetch location. Please try again.");
      return null;
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await locationService.getLocations();
        switch (response.statusCode) {
          case 200:
            setLocations(response.locations);
            break;
          default:
            showError(response.message);
            setLocations([]);
            break;
        }
      } catch (error) {
        showError("Failed to fetch locations. Please try again.");
      }
    };

    const getSuperAdminLocations = async () => {
      try {
        const response = await locationService.getSuperadminLocations();
        switch (response.statusCode) {
          case 200:
            setSuperadminLocations(response.locations);
            break;
          default:
            showError(response.message);
            setSuperadminLocations([]);
            break;
        }
      } catch (error) {
        showError("Failed to fetch locations. Please try again.");
      }
    };

    getSuperAdminLocations();
    fetchLocations();

    if (userBusinessId) {
      fetchVendorLocations(userBusinessId).then((locations) => {
        setVendorLocations(locations);
      });
    }
  }, []);

  return {
    locations,
    superadminLocations,
    fetchLocationName,
    fetchVendorLocations,
    vendorLocations,
    fetchLocation,
  };
};

export default useLocations;
