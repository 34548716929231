import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { PiWarningCircleFill } from "react-icons/pi";
import { useState } from "react";

const CategoryListItem = ({ category, isSelected, onSelect, onDelete }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openModal = () => setIsDeleteModalOpen(true);
  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div
      className={`flex items-center justify-between p-4 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
    >
      <div className="relative flex items-center">
        <img
          src={
            category.images
              ? category.images[0]
              : "https://spots-images.fra1.cdn.digitaloceanspaces.com/spots-images/images/no-image.png"
          }
          alt={category.name.en}
          className="w-12 h-12 px-1 bg-gray-200 rounded-md shadow-md "
        />
        <span
          className={` ml-5 text-14 font-medium ${
            isSelected ? "text-[#DE0607]" : "text-[#000000D9]"
          }`}
        >
          {category.name.en}
        </span>
      </div>

      {/* Actions */}
      <div className="relative flex flex-row">
        <LuTrash
          className="w-6 h-6 text-[#DE0607] cursor-pointer"
          onClick={openModal}
        />
        {isDeleteModalOpen && (
          <div className="absolute transform -translate-x-full translate-y-1/4 bg-white border border-gray-300 rounded-lg z-50 flex items-center justify-center w-[230px] h-28">
            <div className="w-full h-full p-4 bg-white rounded-lg shadow-lg">
              <div className="flex items-center justify-center mb-2 space-x-2">
                <PiWarningCircleFill className="w-6 h-6 text-[#FAAD14]" />
                <h2 className=" text-14 h-11 font-normal text-[#000000D9]">
                  Are you sure delete parent category?
                </h2>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="h-7 w-9 text-14 font-normal text-[#000000D9] border border-[#DE0607]  bg-white rounded hover:bg-gray-100 "
                  onClick={closeModal}
                >
                  No
                </button>
                <button
                  className=" h-7 w-9 text-14 font-normal  text-white bg-[#DE0607] rounded hover:bg-red-500"
                  onClick={() => {
                    onDelete(category);
                    closeModal();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="h-6 mx-4 border-r border-gray-300" />
        {isSelected ? (
          <IoIosArrowForward className="w-6 h-6 text-[#DE0607] cursor-pointer" />
        ) : (
          <HiDotsVertical
            className="w-6 h-6 text-black cursor-pointer"
            onClick={onSelect}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryListItem;
