import React, { useEffect, useState } from "react";

const MultiSelectInput = ({
  options,
  selectedValues,
  onChange,
  label,
  checkboxClassName,
  className,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    let newSelectedValues = [];
    if (selectedValues && selectedValues.length > 0) {
      newSelectedValues = selectedValues.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      if (newSelectedValues.length === selectedValues.length) {
        newSelectedValues.push(option);
      }
    } else {
      newSelectedValues.push(option);
    }
    onChange(newSelectedValues);
  };

  const isExist = (option) => {
    let returnValue = false;
    if (selectedValues && selectedValues.length > 0) {
      selectedValues.map((selectedOption) => {
        let optionValue = option.value;
        let selectedOptionValue = selectedOption.value;
        if (optionValue === selectedOptionValue) {
          returnValue = true;
        }
      });
    }
    return returnValue;
  };

  return (
    <div className={`flex flex-row w-full h-full ${className}`}>
      {label && (
        <p className="text-sm font-semibold w-1/2 max-w-[160px] text-[#000000d9]">
          {label}
        </p>
      )}

      <div
        className={`flex flex-col items-start justify-between  w-fit ${checkboxClassName}`}
      >
        {options.map((option) => (
          <div key={option.value} className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-red-600 cursor-pointer md:w-4 md:h-4 form-checkbox"
              style={{ accentColor: "#DE0607" }}
              checked={selectedValues && isExist(option)}
              onChange={() => handleOptionClick(option)}
              disabled={disabled}
            />
            {option.image && (
              <img
                src={option.image}
                alt={option.label}
                className="w-10 h-10"
              />
            )}
            <div className="flex flex-col">
              <p className="text-16 min-w-fit  font-medium text-[#000000D9]">
                {option.label}
              </p>
              <p className="text-14 min-w-fit  font-medium text-[#00000073]">
                {option.speciality && option.speciality}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSelectInput;
