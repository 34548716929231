import { useEffect, useState } from "react";
import reviewsService from "services/reviews/reviewsService";
import { showError } from "utils/helpers";

const useReviews = () => {
  const [reviews, setReviews] = useState([]);

  const fetchReview = async (id) => {
    try {
      const response = await reviewsService.getReview(id);
      switch (response.statusCode) {
        case 200:
          return response.review;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };
  const fetchReviews = async () => {
    try {
      const response = await reviewsService.getReviews();
      switch (response.statusCode) {
        case 200:
          setReviews(response.reviews);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);
  return { reviews, fetchReview, fetchReviews };
};

export default useReviews;
