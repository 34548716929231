const ProgressBar = ({ label, value, max }) => {
  const width = (value / max) * 100;

  return (
    <div className="flex items-center justify-between w-full my-2">
      <p className="w-1/4 pl-2 text-[#000000D9] text-14">{label}</p>

      <div className="w-3/4">
        <div className="h-2 bg-[#F5F5F5] rounded-full ">
          <div
            className="h-2 transition-all duration-700 ease-in-out bg-[#F5222D] rounded-full"
            style={{ width: `${width}%` }}
          ></div>
        </div>
      </div>
      <p className="w-1/4 pl-2 text-center text-[#000000D9] text-12">{value}</p>
    </div>
  );
};

export default ProgressBar;
