import React, { useEffect, useState } from "react";
import Button from "../../core/Button";
import useBusinessForum from "utils/hooks/useBusinessForum";
import Table from "components/core/Table/TableComponent";
import Modal from "components/core/Modal";
import { showError, showInfo, showSuccess } from "utils/helpers";
import businessService from "services/business/businessService";
import useCategory from "utils/hooks/useCategory";
import businessForumService from "services/business/businessForumService";

function BusinessForum() {
  const { businessForums, refresh } = useBusinessForum();
  const { fetchedCategories: categoryOptions } = useCategory();

  const [filteredBusinessForums, setFilteredBusinessForums] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!businessForums) return;
    setFilteredBusinessForums(
      businessForums.map((forum) => {
        const business = forum.business;
        const categoryName =
          business.categoryId !== null &&
          categoryOptions &&
          categoryOptions.find((item) => item.id === business.categoryId);

        return {
          ...forum,
          businessName: business.name.en ? business.name.en : business.name,
          category: categoryName?.name?.en
            ? categoryName?.name?.en
            : categoryName?.name,
          phone: business.phone[0],
          email: business.email,
          status: forum.status,
        };
      })
    );
  }, [businessForums, categoryOptions]);

  const handleEdit = async (item, onClose) => {
    try {
      const response = await businessForumService.updateBusinessForum(item.id, {
        status: item.status === "UNREAD" ? "READ" : "UNREAD",
      });
      switch (response.statusCode) {
        case 404:
          showError(response.message);
          break;
        case 400:
          showError(response.message);
          break;
        default:
          showSuccess(response.message);
          refresh();
          onClose();
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await businessForumService.deleteBusinessForum(
        selectedBusiness.id
      );
      switch (response.statusCode) {
        case 404:
          showError(response.message);
          break;
        case 400:
          showError(response.message);
          break;
        default:
          showSuccess("Business Forum deleted successfully");
          setIsOpen(false);
          refresh();
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <Table
          data={filteredBusinessForums}
          headers={[
            {
              key: "businessName",
              value: "Business Name",
            },
            {
              key: "category",
              value: "Business Category",
            },
            {
              key: "phone",
              value: "Phone Number",
            },
            {
              key: "email",
              value: "Email",
            },
            {
              key: "status",
              value: "Status",
            },
          ]}
          numberOfRows={10}
          setData={setFilteredBusinessForums}
          usePopover
          popoverActions={{
            onEdit: (item, index, onClose) => {
              handleEdit(item, onClose);
            },
            onDelete: (item) => {
              setSelectedBusiness(item);
              setIsOpen(true);
            },
          }}
        />
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        headerText={"Delete Business"}
      >
        <div className="p-5">
          <h2 className="text-16 text-[#000000D9] font-medium">
            Are you sure you want to delete this business?
          </h2>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              className="px-4 py-2 text-black rounded hover:bg-red-600"
              style={{ borderWidth: 1, borderColor: "#DC2626" }}
              onClick={() => setIsOpen(false)}
              variant="outline"
            >
              <p>Cancel</p>
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400"
            >
              <p>Delete</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BusinessForum;
