import api from "../apiConfig";

const subcategoryServices = {
  getSubcategories: async () => {
    try {
      const response = await api.get("/subcategory");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSubcategory: async (id) => {
    try {
      const response = await api.get(`/subcategory/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addSubcategory: async (subcategoryData) => {
    try {
      const response = await api.post("/subcategory", subcategoryData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateSubcategory: async (id, updateData) => {
    try {
      const response = await api.put(`/subcategory/${id}`, updateData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  deleteSubcategory: async (id) => {
    try {
      const response = await api.delete(`/subcategory/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default subcategoryServices;
