import api from "services/apiConfig";

const notificationService = {
  pushNotification: async (notificationData) => {
    try {
      const response = await api.post("/push-notification", notificationData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getNotifications: async () => {
    try {
      const response = await api.get("/push-notification");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default notificationService;
