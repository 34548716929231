import React from "react";
import ProgressBar from "./ProgressBar";

const ProgressBarCard = ({ title, progressbarValues = [] }) => {
  return (
    <div className="flex flex-col py-4 space-y-6 px-3 shadow-md border rounded-md  border-[#00000012]">
      <p className="text-16 leading-6 font-medium text-[#000000D9]">{title}</p>
      {Array.isArray(progressbarValues) ? (
        progressbarValues.map((value, index) => (
          <ProgressBar
            key={index}
            label={value.label}
            count={value.count}
            percentage={value.percentage ? value.percentage.toFixed(2) : 0}
            max={value.max ? value.max : 0}
          />
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ProgressBarCard;
