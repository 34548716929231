import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./styles.css"; // make sure to create a Modal.css file for styling
import ModalContent from "./ModalContent/ModalContent";

const BookingDetailsHalfScreen = ({
  employees,
  bookingDetails,
  isShowing,
  hide,
  children,
  isCreate,
  selectedLocationId,
  fetchBookings,
  selectedLocation,
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-booking">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                  <h1 style={{ marginLeft: 10, fontWeight: "bold" }}>
                    Booking details
                  </h1>
                </button>
              </div>
              <ModalContent
                employees={employees}
                bookingDetails={bookingDetails}
                selectedLocation={selectedLocation}
                fetchBookings={() => fetchBookings()}
                selectedLocationId={selectedLocationId}
                isCreate={isCreate}
                hide={hide}
              />
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default BookingDetailsHalfScreen;
