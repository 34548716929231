import axios from "axios";

const AuthAPI = axios.create({
  baseURL: "https://dev.admin.tryspots.net/api/auth",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default AuthAPI;
