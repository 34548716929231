import { LuTrash } from "react-icons/lu";
import { GoDash } from "react-icons/go";
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import { FaStarHalfAlt } from "react-icons/fa";
import { useEffect, useState } from "react";

const RatingStar = ({ starCount, filledCount, updateRatingFunction }) => {
  const [fillTo, setFillTo] = useState(filledCount - 1);

  useEffect(() => {
    setFillTo(filledCount - 1);
  }, [filledCount]);

  const handleFill = (index) => {
    setFillTo(index);
    if (updateRatingFunction != null) {
      updateRatingFunction(index + 1);
    }
  };

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      {Array.from({ length: starCount }, (_, index) => {
        if (fillTo >= index) {
          return (
            <button type="button" onClick={() => handleFill(index)} key={index}>
              <FaStar color="#DE0607" key={index} />
            </button>
          );
        } else {
          return (
            <button type="button" onClick={() => handleFill(index)} key={index}>
              <CiStar color="#DE0607" key={index} />
            </button>
          );
        }
      })}
    </div>
  );
};

export default RatingStar;
