import React, { useEffect, useState } from "react";
import photoService from "services/photoService";
import { showError, showSuccess } from "utils/helpers";
import ImageInput from "components/core/ImageInput";
import InputField from "components/core/inputField";
import Button from "components/core/Button";
import { useNavigate } from "react-router-dom";
import useLocations from "utils/hooks/useLocations";
import SelectInput from "components/core/SelectInput";
import locationManagerService from "services/locationManager/locationManagerService";
import { locationManagerSchema } from "utils/schemas/validationSchemas";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const AddLM = () => {
  const { vendorLocations: locations } = useLocations();
  const businessId = useSelector(selectBusinessId);
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [assignedLocations, setAssignedLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddLM = async () => {
    setLoading(true);

    try {
      const data = {
        name,
        avatarPath: selectedImage,
        email,
        phone,
        locationIds: assignedLocations.map((location) => location.value),
        businessId: businessId,
      };

      await locationManagerSchema.validate(data).then(async () => {
        const response = await locationManagerService.createLocationManager(
          data
        );
        switch (response.statusCode) {
          case 201:
            showSuccess("Location Manager added successfully");
            setLoading(false);
            navigate("/location-manager");
            break;
          default:
            showError(response.message);
            setLoading(false);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="flex flex-col w-full h-full p-4 my-4 overflow-hidden bg-white rounded-md ">
      <div className="flex items-center justify-center w-32 h-32 ">
        <ImageInput
          value={selectedImage}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          imageSize={"w-32 h-32"}
          className="w-32 h-32 bg-gray-100 border-0 rounded-full"
          isRounded
          supportedFormats={["image/jpg", "image/png", "image/svg+xml"]}
        />
      </div>
      <div className="flex flex-col mt-4 space-y-4 max-w-[500px]   h-full">
        <InputField
          label="Name"
          placeholder="Enter name"
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <InputField
          label="Email"
          placeholder="Enter email"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputField
          label="Phone Number"
          placeholder="Enter phone"
          value={phone}
          type="tel"
          onChange={(e) => setPhone(e.target.value)}
        />
        <SelectInput
          label="Assign Location"
          options={locations.map((location) => ({
            value: location.id,
            label: location.name.en,
          }))}
          value={assignedLocations}
          onSelect={(value) => setAssignedLocations(value)}
          isMulti
        />
        <div className="flex items-center justify-end w-full h-20 space-x-4">
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={() => navigate("/location-manager")}
          />
          <Button
            label="Save"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={handleAddLM}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLM;
