import React from "react";
import Select from "react-select";

const SelectInput = ({
  id,
  label,
  selected,
  onSelect,
  options = [],
  placeholder = "Select...",
  formatOptionLabel = false,
  onChange,
  maxHeight = 200,
  isClearable = true,
  isDisabled = false,
  isMulti = false, // Added prop to toggle between single and multi-select
  padding = "2px",
  width,
  error,
  border,
  backgroundColor,
  inputClassName = "",
  labelClassName = "",
  isRequired = false,
  optionsBackgroundColor,
  menuPlacement,
  ...props
}) => {
  const styles = {
    control: (base) => ({
      ...base,
      lineHeight: "20px",
      borderRadius: "7px",
      borderColor: error ? "#F87171" : "#E5E7EB",
      maxWidth: width || undefined,
      padding,
      backgroundColor: backgroundColor || isDisabled ? "#F5F5F5" : "#FFFFFF",
      border: border ? "1px solid" : "none",
      "&:hover": {
        borderColor: "#D9D9D9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#4E4E4E",
      fontWeight: "300",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#eff6ff",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#000000D9",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: isDisabled ? "#00000040" : "#000000D9",
      "&:hover": {
        backgroundColor: " #00000014",
        color: "#00000073",
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight,
    }),
    option: (styles, { isFocused, isSelected }) => {
      let backgroundColor = null;
      if (isSelected) {
        backgroundColor = "#eff6ff";
      } else if (isFocused) {
        backgroundColor = optionsBackgroundColor || "#F3F4F6";
      }
      return {
        ...styles,
        backgroundColor,
        color: "#4E4E4E",
        fontSize: "14px",
        fontWeight: "300",
      };
    },
  };

  const handleChange = (selectedOption) => {
    onSelect(selectedOption);
    if (onChange) {
      onChange();
    }
  };

  return (
    <div id={id} {...props}>
      <div className="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0 text-color-light-gray focus-within:text-sky-400">
        {label && (
          <label
            className={`text-sm font-bold justify-start text-left text-gray-700 md:w-1/5 min-w-32 ${labelClassName}`}
            htmlFor={id}
          >
            {label}:{isRequired && <span className="text-red-500 ">*</span>}
          </label>
        )}
        <Select
          placeholder={placeholder}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          formatOptionLabel={formatOptionLabel}
          options={options}
          styles={styles}
          onChange={handleChange}
          value={selected}
          isClearable={isClearable}
          isDisabled={isDisabled}
          classNamePrefix="react_select"
          className={`w-full h-auto border rounded-md ${inputClassName}`}
          menuPlacement={"auto"}
        />
      </div>
      {error && <label className="text-xs text-red-500">{error}</label>}
    </div>
  );
};

export default SelectInput;
