import React, { useState, useEffect } from "react";
import InputField from "../../core/inputField";
import Checkbox from "../../core/Checkbox";
import InputFieldWithLanguage from "../ServicesTab/InputFieldWithLanguage";
import Button from "components/core/Button";
import { HiPlus } from "react-icons/hi";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import Modal from "components/core/Modal";
import useServices from "utils/hooks/useServices";
import { LuTrash } from "react-icons/lu";
import SimpleDatePicker from "components/core/Input/SimpleDatePicker";
import useUsers from "utils/hooks/useUsers";
import { showError } from "utils/helpers";

const DiscountForm = ({ data, onClose, onSave, locations }) => {
  // states
  const { businessSubservices: serviceOptions, fetchSubservice } =
    useServices();
  const { fetchProfile } = useUsers();
  const [discountTitle, setDiscountTitle] = useState("");
  const [code, setCode] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [durationType, setDurationType] = useState("");
  const [budgetType, setBudgetType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState("");
  const [totalUsages, setTotalUsages] = useState("");
  const [percentage, setPercentage] = useState("");
  const [fixPrice, setFixPrice] = useState("");
  const [subserviceData, setSubserviceData] = useState([]);
  const [subserviceIds, setSubserviceIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSelectedServices, setModalSelectedServices] = useState([]);

  const getMaxAllowedDiscount = () => {
    const minPrice = selectedServices.reduce((min, service) => {
      const serviceMinPrice = service.childSubservices
        ? Math.min(...service.childSubservices.map((cs) => cs.price))
        : service.price;
      return serviceMinPrice < min ? serviceMinPrice : min;
    }, Infinity);

    return (minPrice * 0.8).toFixed(0);
  };

  const handleFixPriceChange = (e) => {
    let value = parseInt(e.target.value);
    const maxAllowed = getMaxAllowedDiscount();

    if (isNaN(value)) {
      value = 0;
    }

    if (value > maxAllowed) {
      showError(`Fixed Price cannot exceed ${maxAllowed} KWD`);
      setFixPrice(maxAllowed);
    } else {
      setFixPrice(value);
    }
    setPercentage(null);
  };

  const handlePercentageChange = (e) => {
    const value = parseInt(e.target.value);
    const maxAllowed = 80;

    if (isNaN(value)) {
      setPercentage(0);
      return;
    }

    if (value > maxAllowed) {
      showError(`Percentage of Sale cannot exceed ${maxAllowed}%`);
      setPercentage(maxAllowed);
    } else {
      setPercentage(value);
    }
    setFixPrice(null);
  };

  // useEffects
  useEffect(() => {
    if (data && data.id) {
      setDiscountTitle(data.title);
      setCode(data.code);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setPeriod(data.period);
      setTotalUsages(data.totalUsages);
      setPercentage(data.percentage);
      setFixPrice(data.fixPrice);
      // setModalSelectedServices(data.subservices);
      setDurationType(
        data.startDate !== "" && data.endDate !== "" ? "period" : "usageAmount"
      );
      setBudgetType(data.discountType.toLowerCase());
      setSubserviceIds(data.subservices.map((subservice) => subservice.id));
    }
  }, [data]);

  useEffect(() => {
    if (subserviceIds.length > 0) {
      subserviceIds.forEach((subserviceId) => {
        fetchSubservice(subserviceId).then((subservice) => {
          if (
            subservice.childSubservices !== null &&
            subservice.childSubservices.length > 0
          ) {
            setSelectedServices((prev) => {
              const exists = prev.some(
                (service) => service.id === subservice.id
              );
              if (!exists) {
                return [
                  ...prev,
                  {
                    id: subservice.id,
                    name: subservice.name,
                    childSubservices: subservice.childSubservices,
                  },
                ];
              }
              return prev;
            });
            setModalSelectedServices((prev) => {
              const exists = prev.some(
                (service) => service.id === subservice.id
              );
              if (!exists) {
                return [
                  ...prev,
                  {
                    id: subservice.id,
                    name: subservice.name,
                    price: subservice.price,
                    duration: subservice.duration,
                  },
                ];
              }
              return prev;
            });
          } else {
            setSelectedServices((prev) => {
              const exists = prev.some(
                (service) => service.id === subservice.id
              );
              if (!exists) {
                return [
                  ...prev,
                  {
                    id: subservice.id,
                    name: subservice.name,
                    duration: subservice.duration,
                    price: subservice.price,
                  },
                ];
              }
              return prev;
            });
            setModalSelectedServices((prev) => {
              const exists = prev.some(
                (service) => service.id === subservice.id
              );
              if (!exists) {
                return [
                  ...prev,
                  {
                    id: subservice.id,
                    name: subservice.name,
                    duration: subservice.duration,
                    price: subservice.price,
                  },
                ];
              }
              return prev;
            });
          }
        });
      });
    }
  }, [subserviceIds]);

  const durationOptions = [
    {
      label: "Period",
      value: "period",
      content: (
        <div className="flex w-full">
          <SimpleDatePicker
            startDate={startDate}
            setStartDate={(date) => {
              setStartDate(date);
              setTotalUsages(null);
            }}
            endDate={endDate}
            setEndDate={(date) => {
              setEndDate(date);
              setTotalUsages(null);
            }}
          />
        </div>
      ),
    },
    {
      label: "Usage Amount",
      value: "usageAmount",
      content: (
        <div className="flex flex-col w-full space-y-2">
          <InputField
            placeholder="Enter time"
            value={totalUsages}
            onChange={(e) => {
              setStartDate(null);
              setEndDate(null);
              setTotalUsages(e.target.value);
            }}
            className="w-full"
          />
        </div>
      ),
    },
  ];

  const budgetOptions = [
    {
      label: "Percentage of Sale",
      value: "percentage",
      content: (
        <div className="flex flex-col w-full space-y-2">
          <InputFieldWithLanguage
            placeholder="Enter percentage"
            language={"%"}
            value={percentage}
            onChange={handlePercentageChange}
            className="w-full"
          />
        </div>
      ),
    },
    {
      label: "Fixed Price",
      value: "fix",
      content: (
        <div className="flex flex-col w-full space-y-2">
          <InputFieldWithLanguage
            placeholder="Enter price"
            language={"KWD"}
            value={fixPrice}
            onChange={handleFixPriceChange}
            labelClassName={"!w-14"}
          />
        </div>
      ),
    },
  ];

  const handleSave = () => {
    const convertToISO = (date) => {
      // if date is already in ISO format return it
      if (typeof date === "string" && date.includes("T")) return date;
      const utcDate = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      );
      return utcDate.toISOString();
    };
    onSave({
      title: discountTitle,
      code,
      subserviceIds:
        selectedServices.length > 0
          ? selectedServices.map((subservice) =>
              subservice.id ? subservice.id : subservice
            )
          : [],
      startDate: startDate ? convertToISO(startDate) : "",
      endDate: endDate ? convertToISO(endDate) : "",
      totalUsages: totalUsages ? parseInt(totalUsages) : 0,
      alreadyUsed: 0,
      percentage: percentage ? parseInt(percentage) : 0,
      fixPrice: fixPrice ? parseInt(fixPrice) : 0,
      discountType: budgetType ? budgetType.toUpperCase() : "",
    });
  };

  const handleRemoveService = (serviceId) => {
    setSelectedServices(
      selectedServices.filter((service) => service.id !== serviceId)
    );
    setModalSelectedServices(
      modalSelectedServices.filter((service) => service.id !== serviceId)
    );
  };

  return (
    <div className="flex flex-col h-full px-4 bg-white border rounded-md shadow-md">
      {/* Discount Title */}
      <div className="flex w-full mt-5">
        <p className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Discount Title:
          <span className="text-red-500 text-16">*</span>
        </p>
        <div className="flex flex-col w-full space-y-2">
          <InputFieldWithLanguage
            placeholder={"Discount Title"}
            language={"En"}
            onChange={(e) => {
              setDiscountTitle(e.target.value);
            }}
            value={discountTitle}
            className={""}
          />
        </div>
      </div>
      {/* Code */}
      <div className="flex w-full mt-5">
        <p className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Code: <span className="text-red-500 text-16">*</span>
        </p>
        <InputField
          placeholder="Type the code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="w-full"
        />
      </div>

      <div className="w-full my-5 border-b" />

      {/* Services */}
      <div className="flex w-full mt-5">
        <label className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Services:
        </label>
        <div className="flex flex-col w-full space-y-2">
          {selectedServices.length > 0 ? (
            <div className="flex flex-col w-full space-y-2">
              {selectedServices.map((subservice) => (
                <div
                  className="flex justify-between w-full"
                  key={subservice.id || subservice.subserviceId}
                >
                  <div className="flex flex-col items-start justify-between w-full">
                    {subservice.childSubservices &&
                    subservice.childSubservices !== null &&
                    subservice.childSubservices.length > 0 ? (
                      <div className="flex flex-col justify-between w-full space-y-2">
                        <div className="flex justify-between w-full">
                          <p className="text-[#000000D9] text-14 font-medium">
                            {subservice.name.en
                              ? subservice.name.en
                              : "No Service"}
                          </p>
                          <LuTrash
                            className="text-[#FF4D4F] cursor-pointer"
                            onClick={() => handleRemoveService(subservice.id)}
                          />
                        </div>
                        {subservice.childSubservices.map((childSubservice) => (
                          <div
                            className="flex justify-between w-full ml-4"
                            key={childSubservice.id}
                          >
                            <div className="flex flex-col items-start justify-between w-fit">
                              <p className="text-[#000000D9] text-14 font-medium">
                                {childSubservice.name.en}
                              </p>
                              <p className="text-[#00000073] text-12 font-normal">
                                {childSubservice.duration} min
                              </p>
                            </div>
                            <div className="flex items-center justify-between w-24">
                              <p className="text-[#000000D9] text-14 font-medium ">
                                KWD {childSubservice.price}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex justify-between w-full">
                        <div className="flex flex-col items-start justify-between w-fit">
                          <p className="text-[#000000D9] text-14 font-medium">
                            {subservice.name.en}
                          </p>
                          <p className="text-[#00000073] text-12 font-normal">
                            {subservice.duration ?? 0} min
                          </p>
                        </div>
                        <div className="flex items-center justify-between w-24">
                          <p className="text-[#000000D9] text-14 font-medium ">
                            KWD {subservice.price ?? 0}
                          </p>
                          <LuTrash
                            className="text-[#FF4D4F] cursor-pointer"
                            onClick={() => handleRemoveService(subservice.id)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No services selected</p>
          )}
          <Button
            label="Add Services"
            className="h-10 w-36"
            icon={<HiPlus size={16} />}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>

      <div className="w-full my-5 border-b" />

      {/* Duration of discount */}
      <div className="flex w-full mt-5">
        <p className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Duration of discount: <span className="text-red-500 text-16">*</span>
        </p>
        <RadioButtonGroup
          name={"duration"}
          options={durationOptions}
          onChange={(value) => {
            setDurationType(value.target.value);
          }}
          selectedValue={durationType}
          className={"w-full flex-col items-start space-x-0 space-y-4"}
        />
      </div>

      <div className="w-full my-5 border-b" />

      {/* Budget */}
      <div className="flex w-full mt-5">
        <p className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Promotion budget: <span className="text-red-500 text-16">*</span>
        </p>
        <RadioButtonGroup
          name={"budget"}
          options={budgetOptions}
          onChange={(value) => {
            setBudgetType(value.target.value);
          }}
          selectedValue={budgetType}
          className={"w-full flex-col items-start space-x-0 space-y-4"}
        />
      </div>

      <div className="w-full my-5 border-b" />

      {/* Footer with actions */}
      <div className="flex items-center justify-end mt-4 space-x-4">
        <button
          type="button"
          className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          onClick={handleSave}
        >
          Save
        </button>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        headerText={"Select service"}
      >
        <div className="w-[500px] h-full space-y-4">
          <Checkbox
            label={"Select All"}
            onChange={() => {
              setModalSelectedServices(serviceOptions);
            }}
          />
          <div className="w-full my-5 border-b" />
          <div className=" overflow-y-scroll h-[450px] space-y-4">
            {serviceOptions.map((service) => (
              <div className="flex flex-col w-full " key={service.id}>
                {service.childSubservices !== null &&
                service.childSubservices.length > 0 ? (
                  <div className="flex flex-col w-full space-y-2">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-[#000000D9] text-14 font-bold">
                        {service.name.en}
                      </p>
                      <Checkbox
                        checked={
                          modalSelectedServices
                            ? modalSelectedServices.some(
                                (subservice) => subservice.id === service.id
                              )
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setModalSelectedServices([
                              ...modalSelectedServices,
                              {
                                id: service.id,
                                name: service.name,
                                childSubservices: service.childSubservices,
                              },
                            ]);
                          } else {
                            setModalSelectedServices(
                              modalSelectedServices.filter(
                                (subservice) => subservice.id !== service.id
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full space-y-2">
                      {service.childSubservices.map((childSubservice) => (
                        <div
                          className="flex justify-between w-full"
                          key={childSubservice.id}
                        >
                          <div className="flex flex-col items-start justify-between w-fit">
                            <p className="text-[#000000D9] text-14 font-medium">
                              {childSubservice.name.en}
                            </p>
                            <p className="text-[#00000073] text-12 font-normal">
                              {childSubservice.duration} min
                            </p>
                          </div>
                          <div className="flex items-center justify-between w-24">
                            <p className="text-[#000000D9] text-14 font-medium ">
                              KWD {childSubservice.price}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex justify-between w-full "
                    key={service.id}
                  >
                    <div className="flex flex-col items-start justify-between w-fit">
                      <p className="text-[#000000D9] text-14 font-medium">
                        {service.name.en}
                      </p>
                      <p className="text-[#00000073] text-12 font-normal">
                        {service.duration} min
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-24">
                      <p className="text-[#000000D9] text-14 font-medium ">
                        KWD {service.price}
                      </p>
                      <Checkbox
                        checked={
                          modalSelectedServices
                            ? modalSelectedServices.some(
                                (subservice) => subservice.id === service.id
                              )
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setModalSelectedServices([
                              ...modalSelectedServices,
                              {
                                id: service.id,
                                name: service.name,
                                duration: service.duration,
                                price: service.price,
                              },
                            ]);
                          } else {
                            setModalSelectedServices(
                              modalSelectedServices.filter(
                                (subservice) => subservice.id !== service.id
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="w-full my-3 border-b" />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-end h-20 space-x-2">
            <Button
              label={"Cancel"}
              onClick={() => {
                setIsModalOpen(false);
                setModalSelectedServices(selectedServices);
              }}
              className={
                "w-40 h-10 bg-white text-[#000000D9] rounded-xl border border-[#FFA39E]"
              }
              textColor="text-[#000000D9]"
            />
            <Button
              label={"Add"}
              onClick={() => {
                setIsModalOpen(false);
                setSubserviceData(
                  modalSelectedServices.map((service) => service.id)
                );
                setSelectedServices(modalSelectedServices);
              }}
              className={"w-40 h-10 bg-[#DE0607] text-white rounded-xl"}
              textColor="text-white"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DiscountForm;
