import * as Yup from "yup";
import { requiredMessage } from "../schemaUtils";

export const CityAddSchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required(requiredMessage("English City Name")),
    ar: Yup.string().required(requiredMessage("Arabic City Name")),
  }),
  images: Yup.array()
    .of(Yup.string())
    .max(30, "You can upload a maximum of 30 images")
    .nullable(),
});

export const CityEditSchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required(requiredMessage("English City Name")),
    ar: Yup.string().required(requiredMessage("Arabic City Name")),
  }),
  images: Yup.array()
    .of(Yup.string())
    .max(30, "You can upload a maximum of 30 images")
    .nullable(),
});
