import React, { useState, useEffect } from "react";
import "./styles.css";
import { CiCalendar, CiCircleInfo } from "react-icons/ci";
import { BsPeople } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import subserviceServices from "services/service/subserviceServices";

const ListViewComplatedCalendar = ({ bookings }) => {
  const [subservices, setSubservices] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
      timeZone: "UTC",
    })
      .format(date)
      .replace(",", "");

    return formattedDate;
  };

  const convertStatusEnumToString = (status) => {
    switch (status) {
      case "COMPLETED":
        return "Completed";
      case "CANCELED":
        return "Cancelled";
      case "NO_SHOW":
        return "No showed";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    if (bookings == undefined || bookings == null) {
      return;
    }
    const filterBookings = () => {
      const result = bookings.filter(
        (booking) => booking.status !== "CONFIRMED"
      );
      setFilteredBookings(result);
    };

    filterBookings();

    const fetchSubservices = async () => {
      const promises = filteredBookings.map(async (booking) => {
        if (booking.subserviceIds.length == 0) {
          return;
        }
        const response = await subserviceServices.getSubservice(
          booking.subserviceIds[0],
          false
        );
        return response.subservice.name;
      });

      const subserviceNames = await Promise.all(promises);
      setSubservices(subserviceNames);
    };

    fetchSubservices();
  }, [bookings]);

  return (
    <div style={{ flex: 1 }}>
      {filteredBookings.map((booking, index) => (
        <div key={index} className="booking-card">
          <div className="list-view-details-container">
            <div className="name-container">
              <h2>{booking.userName}</h2>
              <div className="list-view-phone-container">
                <p className="list-view-phone-number">
                  <span class="phone-number-title">Phone number:</span>{" "}
                  <span class="phone-number">{booking.userPhoneNumber}</span>
                </p>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="detail-texts">
                <CiCalendar
                  style={{ marginRight: 5 }}
                  color="#8c8c8c"
                  size={22}
                />
                <p>{formatDate(booking.date)}</p>
              </div>
              <div className="detail-texts">
                <BsPeople
                  style={{ marginRight: 5 }}
                  color="#8c8c8c"
                  size={22}
                />
                <p>{booking.provider.name}</p>
              </div>
              <div className="detail-texts">
                <CiCircleInfo
                  style={{ marginRight: 5 }}
                  color="#8c8c8c"
                  size={22}
                />
                <p>
                  {subservices[index]} {(booking.duration / 60).toFixed(1)}h
                </p>
              </div>
            </div>
          </div>
          <div className="list-view-button-containers">
            <FaCircle
              style={{
                marginRight: 4,
                alignSelf: "center",
                justifyContent: "center",
              }}
              size={10}
              color={
                booking.status == "COMPLETED"
                  ? "green"
                  : booking.status == "CANCELED"
                  ? "red"
                  : "blue"
              }
            />
            <span
              style={{
                alignSelf: "center",
                textAlign: "left",
                color:
                  booking.status == "COMPLETED"
                    ? "green"
                    : booking.status == "CANCELED"
                    ? "red"
                    : "blue",
              }}
              className="status-info"
              onClick={() => {
                /* handle cancel booking */
              }}
            >
              {convertStatusEnumToString(booking.status)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListViewComplatedCalendar;
