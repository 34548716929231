import api from "services/apiConfig";
import { showError } from "utils/helpers";

const providersService = {
  getProviderWithoutLocation: async () => {
    try {
      const response = await api.get("/provider");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProvider: async (locationId) => {
    try {
      const response = await api.get(`/provider?locationId=${locationId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProviderById: async (id) => {
    try {
      const response = await api.get(`/provider/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addProvider: async (providerData) => {
    try {
      const response = await api.post("/provider", providerData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateProvider: async (id, updateData) => {
    try {
      const response = await api.put(`/provider/${id}`, updateData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  deleteProvider: async (id) => {
    try {
      const response = await api.delete(`/provider/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateProviderSettings: async (id, updateData) => {
    try {
      const response = await api.put(`/provider/${id}/settings`, updateData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default providersService;
