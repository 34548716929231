import React, { useEffect, useState } from "react";
import TableComponent from "../../core/Table/TableComponent";
import reviewsData from "../../../constants/data/reviewsData";
import renderStars from "../../../utils/RenderStar";
import { LuTrash } from "react-icons/lu";
import { HiDotsVertical } from "react-icons/hi";

const RatingsAndReviews = ({ data }) => {
  const [reviewData, setReviewData] = useState([]);
  const columns = [
    {
      key: "dateCreated",
      value: "Date Created",
    },
    {
      key: "userName",
      value: "User Name",
    },
    {
      key: "reviews",
      value: "Reviews",
    },
    {
      key: "rating",
      value: "Rating",
    },
  ];

  useEffect(() => {
    if (!data) return;
    const reviews = data.reviews.map((review) => {
      let fullStars = Math.floor(review.rating);
      let halfStar = review.rating % 1 !== 0;
      let emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
      return {
        dateCreated: new Date(review.createdAt)
          .toUTCString()
          .split(" ")
          .slice(1, 4)
          .join(" "),
        userName: review.name,
        reviews: review.text.en || review.text.ar || "No review",
        rating: renderStars({
          fullStars: fullStars,
          halfStar: halfStar,
          emptyStars: emptyStars,
          starColor: "text-[#DE0607]",
        }),
        ratingValue: review.rating,
        id: review.id,
      };
    });
    setReviewData(reviews);
  }, []);

  return (
    <div>
      {reviewData.length === 0 ? (
        <div className="flex items-center justify-center w-full h-32 text-gray-400">
          No reviews available
        </div>
      ) : (
        <TableComponent headers={columns} data={reviewData} numberOfRows={5} />
      )}
    </div>
  );
};

export default RatingsAndReviews;
