import React, { useEffect, useState } from "react";
import ProvidersCard from "../components/ProvidersCard";
import useWindowDimension from "../../../utils/hooks/useWindowDimensions";
import { useParams } from "react-router-dom";
import { showError, showSuccess } from "utils/helpers";
import providersService from "services/providers/providersService";

const StaffPage = ({ data, refreshData }) => {
  const { width, height } = useWindowDimension();
  const [userData, setUserData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (!data) return;
    let providerData = data.map((provider) => {
      let workingTimes =
        provider.availability &&
        provider.availability.filter(
          (availability) => availability.locationId == id
        );
      return {
        id: provider.id,
        name: provider.name,
        speciality: provider.speciality,
        workingTimes: workingTimes ? workingTimes.map((time) => time.days) : [],
        image: provider.avatarPath,
      };
    });
    setUserData(providerData);
  }, [data]);

  const handleDelete = async (providerId) => {
    try {
      let providerData = data.find((provider) => provider.id === providerId);
      if (!providerData) return;
      let filteredLocations = providerData.availability.filter(
        (availability) => availability.locationId != id
      );
      providerData.availability = filteredLocations;

      const response = await providersService.updateProvider(providerId, {
        availability: providerData.availability,
      });
      switch (response.statusCode) {
        case 200:
          showSuccess("Provider deleted successfully");
          refreshData();
          break;
        default:
          showError(response.message || "Error deleting provider");
          break;
      }
    } catch {
      showError("Error deleting provider");
    }
  };

  if (userData.length === 0) {
    return (
      <div className="flex items-center justify-center h-96">
        <p className="text-2xl font-medium text-[#00000073]">No Providers</p>
      </div>
    );
  }

  return (
    <div
      className={` space-y-4 hide-scrollbar overflow-y-scroll max-h-[600px] md:max-h-[600px] lg:max-h-[700px]`}
    >
      {userData.map((user) => (
        <ProvidersCard
          key={user.id}
          data={user}
          onDelete={(data) => handleDelete(data.id)}
        />
      ))}
    </div>
  );
};

export default StaffPage;
