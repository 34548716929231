import React, { useEffect } from "react";
import renderStars from "../../utils/RenderStar";
import ImageUpload from "../core/ImageInput";

const EditHeader = (props) => {
  const { name, rating, location, address, openHours } = props.location;
  const [fullStars, setFullStars] = React.useState(0);
  const [halfStar, setHalfStar] = React.useState(false);
  const [emptyStars, setEmptyStars] = React.useState(0);

  useEffect(() => {
    let fullStars = Math.floor(rating);
    let halfStar = rating % 1 !== 0;
    let emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    setFullStars(fullStars);
    setHalfStar(halfStar);
    setEmptyStars(emptyStars);
  }, [rating]);

  return (
    <div className="flex items-center justify-start w-full md:p-4 ">
      <div className="flex items-center justify-center w-24 h-24 bg-gray-200 md:w-48 md:h-48 mr-7">
        <ImageUpload
          className="w-24 h-24 md:w-48 md:h-48"
          onUpload={(image) => props.onUpload(image)}
          showLabel={false}
          value={props.image}
          imageSize={"w-full h-full"}
          supportedFormats={["image/jpeg", "image/png", "image/jpg"]}
        />
      </div>
      <div className="flex flex-col items-start justify-start w-full md:justify-between h-44">
        <div className="font-medium text-black capitalize text-16 md:text-24">
          {name}
        </div>
        {renderStars({
          fullStars: fullStars,
          halfStar: halfStar,
          emptyStars: emptyStars,
        })}
        <div className="mt-2">
          <div className="mb-1 font-normal text-gray-500 text-13 md:text-sm">
            Location:
            <span className="font-normal text-gray-800">{location}</span>
          </div>
          <div className="mb-1 font-normal text-gray-500 text-13 md:text-sm">
            Address:
            <span className="font-normal text-gray-800">{address}</span>
          </div>
          <div className="font-normal text-gray-500 text-13 md:text-sm">
            Open Hours:
            <span className="font-normal text-gray-800">{openHours}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHeader;
