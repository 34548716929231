import React, { useEffect, useState } from "react";
import WeeklySchedule from "../components/WeeklySchedule";
import { useNavigate, useParams } from "react-router-dom";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";
import { LocationEditAvailabilitySchema } from "utils/schemas/Location/LocationSchemas";
import { selectRole } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const AvailabilityPage = ({ data, setData }) => {
  const [schedule, setSchedule] = useState([]);
  const userRole = useSelector(selectRole);
  const navigation = useNavigate();
  const [isDayEnabledData, setIsDayEnabledData] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const { id } = useParams();

  useEffect(() => {
    if (!data) return;
    if (!data.availability) return;
    let newSchedule = data.availability.days.map((day) => {
      return {
        day: day.name,
        slots: day.schedule.map((slot) => {
          return {
            id: slot.id,
            startsAt: slot.startTime,
            finishesAt: slot.endTime,
          };
        }),
      };
    });
    setSchedule(newSchedule);
    data.availability.days.forEach((day) => {
      setIsDayEnabledData((prev) => {
        return {
          ...prev,
          [day.name]: true,
        };
      });
    });
  }, [data]);

  const onSave = async (data) => {
    let days = data.map((day) => {
      return {
        name: day.day,
        schedule: day.slots.map((slot) => {
          return {
            startTime: slot.startsAt,
            endTime: slot.finishesAt,
          };
        }),
      };
    });
    let schedule = {
      availability: {
        days: days,
      },
    };
    try {
      await LocationEditAvailabilitySchema.validate(schedule).then(async () => {
        const response = await locationService.updateLocation(id, schedule);
        switch (response.statusCode) {
          case 200:
            showSuccess(response.message);
            setData((prev) => {
              return {
                ...prev,
                availability: schedule.availability,
              };
            });
            break;
          default:
            showError(response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const handleCancel = () => {
    navigation(`/locations`);
  };

  return (
    <div>
      <div className="w-full xl:w-1/2 ">
        <WeeklySchedule
          scheduleData={schedule}
          setScheduleData={setSchedule}
          isDayEnabledData={isDayEnabledData}
          setIsDayEnabledData={setIsDayEnabledData}
          onSave={(data) => onSave(data)}
          onCancel={handleCancel}
          disabled={userRole === "PROVIDER"}
        />
      </div>
    </div>
  );
};

export default AvailabilityPage;
