// src/components/Link.js
import React from "react";
import { useNavigate } from "react-router";

const Link = ({ text, href, className }) => {
  const navigate = useNavigate();
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        navigate(href);
      }}
      className={className}
    >
      {text}
    </a>
  );
};

export default Link;
