import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import ActionsModal from "../ActionsModal/ActionsModal";
import usersService from "../../../../../services/users/usersService";
import { showError, showSuccess } from "../../../../../utils/helpers";
import PopoverThreeDot from "../../../../../components/Popover/PopoverThreeDot";
import Modal from "../../../../core/Modal";
import Button from "../../../../core/Button";
import userService from "services/user/userService";

const UserTableList = ({
  headers,
  data,
  numberOfRows,
  setData,
  setIsModalOpen,
  setEditUser,
  users,
  fullUsers,
  fetchUsers,
  setCurrentDataAll,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [lastRowIndex, setLastRowIndex] = useState();
  const [firstRowIndex, setFirstRowIndex] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(-1);
  const [openOptionsModal, setOpenOptionsModal] = useState(-1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const buttonRefs = useRef([]);

  useEffect(() => {
    buttonRefs.current = buttonRefs.current.slice(0, currentData.length);
  }, [currentData]);

  const TargetModal = ({ show, children, targetRef, index }) => {
    if (!show || !targetRef || !targetRef.current) {
      // Safeguard: Return null if show is false, targetRef is undefined, or targetRef.current is undefined
      return null;
    }

    const style = {
      position: "absolute",
      top: `${targetRef.current.offsetTop}px`,
      left: `${targetRef.current.offsetLeft + targetRef.current.offsetWidth}px`,
      backgroundColor: "white",
      border: "1px solid #ccc",
      padding: "10px",
      zIndex: 1000,
    };

    return <div style={style}>{children}</div>;
  };

  useEffect(() => {
    setRowsPerPage(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    const currentData = data.slice(first, last);
    setCurrentData(currentData);
    setCurrentDataAll(currentData);
  }, [currentPage, rowsPerPage, data, rowsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const deleteUser = async (id) => {
    try {
      const response = await usersService.deleteUser(id);
      switch (response.statusCode) {
        case 400:
          showError(response.message);
          break;
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess("User deleted successfully");
          setData((prev) => prev.filter((user) => user.id !== id));
          break;
      }

      // fetchUsers();
      // setOpenOptionsModal(-1);
    } catch (error) {
      showError(error.message);
    }
  };

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const editItem = async (index) => {
    await setEditUser(currentData[index]);
    setIsModalOpen(true);
    setOpenOptionsModal(-1);
  };

  const handleOptionsModal = (index) => {
    setOpenOptionsModal((prev) => (prev === index ? -1 : index));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });
  const [showModal, setShowModal] = useState(null);
  const buttonTargetRef = useRef(null);

  return (
    <div className="bg-white">
      <table className="w-full leading-normal">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="w-full px-5 py-3 text-14  font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"
              >
                {header}
              </th>
            ))}
            <th className="w-full px-5 py-3 text-14  font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"></th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={index}>
              {Object.values(item).map((val, idx) => {
                if (idx == Object.keys(item).length - 1) {
                  return null;
                } else {
                  return (
                    <td
                      key={idx}
                      className="p-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200"
                    >
                      {val}
                    </td>
                  );
                }
              })}
              <td className="p-4 text-sm bg-white border-b border-gray-200">
                <PopoverThreeDot
                  onEdit={(index) => editItem(index)}
                  onDelete={(index) => {
                    setSelectedId(currentData[index].id);
                    setIsDeleteModalOpen(true);
                  }}
                  index={index}
                  isOpen={openOptionsModal == index}
                  setIsOpen={setIsOpen}
                  handleOptionsModal={handleOptionsModal}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>

        <div className="inline-block">
          <select
            className=" w-full h-8 form-select text-14 text-[#000000D9] leading-6 border rounded-md border-[#D9D9D9] flex justify-center items-center"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setCurrentPage(1);
            }}
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}/page
              </option>
            ))}
          </select>
        </div>
      </div>
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setOpenOptionsModal(-1);
          }}
          headerText={"Delete User"}
        >
          <div className="p-5">
            <h2 className="text-16 text-[#000000D9] font-medium">
              Are you sure you want to delete this user?
            </h2>
            <div className="flex justify-end mt-6 space-x-4">
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setOpenOptionsModal(-1);
                }}
                variant="outline"
                className="px-4 py-2 text-black rounded hover:bg-red-600"
                style={{ borderWidth: 1, borderColor: "#DC2626" }}
              >
                <p>Cancel</p>
              </button>
              <button
                onClick={() => {
                  deleteUser(selectedId);
                  setIsDeleteModalOpen(false);
                  setOpenOptionsModal(-1);
                }}
                className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400"
              >
                <p>Delete</p>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserTableList;
