import React, { useEffect, useMemo, useState } from "react";
import AvailabilityPage from "./AvailabilityPage";
import ServicesPage from "./ServicesPage";
import StaffPage from "./ProvidersPage";
import InfoLocationPage from "./InfoLocationPage";
import RatingsAndReviews from "./RatingsAndReviews";
import { useParams } from "react-router-dom";
import locationService from "services/location/locationService";
import { showError } from "utils/helpers";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import providersService from "services/providers/providersService";

const EditPage = () => {
  const [activeTab, setActiveTab] = useState("Info");
  const { id } = useParams();
  const [location, setLocation] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLocation = async () => {
    setLoading(true);
    try {
      const response = await locationService.getLocation(id);
      if (response.statusCode === 200) {
        setLocation(response.location);
        setUserData(response.location.providers);
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchProvider = async () => {
    setLoading(true);
    try {
      const response = await providersService.getProvider(id);
      if (response.statusCode === 200) {
        setUserData(response.providers);
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocation();
    // fetchProvider();
  }, [id]);

  const tabs = useMemo(
    () => [
      {
        name: "Info",
        component: (
          <InfoLocationPage
            locationData={location}
            setLocationData={setLocation}
          />
        ),
      },
      {
        name: "Availability",
        component: <AvailabilityPage data={location} setData={setLocation} />,
      },
      {
        name: "Services",
        component: <ServicesPage data={location} refreshData={fetchLocation} />,
      },
      {
        name: "Providers",
        component: <StaffPage data={userData} refreshData={fetchLocation} />,
      },
      {
        name: "Ratings & Reviews",
        component: <RatingsAndReviews data={location} />,
      },
    ],
    [location, userData]
  );

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="h-full mt-4 bg-white rounded-md">
        <div className="flex p-5 space-x-4 bg-white border-b">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`p-2 ${
                activeTab === tab.name
                  ? "text-[#DE0607] border-b border-[#DE0607]"
                  : "text-[#000000d9]"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="p-5">
          {tabs.find((tab) => tab.name === activeTab)?.component}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default EditPage;
