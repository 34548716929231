import React, { useEffect, useState } from "react";
import ProfileCard from "./components/ProfileCard";
import Table from "../core/Table/TableComponent";
import { useParams } from "react-router-dom";
import { formatDate, showError } from "utils/helpers";
import userService from "services/user/userService";
import useLocations from "utils/hooks/useLocations";
import useServices from "utils/hooks/useServices";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import useBusinesses from "utils/hooks/useBusiness";

const ClientDetails = () => {
  const { fetchLocationName } = useLocations();
  const { business } = useBusinesses();
  const { fetchSubservice, fetchChildSubservice } = useServices();
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [statsInfo, setStatsInfo] = useState([]);
  const [previousVisits, setPreviousVisits] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPreviousVisits = async () => {
    try {
      setLoading(true);
      let businessLocationIds = business.locations.map(
        (location) => location.id
      );
      const response = await userService.getUser(id);
      if (response.statusCode === 200) {
        const previousVisitsPromises = response.user.bookings
          .filter((booking) => businessLocationIds.includes(booking.locationId))
          .map(async (booking) => {
            // if (!businessLocationIds.includes(booking.locationId)) return;
            const locationName = booking.locationId
              ? await fetchLocationName(booking.locationId)
              : "Unknown Location";

            const servicesDone = await Promise.all(
              booking.childSubserviceIds &&
                booking.childSubserviceIds.length > 0
                ? booking.childSubserviceIds.map(async (serviceId) => {
                    if (serviceId === null || serviceId === undefined)
                      return null;
                    const service = await fetchChildSubservice(serviceId);
                    if (!service || !service.name) return null;
                    return service.name.en || service.name.ar;
                  })
                : booking.subserviceIds.map(async (serviceId) => {
                    if (serviceId === null || serviceId === undefined)
                      return null;
                    const service = await fetchSubservice(serviceId);
                    if (!service || !service.name) return null;
                    return service.name.en || service.name.ar;
                  })
            );
            return {
              date: formatDate(booking.date),
              amountPaid: booking.totalPrice,
              staffMember:
                booking.provider.name + " " + (booking.provider.surname || ""),
              location: locationName.en || locationName.ar,
              servicesDone:
                servicesDone === null ||
                servicesDone.length === 0 ||
                servicesDone === undefined
                  ? "No services"
                  : servicesDone
                      .filter((service) => service !== null)
                      .join(", "),
            };
          });
        const previousVisits = await Promise.all(previousVisitsPromises);
        setPreviousVisits(previousVisits);
        setLoading(false);
      } else {
        showError(response.message);
        setLoading(false);
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const fetchClientDetails = async () => {
    try {
      setLoading(true);
      const response = await userService.getUser(id);
      switch (response.statusCode) {
        case 200:
          let totalVisits = response.user.bookingsCount;
          let totalSpend = response.user.bookings.reduce(
            (acc, curr) => acc + curr.totalPrice,
            0
          );
          let noShows = response.user.bookings.filter(
            (booking) => booking.status === "NO_SHOW"
          ).length;
          let cancellations = response.user.bookings.filter(
            (booking) => booking.status === "CANCELED"
          ).length;
          setStatsInfo([
            { label: "Visits", value: totalVisits },
            { label: "No shows", value: noShows },
            { label: "Cancellations", value: cancellations },
            { label: "Total spend", value: totalSpend },
          ]);
          setUserInfo({
            name: response.user.name,
            number: response.user.phone,
            avatar: response.user.avatarPath,
            gender: response.user.gender,
            age:
              response.user.bookings && response.user.bookings.length > 0
                ? response.user.bookings[0].userAgeGroup ||
                  response.user.ageGroup
                : response.user.ageGroup,
            email: response.user.email,
          });

          setLoading(false);
          break;
        case 404:
          showError(response.message);
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!business) return;
    fetchClientDetails();
    fetchPreviousVisits();
  }, [id, business]);

  const tableHeaders = [
    {
      key: "date",
      value: "Date",
    },
    {
      key: "location",
      value: "Location",
    },
    {
      key: "servicesDone",
      value: "Services Done",
    },
    {
      key: "amountPaid",
      value: "Amount Paid",
    },
    {
      key: "staffMember",
      value: "Staff Member",
    },
  ];

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="flex flex-col h-screen px-4 py-6 mt-4 bg-white rounded-md shadow">
        <div className="">
          <ProfileCard userInfo={userInfo} stats={statsInfo} />
        </div>
        <div className="p-5 mt-6 rounded-md shadow-lg border  shadow-[#00000026]">
          <p className="text-24 font-medium leading-8 text-[#000000D9] mb-6">
            Previous visits
          </p>
          {previousVisits.length === 0 ? (
            <p className="text-16 text-[#00000099]">No previous visits</p>
          ) : (
            <Table
              headers={tableHeaders}
              data={previousVisits}
              numberOfRows={6}
            />
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default ClientDetails;
