const TimePicker = ({ value, onChange, disabled, className }) => {
  return (
    <div
      className={`flex border border-gray-300 rounded-md
    ${className ? className : ""}
    `}
    >
      <input
        type="time"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="p-2 border-0 rounded-l-md focus:ring-0 focus:border-gray-300"
        placeholder="Select time"
        disabled={disabled}
      />
    </div>
  );
};

export default TimePicker;
