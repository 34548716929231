import PersonalInfo from "./PersonalInfo";
import Stat from "./Stat";

const ProfileCard = ({ userInfo, stats }) => (
  <div className="flex justify-between w-full space-x-3 bg-white rounded-md">
    <div className="w-3/5">
      <PersonalInfo {...userInfo} />
    </div>
    <div className="flex items-stretch w-2/5">
      <div className="grid w-full grid-cols-2 gap-3">
        {stats.map((stat, index) => (
          <Stat key={index} {...stat} />
        ))}
      </div>
    </div>
  </div>
);

export default ProfileCard;
