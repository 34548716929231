import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoMdEye } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import { BsDownload } from "react-icons/bs";

const InvoicesTable = ({
  headers,
  data,
  numberOfRows,
  showPagination = true,
  setData,
  isClickable = false,
  onDelete,
  actions,
  onRowClick = () => {},
  usePopover = false,
  popoverActions = [],
  setDetailsModalOpen,
  setSelectedItem,
  selectedItem,
  handleDownloadPdf, // Receive the download handler
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [lastRowIndex, setLastRowIndex] = useState();
  const [firstRowIndex, setFirstRowIndex] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [openOptionsModal, setOpenOptionsModal] = useState(-1);

  useEffect(() => {
    setRowsPerPage(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    setCurrentData(data.slice(first, last));
  }, [currentPage, rowsPerPage, data, rowsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOptionsModal = (index) => {
    setOpenOptionsModal(index);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  const renderHeaders = () => (
    <tr className="items-center justify-between flex-1 ">
      {headers.map((header, index) => (
        <th
          key={index}
          className={`w-${
            headers.length > 1 ? `1/${headers.length - 1}` : "full"
          } 
          px-5 py-3 text-nowrap text-14 font-medium tracking-normal text-left text-[#000000D9] capitalize bg-[#FAFAFA]`}
        >
          {header.value}
        </th>
      ))}
      {(actions || usePopover) && (
        <th className="px-5 py-3 text-14 font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"></th>
      )}
    </tr>
  );

  const getClassName = ({ item, idx, data }) => {
    if (idx === 5) {
      return (
        <td
          key={idx}
          className="py-[3.5px] px-[8px] bg-white border-b border-gray-200 text-center"
        >
          <div
            style={{
              paddingBlock: "3px",
              paddingInline: "4px",
              borderStyle: "solid",
              color: "black",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "20px",
              fontWeight: "normal",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "25%",
            }}
          >
            <button
              onClick={() => {
                setSelectedItem(data);
                setDetailsModalOpen(true);
              }}
            >
              <IoMdEye />
            </button>
          </div>
        </td>
      );
    }
    if (idx === 6) {
      return (
        <td
          key={idx}
          className="py-[3.5px] px-[8px] bg-white border-b border-gray-200 text-center"
        >
          <div
            style={{
              paddingBlock: "3px",
              paddingInline: "4px",
              borderStyle: "solid",
              color: "black",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "20px",
              fontWeight: "normal",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "25%",
            }}
          >
            <button
              onClick={() => {
                handleDownloadPdf(data); // Trigger the download handler
              }}
            >
              <BsDownload />
            </button>
          </div>
        </td>
      );
    } else {
      return (
        <td
          key={idx}
          className="p-5 text-sm font-normal text-gray-900 bg-white border-b border-gray-200"
        >
          {item}
        </td>
      );
    }
  };

  return (
    <div className="bg-white ">
      <table className="w-full leading-normal">
        <thead>{renderHeaders()}</thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr
              key={index}
              onClick={() => onRowClick(item)}
              className={`${isClickable ? "cursor-pointer" : ""} `}
            >
              {headers.map((header, idx) =>
                getClassName({
                  item: item[header.key],
                  idx,
                  data: item,
                })
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={` items-center justify-end mt-4 space-x-2
      ${showPagination ? "flex" : "hidden"}
        `}
      >
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>
        <div className={`${data.length == 0 ? "pt-2" : ""}`}>
          <select
            className=" w-full h-8 form-select text-14 text-[#000000D9] leading-6 border rounded-md border-[#D9D9D9] flex justify-center items-center"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setCurrentPage(1);
            }}
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}/page
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default InvoicesTable;
