import EmptyStateComponent from "../../EmptyStateComponents";
import ServiceForm from "./ServiceForm";

const ServiceFormWrapper = ({
  formMode,
  selectedService,
  handleSave,
  handleAdd,
  locations,
  setFormMode,
}) => {
  return formMode === "edit" ? (
    <ServiceForm
      formMode={formMode}
      selectedService={selectedService}
      onSave={handleSave}
      locations={locations}
      setFormMode={setFormMode}
    />
  ) : formMode === "add" ? (
    <ServiceForm
      formMode={formMode}
      onSave={handleAdd}
      locations={locations}
      selectedService={null}
      setFormMode={setFormMode}
    />
  ) : (
    <EmptyStateComponent text={"Select a category to view sub-categories"} />
  );
};

export default ServiceFormWrapper;
