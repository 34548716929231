import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Button from "../core/Button";
import restaurant from "../../assets/restaurant.svg";
import { useNavigate } from "react-router-dom";
import RenderStar from "../../utils/RenderStar";
import cityService from "../../services/city/cityService";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";
import Modal from "components/core/Modal";
import { selectRole } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
const priceSymbols = ["$", "$$", "$$$"];

const LocationCard = ({ data, onDelete }) => {
  const [id, setId] = useState();
  const [name, setName] = useState({ en: "", ar: "" });
  const [category, setCategory] = useState({ name: { en: "", ar: "" } });
  const [subcategory, setSubcategory] = useState([]);
  const [images, setImages] = useState([]);
  const [city, setCity] = useState({ name: { en: "", ar: "" } });
  const [priceRange, setPriceRange] = useState("$");
  const [availability, setAvailability] = useState([]);
  const [fullStars, setFullStars] = useState(0);
  const [halfStar, setHalfStar] = useState(false);
  const [emptyStars, setEmptyStars] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const userRole = useSelector(selectRole);
  const navigate = useNavigate();

  const hasAccess = (roles) => {
    if (userRole === undefined || !userRole) {
      return false;
    }
    return roles.includes(userRole);
  };

  const handleDelete = async () => {
    const response = await locationService.deleteLocation(id);
    switch (response.statusCode) {
      case 404:
        showError(response.message);
        break;
      case 400:
        showError(response.message);
        break;
      default:
        showSuccess("Location deleted successfully");
        setIsDeleteModalOpen(false);
        onDelete();
        break;
    }
  };

  const fetchData = () => {
    data.priceRange && setPriceRange(priceSymbols[data.priceRange]);
    data.id && setId(data.id);
    data.name && setName(data.name);
    data.category && setCategory(data.category);
    data.subcategories && setSubcategory(data.subcategories);
    data.logo && setImages(data.logo);
    data.city && setCity(data.city);
    data.availability && setAvailability(data.availability);
    data.reviews && setReviewCount(data.reviews.length);
    if (data.averageRating) {
      const rating = data.averageRating;
      const fullStars = Math.floor(rating);
      const halfStar = rating % 1 !== 0;
      const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
      setFullStars(fullStars);
      setHalfStar(halfStar);
      setEmptyStars(emptyStars);
    } else {
      setFullStars(0);
      setHalfStar(false);
      setEmptyStars(5);
    }
  };
  useEffect(() => {
    fetchData();
  }, [data]);

  const findOpeningHoursForToday = (availabilityData) => {
    if (!availabilityData || !availabilityData.days) return "";
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const currentDay = new Date().getDay(); // Bugünün haftanın kaçıncı günü olduğunu al (0 Pazar, 6 Cumartesi)

    // Gün adını bul
    const todayName = daysOfWeek[currentDay];

    // Gün adına göre uygun günü bul
    const todaySchedule = availabilityData.days.find(
      (day) => day.name === todayName
    );

    if (todaySchedule && todaySchedule.schedule.length > 0) {
      const { startTime, endTime } = todaySchedule.schedule[0];
      return { startTime, endTime };
    } else {
      return null;
    }
  };

  return (
    <div className="flex items-center justify-between w-full h-full py-4 mt-4 overflow-hidden bg-white rounded-lg shadow">
      <div className="flex w-1/2 h-full ml-4">
        <div className="mr-5 max-w-52">
          <img
            src={images ? images : restaurant}
            alt="restaurant"
            className="rounded-l-lg w-52 h-52"
          />
        </div>
        <div>
          <h2 className="mb-2 text-24 font-medium text-[#000000D9]">{`
           ${name.en !== undefined && name.en}
          `}</h2>
          <div className="flex items-center mb-2">
            <RenderStar
              fullStars={fullStars}
              halfStar={halfStar}
              emptyStars={emptyStars}
              starColor="text-[#DE0607]"
            />
            <p className="ml-2 text-[#000000D9] text-14 font-normal leading-6">
              {reviewCount} Reviews
            </p>
          </div>
          <p className="text-14 mt-2 font-medium text-[#000000D9] leading-6">
            {priceRange} <span className="text-[#67666D] mx-2">•</span>{" "}
            {category.name.en !== undefined ? category.name.en : ""}
            <span className="text-[#67666D] mx-2">•</span>
            {subcategory.length === 0
              ? ""
              : subcategory.map((sub, index) => {
                  return (
                    <span key={index}>
                      {sub.name.en}
                      {index < subcategory.length - 1 ? " - " : ""}
                    </span>
                  );
                })}
          </p>
          <p className="text-14 mt-1 font-medium text-[#000000D9]">
            {city.name.en !== undefined ? city.name.en : ""}
          </p>
          {findOpeningHoursForToday(availability) ? (
            <p className="text-14 mt-1 font-normal text-[#00000073]">
              Open:
              <span className="ml-2 text-[#000000D9] text-14">
                Today {findOpeningHoursForToday(availability).startTime} -{" "}
                {findOpeningHoursForToday(availability).endTime}
              </span>
            </p>
          ) : (
            <p className="text-14 mt-1 font-normal text-[#00000073]">Closed</p>
          )}
        </div>
      </div>

      <div className="flex flex-col items-end justify-between p-4 h-52">
        {hasAccess(["ADMIN", "SUPERADMIN"]) ? (
          <FaTrashAlt
            className="w-4 h-4 text-gray-400 cursor-pointer hover:text-red-600"
            onClick={() => setIsDeleteModalOpen(true)}
          />
        ) : (
          <div></div>
        )}
        <Button
          className="w-40 h-10 text-sm bg-[#DE0607] text-white rounded-xl hover:bg-[#9F060F]"
          textColor="text-white"
          label={"More Info"}
          onClick={() => navigate(`/locations/${id}`)}
        />
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        headerText={"Delete Location"}
      >
        <div className="p-4">
          <p className="text-lg font-medium">
            Are you sure you want to delete this location?
          </p>
          <div className="flex items-center justify-end mt-4">
            <Button
              label={"Cancel"}
              className="w-24 bg-white border border-[#FFA39E] rounded-xl text-black"
              textColor="text-black"
              onClick={() => setIsDeleteModalOpen(false)}
            />
            <Button
              label={"Delete"}
              className="w-24 ml-4 bg-[#DE0607] rounded-xl text-white"
              textColor="text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LocationCard;
