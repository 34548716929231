import React, { useEffect, useState } from "react";
import ServicesList from "../components/ServicesList";
import locationService from "services/location/locationService";
import { showError } from "utils/helpers";

const ServicesPage = ({ data, refreshData }) => {
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [subserviceIds, setSubserviceIds] = useState([]);

  useEffect(() => {
    if (!data) return;
    if (data.subserviceIds) setSubserviceIds(data.subserviceIds);
    if (data.services) setServices(data.services);
    if (data.subservices) setSubServices(data.subservices);
  }, [data]);

  return (
    <div className="space-y-8">
      {services.map((service, index) => {
        if (!service.businessId) return null;
        return (
          <ServicesList
            category={service.name.en}
            services={subServices.filter((sub) => sub.serviceId === service.id)}
            key={index}
            subserviceIds={subserviceIds}
            locationId={data.id}
            refreshData={refreshData}
          />
        );
      })}
    </div>
  );
};

export default ServicesPage;
