import React, { useEffect, useMemo, useState } from "react";
import AvailabilityPage from "./AvailabilityPage";
import InfoLocationPage from "./InfoLocationPage";
import { useNavigate } from "react-router-dom";
import Button from "components/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocationData,
  selectLocation,
  selectLocationAvailability,
  selectUploadedImageFile,
} from "../../../redux/location/locationSlice";
import photoService from "services/photoService";
import { base64ToFile, showError, showSuccess } from "utils/helpers";
import locationService from "services/location/locationService";
import useUsers from "utils/hooks/useUsers";
import { LocationAddSchema } from "utils/schemas/Location/LocationSchemas";
import businessService from "services/business/businessService";

const AddPage = () => {
  const [activeTab, setActiveTab] = useState("Info");
  const { fetchProfile } = useUsers();
  const locationData = useSelector(selectLocation);
  const availabilityData = useSelector(selectLocationAvailability);
  const imageData = useSelector(selectUploadedImageFile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = useMemo(
    () => [
      {
        name: "Info",
        component: <InfoLocationPage />,
      },
      {
        name: "Availability",
        component: <AvailabilityPage />,
      },
    ],
    []
  );

  const addLocation = async (mergedData) => {
    try {
      const res = await locationService.addLocation(mergedData);
      switch (res.statusCode) {
        case 201:
          showSuccess(res.message);
          dispatch(clearLocationData());
          navigate("/locations");
          break;
        default:
          showError(res.response.data.message || res.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleAddLocation = async () => {
    const userProfileData = await fetchProfile();
    let businessId;
    switch (userProfileData.roles[0]) {
      case "ADMIN":
        businessId = userProfileData.adminBusinessId;
        break;
      case "PROVIDER":
        businessId = userProfileData.businessId;
        break;
      default:
        businessId = 0;
        break;
    }

    const businessResponse = await businessService.getBusiness(businessId);

    let newInfoData = {
      name: locationData.name,
      description: locationData.description,
      cityId: locationData.city.id,
      businessId: businessId,
      categoryId: businessResponse.business.categoryId,
      subcategoryIds: locationData.subcategories.map((sub) => sub.id),
      subserviceIds: [],
      images: locationData.images ? locationData.images : [],
      logo: locationData.logo ? locationData.logo : "",
      phone: locationData.phoneNumbers.map((phone) => phone.number),
      email: locationData.email,
      whatsapp: locationData.whatsapp ? locationData.whatsapp : null,
      instagram: locationData.instagram,
      website: locationData.website,
      address: locationData.address ? locationData.address : "",
      priceRange: locationData.priceRange
        ? parseInt(locationData.priceRange)
        : null,
      amenities: locationData.amenities
        ? locationData.amenities.map((amenity) => amenity.value)
        : [],
    };
    let tmpAvailabilityData = !Array.isArray(availabilityData)
      ? null
      : availabilityData.map((day) => {
          return {
            name: day.day.toUpperCase(),
            schedule: day.slots.map((slot) => {
              return {
                startTime: slot.startsAt,
                endTime: slot.finishesAt,
              };
            }),
          };
        });

    let newAvailabilityData = {
      days: tmpAvailabilityData,
    };

    try {
      let mergedData = { ...newInfoData, availability: newAvailabilityData };
      await LocationAddSchema.validate(mergedData);
      await addLocation(mergedData);
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="h-full mt-4 bg-white rounded-md">
      <div className="flex p-5 space-x-4 bg-white border-b ">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`p-2 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="p-5">
        {tabs.find((tab) => tab.name === activeTab)?.component}
        <div className="flex items-center justify-end w-full p-4">
          <Button
            label={"Cancel"}
            className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
            textColor="text-black"
            onClick={() => {
              dispatch(clearLocationData());
              navigate("/locations");
            }}
          />
          <Button
            label={"Save"}
            className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
            textColor="text-white"
            onClick={handleAddLocation}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPage;
