import React, { useMemo, useState } from "react";
import AvailabilityPage from "./AvailabilityPage";
import InfoListingPage from "./InfoListingPage";
import RatingAndReviews from "./RatingAndReviews";

const ListingDetails = () => {
  const [activeTab, setActiveTab] = useState("Info");

  const tabs = useMemo(
    () => [
      {
        name: "Overview",
        component: <InfoListingPage />,
      },
      {
        name: "Availability",
        component: <AvailabilityPage />,
      },
      {
        name: "Rating and Reviews",
        component: <RatingAndReviews />,
      },

    ],
    []
  );

  return (
    <div className="mt-4 overflow-hidden bg-white rounded-md">
      <div className="flex p-5 space-x-4 bg-white border-b ">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`p-2 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="p-5">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
    </div>
  );
};

export default ListingDetails;
