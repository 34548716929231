import React, { useEffect, useState } from "react";
import DaySchedule from "./DaySchedule";
import { showError } from "utils/helpers";
import Button from "components/core/Button";

const daysOfWeek = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const WeeklySchedule = ({
  scheduleData,
  setScheduleData,
  isDayEnabledData,
  setIsDayEnabledData,
  onSave = null,
  onCancel = null,
  disabled = false,
}) => {
  const [schedule, setSchedule] = useState([]);

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const isOverlapping = (day, start, end, index = -1) => {
    const daySchedule = schedule.find((d) => d.day === day);
    if (!daySchedule) return false;

    for (let i = 0; i < daySchedule.slots.length; i++) {
      if (i === index) continue;
      const slot = daySchedule.slots[i];
      const slotStart = timeToMinutes(slot.startsAt);
      const slotEnd = timeToMinutes(slot.finishesAt);
      if (
        (timeToMinutes(start) < slotEnd && timeToMinutes(start) >= slotStart) ||
        (timeToMinutes(end) > slotStart && timeToMinutes(end) <= slotEnd)
      ) {
        return true;
      }
    }
    return false;
  };

  const removeTimeSlot = (day, index) => {
    setScheduleData((schedule) =>
      schedule.map((scheduleDay) =>
        scheduleDay.day === day
          ? {
              ...scheduleDay,
              slots: scheduleDay.slots.filter(
                (_, slotIndex) => slotIndex !== index
              ),
            }
          : scheduleDay
      )
    );
  };

  const addTimeSlot = (day) => {
    let slot = schedule.find((slot) => slot.day === day);
    let newSlot;
    if (!slot) {
      newSlot = { day: day, slots: [{ startsAt: "", finishesAt: "" }] };
    } else {
      newSlot = {
        day: day,
        slots: [...slot.slots, { startsAt: "", finishesAt: "" }],
      };
    }
    let newSchedule = schedule.filter((slot) => slot.day !== day);
    newSchedule.push(newSlot);
    setScheduleData(newSchedule);

    setIsDayEnabledData((prev) => ({ ...prev, [day]: true }));
  };

  const updateTimeSlot = (day, index, startsAt, finishesAt) => {
    if (isOverlapping(day, startsAt, finishesAt, index)) {
      showError("Time slots cannot overlap on the same day.");
      return;
    }

    let slot = schedule.find((slot) => slot.day === day);
    let newSlots = slot.slots.map((slot, i) =>
      i === index ? { startsAt, finishesAt } : slot
    );
    let newSlot = { day: day, slots: newSlots };
    let newSchedule = schedule.filter((slot) => slot.day !== day);
    newSchedule.push(newSlot);
    setScheduleData(newSchedule);
  };

  const handleSaveSchedule = () => {
    const filteredSchedule = schedule.filter(
      (slot) => isDayEnabledData[slot.day]
    );

    if (filteredSchedule.length === 0) {
      return onSave([]);
    }

    const hasEmptyTimeSlots = filteredSchedule.some((slot) =>
      slot.slots.some((timeSlot) => !timeSlot.startsAt || !timeSlot.finishesAt)
    );

    if (hasEmptyTimeSlots) {
      showError("Please fill in all time slots.");
      return;
    }

    const isCheckedButEmpty = filteredSchedule.some(
      (slot) => slot.slots.length === 0 && isDayEnabledData[slot.day]
    );

    if (isCheckedButEmpty) {
      showError("Please add at least one time slot for active days.");
      return;
    }

    onSave(filteredSchedule);
  };

  useEffect(() => {
    if (scheduleData) {
      setSchedule(scheduleData);
    }
    if (isDayEnabledData) {
      setIsDayEnabledData(isDayEnabledData);
    }
  }, [scheduleData, isDayEnabledData]);

  return (
    <div className="container p-4 mx-auto my-6">
      <div className="hidden py-2  md:flex md:w-full text-left text-16  max-w-[600px] text-[#000000D9]">
        <div className="min-w-40 w-fit">
          <h3 className="font-semibold ">Day</h3>
        </div>
        <div className="min-w-36 w-fit">
          <h3 className="font-semibold ">Starts at</h3>
        </div>
        <div className="min-w-36 w-fit">
          <h3 className="font-semibold ">Finishes at</h3>
        </div>
        <div className=""></div>
      </div>
      <div className="flex flex-col gap-8">
        {daysOfWeek.map((day) => (
          <DaySchedule
            key={day}
            dayName={day}
            slots={schedule.find((slot) => slot.day === day)}
            addTimeSlot={() => {
              addTimeSlot(day);
            }}
            removeTimeSlot={(index) => removeTimeSlot(day, index)}
            updateTimeSlot={updateTimeSlot}
            setIsDayEnabled={(value) => {
              setIsDayEnabledData((prev) => ({ ...prev, [day]: value }));
              setScheduleData((prev) => {
                if (value) {
                  let newSchedule = prev.filter((slot) => slot.day !== day);
                  newSchedule.push({
                    day: day,
                    slots: [{ startsAt: "", finishesAt: "" }],
                  });
                  return newSchedule;
                } else {
                  return prev.filter((slot) => slot.day !== day);
                }
              });
            }}
            isDayEnabled={isDayEnabledData[day]}
            roleDisabled={disabled}
          />
        ))}
      </div>

      {onSave && !disabled && (
        <div className="flex items-center justify-end w-full p-4 bg-white ">
          <Button
            label="Cancel"
            className={
              "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
            }
            textColor="text-[#000000D9]"
            onClick={onCancel}
          />
          <Button
            label="Save"
            className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
            textColor="text-white"
            onClick={handleSaveSchedule}
          />
        </div>
      )}
    </div>
  );
};

export default WeeklySchedule;
