import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import AgeGroup from "constants/enum/ageGroup";

export const showSuccess = (message = "Success", timeout = 2000) => {
  toast(message, {
    id: "success-toast",
    position: "top-center",
    icon: <FaCheckCircle className="h-5 text-green-400" />,
    className:
      "text-xs sm:text-sm leading-5 font-medium text-green-600 bg-green-50 lg:max-w-md 2xl:max-w-lg",
    duration: timeout,
  });
};

export const showError = (error, timeout = 2000) => {
  toast(error, {
    id: "error-toast",
    position: "top-center",
    icon: <RiCloseCircleFill className="h-5 text-red-500" />,
    className:
      "text-xs sm:text-sm leading-5 font-medium text-red-600 bg-red-100 lg:max-w-md 2xl:max-w-lg",
    duration: timeout,
  });
};

// export function base64ToBlob(base64, mimeType) {
//   const byteCharacters = atob(base64.split(",")[1]);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512);
//     const byteNumbers = new Array(slice.length);

//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   return new Blob(byteArrays, { type: mimeType });
// }

// export function base64ToFile(base64String, filename) {
//   const arr = base64String.split(",");
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const blob = base64ToBlob(base64String, mime);
//   return new File([blob], filename, { type: mime });
// }

export function base64ToBlob(base64, mimeType) {
  // base64 string'in doğru biçimde geldiğini kontrol et
  if (!base64) {
    throw new Error("Invalid input: base64 string is null or undefined.");
  }

  const base64ContentArray = base64.split(",");
  if (base64ContentArray.length !== 2) {
    throw new Error("Invalid base64 string.");
  }

  const byteCharacters = atob(base64ContentArray[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

export function base64ToFile(base64String, filename) {
  // base64 string'in doğru biçimde geldiğini kontrol et
  if (!base64String) {
    throw new Error("Invalid input: base64String is null or undefined.");
  }

  const arr = base64String.split(",");
  if (arr.length < 2 || !arr[0].includes(":")) {
    throw new Error("Invalid base64 string format.");
  }

  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) {
    throw new Error("MIME type not found in base64 string.");
  }

  const mime = mimeMatch[1];
  const blob = base64ToBlob(base64String, mime);
  return new File([blob], filename, { type: mime });
}

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Okuma işlemi tamamlandığında tetiklenecek event
    reader.onload = function (event) {
      resolve(event.target.result); // Base64 string olarak dönüş yapılır
    };

    // Okuma işlemi sırasında hata oluşursa
    reader.onerror = function (error) {
      reject(error);
    };

    // File objesini Data URL (base64) olarak oku
    reader.readAsDataURL(file);
  });
}

export const showInfo = (message, timeout = 2000) => {
  toast(message, {
    id: "info-toast",
    position: "top-center",
    icon: <FaInfoCircle className="h-5 text-blue-500" />,
    className:
      "text-xs sm:text-sm leading-5 font-medium text-blue-600 bg-blue-50 lg:max-w-md 2xl:max-w-lg",
    duration: timeout,
  });
};

export function getAgeGroupString(ageGroup) {
  switch (ageGroup) {
    case AgeGroup.UNDER_18:
      return "Under 18";
    case AgeGroup.AGE_18_25:
      return "18-25";
    case AgeGroup.AGE_26_35:
      return "26-35";
    case AgeGroup.AGE_36_45:
      return "36-45";
    case AgeGroup.AGE_46_60:
      return "46-60";
    case AgeGroup.AGE_60_PLUS:
      return "60+";
    default:
      return "Unknown";
  }
}

export function convertAgeGroupStringToEnum(ageGroup) {
  switch (ageGroup) {
    case "Under 18":
      return AgeGroup.UNDER_18;
    case "18-25":
      return AgeGroup.AGE_18_25;
    case "26-35":
      return AgeGroup.AGE_26_35;
    case "36-45":
      return AgeGroup.AGE_36_45;
    case "46-60":
      return AgeGroup.AGE_46_60;
    case "60+":
      return AgeGroup.AGE_60_PLUS;
    default:
      return AgeGroup.UNDER_18;
  }
}

export function convertAgeToAgeGroup(age) {
  if (age < 18) {
    return "UNDER_18";
  } else if (age >= 18 && age <= 25) {
    return "AGE_18_25";
  } else if (age >= 26 && age <= 35) {
    return "AGE_26_35";
  } else if (age >= 36 && age <= 45) {
    return "AGE_36_45";
  } else if (age >= 46 && age <= 60) {
    return "AGE_46_60";
  } else {
    return "AGE_60_PLUS";
  }
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts.map((part) => part[0]).join("");
  return initials.toUpperCase();
};

export const convertToISOString = (dateString) => {
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset() * 60000;
  const utcDate = new Date(date.getTime() - offset);
  return utcDate.toISOString().replace("Z", "") + "Z";
};
