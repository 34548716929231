import React, { useEffect, useState } from "react";
import DeliveryFeeList from "./DeliveryFeeList";
import SelectInput from "components/core/SelectInput";
import Button from "components/core/Button";
import InputField from "components/core/inputField";
import useCity from "utils/hooks/useCity";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";
import { useSelector } from "react-redux";
import { selectRole } from "../../../redux/auth/authSlice";

const LocationForm = ({ data, setData, isHomeService, onSave }) => {
  const { cities: cityOptions } = useCity();
  const [locationName, setLocationName] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [timeBlockedBeforeHomeService, setTimeBlockedBeforeHomeService] =
    useState("");
  const [timeBeforeUnit, setTimeBeforeUnit] = useState({
    label: "Minutes",
    value: "minutes",
  });
  const [timeBlockedAfterHomeService, setTimeBlockedAfterHomeService] =
    useState("");
  const [timeAfterUnit, setTimeAfterUnit] = useState({
    label: "Minutes",
    value: "minutes",
  });
  const [feesData, setFeesData] = useState([]);
  const userRole = useSelector(selectRole);

  const hasPermission = (roles) => {
    if (userRole === undefined || !userRole) {
      return false;
    }
    return roles.includes(userRole);
  };

  const timeSlotOptions = [
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
  ];

  const formatTimeAndUnit = (time, valueSet, unitSet) => {
    if (time > 60) {
      valueSet(time / 60);
      unitSet({ label: "Hours", value: "hours" });
    } else {
      valueSet(time);
      unitSet({ label: "Minutes", value: "minutes" });
    }
  };

  useEffect(() => {
    if (!data) return;
    setLocationName(`
        ${data.name.en} ${data.name.ar ? `- ${data.name.ar}` : ""}
      `);
    setMinAmount(data.minPurchaseAmount);
    formatTimeAndUnit(
      data.timeBlockedBefore,
      setTimeBlockedBeforeHomeService,
      setTimeBeforeUnit
    );
    formatTimeAndUnit(
      data.timeBlockedAfter,
      setTimeBlockedAfterHomeService,
      setTimeAfterUnit
    );
    setFeesData(
      Array.isArray(data.settings) &&
        data.settings.map((item) => {
          return {
            id: item.id,
            cities: item.cities.map((city) => {
              return {
                value: city.slug,
                label: city.name.en,
                id: city.id,
              };
            }),
            deliveryFee: item.deliveryFee,
          };
        })
    );
  }, [data]);

  const formatTime = (time, unit) => {
    if (unit.value === "hours") {
      return time * 60;
    }
    return time;
  };

  const handleSave = async () => {
    const newData = {
      minPurchaseAmount: parseInt(minAmount),
      timeBlockedBefore: parseInt(
        formatTime(timeBlockedBeforeHomeService, timeBeforeUnit)
      ),
      timeBlockedAfter: parseInt(
        formatTime(timeBlockedAfterHomeService, timeAfterUnit)
      ),
    };
    try {
      const response = await locationService.updateLocation(data.id, newData);
      switch (response.statusCode) {
        case 200:
          showSuccess("Location updated successfully");
          setData(response.location);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="px-5">
      <p className="text-16 font-medium leading-6 text-[#000000D9]">
        Location {locationName}
      </p>
      <div className="w-full mt-6 border-b" />
      {hasPermission(["ADMIN"]) && (
        <DeliveryFeeList
          cityOptions={cityOptions.map((city) => {
            return { value: city.slug, label: city.name.en, id: city.id };
          })}
          feesData={feesData}
          setData={setData}
          locationId={data?.id}
          onSave={onSave}
        />
      )}

      <div className="w-full mt-4 border-b" />
      {isHomeService && (
        <div className="w-full mt-5 space-y-4">
          <div className="flex w-full space-x-2">
            <p className="text-[#000000D9] text-14 font-normal leading max-w-[200px] w-full">
              Minimum purchase amount:
            </p>
            <InputField
              placeholder={"30"}
              type="text"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              inputClassName={" h-9"}
              className="w-full"
              disabled={!hasPermission(["ADMIN"])}
            />
          </div>
          <div className="flex w-full space-x-2">
            <p className="text-[#000000D9] text-14 font-normal leading-20 max-w-[200px] w-full">
              Time blocked before home service:
            </p>
            <InputField
              placeholder={"30"}
              type="number"
              value={timeBlockedBeforeHomeService}
              onChange={(e) => setTimeBlockedBeforeHomeService(e.target.value)}
              inputClassName={" w-full h-9"}
              className="w-full"
              disabled={!hasPermission(["ADMIN"])}
            />
            <SelectInput
              options={timeSlotOptions}
              onSelect={(value) => setTimeBeforeUnit(value)}
              selected={timeBeforeUnit}
              inputClassName=" h-9"
              className="w-full mt-1"
              isDisabled={!hasPermission(["ADMIN"])}
            />
          </div>
          <div className={`flex w-full space-x-2`}>
            <p className="text-[#000000D9] text-14 font-normal leading-20 max-w-[200px] w-full">
              Time blocked after home service:
            </p>
            <InputField
              placeholder={"30"}
              type="number"
              value={timeBlockedAfterHomeService}
              onChange={(e) => setTimeBlockedAfterHomeService(e.target.value)}
              inputClassName={" h-9"}
              className="w-full"
              disabled={!hasPermission(["ADMIN"])}
            />
            <SelectInput
              options={timeSlotOptions}
              onSelect={(value) => setTimeAfterUnit(value)}
              selected={
                timeSlotOptions.find(
                  (option) => option.value === timeAfterUnit
                ) || timeAfterUnit
              }
              inputClassName="h-9"
              className="w-full mt-1"
              isDisabled={!hasPermission(["ADMIN"])}
            />
          </div>
          <div
            className={`flex items-center justify-end w-full py-6 bg-white
          ${!hasPermission(["ADMIN"]) ? "hidden" : ""}
        `}
          >
            <Button
              label="Cancel"
              className={
                "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
              }
              textColor="text-[#000000D9]"
              onClick={() => {}}
            />
            <Button
              label="Save"
              className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
              textColor="text-white"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationForm;
