import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import discountServices from "services/discount/discountServices";
import { showError } from "utils/helpers";

const useDiscount = () => {
  const [discounts, setDiscounts] = useState(0);
  const businessId = useSelector(selectBusinessId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await discountServices.getDiscounts(businessId);
        switch (response.statusCode) {
          case 200:
            setDiscounts(response.discounts);
            break;
          default:
            showError(response.message);
            break;
        }
      } catch (error) {
        showError(error.message);
      }
    };
    fetchData();
  }, []);

  return { discounts };
};

export default useDiscount;
