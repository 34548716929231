import React, { useEffect, useState } from "react";
import LocationList from "./LocationComponents/LocationList";
import LocationForm from "./LocationComponents/LocationForm";
import useLocations from "utils/hooks/useLocations";
import Button from "components/core/Button";
import userService from "services/user/userService";
import { useParams } from "react-router-dom";
import { showError, showSuccess } from "utils/helpers";
import providersService from "services/providers/providersService";
import { providerEditAvailabilitySchema } from "utils/schemas/Provider/ProviderSchemas";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../../redux/auth/authSlice";

const ProviderLocations = ({ data, setData }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeLocationIds, setActiveLocationIds] = useState([]);
  const [locations, setLocations] = useState([]);
  const { fetchVendorLocations } = useLocations();
  const businessId = useSelector(selectBusinessId);
  const [schedule, setSchedule] = useState([]);
  const { id } = useParams();

  const [isDayEnabled, setIsDayEnabled] = useState({
    SUNDAY: false,
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
  });

  const handleSelectLocation = (location) => {
    if (selectedLocation && selectedLocation.id === location.id) {
      setSelectedLocation(null);
      setSchedule([]);
      setIsDayEnabled({
        SUNDAY: false,
        MONDAY: false,
        TUESDAY: false,
        WEDNESDAY: false,
        THURSDAY: false,
        FRIDAY: false,
        SATURDAY: false,
      });

      return;
    }
    const foundItem = Array.isArray(data.availability)
      ? data.availability.find((item) => item.locationId === location.id)
      : null;

    if (!foundItem) {
      setSchedule([]);
      setIsDayEnabled({
        SUNDAY: false,
        MONDAY: false,
        TUESDAY: false,
        WEDNESDAY: false,
        THURSDAY: false,
        FRIDAY: false,
        SATURDAY: false,
      });
      setSelectedLocation(location);
      return;
    }

    let newSchedule = foundItem.days.map((day) => {
      return {
        day: day.name,
        slots: day.schedule.map((slot) => {
          return {
            startsAt: slot.startTime,
            finishesAt: slot.endTime,
          };
        }),
      };
    });

    let newIsDayEnabled = {
      SUNDAY: false,
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
    };
    data.availability
      .find((item) => item.locationId === location.id)
      .days.forEach((day) => {
        newIsDayEnabled[day.name] = true;
      });

    setSchedule(newSchedule);
    setIsDayEnabled(newIsDayEnabled);
    setSelectedLocation(location);
  };

  const handleSave = async () => {
    // Map through existing data to create a base structure
    let prevData = data.availability.map((item) => ({
      locationId: item.locationId,
      days: item.days.map((day) => ({
        name: day.name,
        schedule: day.schedule.map((slot) => ({
          startTime: slot.startTime,
          endTime: slot.endTime,
        })),
      })),
    }));

    // Check if the selected location is already in prevData
    const existingLocationIndex = prevData.findIndex(
      (item) => item.locationId === selectedLocation.id
    );

    // Construct the days array for the selected location
    const daysForSelectedLocation = schedule
      .filter((day) => isDayEnabled[day.day]) // First, filter out days that are not enabled
      .map((day) => ({
        // Then, map over the filtered days to construct the day objects
        name: day.day,
        schedule: day.slots.map((slot) => ({
          startTime: slot.startsAt,
          endTime: slot.finishesAt,
        })),
      }));

    if (existingLocationIndex === -1) {
      // If the selected location isn't in prevData, and days array isn't empty, add it
      if (daysForSelectedLocation.length > 0) {
        prevData.push({
          locationId: selectedLocation.id,
          days: daysForSelectedLocation,
        });
      }
    } else {
      // If the selected location is in prevData, update it
      if (daysForSelectedLocation.length > 0) {
        prevData[existingLocationIndex] = {
          ...prevData[existingLocationIndex],
          days: daysForSelectedLocation,
        };
      } else {
        // If the days array is empty, remove the location from prevData
        prevData.splice(existingLocationIndex, 1);
      }
    }

    // Construct the final data structure for submission
    let newData = {
      availability: prevData,
    };

    let mergedData = { ...data, ...newData };
    try {
      await providerEditAvailabilitySchema.validate(newData);
      const response = await providersService.updateProvider(id, mergedData);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setData(response.user);
          break;
        default:
          showError(response.message);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleCancel = () => {
    setSelectedLocation(null);
    setSchedule([]);
    setIsDayEnabled({
      SUNDAY: false,
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
    });
  };

  useEffect(() => {
    if (!data) return;
    if (data.activeLocations && data.activeLocations.length > 0) {
      setActiveLocationIds(
        data.activeLocations.map((location) => {
          if (location !== null) {
            return location.id;
          }
        })
      );
    }
    if (businessId) {
      fetchVendorLocations(businessId).then((data) => {
        setLocations(data);
      });
    }
  }, [data, businessId]);

  return (
    <div className="h-full ">
      <div className="flex flex-row bg-white">
        <div className="w-2/5 rounded-md border-r border-[#0000000F] min-h-[500px] h-full">
          <p className="text-16 px-4 font-medium leading-6 text-[#000000D9]">
            {locations.length} Locations
          </p>
          <div className="w-full mt-6 border-b" />
          {locations.length > 0 ? (
            <LocationList
              fetchedLocations={locations}
              selectedLocation={selectedLocation}
              activeLocationIds={activeLocationIds}
              onSelect={handleSelectLocation}
            />
          ) : (
            <p className="text-16 font-medium leading-6 text-[#000000D9]">
              No Location found
            </p>
          )}
        </div>

        <div className="w-3/5 ml-2 rounded-md shadow">
          {selectedLocation ? (
            <LocationForm
              schedule={schedule}
              setSchedule={setSchedule}
              isDayEnabled={isDayEnabled}
              setIsDayEnabled={setIsDayEnabled}
              location={selectedLocation}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-1/2">
              <p className="text-16 font-medium leading-6 text-[#000000D9]">
                Select a location to view or edit details
              </p>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex items-center justify-end w-full p-4 bg-white ">
        <Button
          label="Cancel"
          className={
            "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
          }
          textColor="text-[#000000D9]"
          onClick={() => {
          }}
        />
        <Button
          label="Save"
          className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
          textColor="text-white"
          onClick={handleSave}
        />
      </div> */}
    </div>
  );
};

export default ProviderLocations;
