import SelectInput from "components/core/SelectInput";
import InputField from "components/core/inputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookings,
  selectLocationId,
  setSpecificBookingData,
} from "../../../../../redux/bookings/bookingsSlice";
import useLocations from "utils/hooks/useLocations";
import Button from "components/core/Button";
import { DetailsTabSchema } from "utils/schemas/Booking/BookingSchemas";
import { showError } from "utils/helpers";

const DetailsTab = ({ hide }) => {
  const [location, setLocation] = useState({});
  const { vendorLocations } = useLocations();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState({ value: "", label: "" });
  const [age, setAge] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({ value: "", label: "" });
  const [paymentLink, setPaymentLink] = useState({ value: "", label: "" });
  const selectedReduxLocationId = useSelector(selectLocationId);
  const bookingsReduxData = useSelector(selectBookings);
  const dispatch = useDispatch();
  const genderOptions = [
    {
      value: "MALE",
      label: "Male",
    },
    {
      value: "FEMALE",
      label: "Female",
    },
  ];

  const paymentLinkOptions = [
    {
      value: "OFFLINE",
      label: "Offline",
    },
    {
      value: "LINK",
      label: "Payment link",
    },
  ];

  const paymentMethodOptions = [
    {
      value: "CREDIT_CARD",
      label: "Credit Card",
    },
    {
      value: "KNET",
      label: "Knet",
    },
    {
      value: "APPLE_PAY",
      label: "Apple Pay",
    },
    {
      value: "GOOGLE_PAY",
      label: "Google Pay",
    },
    {
      value: "SAMSUNG_PAY",
      label: "Samsung Pay",
    },
  ];

  const handleChange = (state, value, field) => {
    if (!value) {
      state("");
      dispatch(setSpecificBookingData({ field, value: null }));
      return;
    }
    state(value);
    dispatch(setSpecificBookingData({ field, value }));
  };

  useEffect(() => {
    if (selectedReduxLocationId != null && vendorLocations.length > 0) {
      let foundLocation = vendorLocations.find(
        (location) => location.id === selectedReduxLocationId
      );
      if (!foundLocation) {
        setLocation(null);
        return;
      }
      let formattedLocation = {
        value: foundLocation.id,
        label: foundLocation.name.en || foundLocation.name.ar,
      };
      dispatch(
        setSpecificBookingData({
          field: "locationData",
          value: foundLocation,
        })
      );
      dispatch(
        setSpecificBookingData({
          field: "location",
          value: formattedLocation,
        })
      );
      setLocation(formattedLocation);
    } else {
      setLocation(null);
    }
  }, [selectedReduxLocationId, vendorLocations]);

  useEffect(() => {
    if (bookingsReduxData) {
      setPhoneNumber(bookingsReduxData.userPhoneNumber);
      setName(bookingsReduxData.userName);
      setEmail(bookingsReduxData.userEmail);
      setGender(bookingsReduxData.userGender);
      setAge(bookingsReduxData.userAge);
      setPaymentMethod(bookingsReduxData.paymentMethod);
      setLocation(bookingsReduxData.location);
      setPaymentLink(bookingsReduxData.paymentLink);
    }
  }, [bookingsReduxData]);

  return (
    <div className="flex flex-col justify-between w-full space-y-5">
      <SelectInput
        label="Location"
        selected={location}
        onSelect={(location) => {
          handleChange(setLocation, location, "location");
          location &&
            location.settings &&
            location.settings.length > 0 &&
            dispatch(
              setSpecificBookingData({
                field: "locationSettings",
                value: location.settings,
              })
            );
          location
            ? dispatch(
                setSpecificBookingData({
                  field: "locationId",
                  value: location.value,
                })
              )
            : dispatch(
                setSpecificBookingData({ field: "locationId", value: null })
              );
        }}
        options={vendorLocations.map((location) => ({
          ...location,
          value: location.id,
          label: location.name.en || location.name.ar,
        }))}
        placeholder="Select Location"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
        inputClassName="!text-16 font-normal !text-[#000000D9]"
        isRequired
      />
      <InputField
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) =>
          handleChange(setPhoneNumber, e.target.value, "userPhoneNumber")
        }
        placeholder="Enter Phone Number"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
        inputClassName="!text-16 font-normal !text-[#000000D9]"
        isRequired
      />

      <InputField
        label="Customer Name"
        value={name}
        onChange={(e) => handleChange(setName, e.target.value, "userName")}
        placeholder="Enter Name"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
        isRequired
      />
      <InputField
        label="Email"
        value={email}
        onChange={(e) => handleChange(setEmail, e.target.value, "userEmail")}
        placeholder="Enter Email"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
        isRequired
      />
      <SelectInput
        label={"Gender"}
        selected={gender}
        onSelect={(value) => {
          handleChange(setGender, value, "userGender");
        }}
        options={genderOptions}
        placeholder="Select a gender"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
      />
      <SelectInput
        label="Age"
        selected={age}
        onSelect={(value) => handleChange(setAge, value, "userAge")}
        options={[
          { value: "UNDER_18", label: "Under 18" },
          { value: "AGE_18_25", label: "18-24" },
          { value: "AGE_26_35", label: "25-34" },
          { value: "AGE_36_45", label: "35-44" },
          { value: "AGE_46_60", label: "45-59" },
          { value: "AGE_60_PLUS", label: "60+" },
        ]}
        placeholder="Select Age"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
      />

      <SelectInput
        label="Payment Method"
        selected={paymentLink}
        onSelect={(value) => handleChange(setPaymentLink, value, "paymentLink")}
        options={paymentLinkOptions}
        placeholder="Select Payment Method"
        labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
        isRequired
      />
      {paymentLink && paymentLink.value === "LINK" && (
        <SelectInput
          label="Payment Link"
          selected={paymentMethod}
          onSelect={(value) =>
            handleChange(setPaymentMethod, value, "paymentMethod")
          }
          options={paymentMethodOptions}
          placeholder="Select Payment Link"
          labelClassName="!text-12 min-w-52 font-bold !text-[#00000073] justify-start"
          isRequired
        />
      )}

      <div className="absolute bottom-0 flex justify-end w-full h-20 mt-4 space-x-2 right-6 ">
        <Button
          label="Cancel"
          className={
            "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
          }
          textColor="text-black"
          onClick={hide}
        />
        <Button
          label="Next"
          className={"w-40 bg-[#DE0607] text-white rounded-lg"}
          textColor="text-white"
          onClick={async () => {
            if (
              paymentLink &&
              paymentLink.value === "LINK" &&
              paymentMethod === null
            ) {
              showError("Please select a payment method");
              return;
            }
            await DetailsTabSchema.validate({
              name,
              phoneNumber,
              email,
              gender,
              age,
              paymentMethod,
              paymentLink,
              location,
            })
              .then(() => {
                dispatch(
                  setSpecificBookingData({
                    field: "activeTab",
                    value: "Services",
                  })
                );
              })
              .catch((err) => {
                showError(err.message);
              });
          }}
        />
      </div>
    </div>
  );
};

export default DetailsTab;
