import LocationListItem from "./LocationListItem";

const LocationList = ({
  fetchedLocations,
  selectedLocation,
  onSelect,
  locationData,
}) => {
  return (
    <div className="mt-6 ">
      {fetchedLocations.map((location) => (
        <LocationListItem
          key={location.id}
          location={location}
          isSelected={selectedLocation?.id === location.id}
          onSelect={() => onSelect(location)}
          locationData={locationData.find(
            (data) => data.locationId === location.id
          )}
        />
      ))}
    </div>
  );
};

export default LocationList;
