import React, { useState, useEffect } from "react";
import InputField from "../../core/inputField";
import MultiSelectInput from "../../core/select/MultiSelectInput";
import Checkbox from "../../core/Checkbox";
import ServicesList from "./ServiceList";
import Button from "components/core/Button";
import InputFieldWithLanguage from "../ServicesTab/InputFieldWithLanguage";
import { selectRole } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ServiceCategoryForm = ({
  formMode,
  selectedService = null,
  onSave,
  locations = [],
}) => {
  const [serviceName, setServiceName] = useState({ en: "", ar: "" });
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [assignedServices, setAssignedServices] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const userRole = useSelector(selectRole);

  const handleSave = () => {
    onSave({
      name: serviceName,
      locations: selectedLocations,
    });
  };

  useEffect(() => {
    if (selectedService) {
      setServiceName(selectedService.name);
      setAssignedServices(selectedService.subservices);
    }
    if (locations && selectedService && formMode === "edit") {
      setLocationsOptions(
        locations.map((location) => {
          return {
            id: location.id,
            value: location.slug,
            label: location.name.en,
          };
        })
      );
      let addLocations = [];
      locations.map((location) => {
        location.services.map((service) => {
          if (selectedService.id === service.id) {
            addLocations.push({
              id: location.id,
              value: location.slug,
              label: location.name.en,
            });
          }
        });
      });
      setSelectedLocations(addLocations);
    }
    if (formMode === "add") {
      setServiceName({ en: "", ar: "" });
      setSelectedLocations([]);
      setAssignedServices([]);
      setLocationsOptions(
        locations.map((location) => {
          return {
            id: location.id,
            value: location.slug,
            label: location.name.en,
          };
        })
      );
    }
  }, [selectedService, formMode, locations]);

  return (
    <div className="flex flex-col h-full px-4 bg-white border rounded-md shadow-md">
      {/* Header */}
      <div className="mt-4">
        <p className="text-16 font-medium leading-6 text-[#000000D9]">
          {formMode === "add"
            ? "Add new service category"
            : "Edit service category"}
        </p>
      </div>

      <div className="w-full mt-6 border-b" />

      <div className="mt-6">
        <h2 className="mb-2 text-lg font-semibold">Basic info</h2>
        <p className="text-sm text-gray-500">Add a service category name</p>
      </div>
      <div className="flex items-start justify-between w-full space-x-2">
        <label
          className="pt-2 text-sm font-bold text-left text-gray-700 min-w-44"
          htmlFor="name"
        >
          Service Category Name:
        </label>
        <div className="flex flex-col items-center w-full mb-6 space-y-4">
          <InputFieldWithLanguage
            placeholder="Enter the name"
            language={"En"}
            onChange={(e) => {
              setServiceName({ ...serviceName, en: e.target.value });
            }}
            value={serviceName.en || ""}
            className={"w-full"}
            labelClassName={"!w-14"}
            isDisabled={userRole === "PROVIDER"}
          />
          <InputFieldWithLanguage
            placeholder="أدخل الاسم"
            language={"Ar"}
            onChange={(e) => {
              setServiceName({ ...serviceName, ar: e.target.value });
            }}
            value={serviceName.ar || ""}
            className={"w-full"}
            labelClassName={"!w-14"}
            dir="rtl"
            isDisabled={userRole === "PROVIDER"}
          />
        </div>
      </div>
      <div className="w-full my-5 border-b" />
      <p className="mb-2 text-lg font-semibold">Locations</p>
      <p className="mb-5 text-sm text-gray-500">
        Choose location where this service category provided
      </p>
      <Checkbox
        label={"All"}
        checked={allSelected}
        onChange={() => {
          setAllSelected(!allSelected);
          setSelectedLocations(
            allSelected ? [] : locationsOptions.map((option) => option)
          );
        }}
        disabled={userRole === "PROVIDER"}
      />
      <MultiSelectInput
        options={locationsOptions}
        selectedValues={
          allSelected
            ? locationsOptions.map((option) => option)
            : selectedLocations
        }
        checkboxClassName={
          "grid grid-cols-2 space-y-0 gap-4 h-fit items-center justify-start"
        }
        className={"w-full space-x-2 h-fit mt-4"}
        onChange={setSelectedLocations}
        disabled={userRole === "PROVIDER"}
      />

      <div className="w-full my-5 border-b" />
      <div className="mt-6">
        <h3 className="mb-2 text-lg font-semibold">Assigned services</h3>
        <ServicesList services={assignedServices} />
      </div>

      {/* Footer with actions */}
      <div className="flex items-center justify-end w-full p-4">
        <Button
          label={"Cancel"}
          className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
          textColor="text-black"
          onClick={() => {}}
        />
        <Button
          label={"Save"}
          className={`w-40 ml-4  rounded-xl 
          ${
            userRole === "PROVIDER"
              ? "!cursor-not-allowed bg-gray-300 text-white"
              : "bg-[#DE0607] text-white"
          }
            `}
          textColor="text-white"
          onClick={userRole !== "PROVIDER" ? handleSave : null}
        />
      </div>
    </div>
  );
};

export default ServiceCategoryForm;
