import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { showError } from "utils/helpers";

const {
  default: businessService,
} = require("services/business/businessService");

const useBusinesses = () => {
  const [businesses, setBusinesses] = useState([]);
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const userBusinessId = useSelector(selectBusinessId);

  const fetchBusinesses = async () => {
    try {
      const response = await businessService.getBusinesses();
      switch (response.statusCode) {
        case 200:
          setBusinesses(response.businesses);
          break;
        default:
          showError(response.message);
          setBusinesses([]);
          break;
      }
    } catch (error) {
      showError("Failed to fetch businesses. Please try again.");
    }
  };

  const fetchBusiness = async (id) => {
    try {
      const response = await businessService.getBusiness(id);
      switch (response.statusCode) {
        case 200:
          setBusiness(response.business);
          break;
        default:
          showError(response.message);
          setBusiness(null);
          break;
      }
    } catch (error) {
      showError("Failed to fetch business. Please try again.");
    }
  };

  useEffect(() => {
    fetchBusinesses();
    userBusinessId && fetchBusiness(userBusinessId);
  }, []);

  const refresh = () => {
    fetchBusinesses();
  };

  return { businesses, refresh, business, fetchBusiness };
};

export default useBusinesses;
