import React, { useEffect, useState } from "react";
import { IoTrash } from "react-icons/io5";
import Button from "./Button";
import { HiOutlinePlus } from "react-icons/hi";

const PhoneNumberInput = ({
  id,
  value,
  onChange,
  isValid,
  onRemove,
  canRemove,
  labelClassName,
  disabled,
  isRequired,
}) => {
  return (
    <div>
      <div className="flex items-center w-full h-8 mt-2 space-x-2">
        <label
          htmlFor={id}
          className={`w-1/5 h-full justify-start font-bold text-right text-gray-700 capitalize text-14 min-w-32
              ${labelClassName}
            `}
        >
          Phone number: {isRequired && <span className="text-red-500">*</span>}
        </label>
        <input
          id={id}
          type="tel"
          value={value}
          onChange={(e) => onChange(e.target.value)} // Simplify event handling
          className={`block h-full w-full p-2 border rounded-md shadow-sm sm:text-sm focus:outline-none ${
            isValid
              ? "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              : "border-red-500 focus:ring-red-500 focus:border-red-500"
          }`}
          placeholder="+965 12345678"
          disabled={disabled}
        />
        {canRemove && (
          <button
            onClick={onRemove}
            className="flex items-center justify-center w-8 h-full border border-[#D9D9D9] text-white rounded-md"
            disabled={disabled}
          >
            <IoTrash className="text-red-500" size={16} />
          </button>
        )}
      </div>
      {!isValid && (
        <span className="text-sm text-red-500">
          Please enter a valid phone number.
        </span>
      )}
    </div>
  );
};

const MultiplePhoneNumbers = ({
  className = "",
  phoneNumbers = [],
  setPhoneNumbers,
  labelClassName,
  disabled,
  isRequired,
}) => {
  // Initialize with at least one empty phone number if none provided
  const [numbers, setNumbers] = useState(
    phoneNumbers.length > 0
      ? phoneNumbers
      : [{ id: Date.now(), number: "", isValid: true }]
  );

  const handlePhoneNumberChange = (id, newNumber) => {
    setNumbers(
      numbers.map((phone) =>
        phone.id === id
          ? {
              ...phone,
              number: newNumber,
              isValid: validatePhoneNumber(newNumber),
            }
          : phone
      )
    );
    setPhoneNumbers(
      numbers.map((phone) =>
        phone.id === id
          ? {
              ...phone,
              number: newNumber,
              isValid: validatePhoneNumber(newNumber),
            }
          : phone
      )
    );
  };

  const validatePhoneNumber = (number) => {
    const regex = /^\+965[1-9]\d{7}$/;
    return regex.test(number);
  };

  const addPhoneNumber = () => {
    setNumbers([...numbers, { id: Date.now(), number: "", isValid: true }]);
  };

  const removePhoneNumber = (index) => {
    setNumbers(numbers.filter((_, i) => i !== index));
  };

  useEffect(() => {
    phoneNumbers.length > 0
      ? setNumbers(phoneNumbers)
      : setNumbers([{ id: Date.now(), number: "", isValid: true }]);
  }, [phoneNumbers]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {numbers.map((phone, index) => (
        <PhoneNumberInput
          key={phone.id}
          id={`phone_number_${index}`}
          value={phone.number}
          onChange={(newNumber) => handlePhoneNumberChange(phone.id, newNumber)}
          isValid={phone.isValid}
          onRemove={() => removePhoneNumber(index)}
          canRemove={numbers.length > 1} // Can remove if more than one phone number
          labelClassName={labelClassName}
          disabled={disabled}
          isRequired={isRequired}
        />
      ))}
      <div
        className={`flex w-full mt-2 mb-4
          ${disabled ? "hidden" : ""}
        `}
      >
        <div className="w-1/5 min-w-32" />
        <Button
          icon={<HiOutlinePlus size={14} />}
          onClick={addPhoneNumber}
          className={`h-full text-white shadow-none w-fit`}
          label="Add phone number"
          labelStyle="text-[10px]"
        />
      </div>
    </div>
  );
};

export default MultiplePhoneNumbers;
