import React, { useEffect, useMemo, useState } from "react";
import AddProfile from "./AddProfile";
import AddLocations from "./AddLocations";
import AddServices from "./AddServices";
import Button from "components/core/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProviderData,
  selectProvider,
  setSpecificProviderData,
} from "../../../redux/provider/providerSlice";
import { showError, showSuccess } from "utils/helpers";
import providersService from "services/providers/providersService";
import useUsers from "utils/hooks/useUsers";
import { providerSchema } from "utils/schemas/Provider/ProviderSchemas";

const AddProvider = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const { fetchProfile } = useUsers();
  const providerData = useSelector(selectProvider);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const processLocations = (data) => {
    return data
      .filter((location) => location.days && location.days.length > 0)
      .map((location) => ({
        ...location,
        days: location.days.map((day) => ({
          name: day.name,
          schedule: day.slots,
        })),
      }));
  };

  const tabs = useMemo(
    () => [
      {
        name: "Profile",
        component: <AddProfile />,
      },
      {
        name: "Locations",
        component: <AddLocations />,
      },
      {
        name: "Services",
        component: <AddServices />,
      },
    ],
    [activeTab, providerData]
  );

  const handleAddProvider = async () => {
    try {
      let oldAvailabilities = providerData.availabilities;
      const availability = processLocations(oldAvailabilities);
      const data = {
        email: providerData.email,
        phone: providerData.phone,
        name: providerData.name,
        avatarPath: providerData.logo,
        availability: availability,
        subserviceIds: providerData.subserviceIds,
        speciality: providerData.speciality,
        businessId: providerData.businessId,
      };

      await providerSchema.validate(data);
      const response = await providersService.addProvider(data);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          navigate("/providers");
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  // useEffect(() => {
  //   fetchProfile().then((data) => {
  //     dispatch(
  //       setSpecificProviderData({
  //         field: "businessId",
  //         value:
  //           data.roles[0] === "PROVIDER"
  //             ? data.businessId
  //             : data.adminBusinessId,
  //       })
  //     );
  //   });
  // }, []);

  return (
    <div className="h-full mt-4 bg-white rounded-md">
      <div className="flex p-5 space-x-4 bg-white border-b ">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`p-2 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="mt-10 ml-4">
        {tabs.find((tab) => tab.name === activeTab)?.component}
        <div className="flex items-center justify-end w-full h-20 space-x-4">
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={() => {
              dispatch(clearProviderData());
              navigate("/providers");
            }}
          />
          <Button
            label="Save"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={handleAddProvider}
          />
        </div>
      </div>
    </div>
  );
};

export default AddProvider;
