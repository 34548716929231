import React from "react";

// This component expects a 'title' prop to display
const TitleComponent = ({ title }) => {
  return (
    <div className="flex items-center justify-center py-2 space-x-4">
      <div className="flex-1 border-t border-gray-300"></div>
      <h1 className="text-14 font-bold whitespace-nowrap text-[#00000073]">
        {title}
      </h1>
      <div className="flex-1 border-t border-gray-300"></div>
    </div>
  );
};

export default TitleComponent;
