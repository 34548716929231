import * as Yup from "yup";

// Genel hata mesajları
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;
const incorrectMessage = (field) => `Incorrect ${field}`;
const alreadyExistsMessage = (field) =>
  `${field} already exists in the system. ${field} must be unique`;

// Email regex pattern
const emailRegex =
  /^[A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Location Manager Add Schema
export const locationManagerSchema = Yup.object({
  name: Yup.string()
    .required(requiredMessage("Name"))
    .matches(/^[A-Za-z\s]+$/, invalidMessage("Name. Only letters are allowed"))
    .min(1, requiredMessage("Name. Minimum 1 character required"))
    .max(20, requiredMessage("Name. Maximum 20 characters allowed"))
    .required(requiredMessage("Name")),
  email: Yup.string()
    .nullable()
    .test("email-required", requiredMessage("Email address"), function (value) {
      return value !== "";
    })
    .matches(emailRegex, incorrectMessage("Email address"))
    .max(75, invalidMessage("Email address"))
    .required(requiredMessage("Email address")),
  phone: Yup.string()
    .nullable()
    .test("phone-required", requiredMessage("Phone number"), function (value) {
      return value !== "";
    })
    .matches(/^\+965[1-9]\d{7}$/, invalidMessage("Phone number"))
    .required(requiredMessage("Phone number")),
  locationIds: Yup.array().min(1, "At least one location must be selected"),
});

// Location Manager Edit Schema
export const locationManagerEditSchema = Yup.object({
  name: Yup.string()
    .required(requiredMessage("Name"))
    .matches(/^[A-Za-z\s]+$/, invalidMessage("Name. Only letters are allowed"))
    .min(1, requiredMessage("Name"))
    .max(20, invalidMessage("Name. Maximum 20 characters allowed"))
    .required(requiredMessage("Name")),
  locationIds: Yup.array(),
});
