import CategoryForm from "../CategoryForm";
import EmptyStateComponent from "../../EmptyStateComponents";

const CategoryFormWrapper = ({
  formMode,
  selectedCategory,
  handleSave,
  handleAdd,
  handleCancel,
}) => {
  if (formMode === "edit") {
    return (
      <CategoryForm
        category={selectedCategory}
        icon={selectedCategory?.images[0] ?? ""}
        subcategories={selectedCategory?.subcategories}
        id={selectedCategory?.id}
        onSave={(category) => {
          handleSave(category);
        }}
        onCancel={handleCancel}
      />
    );
  } else if (formMode === "add") {
    return (
      <CategoryForm
        category={""}
        icon={""}
        subcategories={[]}
        onSave={(category) => {
          handleAdd(category);
        }}
        onCancel={handleCancel}
      />
    );
  } else {
    return (
      <EmptyStateComponent text={"Select a category to view sub-categories"} />
    );
  }
};

export default CategoryFormWrapper;
