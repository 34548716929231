import React, { useEffect, useState } from "react";
import useLocations from "utils/hooks/useLocations";
import { showError, showSuccess } from "utils/helpers";
import LocationList from "./LocationComponents/LocationList";
import LocationForm from "./LocationComponents/LocationForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAvailabilities,
  selectAvailabilities,
  selectProvider,
  updateAvailabilitiesByLocation,
} from "../../../redux/provider/providerSlice";
import Button from "components/core/Button";
import { selectBusinessId } from "../../../redux/auth/authSlice";

const AddLocations = () => {
  const availabilities = useSelector(selectAvailabilities);
  const [locationData, setLocationData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const userBusinessId = useSelector(selectBusinessId);
  const { fetchVendorLocations } = useLocations();
  const [schedule, setSchedule] = useState([]);
  const dispatch = useDispatch();

  const [isDayEnabled, setIsDayEnabled] = useState({
    SUNDAY: false,
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
  });

  const handleSelectLocation = (location) => {
    if (locationData.length > 0) {
      setSelectedLocation(location);
      let selected = locationData.find((loc) => loc.locationId === location.id);
      if (selected) {
        let data =
          selected.days &&
          selected.days.map((day) => {
            return {
              day: day.name ?? "",
              slots:
                Array.isArray(day.slots) &&
                day.slots.length > 0 &&
                day.slots.map((slot) => {
                  return {
                    startsAt: slot.startTime,
                    finishesAt: slot.endTime,
                  };
                }),
            };
          });
        setSchedule(data);
        setIsDayEnabled((prev) => {
          let newState = {};
          for (let key in prev) {
            newState[key] = data.find((day) => day.day === key) ? true : false;
          }
          return newState;
        });
      } else {
        setSelectedLocation(location);
        setSchedule([]);
        setIsDayEnabled({
          SUNDAY: false,
          MONDAY: false,
          TUESDAY: false,
          WEDNESDAY: false,
          THURSDAY: false,
          FRIDAY: false,
          SATURDAY: false,
        });
      }
    } else {
      setSelectedLocation(location);
      setSchedule([]);
      setIsDayEnabled({
        SUNDAY: false,
        MONDAY: false,
        TUESDAY: false,
        WEDNESDAY: false,
        THURSDAY: false,
        FRIDAY: false,
        SATURDAY: false,
      });
    }
  };

  useEffect(() => {
    if (selectedLocation && schedule.length > 0) {
      let data = {
        locationId: selectedLocation.id,
        days: schedule.map((day) => {
          if (!isDayEnabled[day.day] || !day.slots) return;
          return {
            name: day.day,
            slots:
              day.slots &&
              day.slots.map((slot) => {
                return {
                  startTime: slot.startsAt,
                  endTime: slot.finishesAt,
                };
              }),
          };
        }),
      };
      dispatch(updateAvailabilitiesByLocation(data));
    }
    if (selectedLocation && schedule.length === 0) {
      let data = {
        locationId: selectedLocation.id,
        days: [],
      };
      dispatch(updateAvailabilitiesByLocation(data));
    }
  }, [schedule]);

  useEffect(() => {
    if (availabilities.length > 0) {
      let data = availabilities.map((availability) => {
        return {
          locationId: availability.locationId,
          days: availability.days,
        };
      });
      setLocationData(data);
    } else {
      setLocationData([]);
    }
  }, [availabilities]);

  useEffect(() => {
    if (userBusinessId) {
      fetchVendorLocations(userBusinessId).then((data) => {
        setLocations(data);
      });
    }
  }, [userBusinessId]);

  return (
    <div className="h-full ">
      <div className="flex flex-row bg-white">
        <div className="w-2/5 rounded-md border-r border-[#0000000F] min-h-[500px] h-full">
          <p className="text-16 px-4 font-medium leading-6 text-[#000000D9]">
            {locations.length} Locations
          </p>
          <div className="w-full mt-6 border-b" />
          {locations.length > 0 ? (
            <LocationList
              fetchedLocations={locations}
              selectedLocation={selectedLocation}
              locationData={locationData}
              onSelect={handleSelectLocation}
            />
          ) : (
            <p className="text-16 font-medium leading-6 text-[#000000D9]">
              No Location found
            </p>
          )}
        </div>

        <div className="w-3/5 ml-2 rounded-md shadow">
          {selectedLocation ? (
            <LocationForm
              schedule={schedule}
              setSchedule={setSchedule}
              isDayEnabled={isDayEnabled}
              setIsDayEnabled={setIsDayEnabled}
              location={selectedLocation}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-1/2">
              <p className="text-16 font-medium leading-6 text-[#000000D9]">
                Select a location to view or edit details
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full mt-6 border-b" />
    </div>
  );
};

export default AddLocations;
