import React from "react";

// RadioButton component
const RadioButton = ({ value, checked, onChange, name, label, disabled }) => {
  return (
    <label className="inline-flex items-center space-x-2">
      <input
        type="radio"
        className="text-red-700 form-radio"
        style={{ accentColor: "#DE0607" }}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
