import * as Yup from "yup";

// General error messages
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;
const incorrectMessage = (field) => `Incorrect ${field}`;
const finishLaterThanStartMessage = "Finish time must be later than start time";
const noOverlappingTimeSlotsMessage = "No overlapping time slots allowed";

// Email regex pattern
const emailRegex =
  /^[A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Time range validation schema
const timeRangeSchema = Yup.object().shape({
  startTime: Yup.string().required(requiredMessage("Start time")),
  endTime: Yup.string()
    .required(requiredMessage("End time"))
    .test("is-greater", finishLaterThanStartMessage, function (value) {
      const { startTime } = this.parent;
      return value > startTime;
    }),
});

// Daily time ranges validation schema
const dailyTimeRangesSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage("Day")),
  schedule: Yup.array()
    .of(timeRangeSchema)
    .test("no-overlapping", noOverlappingTimeSlotsMessage, function (schedule) {
      const seenRanges = [];
      return schedule.every(({ startTime, endTime }) => {
        const overlap = seenRanges.some(
          (range) =>
            (startTime >= range.startTime && startTime < range.endTime) ||
            (endTime > range.startTime && endTime <= range.endTime)
        );
        if (!overlap) {
          seenRanges.push({ startTime, endTime });
          return true;
        }
        return false;
      });
    }),
});

// Availability validation schema
const availabilitySchema = Yup.array().of(
  Yup.object().shape({
    locationId: Yup.number().required(requiredMessage("Location ID")),
    days: Yup.array()
      .of(dailyTimeRangesSchema)
      .required(requiredMessage("Days")),
  })
);

// Provider Add validation schema
export const providerSchema = Yup.object({
  email: Yup.string()
    .required(requiredMessage("Email address"))
    .matches(emailRegex, incorrectMessage("Email address"))
    .max(75, invalidMessage("Email address")),
  phone: Yup.string()
    .required(requiredMessage("Phone number"))
    .matches(
      /^\+965[1-9]\d{7}$/,
      invalidMessage("Phone number. Must be in the format +965XXXXXXXX")
    ),
  name: Yup.string()
    .matches(
      /^[A-Za-z0-9\s]+$/,
      invalidMessage("Name. Only letters and numbers are allowed")
    )
    .min(1, requiredMessage("Name"))
    .max(50, invalidMessage("Name. Maximum 50 characters allowed"))
    .required(requiredMessage("Name")),
  locations: Yup.array().min(1, "At least one location must be selected"),
  speciality: Yup.string().required(requiredMessage("Speciality")),
  availability: availabilitySchema,
});

// Provider Edit validation schema
export const providerEditProfileSchema = Yup.object({
  name: Yup.string()
    .matches(
      /^[A-Za-z0-9\s]+$/,
      invalidMessage("Name. Only letters and numbers are allowed")
    )
    .min(1, requiredMessage("Name"))
    .max(50, invalidMessage("Name. Maximum 50 characters allowed"))
    .required(requiredMessage("Name")),
  speciality: Yup.string().required(requiredMessage("Speciality")),
});

export const providerEditAvailabilitySchema = Yup.object({
  availability: availabilitySchema,
});

export const providerEditServicesSchema = Yup.object({
  subserviceIds: Yup.array().min(1, "At least one service must be selected"),
});
