import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PiCalendarBlank } from "react-icons/pi";
import moment from "moment-timezone";
import { showError } from "utils/helpers";
import bookingService from "services/booking/bookingService";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectRole } from "../../../redux/auth/authSlice";

const DatePickerComponent = ({
  startDate,
  setStartDate,
  className,
  showTimeSelect = false,
  startDateDisabled = false,
  availability = [],
  textClassName = "",
  providerId,
  serviceDuration,
  locationId,
  type,
  onConfirm,
}) => {
  const pickerRef = useRef(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [open, setOpen] = useState(false);
  const userRole = useSelector(selectRole);

  useEffect(() => {
    if (startDate) {
      fetchAvailableTimes(startDate);
    }
  }, [startDate]);

  const fetchAvailableTimes = async (date) => {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const response = await bookingService.checkAvailability(
        providerId,
        serviceDuration,
        formattedDate,
        locationId,
        type !== null ? type.toUpperCase() : null
      );

      if (response.statusCode === 200) {
        let times = response.availableTimes.map((time) =>
          moment(time).utc(false)
        );
        const now = new Date();

        if (moment(date).isSame(moment(), "day")) {
          times = times.filter((time) => {
            if (time.hours() > now.getHours()) {
              return time;
            } else if (
              time.hours() === now.getHours() &&
              time.minutes() > now.getMinutes()
            ) {
              return time;
            }
          });
        }

        const startTime = moment(startDate).utc(false);
        const timeExists = times.some(
          (time) =>
            time.hours() === startTime.hours() &&
            time.minutes() === startTime.minutes()
        );

        if (!timeExists) {
          times.push(startTime);
          times.sort((a, b) => a - b);
        }

        if (times.length === 0) {
          showError("No availability left on that day");
          setStartDate(null);
          setAvailableTimes([]);
        } else {
          setAvailableTimes(times);
        }
      } else {
        showError("No availability left on that day");
        setStartDate(null);
        setAvailableTimes([]);
      }
    } catch (error) {
      showError("No availability left on that day");
      setStartDate(null);
      setAvailableTimes([]);
    }
  };

  const isAvailableDate = (date) => {
    const dayName = date
      .toLocaleDateString("en-US", { weekday: "long" })
      .toUpperCase();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    return (
      date >= today && availability[0].days.some((day) => day.name === dayName)
    );
  };

  const handleDateChange = (date) => {
    const momentDate = moment(date).utc(false).add(3, "hours").toDate();
    setStartDate(momentDate);
    fetchAvailableTimes(momentDate);
  };

  const includeTimes = availableTimes
    .map((time) => {
      if (time instanceof moment && !isNaN(time)) {
        const selectedDate = moment(startDate)
          .set({
            hour: time.hours(),
            minute: time.minutes(),
            second: 0,
            millisecond: 0,
          })
          .toDate();
        return selectedDate;
      }
      return null;
    })
    .filter(Boolean)
    .filter((time) => {
      if (!startDate) return true;
      const today = new Date();
      if (
        startDate.getDate() === today.getDate() &&
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear()
      ) {
        return time > today;
      }
      return true;
    });

  return (
    <div
      className={`flex items-center justify-between overflow-hidden border border-gray-300 rounded-md ${className}`}
    >
      <DatePicker
        ref={pickerRef}
        selected={moment(startDate).utc(false).add(-3, "hours").toDate()}
        onChange={handleDateChange}
        selectsStart
        readOnly
        shouldCloseOnSelect={false}
        showYearDropdown
        showMonthDropdown
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? "MMM d, yyyy hh:mm aa" : "MMM d, yyyy"}
        timeFormat="HH:mm"
        dropdownMode="select"
        startDate={startDate}
        className={`w-full mr-10 text-16 text-[#000000D9] font-normal
            ${textClassName}
          ${startDateDisabled && "bg-gray-100 cursor-not-allowed"}`}
        placeholderText="Start date"
        disabled={startDateDisabled || userRole === "PROVIDER"}
        onInputClick={() => setOpen(true)}
        onClickOutside={() => setOpen(false)}
        open={open}
        filterDate={isAvailableDate}
        includeTimes={includeTimes}
        minDate={new Date()} // Prevent selecting past dates
      >
        {startDate && (
          <div className={`flex items-center justify-end w-full `}>
            <button
              className="px-1 py-1 ml-2 text-white bg-[#DE0607] rounded"
              onClick={() => {
                if (onConfirm) {
                  let selectedDate = moment(startDate).utc(false);
                  onConfirm(selectedDate.toISOString());
                  setOpen(false);
                }
              }}
            >
              OK
            </button>
          </div>
        )}
      </DatePicker>
      <span className={`px-2 text-gray-500 flex items-center `}>
        {startDate && <div className="h-6 w-[1px] bg-gray-300 mr-[6px]" />}
        <PiCalendarBlank
          size={24}
          onClick={() => {
            userRole !== "PROVIDER" && setOpen(!open);
          }}
        />
      </span>
    </div>
  );
};

export default DatePickerComponent;
