import React, { useEffect, useMemo, useState } from "react";
import BookingsTab from "./BookingsTab";
import OverviewTab from "./OverviewTab";
import SalesTab from "./SalesTab";
import analyticsService from "services/analytics/analyticsService";
import { showError } from "utils/helpers";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import SelectInput from "components/core/SelectInput";
import { useSelector } from "react-redux";
import { selectBusinessId, selectRole } from "../../redux/auth/authSlice";
import locationService from "services/location/locationService";
import locationManagerService from "services/locationManager/locationManagerService";
import SimpleDatePicker from "components/core/Input/SimpleDatePicker";

const Analytics = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [analytics, setAnalytics] = useState([]);
  const [sales, setSales] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [dates, setDates] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      .toISOString()
      .split("T")[0],
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toISOString()
      .split("T")[0],
  ]);
  const userBusinessId = useSelector(selectBusinessId);

  const fetchAnalytics = async (dates, locationIds) => {
    // setLoading(true);
    try {
      if (new Date(dates[1]) < new Date(dates[0])) {
        const tomorrow = new Date(
          new Date(dates[0]).getTime() + 24 * 60 * 60 * 1000
        );
        dates[1] = tomorrow.toISOString().split("T")[0];
        setDates(dates);
      }

      const data = await analyticsService.getAnalytics(dates, locationIds);
      switch (data.statusCode) {
        case 200:
          setAnalytics(data.data.dashboard);
          setSales(data.data.sales);
          setBookings(data.data.bookings);
          // setLoading(false);
          break;
        default:
          showError(data.message);
          setAnalytics([]);
          // setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setAnalytics([]);
      // setLoading(false);
      return;
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const queryResponse = await locationService.getLocationWQuery(
        `businessId=${userBusinessId}`
      );
      switch (queryResponse.statusCode) {
        case 200:
          const locationData = queryResponse.locations;
          setLocationIds(
            locationData.map((location) => {
              return {
                value: location.id,
                label: location.name.en,
              };
            })
          );
          fetchAnalytics(
            dates,
            locationData.map((location) => location.id)
          );
          setLoading(false);
          break;
        default:
          showError(queryResponse.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        name: "Overview",
        component: <OverviewTab analyticsData={analytics} />,
      },
      {
        name: "Bookings",
        component: <BookingsTab bookingsData={bookings} />,
      },
      {
        name: "Sales",
        component: <SalesTab salesData={sales} />,
      },
    ];
  }, [analytics, sales, bookings]);

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="h-full mt-4 bg-white rounded-md ">
        <div className="flex items-center justify-between w-full p-5 border-b">
          <div className="flex space-x-4 bg-white ">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={`p-2 ${
                  activeTab === tab.name
                    ? " text-[#DE0607] border-b border-[#DE0607]"
                    : "text-[#000000d9]"
                }`}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </div>
          <div className="flex items-center space-x-4">
            <SelectInput
              selected={selectedLocations}
              options={locationIds}
              onSelect={(selected) => {
                setSelectedLocations(selected);
                fetchAnalytics(
                  dates,
                  selected.map((location) => location.value)
                );
              }}
              isMulti
            />
          </div>

          <div className="flex items-center space-x-4">
            <SimpleDatePicker
              startDate={dates[0]}
              setStartDate={(date) => {
                let formattedDate = new Date(date).toISOString().split("T")[0];
                setDates([formattedDate, dates[1]]);
                fetchAnalytics(
                  [formattedDate, dates[1]],
                  locationIds.map((location) => location.value)
                );
              }}
              endDate={dates[1]}
              setEndDate={(date) => {
                let formattedDate = new Date(date).toISOString().split("T")[0];
                setDates([dates[0], formattedDate]);
                fetchAnalytics(
                  [dates[0], formattedDate],
                  locationIds.map((location) => location.value)
                );
              }}
              showEndDate
            />
          </div>
        </div>
        <div className="p-5 ">
          {tabs.find((tab) => tab.name === activeTab)?.component}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default Analytics;
