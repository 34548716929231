import api from "../apiConfig";

const analyticsService = {
  // GET request to retrieve analytics
  getAnalytics: async (dates, locationIds) => {
    try {
      const response = await api.get(
        `/analytics?dates=[${dates.map(
          (date) => `"${date}"`
        )}]&locationIds=[${locationIds}]`
      );
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getDashboardAnalytics: async (dates) => {
    try {
      let startDate = JSON.stringify(dates[0]);
      let endDate = JSON.stringify(dates[1]);
      const response = await api.get(
        `/analytics/dashboard?dates=[${startDate},${endDate}]`
      );
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getVendorAnalytics: async (id) => {
    try {
      const response = await api.get(`/analytics/vendor/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
};

export default analyticsService;
