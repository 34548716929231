// src/components/FormField.js
import React from "react";

const FormField = ({
  label,
  icon,
  placeholder,
  type,
  name,
  value,
  onChange,
  className,
  endIcon,
}) => {
  return (
    <div className={`flex flex-col mb-4 ${className}`}>
      {label && (
        <label htmlFor={name} className="mb-1 text-gray-700">
          {label}
        </label>
      )}
      <div className="relative flex">
        <span className="inline-flex items-center px-3 text-sm text-gray-600 bg-white border-t border-b border-l border-gray-300 rounded-l-md">
          {icon}
        </span>
        <input
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="flex-1 w-full py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-l-0 border-gray-300 shadow-sm appearance-none rounded-r-md"
        />
        {endIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
            {endIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormField;
