import React, { useEffect, useState } from "react";
import InputField from "../core/inputField";
import { CiSearch } from "react-icons/ci";
import { showError } from "utils/helpers";
import InvoicesTable from "./components/InvoicesTable";
import InvoiceDetails from "./components/InvoiceDetails/InvoiceDetails";
import { parseISO, format } from "date-fns";
import invoicesService from "services/invoices/invoicesService";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import useLocations from "utils/hooks/useLocations";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import subserviceServices from "services/service/subserviceServices";

const Invoices = () => {
  const { fetchLocationName, fetchLocation } = useLocations();
  const [onlineData, setOnlineData] = useState([]);
  const userBusinessId = useSelector(selectBusinessId);
  const [constData, setContData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [startDate, setStartDate] = useState(
    format(new Date(), "yyyy-MM-dd").toString()
  );
  const [endDate, setEndDate] = useState(
    format(new Date(), "yyyy-MM-dd").toString()
  );
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", {
      month: "short",
      timeZone: "UTC",
    });
    const year = date.getUTCFullYear();
    const hour = date.getUTCHours().toString().padStart(2, "0");
    const minute = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedDate = `${day} ${month} ${year} ${formattedHour}:${minute} ${ampm} UTC`;
    return formattedDate;
  }

  function formatDateStringOnlyDate(dateString) {
    const date = parseISO(dateString);
    return format(date, "dd MMM yyyy");
  }

  const fetchInvoices = async () => {
    try {
      if (userBusinessId == null) {
        showError(
          "You don't have a business, so you don't have any invoices to list!"
        );
        return;
      }
      if (startDate && endDate) {
        const startDateFormat = format(
          parseISO(startDate),
          "yyyy-MM-dd"
        ).toString();
        const endDateFormat = format(
          parseISO(endDate),
          "yyyy-MM-dd"
        ).toString();
        const response = await invoicesService.getInvoices(
          startDateFormat,
          endDateFormat
        );
        switch (response.statusCode) {
          case 200:
            let invoices = response.invoices;
            let formattedData = invoices.map((invoice) => {
              let bookingRelatedData = invoice.bookings.map((booking) => {
                return {
                  no: invoice.id,
                  customerName: booking.userName,
                  paymentDate: formatDateString(invoice.payment.createdAt),
                  bookingDateTime: formatDateString(booking.date),
                  amount: booking.totalPrice,
                  locationId: booking.locationId,
                  address: booking.address,
                  phoneNumber: booking.userPhoneNumber,
                  subserviceIds: booking.subserviceIds,
                  childsubserviceIds: booking.childSubserviceIds,
                  paymentType: invoice.payment.paymentMethod,
                  notes: booking.notes,
                  isDeposit: invoice.payment.deposit,
                  depositAmount: invoice.depositAmount,
                  deliveryFee: booking.deliveryFee,
                  destination: booking.bookingDestination,
                };
              });
              return bookingRelatedData;
            });
            setOnlineData(formattedData.flat());
            setContData(formattedData.flat());
            break;
          default:
            showError(response.message);
            break;
        }
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    fetchInvoices();
    setOnlineData([]);
  }, [startDate, endDate]);

  const columns = [
    {
      key: "no",
      value: "Invoice №",
    },
    {
      key: "customerName",
      value: "Customer Name",
    },
    {
      key: "paymentDate",
      value: "Payment Date",
    },
    {
      key: "bookingDateTime",
      value: "Booking date and time",
    },
    {
      key: "amount",
      value: "Amount",
    },
    {
      key: "viewInvoice",
      value: "View invoice",
    },
    {
      key: "downloadInvoice",
      value: "Download invoice",
    },
  ];

  function removeSpaces(inputString) {
    return inputString.replace(/\s+/g, "");
  }

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    if (removeSpaces(value) === "" || value == null) {
      fetchInvoices();
      return;
    }
    const filteredData = constData.filter(
      (invoice) =>
        invoice.customerName.toLowerCase().includes(value) ||
        invoice.no.toString().includes(value)
    );
    setOnlineData(filteredData);
  };

  const handleStartDate = async (value) => {
    setStartDate(value);
  };

  const handleEndDate = async (value) => {
    setEndDate(value);
  };

  const handleDownloadPdf = async (item) => {
    // Fetch location name asynchronously
    const locDetails = await fetchLocation(item.locationId);
    const locName = await fetchLocationName(item.locationId);
    const address = item.address || locDetails.address || "No address";
    const no = item.no || "No invoice number";
    const bookingDateTime = item.bookingDateTime || "No booking date and time";
    const customerName = item.customerName || "No customer name";
    const phoneNumber = item.phoneNumber || "No phone number";
    const amount = item.amount || "No amount";
    const paymentType = item.paymentType || "No payment type";
    const notes = item.notes || "No notes";

    // Fetch services
    let services = [];
    if (item.childsubserviceIds && item.childsubserviceIds.length > 0) {
      services = await Promise.all(
        item.childsubserviceIds.map(async (childsubserviceId) => {
          const childsubservice = await childSubserviceServices.getSubservice(
            childsubserviceId
          );
          return childsubservice.subservice;
        })
      );
    } else {
      services = await Promise.all(
        item.subserviceIds.map(async (serviceId) => {
          const service = await subserviceServices.getSubservice(serviceId);
          return service.subservice;
        })
      );
    }

    const convertMinutesToHoursAndMinutes = (minutes) => {
      let hours = Math.floor(minutes / 60);
      let remainingMinutes = minutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    };

    const renderServices = services
      .map((service) => {
        if (!service) {
          return `
          <div style="display: flex; width: 100%; justify-content: space-between;">
            <p style="font-size: 14px; width: 180px; font-weight: bold;">No service</p>
            <p style="font-size: 14px; width: 100%;">No duration</p>
            <p style="font-size: 14px; width: 100%;">No price</p>
          </div>
        `;
        }
        return `
        <div style="display: flex; width: 100%; justify-content: space-between;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">${
            service.name.en || service.name.ar
          }</p>
          <p style="font-size: 14px; width: 100%;">${convertMinutesToHoursAndMinutes(
            service.duration
          )}</p>
          <p style="font-size: 14px; width: 100%;">KWD ${service.price}</p>
        </div>
      `;
      })
      .join("");

    const input = document.createElement("div");
    input.style.position = "fixed";
    // center it
    input.style.top = "0";
    input.style.left = "0";
    input.style.zIndex = "-1000";
    input.style.backgroundColor = "white"; // Ensure background color is white for better visibility
    input.innerHTML = `
      <div style="padding: 20px; width: 500px; justify-content: center; align-items: flex-start; display: flex; flex-direction: column; font-family: Arial, sans-serif;">
        <p style="font-size: 16px; font-weight: bold; margin-bottom: 10px;">${
          locName.en || locName.ar
        }</p>
        <p style="font-size: 14px; color: #00000073; margin-bottom: 20px;">${address}</p>
        <div style="display: flex;width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Invoice:</p>
          <p style="font-size: 14px;width: 100%;">${no}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Date:</p>
          <p style="font-size: 14px;width: 100%;">${bookingDateTime}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Customer Name:</p>
          <p style="font-size: 14px;width: 100%;">${customerName}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Phone Number:</p>
          <p style="font-size: 14px;width: 100%;">${phoneNumber}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Total Price:</p>
          <p style="font-size: 14px;width: 100%;">${amount}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Payment Type:</p>
          <p style="font-size: 14px; width: 100%;">${
            paymentType.charAt(0).toUpperCase() +
            paymentType.slice(1).toLowerCase()
          }</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Notes:</p>
          <p style="font-size: 14px;width: 100%;">${notes}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Items:</p>
          <div style="display: flex; flex-direction: column; width: 100%;">
            ${renderServices}
          </div>
        </div>
      </div>
    `;

    document.body.appendChild(input);

    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save("invoice.pdf");
        document.body.removeChild(input);
      });
    }, 1000); // Adjust the timeout as needed to ensure rendering
  };

  return (
    <div className="px-4 py-6 mt-4 overflow-hidden bg-white rounded-md">
      <div className="flex items-center justify-between w-full p-4">
        <InputField
          placeholder="Search"
          onChange={handleSearch}
          icon={<CiSearch size={20} color="#00000099" />}
        />
        <div className="flex items-center space-x-4 ">
          <div className="date-picker-calendar">
            <input
              onChange={(e) => handleStartDate(e.target.value)}
              value={startDate}
              type="date"
            />
          </div>
          <FaArrowRight style={{ alignSelf: "center" }} />
          <div className="date-picker-calendar">
            <input
              onChange={(e) => handleEndDate(e.target.value)}
              value={endDate}
              type="date"
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        {onlineData.length > 0 ? (
          <InvoicesTable
            headers={columns}
            data={onlineData}
            numberOfRows={10}
            isClickable={false}
            setSelectedItem={(value) => setSelectedItem(value)}
            selectedItem={selectedItem}
            setDetailsModalOpen={(value) => setDetailsModalOpen(value)}
            handleDownloadPdf={handleDownloadPdf} // Pass the download handler
          />
        ) : (
          <div className="flex items-center justify-center h-40">
            <p className="text-gray-500">No invoices found</p>
          </div>
        )}
      </div>
      <InvoiceDetails
        selectedItem={selectedItem}
        isOpen={detailsModalOpen}
        onRequestClose={() => setDetailsModalOpen(false)}
      />
    </div>
  );
};

export default Invoices;
