import React, { useEffect, useState } from "react";
import InputField from "components/core/inputField";
import ImagesInput from "components/core/ImagesInput";
import SelectInput from "components/core/SelectInput";
import AddressSelector from "components/core/AddressSelector";
import EditHeader from "components/Header/EditHeader";
import useCategory from "utils/hooks/useCategory";
import useCity from "utils/hooks/useCity";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import MultiSelectInput from "components/core/select/MultiSelectInput";
import MultiplePhoneNumbers from "components/core/MultiplePhoneInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListing,
  updateSpecificListingData,
} from "../../../../redux/listings/listingSlice";
import InputFieldWithLanguage from "components/Services/ServicesTab/InputFieldWithLanguage";
import photoService from "services/photoService";
import { base64ToFile, showError, showSuccess } from "utils/helpers";
import useBusinesses from "utils/hooks/useBusiness";
const GOOGLE_MAPS_API_KEY = "AIzaSyCqNCVTXAdYyepHySFOxI-WcLVKb-MttNc";

const Overview = () => {
  // states
  const locationData = useSelector(selectListing);
  const [vendor, setVendor] = useState({});
  const [name, setName] = useState({ en: "", ar: "" });
  const [category, setCategory] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [city, setCity] = useState({});
  const [description, setDescription] = useState({ en: "", ar: "" });
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [instagram, setInstagram] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [images, setImages] = useState([]);
  const [priceRange, setPriceRange] = useState("");
  const [amenities, setAmenities] = useState([]);
  const dispatch = useDispatch();
  const [amenityOptions, setAmenityOptions] = useState([]);

  // Fetch data
  const {
    fetchedCategories: categoryOptions,
    fetchedSubcategories: subcategoryOptions,
  } = useCategory();
  const { cities: cityOptions } = useCity();
  const { businesses } = useBusinesses();

  useEffect(() => {
    if (locationData) {
      setLogo(locationData.logo);
      setImages(locationData.images);
      setVendor(locationData.vendor);
      setName(locationData.name ? locationData.name : { en: "", ar: "" });
      setCategory(locationData.category);
      setSubcategories(locationData.subcategories);
      setCity(locationData.city);
      setDescription(
        locationData.description ? locationData.description : { en: "", ar: "" }
      );
      setPhoneNumbers(locationData.phoneNumbers);
      setEmail(locationData.email);
      setWhatsapp(locationData.whatsapp);
      setInstagram(locationData.instagram);
      setWebsite(locationData.website);
      setAddress(locationData.address);
      setPriceRange(locationData.priceRange);
      setAmenities(locationData.amenities ? locationData.amenities : []);
    }
  }, [locationData]);

  useEffect(() => {
    if (vendor) {
      let vendorCategory = businesses.find(
        (business) => business?.id === vendor?.id
      );
      if (vendorCategory) {
        setCategory({
          id: vendorCategory?.category?.id,
          value: vendorCategory?.category?.slug,
          label: vendorCategory?.category?.name?.en,
        });
        setSubcategories(null);
        dispatch(
          updateSpecificListingData({
            field: "subcategories",
            value: [],
          })
        );
        dispatch(
          updateSpecificListingData({
            field: "category",
            value: {
              id: vendorCategory?.category?.id,
              value: vendorCategory?.category?.slug,
              label: vendorCategory?.category?.name?.en,
            },
          })
        );
      }
    }
  }, [vendor]);

  useEffect(() => {
    if (category) {
      setAmenityOptions(getAmenityOptions(category.value));
    }
  }, [category]);

  const handleOnChange = ({ value, state, setState }) => {
    if (value === null) {
      value = "";
      setState(value);
      dispatch(
        updateSpecificListingData({
          field: state,
          value: value,
        })
      );
      return;
    }
    setState(value);
    dispatch(
      updateSpecificListingData({
        field: state,
        value: value,
      })
    );
  };

  const handleAddressSelect = (address, lat, lng) => {
    setAddress(address);
    dispatch(updateSpecificListingData({ field: "address", value: address }));
  };

  const getAmenityOptions = (category) => {
    switch (category) {
      case "restaurants":
      case "cafes":
        return [
          { value: "DINE_IN", label: "Dine-in" },
          { value: "TAKEAWAY", label: "Takeaway" },
          { value: "DELIVERY", label: "Delivery" },
          { value: "OUTDOOR", label: "Outdoor seating" },
          { value: "INDOOR", label: "Indoor seating" },
        ];
      case "sports":
        return [
          { value: "INDOOR_FIELD", label: "Indoor Field" },
          { value: "OUTDOOR_FIELD", label: "Outdoor Field" },
        ];
      default:
        return [];
    }
  };

  const shouldShowAmenities = (category) => {
    return category && category.value && category.value !== "health-and-beauty";
  };

  return (
    <div>
      <EditHeader
        location={{
          name: "New Location",
          rating: 0.0,
          location: "",
          address: address,
          openHours: "",
        }}
        onUpload={async (image) => {
          try {
            const formData = new FormData();
            formData.append("files", image);
            const response = await photoService.uploadPhoto(formData);
            setLogo(response[0]);
            dispatch(
              updateSpecificListingData({ field: "logo", value: response[0] })
            );
            showSuccess("Image uploaded successfully");
          } catch (error) {
            showError(error.message);
          }
        }}
        image={logo ? logo : null}
      />
      <div className="flex flex-col items-start justify-start w-full mt-4 duration-300 ease-in-out md:mt-0 md:p-4 md:w-3/4 lg:w-3/5 transition-width">
        <SelectInput
          label="Vendor"
          placeholder="Choose your Vendor"
          options={businesses.map((business) => ({
            id: business.id,
            value: business.id,
            label: business.name,
          }))}
          onSelect={(selected) =>
            handleOnChange({
              value: selected,
              state: "vendor",
              setState: setVendor,
            })
          }
          className="w-full mb-6"
          selected={vendor}
          labelClassName="md:text-right"
          isRequired
        />

        <div className="flex items-start justify-between w-full space-x-2">
          <label
            className="justify-start pt-2 text-sm font-bold text-left text-gray-700 md:text-right md:w-1/5 min-w-32"
            htmlFor="name"
          >
            Business name: <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col items-center w-full mb-6 space-y-4">
            <InputFieldWithLanguage
              placeholder="Enter the name of your business"
              language={"En"}
              onChange={(e) => {
                handleOnChange({
                  value: { ...name, en: e.target.value },
                  state: "name",
                  setState: setName,
                });
              }}
              value={name.en || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
            />
            <InputFieldWithLanguage
              placeholder="أدخل اسم عملك"
              language={"Ar"}
              onChange={(e) => {
                handleOnChange({
                  value: { ...name, ar: e.target.value },
                  state: "name",
                  setState: setName,
                });
              }}
              value={name.ar || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
              dir="rtl"
            />
          </div>
        </div>
        <SelectInput
          label="Category"
          placeholder="Choose your Category"
          options={
            categoryOptions
              ? categoryOptions.map((category) => ({
                  id: category.id,
                  value: category.slug,
                  label: category.name.en,
                }))
              : []
          }
          className="w-full mb-6"
          selected={category}
          labelClassName="md:text-right"
          isDisabled
          isRequired
        />
        <SelectInput
          label="Subcategory"
          placeholder="Choose your Subcategory"
          options={
            subcategoryOptions
              ? subcategoryOptions
                  .filter(
                    (subcategory) => subcategory.categoryId === category.id
                  )
                  .map((subcategory) => ({
                    id: subcategory.id,
                    value: subcategory.slug,
                    label: subcategory.name.en,
                  }))
              : []
          }
          onSelect={(selected) =>
            handleOnChange({
              value: selected,
              state: "subcategories",
              setState: setSubcategories,
            })
          }
          className="w-full mb-6"
          isMulti
          selected={subcategories}
          labelClassName="md:text-right"
          isRequired
        />
        <div className="flex items-center justify-between w-full mb-6">
          <p className="justify-start text-sm font-bold text-left text-gray-700 md:w-1/5 min-w-32">
            Price range: <span className="text-red-500">*</span>
          </p>
          <RadioButtonGroup
            options={[
              { value: "0", label: "$" },
              { value: "1", label: "$$" },
              { value: "2", label: "$$$" },
            ]}
            name="price"
            selectedValue={priceRange}
            onChange={(e) =>
              handleOnChange({
                value: e.target.value,
                state: "priceRange",
                setState: setPriceRange,
              })
            }
            className="w-full ml-3 space-x-6"
          />
        </div>
        <SelectInput
          label="City"
          placeholder="Choose your city"
          options={cityOptions.map((city) => ({
            id: city.id,
            value: city.slug,
            label: city.name.en,
          }))}
          onSelect={(selected) =>
            handleOnChange({
              value: selected,
              state: "city",
              setState: setCity,
            })
          }
          className="w-full mb-6"
          selected={city}
          labelClassName="md:text-right"
          isRequired
        />
        <div className="flex items-start justify-between w-full space-x-2">
          <label
            className="pt-2 text-sm font-bold text-left text-gray-700 md:text-right md:w-1/5 min-w-32"
            htmlFor="description"
          >
            Description:
          </label>
          <div className="flex flex-col items-center w-full mb-6 space-y-4">
            <InputFieldWithLanguage
              placeholder="One or two sentences with key features of your service for headline in advertisement"
              language={"En"}
              onChange={(e) => {
                handleOnChange({
                  value: { ...description, en: e.target.value },
                  state: "description",
                  setState: setDescription,
                });
              }}
              value={description.en || ""}
              className={"w-full h-20"}
              labelClassName={"!w-14"}
            />
            <InputFieldWithLanguage
              placeholder="رسالة أو رسالتين مع الميزات الرئيسية لخدمتك للعناوين الرئيسية في الإعلانات"
              language={"Ar"}
              onChange={(e) => {
                handleOnChange({
                  value: { ...description, ar: e.target.value },
                  state: "description",
                  setState: setDescription,
                });
              }}
              value={description.ar || ""}
              className={"w-full h-20"}
              labelClassName={"!w-14"}
              dir="rtl"
            />
          </div>
        </div>

        {shouldShowAmenities(category) && (
          <div className="flex items-center justify-between w-full mb-6">
            <p className="text-sm font-bold text-left text-gray-700 md:w-1/5 min-w-32">
              Amenities:
            </p>
            <MultiSelectInput
              placeholder="Select Amenities"
              options={amenityOptions}
              onChange={(selected) => {
                setAmenities(selected);
                dispatch(
                  updateSpecificListingData({
                    field: "amenities",
                    value: selected,
                  })
                );
              }}
              className="ml-2"
              checkboxClassName={`!flex-row space-x-2 w-full
                  ${amenityOptions.length < 3 ? "!justify-start" : ""}
                  `}
              selectedValues={amenities || []}
            />
          </div>
        )}

        <MultiplePhoneNumbers
          className="w-full mb-6"
          phoneNumbers={phoneNumbers || [{ id: 1, number: "", isValid: true }]}
          setPhoneNumbers={(phoneNumbers) => {
            handleOnChange({
              value: phoneNumbers,
              state: "phoneNumbers",
              setState: setPhoneNumbers,
            });
          }}
          isRequired
        />
        <InputField
          label="Email"
          placeholder="email@gmail.com"
          type="email"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) =>
            handleOnChange({
              value: e.target.value,
              state: "email",
              setState: setEmail,
            })
          }
          value={email}
          isRequired
        />
        <InputField
          label="WhatsApp"
          placeholder=" +1 123 456 7890"
          type="tel"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) =>
            handleOnChange({
              value: e.target.value,
              state: "whatsapp",
              setState: setWhatsapp,
            })
          }
          value={whatsapp}
          isRequired
        />
        <InputField
          label="Instagram"
          placeholder="instagram.com/username"
          type="text"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) =>
            handleOnChange({
              value: e.target.value,
              state: "instagram",
              setState: setInstagram,
            })
          }
          value={instagram}
        />
        <InputField
          label="Website"
          placeholder="https://example.com"
          type="text"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) =>
            handleOnChange({
              value: e.target.value,
              state: "website",
              setState: setWebsite,
            })
          }
          value={website}
        />

        <AddressSelector
          className="w-full"
          value={address}
          apiKey={GOOGLE_MAPS_API_KEY}
          onAddressSelect={(address, lat, lng) => {
            handleAddressSelect(address, lat, lng);
          }}
          isRequired
        />
        <ImagesInput
          label={"Upload Photo"}
          className="w-full mb-6"
          imageData={images}
          onImagesChange={async (newImages) => {
            try {
              let formData = new FormData();
              newImages.forEach((image) => {
                if (image.file) {
                  const file = base64ToFile(image.src, `${image.file.name}`);
                  formData.append("files", file);
                }
              });
              const response = await photoService.uploadPhoto(formData);
              setImages([...images, ...response]);
              dispatch(
                updateSpecificListingData({
                  field: "images",
                  value: [...images, ...response],
                })
              );
              showSuccess("Images uploaded successfully");
            } catch (error) {
              showError(error.message);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Overview;
