import React, { useState, useEffect } from "react";

import "./styles.css";
import businessService from "services/business/businessService";
import { showError, showSuccess } from "utils/helpers";

const ApproveVendorsModal = ({
  isOpen,
  setIsModalOpen,
  selectedItem,
  fetchVendors,
}) => {
  const [vendorInfo, setVendorInfo] = useState();

  useEffect(() => {
    const data = {
      name: selectedItem?.name,
      email: selectedItem?.email,
      businessType: selectedItem?.category,
      phoneNumber: selectedItem?.phoneNumber[0],
      // booking: false
    };

    setVendorInfo(data);
  }, [selectedItem]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setVendorInfo((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await businessService.updateBusinessStatus(
      selectedItem.id,
      { status: "APPROVED" }
    );
    switch (response.statusCode) {
      case 400:
        showError(response.message);
        break;
      case 404:
        showError(response.message);
        break;
      default:
        showSuccess(response.message);
        fetchVendors();
        setIsModalOpen(false);
        break;
    }
  };

  if (isOpen) {
    return (
      <div className="edit-vendor-modal">
        <div className="edit-vendor-info-modal">
          <div className="edit-vendor-info-modal-header">
            <h2 style={{ fontWeight: "bold" }}>Approve Vendor</h2>
            <button onClick={() => setIsModalOpen(false)}>
              <p style={{ color: "#8c8c8c", fontSize: 20 }}>x</p>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="edit-vendor-input-container">
              <label>
                Business Name:
                <input
                  type="text"
                  name="name"
                  value={vendorInfo.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  className="bg-[#F5F5F5] h-8 text-[#000000D9] px-3 py-1 focus:outline-none"
                  disabled
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={vendorInfo.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  className="bg-[#F5F5F5] h-8 text-[#000000D9] px-3 py-1 focus:outline-none"
                  disabled
                />
              </label>
              <label>
                Business type:
                <input
                  type="text"
                  name="businessType"
                  value={vendorInfo.businessType}
                  onChange={handleChange}
                  placeholder="Enter business type"
                  className="bg-[#F5F5F5] h-8 text-[#000000D9] px-3 py-1 focus:outline-none"
                  disabled
                />
              </label>
              <label>
                Phone number:
                <input
                  type="tel"
                  name="phoneNumber"
                  value={vendorInfo.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  className="bg-[#F5F5F5] h-8 text-[#000000D9] px-3 py-1 focus:outline-none"
                  disabled
                />
              </label>
              <div className="buttons">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="cancel-edit-vendor"
                  type="button"
                >
                  Cancel
                </button>
                <button className="submit-edit-vendor" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default ApproveVendorsModal;
