import React, { useEffect, useState } from "react";
import InputField from "components/core/inputField";
import ImagesInput from "components/core/ImagesInput";
import SelectInput from "components/core/SelectInput";
import AddressSelector from "components/core/AddressSelector";
import EditHeader from "components/Header/EditHeader";
import useCategory from "utils/hooks/useCategory";
import useCity from "utils/hooks/useCity";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import MultiSelectInput from "components/core/select/MultiSelectInput";
import MultiplePhoneNumbers from "components/core/MultiplePhoneInput";
import Button from "components/core/Button";
import { useNavigate, useParams } from "react-router-dom";
import photoService from "services/photoService";
import { base64ToFile, showError, showSuccess } from "utils/helpers";
import locationService from "services/location/locationService";
import amenitiesOptions from "constants/data/amenitiesOptions";
import useUsers from "utils/hooks/useUsers";
import InputFieldWithLanguage from "components/Services/ServicesTab/InputFieldWithLanguage";
import { LocationEditInfoSchema } from "utils/schemas/Location/LocationSchemas";
import { selectRole } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
const GOOGLE_MAPS_API_KEY = "AIzaSyCqNCVTXAdYyepHySFOxI-WcLVKb-MttNc";

const InfoLocationPage = ({ locationData, setLocationData }) => {
  // states
  const { id } = useParams();
  const { fetchProfile } = useUsers();
  const [name, setName] = useState({ en: "", ar: "" });
  const [category, setCategory] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [city, setCity] = useState({});
  const [description, setDescription] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [instagram, setInstagram] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [businessId, setBusinessId] = useState();
  const navigate = useNavigate();
  const userRole = useSelector(selectRole);
  const [todaysOpenHour, setTodaysOpenHour] = useState("");

  // Fetch data
  const {
    fetchedCategories: categoryOptions,
    fetchedSubcategories: subcategoryOptions,
  } = useCategory();
  const { cities: cityOptions } = useCity();

  useEffect(() => {
    if (locationData && locationData.id) {
      setBusinessId(locationData.businessId);
      setName(locationData.name);
      setCategory({
        id: locationData.category?.id,
        value: locationData.category?.slug,
        label: locationData.category?.name.en,
      });
      setSubcategories(
        locationData.subcategories?.map((sub) => {
          return { id: sub.id, value: sub.slug, label: sub.name.en };
        })
      );
      setCity({
        id: locationData.city?.id,
        value: locationData.city?.slug,
        label: locationData.city?.name.en,
      });
      setDescription(locationData.description);
      setPhoneNumbers(
        locationData.phone
          ? locationData.phone.map((phone) => ({
              id: locationData.phone.indexOf(phone) + 1,
              number: phone,
              isValid: true,
            }))
          : []
      );
      setEmail(locationData.email);
      setWhatsapp(locationData.whatsapp);
      setInstagram(locationData.instagram);
      setWebsite(locationData.website);
      setAddress(locationData.address);
      setLogo(locationData.logo);
      setImages(locationData.images ? locationData.images : []);
      setPriceRange(locationData.priceRange);
      setAmenities(locationData.amenities ? locationData.amenities : []);
      const todaysAvailability = locationData.availability.days.find(
        (day) => day.name === moment().format("dddd").toUpperCase()
      );
      // todaysAvailability has schedule array. Schedule array contains objects with startTime and endTime of the day. Choose earliest startTime and latest endTime. Also includes AM/PM in the time. Use moment.js to convert to 24 hour format.
      if (todaysAvailability) {
        const earliestStartTime = todaysAvailability.schedule.reduce(
          (earliest, current) => {
            return moment(current.startTime, "hh:mm A").isBefore(
              moment(earliest.startTime, "hh:mm A")
            )
              ? current
              : earliest;
          }
        );
        const latestEndTime = todaysAvailability.schedule.reduce(
          (latest, current) => {
            return moment(current.endTime, "hh:mm A").isAfter(
              moment(latest.endTime, "hh:mm A")
            )
              ? current
              : latest;
          }
        );
        setTodaysOpenHour(
          `${moment(earliestStartTime.startTime, "hh:mm Aa").format(
            "HH:mm A"
          )} - ${moment(latestEndTime.endTime, "hh:mm A").format("HH:mm A")}`
        );
      }
    }
  }, [locationData]);

  const uploadPhoto = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const imageUrl = await photoService.uploadPhoto(formData);
      return imageUrl[0];
    } catch (error) {
      showError(error.message);
    }
  };

  const saveInfo = async (data) => {
    try {
      const response = await locationService.updateLocation(id, data);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setLocationData(response.location);
          break;
        default:
          showError(response.response.data.message || response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async () => {
    const userProfileData = await fetchProfile();
    let userBusinessId;
    switch (userProfileData.roles[0]) {
      case "ADMIN":
        userBusinessId = userProfileData.adminBusinessId;
        break;
      case "PROVIDER":
        userBusinessId = userProfileData.businessId;
        break;
      default:
        break;
    }

    let uploadedImageUrl;
    if (logo instanceof File) {
      uploadedImageUrl = await uploadPhoto(logo);
    }

    const data = {
      name: name,
      categoryId: category.id,
      subcategoryIds: subcategories.map((sub) => sub.id),
      cityId: city.id,
      description: description,
      logo: uploadedImageUrl !== undefined ? uploadedImageUrl : logo,
      images: images ? images : [],
      email: email,
      whatsapp: whatsapp,
      instagram: instagram,
      website: website,
      address: address,
      priceRange: parseInt(priceRange),
      amenities: amenities,
      phone: locationData.phone,
      businessId: businessId ? businessId : userBusinessId,
      subserviceIds: locationData.subserviceIds,
      availability: locationData.availability,
      phone: phoneNumbers.map((phone) => phone.number),
    };
    try {
      await LocationEditInfoSchema.validate(data);
      await saveInfo(data);
    } catch (error) {
      showError(error.message);
    }
  };

  const handleAddressSelect = (address, lat, lng) => {
    setAddress(address);
  };

  const getAmenityOptions = (category) => {
    switch (category) {
      case "restaurants":
      case "cafes":
        return [
          { value: "DINE_IN", label: "Dine-in" },
          { value: "TAKEAWAY", label: "Takeaway" },
          { value: "DELIVERY", label: "Delivery" },
          { value: "OUTDOOR", label: "Outdoor seating" },
          { value: "INDOOR", label: "Indoor seating" },
        ];
      case "sports":
        return [
          { value: "INDOOR", label: "Indoor Field" },
          { value: "OUTDOOR", label: "Outdoor Field" },
        ];
      default:
        return [];
    }
  };

  const shouldShowAmenities = (category) => {
    return category && category.value && category.value !== "health-and-beauty";
  };

  const amenityOptions = getAmenityOptions(category?.value);

  return (
    <div>
      <EditHeader
        location={{
          name: `${name.en ?? ""} ${name.en ? " / " : ""} ${name.ar ?? ""}`,
          rating: 4.5,
          location: city.label,
          address: address,
          openHours: todaysOpenHour,
        }}
        onUpload={(image) => {
          setLogo(image);
        }}
        image={logo ? logo : null}
      />
      <div className="flex flex-col items-start justify-start w-full mt-4 duration-300 ease-in-out md:mt-0 md:p-4 md:w-3/4 lg:w-3/5 transition-width">
        <div className="flex items-start justify-between w-full space-x-2">
          <label
            className="justify-start pt-2 text-sm font-bold text-left text-gray-700 md:text-right md:w-1/5 min-w-32"
            htmlFor="name"
          >
            <span>Business name:</span>
            <p className="text-red-500 ">*</p>
          </label>
          <div className="flex flex-col items-center w-full mb-6 space-y-4">
            <InputFieldWithLanguage
              placeholder="Enter the name of your business"
              language={"En"}
              onChange={(e) => {
                setName({ ...name, en: e.target.value });
              }}
              value={name.en || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
              isDisabled={userRole === "PROVIDER"}
            />
            <InputFieldWithLanguage
              placeholder="أدخل اسم عملك"
              language={"Ar"}
              onChange={(e) => {
                setName({ ...name, ar: e.target.value });
              }}
              value={name.ar || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
              dir="rtl"
              isDisabled={userRole === "PROVIDER"}
            />
          </div>
        </div>
        <SelectInput
          label="Category"
          placeholder="Choose your Category"
          options={
            categoryOptions
              ? categoryOptions.map((category) => ({
                  id: category.id,
                  value: category.slug,
                  label: category.name.en,
                }))
              : []
          }
          isDisabled
          className="w-full mb-6"
          selected={category}
          labelClassName="md:text-right"
          isRequired
        />
        <SelectInput
          label="Subcategory"
          placeholder="Choose your Subcategory"
          options={
            subcategoryOptions
              ? subcategoryOptions
                  .filter((sub) => sub.categoryId === category.id)
                  .map((sub) => ({
                    id: sub.id,
                    value: sub.slug,
                    label: sub.name.en,
                  }))
              : []
          }
          onSelect={(selected) => {
            setSubcategories(selected);
          }}
          className="w-full mb-6"
          isMulti
          selected={subcategories ? subcategories : []}
          labelClassName="md:text-right"
          isDisabled={userRole === "PROVIDER"}
          isRequired
        />
        <div className="flex items-center justify-between w-full mb-6">
          <p className="justify-start text-sm font-bold text-left text-gray-700 md:w-1/5 min-w-32">
            Price range:
          </p>
          <RadioButtonGroup
            options={[
              { value: "0", label: "$" },
              { value: "1", label: "$$" },
              { value: "2", label: "$$$" },
            ]}
            name="price"
            selectedValue={`${priceRange}`}
            onChange={(e) => {
              setPriceRange(e.target.value);
            }}
            className="w-full ml-3 space-x-6"
            disabled={userRole === "PROVIDER"}
          />
        </div>

        <SelectInput
          label="City"
          placeholder="Choose your city"
          options={cityOptions.map((city) => ({
            id: city.id,
            value: city.slug,
            label: city.name.en,
          }))}
          onSelect={(selected) =>
            setCity({
              id: selected.id,
              value: selected.value,
              label: selected.label,
            })
          }
          className="w-full mb-6"
          selected={city}
          labelClassName="md:text-right"
          isDisabled={userRole === "PROVIDER"}
          isRequired
        />
        <div className="flex items-start justify-between w-full space-x-2">
          <label
            className="pt-2 text-sm font-bold text-left text-gray-700 md:text-right md:w-1/5 min-w-32"
            htmlFor="name"
          >
            Description:
          </label>
          <div className="flex flex-col items-center w-full mb-6 space-y-4">
            <InputFieldWithLanguage
              placeholder="One or two sentnces with key features of your service for headline in advertisment
              "
              language={"En"}
              onChange={(e) => {
                setDescription({ ...description, en: e.target.value });
              }}
              value={description.en || ""}
              className={"w-full h-20"}
              labelClassName={"!w-14"}
              isDisabled={userRole === "PROVIDER"}
            />
            <InputFieldWithLanguage
              placeholder="رسالة أو رسالتين مع الميزات الرئيسية لخدمتك للعناوين الرئيسية في الإعلانات
              "
              language={"Ar"}
              onChange={(e) => {
                setDescription({ ...description, ar: e.target.value });
              }}
              value={description.ar || ""}
              className={"w-full h-20"}
              labelClassName={"!w-14"}
              dir="rtl"
              isDisabled={userRole === "PROVIDER"}
            />
          </div>
        </div>

        {shouldShowAmenities(category) && (
          <div className="flex items-center justify-between w-full mb-6">
            <p className="text-sm font-bold text-left text-gray-700 md:w-1/5 min-w-32">
              Amenities:
            </p>
            <MultiSelectInput
              placeholder="Select Amenities"
              options={amenityOptions}
              onChange={(selected) => {
                let selectedAmenities = selected.map(
                  (amenity) => amenity.value
                );
                setAmenities(selectedAmenities);
              }}
              className="ml-2"
              checkboxClassName={"!flex-row space-x-2 w-full"}
              selectedValues={amenityOptions.filter((amenity) =>
                (amenities || []).includes(amenity.value)
              )}
              disabled={userRole === "PROVIDER"}
            />
          </div>
        )}

        <MultiplePhoneNumbers
          className="w-full mb-6"
          phoneNumbers={phoneNumbers || [{ id: 1, number: "", isValid: true }]}
          setPhoneNumbers={(phoneNumbers) => {
            setPhoneNumbers(phoneNumbers);
          }}
          disabled={userRole === "PROVIDER"}
          isRequired
        />
        <InputField
          label="Email"
          placeholder="email@gmail.com"
          type="email"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={userRole === "PROVIDER"}
          isRequired
        />
        <InputField
          label="WhatsApp"
          placeholder=" +1 123 456 7890"
          type="tel"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) => setWhatsapp(e.target.value)}
          value={whatsapp}
          disabled={userRole === "PROVIDER"}
          isRequired
        />
        <InputField
          label="Instagram"
          placeholder="instagram.com/username"
          type="text"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) => setInstagram(e.target.value)}
          value={instagram}
          disabled={userRole === "PROVIDER"}
        />
        <InputField
          label="Website"
          placeholder="www.website.com"
          type="text"
          className="w-full mb-6"
          labelClassName="md:text-right"
          onChange={(e) => setWebsite(e.target.value)}
          value={website}
          disabled={userRole === "PROVIDER"}
        />

        <AddressSelector
          className="w-full"
          value={address}
          apiKey={GOOGLE_MAPS_API_KEY}
          onAddressSelect={(address, lat, lng) =>
            handleAddressSelect(address, lat, lng)
          }
          disabled={userRole === "PROVIDER"}
          isRequired
        />
        <ImagesInput
          label={"Upload Photo"}
          className="w-full mb-6"
          imageData={images}
          onImagesChange={async (images) => {
            let urlImages = images.filter((image) => image.file === undefined);
            let fileImages = images.filter((image) => image.file !== undefined);

            try {
              let formData = new FormData();
              fileImages.forEach((image) => {
                if (image.file) {
                  const file = base64ToFile(image.src, `${image.file.name}`);
                  formData.append("files", file);
                }
              });
              const response = await photoService.uploadPhoto(formData);
              setImages([...urlImages.map((image) => image.src), ...response]);
              showSuccess("Images uploaded successfully");
            } catch (error) {
              showError(error.message);
            }
          }}
          disabled={userRole === "PROVIDER"}
        />
        {
          // if user is provider, hide save button just add back button
          userRole === "PROVIDER" ? (
            <div className="flex items-center justify-end w-full p-4">
              <Button
                label={"Back"}
                className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
                textColor="text-black"
                onClick={() => {
                  navigate("/locations");
                }}
              />
            </div>
          ) : (
            <div className="flex items-center justify-end w-full p-4">
              <Button
                label={"Cancel"}
                className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
                textColor="text-black"
                onClick={() => {
                  navigate("/locations");
                }}
              />
              <Button
                label={"Save"}
                className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
                textColor="text-white"
                onClick={handleSave}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default InfoLocationPage;
