import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    minAmount: "",
    timeBlockedAfterHomeService: "",
    timeBlockedBeforeHomeService: "",
    feesData: [],
  },
  reducers: {
    setSettingsData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearSettingsData: (state) => {
      state.minAmount = "";
      state.timeBlockedAfterHomeService = "";
      state.timeBlockedBeforeHomeService = "";
      state.feesData = [];
    },
  },
});

export const { setSettingsData, clearSettingsData } = settingsSlice.actions;

export default settingsSlice.reducer;
