import React, { useState, useEffect } from "react";
import Button from "../../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import VendorTableList from "./Components/VendorTableList/VendorTableList";
import ApproveVendorsModal from "./Components/ApproveVendorsModal/ApproveVendorsModal";
import AddVendorModal from "./Components/AddVendor/AddVendorModal";
import businessService from "services/business/businessService";
import categoryService from "services/category/categoryService";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";

function Vendors() {
  const [isAddVendorsModalOpen, setIsAddVendorsModalOpen] = useState(false);
  const [filteredVendorsListings, setFilteredVendorsListings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [users, setUsers] = useState([]);
  const [fullUsers, setFullUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  function formatDate(dateString) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${monthNames[monthIndex]} ${year}`;
  }

  const fetchVendors = async () => {
    setLoading(true);
    const response = await businessService.getBusinesses();
    setFullUsers(response.businesses);
    let dataPromises = response.businesses.map(async (business) => {
      if (business.categoryId != null) {
        const responseCategory = await categoryService.getCategory(
          business?.categoryId
        );
        return {
          name: business.name,
          category: responseCategory.category.name.en,
          subcategory:
            responseCategory.category.subcategories[0]?.name !== undefined
              ? responseCategory.category.subcategories[0].name.en
              : "No Subcategory",
          dateCreated: formatDate(business.createdAt),
          status: business.status,
          id: business.id,
          phoneNumber: business.phone,
          email: business.email,
          admin: business?.admin,
          website: business?.website,
          categoryId: business?.categoryId,
          slug: business.slug,
        };
      } else {
        return {
          name: business.name || "",
          category: "",
          subcategory: "",
          dateCreated: formatDate(business.createdAt),
          status: business.status,
          id: business.id,
          phoneNumber: business.phone,
          email: business.email,
          admin: business?.admin,
          website: business?.website,
          categoryId: business?.categoryId,
          slug: business.slug,
        };
      }
    });

    let data = await Promise.all(dataPromises);

    setUsers(data);
    setFilteredVendorsListings(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="flex-1 mt-4 bg-white">
        <div className="p-4">
          <div
            style={{ justifyContent: "end" }}
            className="flex items-center justify-between w-full p-4"
          >
            <Button
              label="Add New"
              icon={<HiOutlinePlusSm />}
              onClick={() => {
                setIsAddVendorsModalOpen(true);
              }}
            />
          </div>
          <VendorTableList
            data={filteredVendorsListings}
            headers={[
              "Business Name",
              "Category",
              "Subcategory",
              "Date created",
              "Status",
            ]}
            numberOfRows={10}
            setData={setFilteredVendorsListings}
            setIsModalOpen={(value) => setIsModalOpen(value)}
            isModalOpen={isModalOpen}
            setIsAddVendorsModalOpen={(value) =>
              setIsAddVendorsModalOpen(value)
            }
            setModalPosition={(value) => setModalPosition(value)}
            modalPosition={modalPosition}
            setIsApproveOpen={(value) => setIsApproveOpen(value)}
            setSelectedItem={(value) => setSelectedItem(value)}
            fetchVendors={() => fetchVendors()}
          />
        </div>
        <ApproveVendorsModal
          fetchVendors={fetchVendors}
          selectedItem={selectedItem}
          isOpen={isApproveOpen}
          setIsModalOpen={(value) => setIsApproveOpen(value)}
        />
        <AddVendorModal
          fetchVendors={() => fetchVendors()}
          isOpen={isAddVendorsModalOpen}
          onClose={() => setIsAddVendorsModalOpen(false)}
        />
      </div>
    </LoaderWrapper>
  );
}

export default Vendors;
