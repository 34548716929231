import React from "react";
import { getInitials } from "utils/helpers";

const ProvidersCard = ({ data, actions }) => {
  return (
    <div className="flex items-center justify-between p-4 border-t border-b border-[#0000000F] rounded-md">
      {/* First Column */}
      <div className="flex items-center space-x-4">
        {data.avatarPath === null || data.avatarPath === "" ? (
          <div className="flex items-center justify-center w-16 h-16 text-2xl font-medium text-gray-700 bg-gray-300 rounded-full">
            {getInitials(data.name)}
          </div>
        ) : (
          <img
            src={data.avatarPath}
            alt={data.name}
            className="w-16 h-16 rounded-full"
          />
        )}
        <div className="font-medium leading-6">
          <h2 className="text-16  text-[#000000D9]">{data.name}</h2>
          <p className="text-14 text-[#00000073] ">{data.speciality}</p>
        </div>
      </div>

      {/* Second Column */}
      <div className="bg-white w-fit">
        {actions &&
          actions.map((action) => (
            <button
              key={action.id}
              className="p-2 text-14 text-[#00000073] hover:text-[#000000D9] focus:outline-none"
              onClick={() => action.onClick(data.id)}
            >
              {action.icon}
            </button>
          ))}
      </div>
    </div>
  );
};

export default ProvidersCard;
