function StatCard({ title, value, icon, iconBg }) {
  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex justify-between w-full gap-2 p-5 bg-white border rounded-md shadow-lg grow max-md:mt-5">
        <div className="flex flex-col justify-center font-medium">
          <div className="text-base leading-6 text-black text-opacity-50">
            {title}
          </div>
          <div className="mt-2 text-2xl leading-8 text-black text-opacity-80">
            {value}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
