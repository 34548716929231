import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { CiClock2 } from "react-icons/ci";
import { showError } from "utils/helpers";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import subserviceServices from "services/service/subserviceServices";
import locationService from "services/location/locationService";
import useLocations from "utils/hooks/useLocations";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "500px",
    fontFamily: "Arial, sans-serif", // Apply font globally to all content
  },
};

Modal.setAppElement("#root");

const InvoiceDetails = ({ isOpen, onRequestClose, selectedItem }) => {
  const [services, setServices] = useState([]);
  const [locName, setLocName] = useState("");
  const [locAddress, setLocAddress] = useState(null);
  const { fetchLocationName, fetchLocation } = useLocations();

  const fetchServices = async () => {
    try {
      if (!selectedItem) return;
      if (
        selectedItem.childsubserviceIds &&
        selectedItem.childsubserviceIds.length > 0
      ) {
        let childsubserviceIds = selectedItem.childsubserviceIds;
        setServices(
          await Promise.all(
            childsubserviceIds.map(async (childsubserviceId) => {
              const childsubservice =
                await childSubserviceServices.getSubservice(childsubserviceId);
              return childsubservice.subservice;
            })
          )
        );
      } else {
        setServices(
          await Promise.all(
            selectedItem.subserviceIds.map(async (serviceId) => {
              const service = await subserviceServices.getSubservice(serviceId);
              return service.subservice;
            })
          )
        );
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    fetchServices();
    selectedItem &&
      fetchLocation(selectedItem.locationId).then((location) => {
        setLocAddress(location?.address);
        setLocName(location.name.en || location.name.ar);
      });
  }, [selectedItem]);

  const convertMinutesToHoursAndMinutes = (minutes) => {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const renderField = (label, value) => {
    return (
      <div
        className="flex items-center justify-between w-full py-4 border-b border-gray-200"
        key={label}
      >
        <p className="text-14 font-bold leading-[22px] text-left w-full max-w-40 text-[#000000D9]">
          {label}
        </p>
        <p className="text-14 font-normal leading-[22px] text-left w-full text-[#000000D9] ">
          {value}
        </p>
      </div>
    );
  };

  const renderServices = () => {
    return (
      <div className="flex items-center justify-between w-full py-4 border-b border-gray-200">
        <p className="text-14 font-bold leading-[22px] text-left w-full max-w-40 text-[#000000D9]">
          Items:
        </p>
        <div className="flex flex-col items-start justify-start w-full">
          {services && services.length === 0 ? (
            <p className="text-14 font-normal leading-[22px] text-left w-full text-[#000000D9] ">
              No items
            </p>
          ) : (
            services.map((service) => {
              if (!service)
                return (
                  <div className="flex items-center justify-between w-full">
                    <p className="text-14 font-normal leading-[22px] text-left w-full text-[#2D2D2D]">
                      No service
                    </p>
                    <p className="text-12 font-normal leading-[14px] text-left w-full text-[#67666D]">
                      No duration
                    </p>
                    <p className="text-12 font-normal leading-[14px] text-left w-full text-[#67666D]">
                      No price
                    </p>
                  </div>
                );
              return (
                <div
                  key={service._id}
                  className="flex items-center justify-between w-full"
                >
                  <p className="text-14 font-normal leading-[22px] text-left w-full text-[#2D2D2D]">
                    {service.name.en || service.name.ar}
                  </p>
                  <p className="text-12 font-normal leading-[14px] text-left w-full text-[#67666D]">
                    {convertMinutesToHoursAndMinutes(service.duration)}
                  </p>
                  <p className="text-12 font-normal leading-[14px] text-left w-full text-[#67666D]">
                    KWD {service.price}
                  </p>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  if (!selectedItem) return null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Invoice Modal"
    >
      <div className="flex flex-col items-center justify-center w-full ">
        <p className="text-16 font-bold leading-[24px] text-[#000000D9] mb-3 capitalize">
          {locName}
        </p>
        <p className="text-14 font-normal leading-[22px] text-[#00000073] mb-3">
          {selectedItem.address
            ? selectedItem.address
            : locAddress
            ? locAddress
            : "No address"}
        </p>
        {renderField("Invoice:", selectedItem?.no)}
        {renderField("Date:", selectedItem?.bookingDateTime)}
        {renderField("Customer Name:", selectedItem?.customerName)}
        {renderField("Phone Number:", selectedItem?.phoneNumber)}
        {renderServices()}
        {renderField("Total Price:", `KWD ${selectedItem?.amount}`)}
        {renderField("Deposit Amount:", `KWD ${selectedItem?.depositAmount}`)}
        {selectedItem?.bookingDestination === "HOME" &&
          renderField("Delivery Fee:", `KWD ${selectedItem?.deliveryFee}`)}
        {renderField(
          "Payment Type:",
          `
            ${selectedItem?.paymentType
              .charAt(0)
              .toUpperCase()}${selectedItem?.paymentType.slice(1).toLowerCase()}
          `
        )}
        {renderField("Notes:", selectedItem?.notes)}
      </div>

      <div
        style={{
          alignSelf: "end",
          alignItems: "end",
          justifyContent: "end",
          display: "flex",
        }}
      >
        <button
          style={{
            backgroundColor: "#ff0000",
            color: "white",
            border: "none",
            padding: "10px 40px",
            cursor: "pointer",
            fontSize: "16px",
            marginTop: "20px",
            alignSelf: "end",
            alignItems: "end",
            justifyContent: "end",
            borderRadius: 10,
          }}
          onClick={onRequestClose}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#e60000")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#ff0000")}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default InvoiceDetails;
