import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    locationInfo: {
      id: null,
      name: "",
      category: "",
      subcategories: [],
      priceRange: "",
      city: "",
      description: "",
      amenities: [],
      phoneNumbers: [],
      email: "",
      whatsapp: "",
      logo: "",
      images: [],
      instagram: "",
      website: "",
      address: "",
    },
    locationAvailability: {
      days: [],
    },
    uploadedImageFile: null,
  },
  reducers: {
    setLocationInfo: (state, action) => {
      state.locationInfo = action.payload.locationInfo;
    },
    setLocationAvailability: (state, action) => {
      state.locationAvailability = action.payload;
    },
    setSpecificLocationAvailability: (state, action) => {
      state.locationAvailability[action.payload.key] = action.payload.value;
    },
    updateSpecificLocationData: (state, action) => {
      const { field, value } = action.payload;
      state.locationInfo = { ...state.locationInfo, [field]: value };
    },
    setUploadedImageFile: (state, action) => {
      const file = action.payload;
      const fileMetaData = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      };
      state.uploadedImageFile = fileMetaData;
    },
    setUploadedImageFileAs64: (state, action) => {
      state.uploadedImageFile = action.payload;
    },
    clearLocationData: (state) => {
      state.locationInfo = {
        id: null,
        name: "",
        category: "",
        subcategories: [],
        priceRange: "",
        city: "",
        description: "",
        amenities: [],
        phoneNumbers: [],
        email: "",
        whatsapp: "",
        instagram: "",
        website: "",
        address: "",
      };
      state.locationAvailability = {
        days: [],
      };
      state.uploadedImageFile = null;
    },
  },
});

export const {
  setLocationAvailability,
  setLocationInfo,
  updateSpecificLocationData,
  setUploadedImageFile,
  setUploadedImageFileAs64,
  clearLocationData,
} = locationSlice.actions;

export const selectLocation = (state) => state.location.locationInfo;
export const selectLocationAvailability = (state) =>
  state.location.locationAvailability;
export const selectUploadedImageFile = (state) =>
  state.location.uploadedImageFile;
