import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineClockCircle } from "react-icons/ai";
import subserviceServices from "services/service/subserviceServices";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import { BsDownload, BsPrinter } from "react-icons/bs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment-timezone";
import useLocations from "utils/hooks/useLocations";

const InvoiceView = ({ data, newDate }) => {
  const {
    id,
    userName,
    userPhoneNumber,
    totalPrice,
    paymentStatus,
    notes,
    provider: { name: providerName },
    date,
    subserviceIds,
    childSubserviceIds,
  } = data;

  const [items, setItems] = useState([]);
  const [location, setLocation] = useState("");
  const { fetchLocation } = useLocations();

  function getPaymentMethodName(slug) {
    switch (slug) {
      case "KNET":
        return "Knet";
      case "CREDIT_CARD":
        return "Credit Card";
      case "APPLE_PAY":
        return "Apple Pay";
      case "GOOGLE_PAY":
        return "Google Pay";
      case "SAMSUNG_PAY":
        return "SamsungPay";
      case "OFFLINE":
        return "Offline";
      default:
        return "Unknown";
    }
  }

  useEffect(() => {
    const fetchSubservices = async () => {
      const subservices = await Promise.all(
        subserviceIds.map((id) => subserviceServices.getSubservice(id))
      );
      const childSubservices = await Promise.all(
        childSubserviceIds.map((id) =>
          childSubserviceServices.getSubservice(id)
        )
      );
      const allServices = [...subservices, ...childSubservices];
      setItems(
        allServices
          .map((service) => service.subservice)
          .filter((service) => service)
      );
    };

    fetchSubservices();
  }, [subserviceIds, childSubserviceIds]);

  useEffect(() => {
    if (!data.locationId) return;
    fetchLocation(data.locationId).then((location) => {
      setLocation(location);
    });
  }, [data]);

  const handleDownloadPdf = async () => {
    const convertMinutesToHoursAndMinutes = (minutes) => {
      let hours = Math.floor(minutes / 60);
      let remainingMinutes = minutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    };

    const renderItems = items
      .map((item) => {
        return `
          <div style="display: flex; width: 100%; justify-content: space-between;">
            <p style="font-size: 14px; width: 180px; font-weight: bold;">${
              item.name ? item.name.en || item.name.ar : "Unnamed"
            }</p>
            <p style="font-size: 14px; width: 100%;">${convertMinutesToHoursAndMinutes(
              item.duration
            )}</p>
            <p style="font-size: 14px; width: 100%;">KWD ${item.price}</p>
          </div>
        `;
      })
      .join("");

    const input = document.createElement("div");
    input.style.position = "fixed";
    input.style.top = "0";
    input.style.left = "0";
    input.style.zIndex = "-1000";
    input.style.backgroundColor = "white";
    input.innerHTML = `
      <div style="padding: 20px; width: 500px; justify-content: center; align-items: center; display: flex; flex-direction: column; font-family: Arial, sans-serif;">
        <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">${
          location.name
            ? `${location.name.en} ${
                location.name.ar ? ` - ${location.name.ar}` : ""
              }`
            : "No Location Name"
        }</p>
        <p style="font-size: 14px; font-weight: 400; margin-bottom: 10px; color: #00000073">${
          location.address
        }</p>
        <p style="font-size: 16px; font-weight: bold; margin-bottom: 10px;">Invoice</p>
        <div style="display: flex;width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Invoice ID:</p>
          <p style="font-size: 14px;width: 100%;">${id}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Date:</p>
          <p style="font-size: 14px;width: 100%;">${moment(newDate)
            .utc()
            .format("DD.MM.YYYY HH:mm:ss")}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Customer Name:</p>
          <p style="font-size: 14px;width: 100%;">${userName}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Phone Number:</p>
          <p style="font-size: 14px;width: 100%;">${userPhoneNumber}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Total Price:</p>
          <p style="font-size: 14px;width: 100%;">KWD ${totalPrice}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Payment Type:</p>
          <p style="font-size: 14px; width: 100%;">${getPaymentMethodName(
            data.invoice.payment.paymentMethod
          )}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Notes:</p>
          <p style="font-size: 14px;width: 100%;">${notes || "No notes"}</p>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; padding: 10px 0; border-bottom: 1px solid #d3d3d3;">
          <p style="font-size: 14px; width: 180px; font-weight: bold;">Items:</p>
          <div style="display: flex; flex-direction: column; width: 100%;">
            ${renderItems}
          </div>
        </div>
      </div>
    `;

    document.body.appendChild(input);

    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save("invoice.pdf");
        document.body.removeChild(input);
      });
    }, 1000); // Adjust the timeout as needed to ensure rendering
  };

  const handlePrint = () => {
    window.print();
  };
  return (
    <InvoiceContainer>
      <SalonName>
        {location
          ? `${location.name.en} ${
              location.name.ar ? ` - ${location.name.ar}` : ""
            }`
          : "No Location Name"}
      </SalonName>
      <SalonAddress>
        {location ? location.address : "No Location Address"}
      </SalonAddress>
      <InvoiceDetails>
        <Detail>
          <strong>Invoice:</strong> <LightText>#{id}</LightText>
        </Detail>
        <Divider />
        <Detail>
          <strong>Date:</strong>{" "}
          <LightText>
            {moment(newDate).utc().format("DD.MM.YYYY HH:mm:ss")}
          </LightText>
        </Detail>
        <Divider />
        <Detail>
          <strong>Customer Name:</strong> <LightText>{userName}</LightText>
        </Detail>
        <Divider />
        <Detail>
          <strong>Phone number:</strong>{" "}
          <LightText>{userPhoneNumber}</LightText>
        </Detail>
        <Divider />
        <DetailContainer>
          <strong>Items:</strong>
          <ItemsList>
            {items.map((item, index) => (
              <ItemDetail key={index}>
                <LightText>
                  {item.name?.en || item.name?.ar || "Unnamed"}
                </LightText>
                <LightText>
                  <ClockText>
                    <AiOutlineClockCircle /> {item.duration} mins
                  </ClockText>
                </LightText>
                <LightText>KD {item.price}</LightText>
              </ItemDetail>
            ))}
          </ItemsList>
        </DetailContainer>
        <Divider />
        <Detail>
          <strong>Total price:</strong> <LightText>KWD {totalPrice}</LightText>
        </Detail>
        <Divider />
        <Detail>
          <strong>Payment type:</strong>{" "}
          <LightText>
            {getPaymentMethodName(data.invoice.payment.paymentMethod)}
          </LightText>
        </Detail>
        <Divider />
        <Detail>
          <strong>Notes:</strong> <LightText>{notes}</LightText>
        </Detail>
      </InvoiceDetails>
      <ActionsModal>
        <ActionButton onClick={handlePrint}>
          <BsPrinter />
        </ActionButton>
        <ActionButton onClick={handleDownloadPdf}>
          <BsDownload />
        </ActionButton>
      </ActionsModal>
    </InvoiceContainer>
  );
};

export default InvoiceView;

const InvoiceContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 420px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SalonName = styled.h2`
  margin-top: 0;
  text-align: center;
  font-weight: bold;
`;

const SalonAddress = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #00000073;
`;

const InvoiceDetails = styled.div`
  margin: 10px 0;
`;

const Detail = styled.div`
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
`;

const LightText = styled.span`
  color: #808080;
`;

const DetailContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
`;

const ItemsList = styled.div`
  margin-left: 10px;
  flex: 1;
`;

const Divider = styled.div`
  border-bottom: 1px solid #d3d3d3;
  margin: 10px 0;
`;

const ItemDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  span {
    margin-left: 10px;
  }
`;

const ClockText = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const ActionsModal = styled.div`
  position: absolute;
  top: 10px;
  right: -60px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  margin: 5px 0;
  cursor: pointer;
`;
