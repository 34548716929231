// apiConfig.js
import axios from "axios";

const api = axios.create({
  baseURL: "https://dev.admin.tryspots.net/api",

  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
  // You can include other default settings
});

// Interceptors are useful for handling global API request configurations
api.interceptors.request.use(
  (config) => {
    // Do something before request is sent, like setting auth tokens
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default api;
