// categoryService.js
import api from "../apiConfig";

const categoryService = {
  // GET request to retrieve categories
  getCategories: async () => {
    try {
      const response = await api.get("/category");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getCategory: async (id) => {
    try {
      const response = await api.get(`/category/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new category
  addCategory: async (categoryData) => {
    try {
      const response = await api.post("/category", categoryData);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // PUT request to update an existing category
  updateCategory: async (id, updateData) => {
    try {
      const response = await api.put(`/category/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a category
  deleteCategory: async (id) => {
    try {
      const response = await api.delete(`/category/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
};

export default categoryService;
