// src/components/Checkbox.js
import React from "react";

const Checkbox = ({ label, onChange, checked, disabled }) => {
  return (
    <div className="flex items-center">
      <input
        id="remember-me"
        type="checkbox"
        className="w-4 h-4 text-red-600 cursor-pointer md:w-4 md:h-4 form-checkbox"
        style={{ accentColor: "#DE0607" }}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor="remember-me"
        className="block ml-2 text-16 pt-2 font-medium text-[#000000D9]"
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
