import api from "../apiConfig";

const usersService = {
  // GET request to retrieve users
  getUsers: async () => {
    try {
      const response = await api.get("/users?perPage=1000");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getUsersWRole: async (role) => {
    try {
      const response = await api.get("/users?role=" + role);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getUsersWRoleAndLocation: async (role, locationId) => {
    try {
      const response = await api.get(
        "/users?role=" + role + "&location_id=" + locationId
      );
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getUser: async (id) => {
    try {
      const response = await api.get(`/users/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new user
  addUser: async (userData) => {
    try {
      const response = await api.post("/users/profile", userData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing user
  updateUser: async (id, updateData) => {
    try {
      const response = await api.put(`/users/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a user
  deleteUser: async (id) => {
    try {
      const response = await api.delete(`/users/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
  getUsersWQuery: async (query) => {
    try {
      const response = await api.get(`/users?${query}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
  addProvider: async (providerData) => {
    try {
      const response = await api.post("/users/register-provider", providerData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default usersService;
