import React from "react";

// Custom input component for service names
const InputFieldWithLanguage = ({
  placeholder,
  className,
  value = "",
  onChange,
  language,
  dir = "ltr",
  labelClassName,
  isDisabled = false,
}) => {
  return (
    <div className={`flex items-center w-full h-10 ${className}`}>
      <span
        className={`bg-[#FAFAFA] min-w-10 w-fit flex justify-center items-center text-center h-full border border-r-0 rounded-l-md border-[#D9D9D9] ${labelClassName}`}
      >
        {language}
      </span>
      <input
        type="text"
        disabled={isDisabled}
        placeholder={placeholder}
        className={`flex-1 px-2 py-1 border h-full text-14 rounded-r-md border-[#D9D9D9]
        ${isDisabled ? "bg-[#F5F5F5]  text-[#00000040]" : "bg-white"}
        `}
        dir={dir}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputFieldWithLanguage;
