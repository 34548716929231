import { createSlice } from "@reduxjs/toolkit";

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState: {
    activeTab: "Details",
    userId: null,
    userName: null,
    userEmail: null,
    userGender: null,
    userPhoneNumber: null,
    userAge: null,
    discountId: null,
    type: "ONLINE",
    bookingDestination: null,
    address: null,
    city: null,
    blockNumber: null,
    streetNumber: null,
    houseNumber: null,
    notes: null,
    paymentMethod: null,
    paymentLink: null,
    locationId: null,
    location: {},
    locationData: {},
    cityId: null,
    totalPrice: null,
    serviceCount: 0,
    properties: [
      {
        subserviceId: null,
        childSubserviceId: null,
        date: null,
        providerId: null,
        price: null,
        service: {},
        staff: {},
        availableHours: [],
      },
    ],
    settings: [],
  },
  reducers: {
    setSpecificBookingData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    clearBookingData: (state) => {
      state.activeTab = "Details";
      state.userId = null;
      state.userName = null;
      state.userEmail = null;
      state.userGender = null;
      state.userPhoneNumber = null;
      state.userAge = null;
      state.discountId = null;
      state.type = "ONLINE";
      state.bookingDestination = null;
      state.address = null;
      state.city = null;
      state.blockNumber = null;
      state.streetNumber = null;
      state.houseNumber = null;
      state.notes = null;
      state.paymentMethod = null;
      state.paymentLink = null;
      state.locationId = null;
      state.location = {};
      state.locationData = {};
      state.cityId = null;
      state.totalPrice = null;
      state.serviceCount = 0;
      state.properties = [
        {
          subserviceId: null,
          childSubserviceId: null,
          date: null,
          providerId: null,
          price: null,
          service: null,
          staff: null,
          availableHours: [],
        },
      ];
      state.settings = [];
    },
    clearServices: (state) => {
      state.properties = [
        {
          subserviceId: null,
          childSubserviceId: null,
          date: null,
          providerId: null,
          price: null,
          service: null,
          staff: null,
          availableHours: [],
        },
      ];
    },
  },
});

export const {
  setSpecificBookingData,
  setProperties,
  clearBookingData,
  clearServices,
} = bookingsSlice.actions;

export const selectBookings = (state) => state.bookings;
export const selectProperties = (state) => state.bookings.properties;
export const selectLocationId = (state) => state.bookings.locationId;
export const selectLocation = (state) => state.bookings.locationData;
export const selectActiveTab = (state) => state.bookings.activeTab;
export const selectTotalPrice = (state) => state.bookings.totalPrice;
