import api from "services/apiConfig";

const discountServices = {
  getDiscounts: async (businessId) => {
    try {
      const response = await api.get(
        `/discount?${businessId ? `businessId=${businessId}` : ""}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  getDiscount: async (id) => {
    try {
      const response = await api.get(`/discount/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createDiscount: async (data) => {
    try {
      const response = await api.post("/discount", data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateDiscount: async (id, data) => {
    try {
      const response = await api.put(`/discount/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  deleteDiscount: async (id) => {
    try {
      const response = await api.delete(`/discount/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};

export default discountServices;
