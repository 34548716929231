import React, { useEffect, useState } from "react";
import LocationList from "./HomeServiceComponents/LocationList";
import LocationForm from "./HomeServiceComponents/LocationForm";
import useLocations from "utils/hooks/useLocations";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const HomeServiceTab = ({ data, setBusinessData }) => {
  const [locationsData, setLocationsData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { locations, fetchVendorLocations, fetchLocation } = useLocations();
  const businessId = useSelector(selectBusinessId);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [isHomeService, setIsHomeService] = useState(false);

  const handleSelectLocation = (location) => {
    if (selectedLocation?.id === location.id) {
      setSelectedLocation(null);
      return;
    }
    fetchLocation(location.id).then((response) => {
      setSelectedLocation(response);
    });
  };

  const handleSave = (updatedLocation) => {
    const updatedLocations = locationsData.map((location) =>
      location.id === updatedLocation.id ? updatedLocation : location
    );
    setLocationsData(updatedLocations);
    setSelectedLocation({
      ...selectedLocation,
      settings: updatedLocation,
    });
  };

  useEffect(() => {
    setLocationsData(locations);
    if (data) {
      setIsHomeService(data.homeServiceEnabled);
    }
  }, [locations, data]);

  useEffect(() => {
    businessId &&
      fetchVendorLocations(businessId).then((response) => {
        setFetchedLocations(response);
      });
  }, [businessId]);

  return (
    <LoaderWrapper isLoading={locations.length === 0}>
      <div className="h-full ">
        <div className="flex flex-row bg-white">
          <div className="w-2/5 rounded-md border-r border-[#0000000F] min-h-[500px] h-full">
            <p className="text-16 px-4 font-medium leading-6 text-[#000000D9]">
              Locations
            </p>
            <div className="w-full mt-6 border-b" />
            {fetchedLocations.length > 0 ? (
              <LocationList
                fetchedLocations={fetchedLocations}
                selectedLocation={selectedLocation}
                onSelect={handleSelectLocation}
              />
            ) : (
              <div className="text-16 flex justify-center items-center font-medium leading-6 text-[#000000D9]">
                No Location found
              </div>
            )}
          </div>

          <div className="w-3/5 ml-2 rounded-md shadow">
            {selectedLocation ? (
              <LocationForm
                data={selectedLocation}
                setData={setSelectedLocation}
                onSave={handleSave}
                isHomeService={isHomeService}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-1/2">
                <p className="text-16 font-medium leading-6 text-[#000000D9]">
                  Select a location to view or edit details
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default HomeServiceTab;
