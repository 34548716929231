import serviceServices from "../../../services/service/serviceServices";
import { showError, showSuccess } from "../../../utils/helpers";

const fetchServices = async ({ setData }) => {
  try {
    await serviceServices.getServices().then((response) => {
      setData(response);
    });
  } catch (error) {
    showError(error.message);
  }
};

const putService = async ({ id, updateData }) => {
  try {
    await serviceServices
      .updateService(id, updateData)
      .then((response) => {
        showSuccess("Service updated successfully");
      })
      .catch((error) => {
        showError(error.message);
      });
  } catch (error) {
    showError(error.message);
  }
};

const postService = async (newService) => {
  try {
    await serviceServices.addService(newService).then((response) => {
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          break;
        default:
          showError(response.response.data.message);
          break;
      }
    });
  } catch (error) {
    showError(error.message);
  }
};

const convertLocation = (location) => {
  // Add your code here
  const convertedLocation = {
    id: location.id,
    createdAt: location.createdAt,
    updatedAt: location.updatedAt,
    businessId: location.businessId,
    name: location.name,
    slug: location.slug,
    description: location.description,
    availabilityId: location.availabilityId,
    phone: location.phone,
    email: location.email,
    whatsapp: location.whatsapp,
    instagram: location.instagram,
    website: location.website,
    address: location.address,
    cityId: location.cityId,
    images: location.images,
    categoryId: location.categoryId,
    isOpen: location.isOpen,
    subcategoryIds: location.subcategoryIds,
    serviceIds: location.serviceIds,
    subserviceIds: location.subserviceIds,
    availability: location.availability,
    business: location.business,
    reviews: location.reviews,
    averageRating: location.averageRating,
  };

  return convertedLocation;
};

export { fetchServices, putService, postService, convertLocation };
