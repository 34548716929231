import React, { useEffect, useState } from "react";
import Table from "../../core/Table/TableComponent";
import InputField from "../../core/inputField";
import { CiSearch } from "react-icons/ci";
import Button from "../../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import usersData from "../../../constants/data/usersData";
import AddListingModal from "../AddListingModal";
import TableListings from "../components/TableListings";
import ListingDetails from "../EditListingPage/ListingDetails";
import { LuUser2 } from "react-icons/lu";
import UserTableList from "./components/UserTableList/UserTableList";
import EditUserModal from "./components/EditUser/EditUserModal";
import AddUserModal from "./components/AddUser/AddUserModal";
import usersService from "../../../services/users/usersService";
import {
  getAgeGroupString,
  showError,
  showSuccess,
} from "../../../utils/helpers";

function Users() {
  const [users, setUsers] = useState([]);
  const [fullUsers, setFullUsers] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [filteredUsersListings, setFilteredUsersListings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUsers = async () => {
    try {
      const response = await usersService.getUsers();
      const users = response.users.filter((user) => user.deleted === false);
      setFullUsers(users);

      let data = users.map((user) => {
        const birthday = new Date(user.birthDate);
        const today = new Date();
        let age = today.getFullYear() - birthday.getFullYear();
        const m = today.getMonth() - birthday.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
          age--;
        }

        return {
          name: `${user.name} ${user.surname || ""}`,
          email: user.email,
          phoneNumber: user.phone,
          gender: user.gender,
          age: getAgeGroupString(user.ageGroup),
          booking: user.bookingsCount,
          reviews: user.reviewsCount,
          id: user.id,
        };
      });

      setUsers(data);

      // Apply search filter if there's a search query
      if (searchQuery) {
        const filteredData = data.filter((user) =>
          user.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredUsersListings(filteredData);
      } else {
        setFilteredUsersListings(data);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  function removeSpaces(inputString) {
    return inputString.replace(/\s+/g, "");
  }

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    if (removeSpaces(value) === "" || value == null) {
      setFilteredUsersListings(users); // Reset to full user list
      return;
    }

    const newFilteredUsersListings = users.filter((user) =>
      user.name.toLowerCase().includes(value)
    );
    setFilteredUsersListings(newFilteredUsersListings);
  };

  if (!isEditOpen) {
    return (
      <div className="flex-1 mt-4 bg-white">
        <div className="p-6">
          <div className="flex items-center justify-between w-full p-4">
            <InputField
              placeholder="Search"
              onChange={handleSearch}
              icon={<CiSearch size={20} color="#00000099" />}
              value={searchQuery}
            />
          </div>
          <UserTableList
            data={filteredUsersListings}
            headers={[
              "Name",
              "Email",
              "Phone number",
              "Gender",
              "Age",
              "Bookings",
              "Reviews",
            ]}
            numberOfRows={10}
            setData={setFilteredUsersListings}
            setIsModalOpen={(value) => setIsModalOpen(value)}
            setIsAddUserModalOpen={(value) => setIsAddUserModalOpen(value)}
            setEditUser={(value) => setEditUser(value)}
            users={users}
            fullUsers={fullUsers}
            fetchUsers={() => fetchUsers()}
            setCurrentDataAll={setCurrentData}
            currentData={currentData}
          />
        </div>

        <EditUserModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setIsModalOpen={(value) => setIsModalOpen(value)}
          selectedUser={editUser}
          users={users}
          fetchUsers={fetchUsers}
          currentData={currentData}
        />
        <AddUserModal
          isOpen={isAddUserModalOpen}
          onClose={() => setIsAddUserModalOpen(false)}
          setIsModalOpen={(value) => setIsAddUserModalOpen(value)}
          setUsers={(value) => setUsers(value)}
          setFilteredUsersListings={(value) => setFilteredUsersListings(value)}
          users={users}
          filteredUsersListings={filteredUsersListings}
        />
      </div>
    );
  } else {
    return <ListingDetails />;
  }
}

export default Users;
