import React, { useEffect, useState } from "react";
import "./styles.css";
import ImageInput from "components/core/ImageInput";
import ImageInputUniqueSize from "./ImageInputUniqueSize";
import { showError, showSuccess } from "utils/helpers";
import photoService from "services/photoService";

function BannerUpload({
  item,
  index,
  listings,
  setItemImageDetails,
  setItemActive,
  setItemLocationDetails,
}) {
  const [isToggled, setIsToggled] = useState(item?.active ? item.active : true);
  const [selectedImage, setSelectedImage] = useState();

  const toggleSwitch = () => {
    const newValue = !isToggled;
    setItemActive(newValue, index);
    setIsToggled(!isToggled);
  };

  const uploadImage = async (image) => {
    if (image === null || image === undefined || image === "") {
      setSelectedImage("");
      setItemImageDetails("", index);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      setItemImageDetails(response[0], index);
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };

  const setLocationId = async (locationId) => {
    setItemLocationDetails(locationId, index);
  };

  useEffect(() => {
    setIsToggled(item?.active);
  }, [item]);

  return (
    <div className="banner-upload-container">
      <div className="banner-upload-header">
        <div className="banner-upload-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={isToggled}
              onChange={toggleSwitch}
            />
            <span className={`slider round ${isToggled ? "on" : "off"}`}>
              {isToggled ? "" : ""}
            </span>
          </label>
        </div>
        <h2>Banner {index + 1}</h2>
      </div>
      <div className="banner-upload-body">
        <ImageInputUniqueSize
          value={item.image != null ? item.image : null}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          containerHeight={130}
          containerWidth={"100%"}
        />

        <div className="banner-upload-dropdown">
        <select
          value={item?.locationId || ""}
          onChange={(e) => setLocationId(e.target.value)}
        >
          <option value="">Select a listing to attach</option>
          {listings?.map((listing) => (
            <option key={listing.id} value={listing.id}>
              {listing.name.en}
            </option>
          ))}
        </select>
        </div>
        <div className="banner-upload-requirements">
          <p>Requirements for images:</p>
          <ul>
            <li>PNG or JPG format</li>
            <li>Aspect ratio height/width ~1/2</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BannerUpload;
