import React, { useState, useEffect } from "react";
import "./styles.css";
import serviceServices from "services/service/serviceServices";
import subserviceServices from "services/service/subserviceServices";
import locationService from "services/location/locationService";
import userService from "services/user/userService";
import {
  getAgeGroupString,
  isObjectEmpty,
  showError,
  showSuccess,
} from "utils/helpers";
import { FaCircle } from "react-icons/fa";
import bookingService from "services/booking/bookingService";
import CalendarIcon from "assets/Icons/calendarIcon.svg";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import { BsClock, BsPeople } from "react-icons/bs";
import CashIcon from "../../../../assets/cash-icon.svg";
import CardIcon from "../../../../assets/card-icon.svg";
import InternetPaymentIcon from "../../../../assets/internet-banking-icon.svg"; //../../../../../assets/internet-banking-icon.svg";
import TextArea from "components/core/textArea";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDollar } from "react-icons/ai";
import Select from "react-select";
import styled from "styled-components";
import InvoiceView from "./InvoicesView";
import { selectRole } from "../../../../redux/auth/authSlice";
import DatePickerComponent from "../DatePicker";
import moment from "moment-timezone";
import providersService from "services/providers/providersService";

const paymentOptions = [
  { value: "knet", label: "Knet" },
  { value: "credit-card", label: "Credit Card" },
  { value: "google-pay", label: "Google Pay" },
  { value: "samsung-pay", label: "Samsung Pay" },
  { value: "apple-pay", label: "Apple Pay" },
];

const BookingDetailsBig = ({
  isOpen,
  onClose,
  bookingDetails,
  selectedLocationId,
  selectedLocation,
  fetchBookings,
  isCreate = false,
}) => {
  const [selectedTab, setSelectedTab] = useState("details");
  const [message, setMessage] = useState("");
  const [service, setService] = useState();

  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerGender, setCustomerGender] = useState("");
  const [customerAge, setCustomerAge] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [bookingTime, setBookingTime] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState(0);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [locations, setLocations] = useState([]);
  const [updatedBookingDate, setUpdatedBookingDate] = useState("");
  const [availability, setAvailability] = useState([]);
  const [duration, setDuration] = useState();
  const [address, setAddress] = useState("");
  const [user, setUser] = useState(null);
  const [totalPriceShow, setTotalPriceShow] = useState(0);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showInvoices, setShowInvoices] = useState(false);
  const userRole = useSelector(selectRole);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#d9d9d9",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#d9d9d9",
      },
      borderRadius: 6,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#d9d9d9",
      "&:hover": {
        color: "#d9d9d9",
      },
    }),
  };

  const convertPaymentMethodToPaymentOption = (paymentMethod) => {
    switch (paymentMethod) {
      case "KNET":
        return paymentOptions[0];
      case "CREDIT_CARD":
        return paymentOptions[1];
      case "GOOGLE_PAY":
        return paymentOptions[2];
      case "SAMSUNG_PAY":
        return paymentOptions[3];
      case "APPLE_PAY":
        return paymentOptions[4];
      default:
        return null;
    }
  };

  const maxLength = 100;

  const formatDateInHours = (dateString) => {
    const date = new Date(dateString);

    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  };

  const setDatas = async () => {
    if (isCreate === false) {
      fetchService();
      if (!isObjectEmpty(bookingDetails)) {
        setCustomerName(bookingDetails.userName);
        setCustomerNumber(bookingDetails.userPhoneNumber);
        setCustomerEmail(bookingDetails.userEmail);
        setCustomerGender(bookingDetails.userGender);
        setCustomerAge(
          bookingDetails?.userAgeGroup != null
            ? bookingDetails?.userAgeGroup
            : bookingDetails?.user?.ageGroup
        );
        setUpdatedBookingDate(bookingDetails.date);
        setBookingDate(bookingDetails.date);
        setBookingTime(formatDateInHours(bookingDetails.date));
        setTotalPrice(bookingDetails.totalPrice);
        setPaymentStatus(bookingDetails.paymentStatus);
        setNotes(bookingDetails.notes);
        setSelectedStaffId(bookingDetails?.provider?.id);
        setSelectedServiceType(bookingDetails.bookingDestination);
        setDuration(bookingDetails.duration);
        setAddress(bookingDetails.address);

        if (userRole !== "PROVIDER") {
          const userTemp = await userService.getUser(bookingDetails.userId);
          setUser(userTemp.user);
          let price = 0;
          await userTemp?.user?.bookings?.map((booking) => {
            price += booking?.totalPrice;
          });

          setTotalPriceShow(price);
        } else {
          const userTemp = await bookingService.getBookingWQuery(
            `userId=${bookingDetails.userId}`
          );
          setUser({
            bookings: userTemp.bookings,
          });
          let price = 0;
          userTemp.bookings &&
            userTemp.bookings.map((booking) => {
              price += booking.totalPrice;
            });
          setTotalPriceShow(price);
        }
      }
    }
  };

  const handleMessageChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setMessage(event.target.value);
    }
  };

  const handleSendMessage = () => {
    alert(`Message to send: ${message}`);
    setMessage("");
  };

  const updateSelectedTab = (value) => {
    setSelectedTab(value);
  };

  const fetchService = async () => {
    try {
      if (
        bookingDetails.subserviceIds &&
        bookingDetails.subserviceIds.length > 0
      ) {
        const serviceId = bookingDetails.subserviceIds[0];
        if (serviceId) {
          const response = await subserviceServices.getSubservice(serviceId);
          setService(response.subservice);
        }
      }
      if (
        bookingDetails.childSubserviceIds &&
        bookingDetails.childSubserviceIds.length > 0
      ) {
        const serviceId = bookingDetails.childSubserviceIds[0];
        if (serviceId) {
          const response = await childSubserviceServices.getSubservice(
            serviceId
          );
          setService(response.subservice);
        }
      }
    } catch (e) {
      showError(e.message || "An error occurred while fetching subservice");
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await locationService.getLocations();

      setLocations(response.locations);
    } catch (error) {
      showError(error.message);
    }
  };

  const changeStatus = async (status) => {
    const body = {
      status: status,
      address: address,
      notes: notes,
      date: moment(updatedBookingDate).toISOString(),
    };

    const response = await bookingService.updateBooking(
      bookingDetails.id,
      body
    );

    fetchBookings();
    //onClose();
  };

  const changeDate = async (date) => {
    const body = {
      date: moment(date).toISOString(),
    };

    try {
      const response = await bookingService.updateBooking(
        bookingDetails.id,
        body
      );

      switch (response.statusCode) {
        case 200:
          showSuccess("Booking date updated successfully");
          break;
        default:
          showError("An error occurred while updating booking date");
          break;
      }

      fetchBookings();
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchProviderAvailability = async (providerId, locationId) => {
    try {
      const response = await providersService.getProviderById(providerId);
      switch (response.statusCode) {
        case 200:
          const provider = response.provider;
          const availability = provider.availability.filter(
            (availability) => availability.locationId === locationId
          );
          if (availability.length === 0) {
            showError("No availability left on that day");
          }
          const newAvailability = [{ days: availability[0].days }];
          setAvailability(newAvailability);
          break;
        default:
          showError("An error occurred while fetching provider availability");
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    fetchService();
    fetchLocations();
    setDatas();
    if (
      bookingDetails &&
      bookingDetails.invoice &&
      bookingDetails.invoice.payment
    ) {
      bookingDetails.invoice.payment.paymentMethod === "OFFLINE"
        ? setSelectedPaymentMethod(0)
        : setSelectedPaymentMethod(1);
      setSelectedOption(
        convertPaymentMethodToPaymentOption(
          bookingDetails.invoice.payment.paymentMethod
        )
      );
    }
  }, [selectedLocationId, bookingDetails]);

  useEffect(() => {
    if (bookingDetails.providerId && bookingDetails.locationId) {
      fetchProviderAvailability(
        bookingDetails.providerId,
        bookingDetails.locationId
      );
    }
  }, [bookingDetails]);

  if (!isOpen) return null;

  const DetailTabs = ({ selectedTab, updateSelectedTab }) => {
    return (
      <header className="header-details-tab">
        <nav className="navigation-details">
          <ul className="nav-list-details">
            <li
              onClick={() => updateSelectedTab("details")}
              className={
                selectedTab === "details"
                  ? "nav-item-details active"
                  : "nav-item-details"
              }
            >
              <a href="#">Details</a>
            </li>
            <li
              onClick={() => updateSelectedTab("services")}
              className={
                selectedTab === "services"
                  ? "nav-item-details active"
                  : "nav-item-details"
              }
            >
              <a href="#">Services</a>
            </li>
            <li
              onClick={() => updateSelectedTab("notes")}
              className={
                selectedTab === "notes"
                  ? "nav-item-details active"
                  : "nav-item-details"
              }
            >
              <a href="#">Notes</a>
            </li>
            {/* <li onClick={()=>updateSelectedTab('message')} className={selectedTab=="message"?"nav-item-details active":"nav-item-details"}><a href="#">Message</a></li> */}
          </ul>
        </nav>
      </header>
    );
  };

  if (showInvoices) {
    return (
      <Container>
        <Header style={{ width: "100%" }}>
          <BackButton
            onClick={() => {
              setShowInvoices(false);
              onClose();
            }}
          >
            ←
          </BackButton>
          <Title style={{ textAlign: "center" }}>View Invoice</Title>
        </Header>
        <Content>
          <InvoiceView data={bookingDetails} newDate={updatedBookingDate} />
        </Content>
      </Container>
    );
  } else {
    if (isCheckedOut) {
      return (
        <div className="modal-overlay-booking-half">
          <div className="modal-booking-half">
            <div className="modal-header-booking-half">
              <h3 className="modal-header-title-booking-half">
                Booking Details
              </h3>
              <button
                className="close-button"
                onClick={() => {
                  setIsCheckedOut(false);
                  onClose();
                }}
              >
                x
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <h2
                style={{
                  borderBottomWidth: 1,
                  padding: 5,
                  paddingBottom: 10,
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: 22,
                }}
              >
                {service?.name?.en ? service.name.en : service?.name}
              </h2>
              <div className="service-details">
                <div
                  style={{ flexDirection: "row", display: "flex", padding: 5 }}
                >
                  <BsPeople
                    color="#8c8c8c"
                    size={18}
                    style={{ alignSelf: "center" }}
                  />
                  {isCreate ? (
                    <input
                      style={{
                        textAlign: "center",
                        marginLeft: 10,
                        textAlignLast: "left",
                      }}
                      value={isCreate ? "" : bookingDetails.provider.name}
                    />
                  ) : (
                    <span style={{ marginLeft: 10 }}>
                      {bookingDetails.provider.name}
                    </span>
                  )}
                </div>
                <div
                  style={{ flexDirection: "row", display: "flex", padding: 5 }}
                >
                  <BsClock
                    color="#8c8c8c"
                    size={18}
                    style={{ alignSelf: "center" }}
                  />
                  {isCreate ? (
                    <input
                      style={{
                        textAlign: "center",
                        marginLeft: 10,
                        textAlignLast: "left",
                      }}
                      value={duration + "h"}
                    />
                  ) : (
                    <span style={{ marginLeft: 10 }}>
                      {duration > 60
                        ? `${Math.floor(duration / 60)}h ${duration % 60}m`
                        : `${duration}m`}
                    </span>
                  )}
                </div>
                <div
                  style={{ flexDirection: "row", display: "flex", padding: 5 }}
                >
                  <AiOutlineDollar
                    color="#8c8c8c"
                    size={18}
                    style={{ alignSelf: "center" }}
                  />
                  {isCreate ? (
                    <input
                      style={{
                        textAlign: "center",
                        marginLeft: 10,
                        textAlignLast: "left",
                      }}
                      value={"KWD " + totalPrice}
                    />
                  ) : (
                    <span style={{ marginLeft: 10 }}>
                      {"KWD " + totalPrice}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-start space-x-3 total-amount">
                <p style={{ fontWeight: "bold", fontSize: 14 }}>
                  Total amount:
                </p>
                {isCreate ? (
                  <p
                    style={{ borderWidth: 1, borderRadius: 5, marginLeft: 5 }}
                    placeholder="total amount"
                  >
                    KWD {totalPrice + bookingDetails.deliveryFee}
                  </p>
                ) : (
                  <div className="flex w-[120px] h-8 border border-[#D9D9D9] rounded-md justify-center items-center">
                    <p className="text-14 font-medium text-[#000000D9] leading-[22px]">
                      KWD {totalPrice + bookingDetails.deliveryFee}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  borderBottomWidth: 1,
                  marginTop: 10,
                  marginBottom: 20,
                }}
              />
              <div className="mb-1">
                <p className="text-12 font-bold text-[#00000073] leading-[20px]">
                  Add notes
                </p>
              </div>
              <TextArea
                value={notes}
                // onChange={(e) => {
                //   setNotes(e.target.value);
                // }}
                placeholder="Enter Notes"
                fieldHeight="200px"
                className="!space-x-0"
              />
              <div
                style={{
                  borderBottomWidth: 1,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              />
              <div className="payment-method">
                <p className="text-16 font-medium text-[#000000D9] leading-6">
                  Payment method
                </p>

                <div className="space-x-5 payment-cards-container">
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginRight: 5,
                      width: "50%",
                    }}
                    className={
                      selectedPaymentMethod == 0
                        ? "method cash selected"
                        : "method cash"
                    }
                  >
                    <button
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginRight: 5,
                      }}
                      disabled
                      // onClick={() => setSelectedPaymentMethod(0)}
                    >
                      <img style={{ paddingRight: 5 }} src={CashIcon}></img>Cash
                    </button>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginRight: 5,
                      width: "50%",
                    }}
                    className={
                      selectedPaymentMethod == 1
                        ? "method online selected"
                        : "method online"
                    }
                  >
                    <button
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginRight: 5,
                      }}
                      // onClick={() => setSelectedPaymentMethod(1)}
                      disabled
                    >
                      <img
                        style={{ paddingRight: 5 }}
                        src={InternetPaymentIcon}
                      ></img>
                      Online
                    </button>
                  </div>
                </div>
                {selectedPaymentMethod == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: 15,
                    }}
                  >
                    <label
                      style={{
                        marginRight: 10,
                        fontWeight: "bold",
                        color: "#8c8c8c",
                        maxWidth: 200,
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      Payment link:<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      value={selectedOption}
                      isDisabled
                      options={paymentOptions}
                      placeholder="Select payment link"
                      styles={customStyles}
                      className="w-full"
                    />
                  </div>
                ) : null}
              </div>
              <div className="services-actions">
                <button
                  onClick={() => setIsCheckedOut(false)}
                  className="cancel-services"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    changeStatus("COMPLETED");
                    setShowInvoices(true);
                  }} //</div>confirmBooking(bookingDetails.id)}
                  className="confirm"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-overlay-booking-half">
          <div className="modal-booking-half">
            <div className="modal-header-booking-half">
              <h3 className="modal-header-title-booking-half">
                Booking Details
              </h3>
              <button
                className="close-button"
                onClick={() => {
                  setUpdatedBookingDate(bookingDate);
                  onClose();
                }}
              >
                x
              </button>
            </div>
            <DetailTabs
              selectedTab={selectedTab}
              updateSelectedTab={(value) => updateSelectedTab(value)}
            />

            {selectedTab === "details" && (
              <div className="p-4 space-y-3">
                <div className="input-container-booking-half">
                  <h1 className="input-title">Customer Name:</h1>
                  <p className="input-text">{bookingDetails.userName}</p>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Number:</h1>
                  <p className="input-text">{bookingDetails.userPhoneNumber}</p>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Email:</h1>
                  <p className="input-text">{bookingDetails.userEmail}</p>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Gender:</h1>
                  <p className="input-text">{bookingDetails.userGender}</p>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Age:</h1>
                  <p className="input-text">
                    {bookingDetails?.userAgeGroup != null
                      ? getAgeGroupString(bookingDetails.userAgeGroup)
                      : getAgeGroupString(bookingDetails?.user?.ageGroup)}
                  </p>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Total Price:</h1>
                  <p className="input-text">
                    {" "}
                    KWD {bookingDetails.totalPrice + bookingDetails.deliveryFee}
                  </p>
                </div>
                <div className="w-full input-container-booking-half">
                  <h1 className="w-1/2 input-title">Payment status:</h1>
                  <div
                    style={{ flexDirection: "row", display: "flex" }}
                    className="w-1/2"
                  >
                    <FaCircle
                      style={{
                        marginRight: 4,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      size={10}
                      color={
                        bookingDetails.invoice.payment.status === "COMPLETED"
                          ? "green"
                          : bookingDetails.invoice.payment.status === "CANCELED"
                          ? "red"
                          : "#FAC714"
                      }
                    />
                    <p className="payment-status-text">
                      {bookingDetails.paymentStatus === "IN_PROCESS"
                        ? "In Process"
                        : bookingDetails.paymentStatus === "COMPLETED"
                        ? "Completed"
                        : bookingDetails.paymentStatus === "CANCELED"
                        ? "Canceled"
                        : "At The Vendor"}
                    </p>
                  </div>
                </div>

                {bookingDetails.paymentStatus === "AT_THE_VENDOR" &&
                  bookingDetails.invoice.payment.deposit === true && (
                    <div className="input-container-booking-short">
                      <h1 className="input-title">Deposit Amount:</h1>
                      <p className="input-text">
                        KWD {bookingDetails.invoice.depositAmount}
                      </p>
                    </div>
                  )}
                {bookingDetails.paymentStatus === "AT_THE_VENDOR" &&
                  bookingDetails.invoice.payment.deposit === true && (
                    <div className="input-container-booking-short">
                      <h1 className="input-title">Remaining Amount:</h1>
                      <p className="input-text">
                        KWD{" "}
                        {bookingDetails.totalPrice -
                          bookingDetails.invoice.depositAmount}
                      </p>
                    </div>
                  )}

                <div className="flex justify-between w-full">
                  <div className="flex flex-col items-center justify-center w-32 border-[#0000000F] border rounded-md h-14">
                    <p className="text-center font-bold text-14 leading-[22px] text-[#000000D9]">
                      {totalPriceShow}
                    </p>
                    <p className="text-center text-[#000000D9] text-14 font-normal leading-[22px]">
                      Total Spent
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center w-32 border-[#0000000F] border rounded-md h-14">
                    <p className="text-center font-bold text-14 leading-[22px] text-[#000000D9]">
                      {user != null ? user?.bookings.length : 0}
                    </p>
                    <p className="text-center text-[#000000D9] text-14 font-normal leading-[22px]">
                      Visits
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center w-32 border-[#0000000F] border rounded-md h-14">
                    <p className="text-center font-bold text-14 leading-[22px] text-[#000000D9]">
                      {user != null
                        ? user?.bookings?.filter(
                            (booking) => booking.status == "CANCELED"
                          ).length
                        : 0}
                    </p>
                    <p className="text-center text-[#000000D9] text-14 font-normal leading-[22px]">
                      Cancellation
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center w-32 border  border-[#0000000F] rounded-md h-14">
                    <p className="text-center font-bold text-14 leading-[22px] text-[#000000D9]">
                      {user != null
                        ? user?.bookings?.filter(
                            (booking) => booking.status == "NO_SHOW"
                          ).length
                        : 0}
                    </p>
                    <p className="text-center text-[#000000D9] text-14 font-normal leading-[22px]">
                      No Shows
                    </p>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "services" && (
              <div className="p-4 space-y-3">
                <div className="input-container-booking-half">
                  <span className="input-title">Service requested:</span>
                  <span className="input-text">
                    {service?.name?.en ? service.name.en : service?.name}
                  </span>
                </div>
                <div className="input-container-booking-half">
                  <span className="input-title">Duration:</span>
                  <span className="input-text">
                    {bookingDetails.duration > 60
                      ? `${Math.floor(bookingDetails.duration / 60)}h ${
                          bookingDetails.duration % 60
                        }m`
                      : `${bookingDetails.duration}m`}
                  </span>
                </div>
                <div className="input-container-booking-half">
                  <span className="input-title">Staff member assigned:</span>
                  <span className="input-text">
                    {bookingDetails.provider.name}
                  </span>
                </div>
                <div className="input-container-booking-half">
                  <h1 className="input-title">Booking date and time:</h1>
                  <DatePickerComponent
                    className={"w-full border-0"}
                    textClassName="!text-14 px-0"
                    startDate={moment(updatedBookingDate).toDate()}
                    setStartDate={setUpdatedBookingDate}
                    locationId={bookingDetails.locationId}
                    providerId={bookingDetails.providerId}
                    serviceDuration={bookingDetails.duration}
                    type={bookingDetails.bookingDestination}
                    showTimeSelect={true}
                    showEndDate={false}
                    availability={availability}
                    onConfirm={(date) => {
                      setUpdatedBookingDate(date);
                      changeDate(date);
                    }}
                  />
                </div>
                <div className="input-container-booking-half">
                  <span className="input-title">Service type:</span>
                  <span className="input-text">
                    {bookingDetails.bookingDestination
                      .slice(0, 1)
                      .toUpperCase() +
                      bookingDetails.bookingDestination.slice(1).toLowerCase()}
                  </span>
                </div>
                <div className="input-container-booking-half">
                  <span className="input-title">Address:</span>
                  <p className="input-text">
                    {bookingDetails.address
                      ? bookingDetails.address
                      : "No address provided"}
                  </p>
                </div>
              </div>
            )}

            {selectedTab === "notes" && (
              <div className="detail-booking-half min-h-36">
                <p
                  className="
                  text-[#000000D9] text-14 font-normal leading-[22px]
                "
                >
                  {bookingDetails.notes
                    ? bookingDetails.notes
                    : "No notes provided"}
                </p>
              </div>
            )}
            {selectedTab === "message" && (
              <div className="send-message-container">
                <div className="message-input-header-booking-half">
                  Whatsapp message
                </div>
                <textarea
                  className="message-input-booking-half"
                  placeholder="Enter a message"
                  value={message}
                  onChange={handleMessageChange}
                />
                <div className="message-input-footer-booking-half">
                  <button
                    className="send-button-booking-half"
                    onClick={handleSendMessage}
                  >
                    Send
                  </button>
                  <span className="char-counter-booking-half">
                    {message.length} / {maxLength}
                  </span>
                </div>
              </div>
            )}

            {userRole !== "PROVIDER" && (
              <div className="modal-actions-booking-half">
                <button
                  onClick={() => changeStatus("NO_SHOW")}
                  className="no-show"
                >
                  No Show
                </button>
                <button
                  onClick={() => changeStatus("CANCELED")}
                  className="cancel"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setIsCheckedOut(true);
                    //changeStatus("COMPLETED")
                  }}
                  className="checkout"
                >
                  Checkout
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
};

const Container = styled.div`
  background-color: gray;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Header = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  margin: 0;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default BookingDetailsBig;
