import Button from "components/core/Button";
import ButtonGroup from "components/core/Button/ButtonGroup";
import InputField from "components/core/inputField";
import TextArea from "components/core/textArea";
import React, { useEffect, useState } from "react";
import notificationService from "services/notifications/notificationService";
import { showError, showSuccess } from "utils/helpers";
import { NotificationSchema } from "utils/schemas/Notification/NotificationSchemas";

const Notification = () => {
  const [buttons, setButtons] = useState([
    { id: 1, label: "En" },
    { id: 2, label: "Ar" },
  ]);

  const [message, setMessage] = useState({
    en: "",
    ar: "",
  });
  const [title, setTitle] = useState({
    en: "",
    ar: "",
  });
  const [selectedButton, setSelectedButton] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSendNotification = async () => {
    setLoading(true);
    try {
      await NotificationSchema.validate(message).then(async () => {
        const response = await notificationService.pushNotification({
          text: message,
          title: title,
        });
        switch (response.statusCode) {
          case 201:
            showSuccess(response.message);
            setMessage({ en: "", ar: "" });
            setTitle({ en: "", ar: "" });
            setLoading(false);
            break;
          default:
            showError(response.message);
            setLoading(false);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setMessage({ en: "", ar: "" });
      setTitle({ en: "", ar: "" });
    };
  }, [selectedButton]);
  return (
    <div className="flex flex-col w-full h-full px-4 pt-6 my-5 bg-white rounded-lg shadow-md">
      <ButtonGroup
        buttons={buttons}
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
      />

      <p className="text-20 text-[#000000D9] font-medium mt-6">
        Push Notifications
      </p>
      <p className="text-14 text-[#000000D9] mt-5">Title</p>
      {selectedButton === 1 ? (
        <InputField
          placeholder="Enter your title here"
          value={title.en}
          onChange={(e) => setTitle({ en: e.target.value })}
          className="mt-2"
        />
      ) : (
        <InputField
          placeholder="أدخل عنوانك هنا"
          value={title.ar}
          onChange={(e) => setTitle({ en: e.target.value })}
          className="mt-2"
          dir="rtl"
        />
      )}

      <p className="text-14 text-[#000000D9] mt-5">Message</p>
      {selectedButton === 1 ? (
        <TextArea
          placeholder="Enter your message here"
          value={message.en}
          onChange={(e) => setMessage({ en: e.target.value })}
          className="mt-2"
        />
      ) : (
        <TextArea
          placeholder="أدخل رسالتك هنا"
          value={message.ar}
          onChange={(e) => setMessage({ en: e.target.value })}
          className="mt-2 "
          dir="rtl"
        />
      )}

      <Button
        label="Send Push Notification"
        className="w-52 bg-[#DE0607] mt-3 rounded-xl text-white"
        textColor="text-white"
        onClick={handleSendNotification}
        loading={loading}
      />
    </div>
  );
};

export default Notification;
