import React from "react";
import { LuTrash } from "react-icons/lu";

const ServiceItem = ({ name, price, duration }) => (
  <tr className="h-10 border-b border-gray-200 hover:bg-gray-100" key={name}>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {name}
      </div>
    </td>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {price}
      </div>
    </td>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {duration}
      </div>
    </td>
  </tr>
);

const ServicesList = ({ services }) => {
  let newServices = [];
  if (services) {
    services.map((service) => {
      if (service.childSubservices && service.childSubservices.length > 0) {
        service.childSubservices.map((childService) => {
          newServices.push({
            id: childService.id,
            name: childService.name.en,
            price: childService.price,
            duration: childService.duration,
          });
        });
      } else {
        newServices.push({
          id: service.id,
          name: service.name.en,
          price: service.price,
          duration: service.duration,
        });
      }
    });
  }

  return (
    <div className="max-w-md mt-4 overflow-hidden bg-white rounded-xl md:max-w-2xl md:mt-0">
      <div className="md:flex-col md:flex md:w-1/2">
        <table className="w-full leading-normal ">
          <thead>
            <tr>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Service
              </th>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Price
              </th>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Duration
              </th>
            </tr>
          </thead>
          <tbody>
            {newServices &&
              newServices.map((service) => (
                <ServiceItem
                  key={service.id}
                  name={service.name}
                  price={service.price}
                  duration={service.duration}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServicesList;
