import { useEffect, useState } from "react";
import SuperAdmin from "./pages/SuperAdmin";
import VendorPage from "./pages/Vendor";
import LoginPage from "./pages/LoginPage";
import { useSelector } from "react-redux";
import {
  selectAccessToken,
  selectAuth,
  selectUser,
} from "./redux/auth/authSlice";
import { Navigate } from "react-router";

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const savedAccessToken = useSelector(selectAccessToken);
  const userAuth = useSelector(selectAuth);

  useEffect(() => {
    if (savedAccessToken) {
      setUser(userAuth.user);
      setRole(userAuth.user.roles[0]);
    } else if (!savedAccessToken) {
      setUser(null);
      setRole(null);
    }
  }, [user, userAuth]);

  if (!user) {
    return <LoginPage />;
  }

  switch (role) {
    case "SUPERADMIN":
      return <SuperAdmin />;
    case "ADMIN":
      return <VendorPage />;
    case "PROVIDER":
      return <VendorPage />;
    case "LOCATION_MANAGER":
      return <VendorPage />;
    default:
      return <Navigate to="/login" replace />;
  }
}

export default App;
