import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import DeleteConfirmModal from "../../SuperAdmin/DeleteConfirmModal";
import { useState } from "react";
import { selectRole } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ServiceCategoryListItem = ({
  service,
  isSelected,
  onSelect,
  onDelete,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openModal = () => setIsDeleteModalOpen(true);
  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };
  const userRole = useSelector(selectRole);

  return (
    <div
      className={`flex items-center justify-between p-4 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
    >
      <div className="flex items-center">
        <span
          className={`ml-5 text-14 font-medium ${
            isSelected ? "text-[#DE0607]" : "text-[#000000D9]"
          }`}
        >
          {service.name.en ?? "No name"} - {service.name.ar ?? "No name"}
        </span>
      </div>

      {/* Actions */}
      <div className="flex flex-row">
        <LuTrash
          className={`w-6 h-6 
              ${
                userRole !== "PROVIDER"
                  ? "text-[#DE0607] cursor-pointer"
                  : "text-gray-300 cursor-not-allowed"
              }
            `}
          onClick={userRole !== "PROVIDER" ? openModal : null}
        />
        {isDeleteModalOpen && (
          <DeleteConfirmModal
            isOpen={isDeleteModalOpen}
            onClose={closeModal}
            onConfirm={() => {
              onDelete(service);
              closeModal();
            }}
          />
        )}
        <div className="h-6 mx-4 border-r border-gray-300" />
        {isSelected ? (
          <IoIosArrowForward className="w-6 h-6 text-[#DE0607] cursor-pointer" />
        ) : (
          <HiDotsVertical
            className="w-6 h-6 text-black cursor-pointer"
            onClick={onSelect}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceCategoryListItem;
