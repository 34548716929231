import React, { useState } from "react";
import FormField from "./FormField";
import Button from "../core/Button";
import Checkbox from "../core/Checkbox";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";
import Link from "./Link";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="w-full md:w-1/2 rounded-md border-[#00000026] shadow-2xl h-[680px] flex flex-col justify-center md:justify-between">
      <h2 className="font-bold text-center md:mt-20 text-38">Sign up</h2>
      <form className="p-4 space-y-4 ">
        <FormField
          label="Enter your email"
          icon={<HiOutlineMail size={16} />}
          name="email"
          placeholder="Email"
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormField
          label="Enter your password"
          icon={<MdOutlineLock size={15} />}
          name="password"
          placeholder="Password"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          label="Log in"
          className="w-full mt-5 text-white bg-[#DE0607] rounded-xl"
          textColor="text-white"
        />
      </form>
      <div className="mb-4">
        <p className="font-normal leading-6 text-center text-16">
          Already have an account?
          <Link
            text="Log in"
            href="/login"
            className={"text-[#DE0607] text-16 font-bold leading-6 ml-2"}
          />
        </p>
      </div>
    </div>
  );
};

export default Signup;
