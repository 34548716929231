import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      id: null,
      email: "",
      roles: [],
      name: "",
      birthDate: "",
      avatarPath: null,
      gender: "",
      businessId: null,
      adminBusinessId: null,
      locManagerBusinessId: null,
    },
    accessToken: null,
    refreshToken: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    logout: (state) => {
      state.user = {};
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export const selectAuth = (state) => state.auth;
export const selectUser = (state) => state.auth.user;
export const selectRole = (state) =>
  state.auth.user && state.auth.user.roles && state.auth.user.roles[0]
    ? state.auth.user.roles[0]
    : null;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectIsAuthenticated = (state) => state.auth.accessToken;
export const selectBusinessId = (state) => {
  if (
    state.auth.user &&
    state.auth.user.roles &&
    state.auth.user.roles[0] === "ADMIN"
  ) {
    return state.auth.user.adminBusinessId;
  } else if (
    state.auth.user &&
    state.auth.user.roles &&
    state.auth.user.roles[0] === "PROVIDER"
  ) {
    return state.auth.user.businessId;
  } else if (
    state.auth.user &&
    state.auth.user.roles &&
    state.auth.user.roles[0] === "LOCATION_MANAGER"
  ) {
    return state.auth.user.locManagerBusinessId;
  }
};
