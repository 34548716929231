import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import InputField from "../core/inputField";
import InputFieldWithLanguage from "components/Services/ServicesTab/InputFieldWithLanguage";

const AddCityModal = ({ isOpen, onClose, onSave }) => {
  const [cityName, setCityName] = useState("");
  const [cityNameEn, setCityNameEn] = useState("");
  const [cityNameAr, setCityNameAr] = useState("");

  const handleSubmit = async(event) => {
    event.preventDefault();
    const canSaved = await onSave({ en: cityNameEn, ar: cityNameAr });
    if(canSaved){
      setCityNameEn("");
      setCityNameAr("");
    }
  };

  if (!isOpen) {
   if(cityNameEn != "" || cityNameAr != ""){
    setCityNameEn("");
    setCityNameAr("");
   }
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-500 bg-opacity-50">
      <div className="relative flex flex-col w-full max-w-md p-8 m-auto bg-white rounded-lg shadow">
        <div className="flex items-center justify-between pb-3">
          <p className="text-2xl font-bold">Add City</p>
          <button
            className="z-50 cursor-pointer modal-close"
            onClick={() => {
              setCityNameEn("");
              setCityNameAr("");
              onClose();
            }}
          >
            <span className="text-2xl">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{ alignSelf: "center", justifySelf: "center" }}
              className="text-12 font-bold min-w-32 md:w-1/5 text-[#00000073]"
            >
              Enter a city name:
            </p>
            <div>
              <InputFieldWithLanguage
                placeholder="Enter a city name"
                language={"En"}
                onChange={(e) => {
                  setCityNameEn(e.target.value);
                }}
                value={cityNameEn || ""}
                className={"w-full"}
                labelClassName={"!w-14"}
              />
              <div style={{ height: 10 }}></div>
              <InputFieldWithLanguage
                placeholder="أدخل الاسم"
                language={"Ar"}
                onChange={(e) => {
                  setCityNameAr(e.target.value);
                }}
                value={cityNameAr || ""}
                className={"w-full"}
                labelClassName={"!w-14"}
                dir="rtl"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4 space-x-4">
            <button
              type="button"
              style={{
                borderWidth: 1,
                borderColor: "#FFA39E",
                borderRadius: 10,
              }}
              className="py-2 text-black rounded px-9 bg-white-400 hover:bg-gray-500"
              onClick={() => {
                setCityNameEn("");
                setCityNameAr("");
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ borderRadius: 10 }}
              className="py-2 text-white bg-red-500 rounded px-9 hover:bg-red-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCityModal;
