import React, { useEffect, useRef, useState } from "react";

import "./styles.css";

import linkIcon from "../../../../../assets/Icons/link.png";
import categoryService from "services/category/categoryService";
import usersService from "services/users/usersService";
import businessService from "services/business/businessService";
import { showError, showInfo, showSuccess } from "utils/helpers";
import { VendorEditSchema } from "utils/schemas/Vendor/VendorSchemas";
import MultiplePhoneNumbers from "components/core/MultiplePhoneInput";

const EditField = ({
  title,
  placeholder,
  phone,
  value,
  onChange,
  imageSrc,
}) => {
  const [phoneCount, setPhoneCount] = useState(1);

  const placeholderStyle = "ml-2 border-1 border-[#D9D9D9] text-[#00000040]";
  const phoneFieldStyle =
    phoneCount > 1
      ? "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9] mt-2"
      : "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9]";
  const phoneFieldStyleFirst =
    "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9]";

  const onClick = () => {
    setPhoneCount(phoneCount + 1);
  };

  const inputs = Array.from({ length: phoneCount }, (_, index) => {
    const firstItem = index === 0;
    return (
      <input
        key={index}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        placeholderStyle={placeholderStyle}
        className={firstItem ? phoneFieldStyleFirst : phoneFieldStyle}
      />
    );
  });

  return (
    <div className="flex flex-row items-center py-2">
      <div className="py-[5px] text-14 font-normal leading-22 text-[#000000D9] w-[140px]">
        {title}
      </div>
      <div className="relative flex-1">
        <div>{inputs}</div>
        {phone && (
          <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            className="flex-1 font-normal text-xs leading-[14.52px] text-[#DE0607] mt-2 text-right"
          >
            + Add phone number
          </div>
        )}
        {imageSrc && (
          <div className="absolute inset-y-0 right-0 my-auto flex items-center px-3 justify-center bg-[#FAFAFA] rounded-tr-md rounded-br-md border border-[#D9D9D9]">
            <img src={imageSrc} alt="Icon" className="w-[14px] h-[14px]" />
          </div>
        )}
      </div>
    </div>
  );
};

const EditVendorPage = ({ selectedVendor, setSelectedVendor }) => {
  const [inputValue, setInputValue] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [businessType, setBusinessType] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [categories, setCategories] = useState([]);
  const inputRef = useRef(null);

  const resetValues = (selectedVendor) => {
    setInputValue(`${selectedVendor?.slug}.tryspots.net`);
    setBusinessName(selectedVendor?.name);
    setEmail(selectedVendor?.admin?.email);
    setBusinessType(selectedVendor?.categoryId);
    setPhoneNumber(
      selectedVendor?.phoneNumber
        ? selectedVendor?.phoneNumber.map((phone) => {
            return {
              id: selectedVendor?.phoneNumber.indexOf(phone) + 1,
              number: phone,
              isValid: true,
            };
          })
        : [{ id: 1, number: "", isValid: true }]
    );
    setWebsite(selectedVendor?.website);
  };

  const fetchCategories = async () => {
    const categories = await categoryService.getCategories();
    setCategories(categories.categories);
  };

  // Mock data for demonstration purposes
  useEffect(() => {
    fetchCategories();
    setInputValue(`${selectedVendor?.slug}.tryspots.net`);
    setBusinessName(selectedVendor?.name);
    setEmail(selectedVendor?.admin?.email);
    setBusinessType(selectedVendor?.categoryId);
    setPhoneNumber(
      selectedVendor?.phoneNumber
        ? selectedVendor?.phoneNumber.map((phone) => {
            return {
              id: selectedVendor?.phoneNumber.indexOf(phone) + 1,
              number: phone,
              isValid: true,
            };
          })
        : [{ id: 1, number: "", isValid: true }]
    );
    setWebsite(selectedVendor?.website);
  }, [selectedVendor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBusinessType(value);
  };

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      showSuccess("Link copied to clipboard");
    }
  };

  const handleSave = async () => {
    const newData = {
      name: businessName,
      categoryId: businessType ? parseInt(businessType) : categories[0].id,
      phone: phoneNumber.map((phone) => phone.number),
      website: website,
    };

    // check if email is changed. if changed, update the email, else do nothing
    if (email !== selectedVendor.admin.email) {
      newData.email = email;
    }

    // check if there is a changes in the data before sending the request
    if (
      newData.name === selectedVendor.name &&
      newData.categoryId === selectedVendor.categoryId &&
      newData.phone.toString() === selectedVendor.phoneNumber.toString() &&
      newData.website === selectedVendor.website &&
      newData.email === selectedVendor.admin.email
    ) {
      showInfo("No changes detected");
      return;
    }

    await VendorEditSchema.validate(newData)
      .then(async () => {
        const response = await businessService.updateBusiness(
          selectedVendor.id,
          newData
        );
        switch (response.statusCode) {
          case 200:
            showSuccess(response.message);
            setEmail(email);
            setBusinessName(newData.name);
            setPhoneNumber(
              newData.phone.map((phone) => {
                return {
                  id: newData.phone.indexOf(phone) + 1,
                  number: phone,
                  isValid: true,
                };
              })
            );
            setWebsite(newData.website);
            setSelectedVendor({
              ...selectedVendor,
              name: newData.name,
              admin: { ...selectedVendor.admin, email: email },
              categoryId: newData.categoryId,
              phoneNumber: newData.phone,
              website: newData.website,
            });
            break;
          default:
            showError(response.response.data.message || response.message);
            break;
        }
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  return (
    <div className="mt-8 ml-4 w-[680px]">
      <div className="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-3 md:space-y-0">
        <label className="text-14 font-normal leading-22 text-[#000000D9] text-left  min-w-32 md:w-1/5">
          Unique Link:
        </label>
        <div className="flex items-center w-full border border-gray-300 rounded-md h-fit">
          <input
            ref={inputRef}
            type="text"
            value={`${inputValue}`}
            readOnly
            onClick={() => window.open(`http://${inputValue}`, "_blank")}
            className="w-full px-3 py-[5px] text-black bg-[#FAFAFA] border-r rounded-md rounded-r-none shadow-sm cursor-pointer"
          />
          <button
            onClick={copyToClipboard}
            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <img src={linkIcon} alt="link" />
          </button>
        </div>
      </div>
      <EditField
        title={"Business name:"}
        placeholder={"Enter business name"}
        extra={"link"}
        value={businessName}
        onChange={(e) => setBusinessName(e.target.value)}
      />
      <EditField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        title={"Email:"}
        placeholder={"Enter email"}
      />
      <label>
        Business type:
        <select
          name="Choose business type"
          value={businessType}
          onChange={handleChange}
        >
          <option value="" disabled>
            Choose business type
          </option>
          {categories.map((item) => {
            return <option value={item.id}>{item?.name?.en}</option>;
          })}
        </select>
      </label>
      <MultiplePhoneNumbers
        phoneNumbers={phoneNumber}
        setPhoneNumbers={setPhoneNumber}
        labelClassName="!text-[#000000D9] font-normal"
        className="w-full"
      />
      <EditField
        title={"Business website:"}
        placeholder={"Enter business website"}
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
      />

      {/* Buttons container */}
      <div className="flex justify-end mt-2 space-x-2">
        {/* Cancel button */}
        <button
          onClick={() => resetValues(selectedVendor)}
          className="px-[15px] py-[7px] w-[160px] border border-[#DE0607] text-[#000000D9] rounded-[12px] bg-white shadow-button mt-6"
        >
          Cancel
        </button>

        {/* Save button */}
        <button
          onClick={() => handleSave()}
          className="px-[15px] py-[7px] w-[160px] text-white bg-[#DE0607] rounded-[12px] mt-6"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditVendorPage;
