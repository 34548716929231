import React, { useEffect, useState } from "react";
import WeeklySchedule from "components/Locations/components/WeeklySchedule";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";
import { useParams } from "react-router-dom";
import { ListingEditAvailabilitySchema } from "utils/schemas/Listings/ListingsSchemas";

const Availability = ({
  availabilityData,
  setAvailabilityData,
  businessId,
}) => {
  const { id } = useParams();
  const [initialAvailabilityData, setInitialAvailabilityData] = useState(null);
  const [initialIsDayEnabledData, setInitialIsDayEnabledData] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [isDayEnabledData, setIsDayEnabledData] = useState({
    SUNDAY: false,
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
  });

  useEffect(() => {
    if (!availabilityData) return;
    setInitialAvailabilityData(availabilityData);
    
    if (availabilityData) {
      setSchedule(
        availabilityData.map((day) => {
          return {
            day: day.name,
            slots: day.schedule.map((slot) => {
              return {
                id: slot.id,
                startsAt: slot.startTime,
                finishesAt: slot.endTime,
              };
            }),
          };
        })
      );
      availabilityData.forEach((day) => {
        setIsDayEnabledData((prev) => {
          return {
            ...prev,
            [day.name]: true,
          };
        });
        setInitialIsDayEnabledData((prev) => {
          return {
            ...prev,
            [day.name]: true,
          };
        });
      });
    }
  }, [availabilityData]);

  const onSave = async (data) => {
    let availability = data.map((day) => {
      return {
        name: day.day,
        schedule: day.slots.map((slot) => {
          return {
            startTime: slot.startsAt,
            endTime: slot.finishesAt,
          };
        }),
      };
    });

    let days = data.map((day) => {
      return {
        name: day.day,
        schedule: day.slots.map((slot) => {
          return {
            startTime: slot.startsAt,
            endTime: slot.finishesAt,
          };
        }),
      };
    });

    let newAvailability = {
      availability: {
        days: days,
      },
      businessId: businessId,
    };

    try {
      await ListingEditAvailabilitySchema.validate(newAvailability).then(
        async () => {
          const response = await locationService.updateLocation(
            id,
            newAvailability
          );
          switch (response.statusCode) {
            case 200:
              showSuccess(response.message);
              setAvailabilityData(response.location.availability?.days);
              break;
            default:
              showError(response.message);
              break;
          }
        }
      );
    } catch (error) {
      showError(error.message);
    }
  };

  const handleCancel = () => {
    setAvailabilityData(initialAvailabilityData);
    setSchedule(
      initialAvailabilityData.map((day) => {
        return {
          day: day.name,
          slots: day.schedule.map((slot) => {
            return {
              id: slot.id,
              startsAt: slot.startTime,
              finishesAt: slot.endTime,
            };
          }),
        };
      })
    );
    setIsDayEnabledData(initialIsDayEnabledData);
  };

  return (
    <div>
      <div className="w-full xl:w-1/2 ">
        <WeeklySchedule
          scheduleData={schedule}
          setScheduleData={setSchedule}
          isDayEnabledData={isDayEnabledData}
          setIsDayEnabledData={setIsDayEnabledData}
          onSave={(data) => onSave(data)}
          onCancel={handleCancel}
        />
      </div>
    </div>
  );
};

export default Availability;
