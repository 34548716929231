import React, { useState } from "react";

const ProgressBar = ({ label, count, percentage, max }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="flex items-center justify-between w-full ">
      <p className="w-1/4 font-normal text-[#000000D9] text-14">{label}</p>

      <div className="relative w-3/4">
        <div
          className="h-2 bg-[#F5F5F5] rounded-full "
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div
            className="h-2 transition-all duration-700 ease-in-out bg-[#F5222D] rounded-full"
            style={{
              width: `${
                percentage ? percentage : count === 0 ? 0 : (count / max) * 100
              }%`,
            }}
          ></div>
          {hover && (
            <div className="absolute top-0 left-0 flex justify-center w-full mt-2">
              <span className="px-2 py-1 text-white bg-black rounded-md text-12">
                {count}
              </span>
            </div>
          )}
        </div>
      </div>
      <p className="w-1/4 pl-2 text-center text-[#000000D9] text-12">
        {percentage ? `${percentage}%` : count}
      </p>
    </div>
  );
};

export default ProgressBar;
