import React from "react";
import WeeklySchedule from "../../../Locations/components/WeeklySchedule";

const LocationForm = ({
  location,
  schedule,
  setSchedule,
  isDayEnabled,
  setIsDayEnabled,
  onSave,
  onCancel,
}) => {
  return (
    <div className="px-5">
      <header className="flex items-center justify-start space-x-2 bg-white">
        <p className="text-16 font-medium leading-6 text-[#000000D9]">
          {location.name !== null ? location.name.en : "East Coast Pizzeria"} /
        </p>
        <span className="text-14 font-medium leading-6 text-[#000000D9]">
          Timing works
        </span>
      </header>
      <div className="w-full mt-6 border-b" />
      <WeeklySchedule
        scheduleData={schedule}
        isDayEnabledData={isDayEnabled}
        setScheduleData={setSchedule}
        setIsDayEnabledData={setIsDayEnabled}
        onSave={onSave}
        onCancel={onCancel}
      />
    </div>
  );
};

export default LocationForm;
