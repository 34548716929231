import React, { useState, useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import InputFieldWithLanguage from "./InputFieldWithLanguage";
import Button from "components/core/Button";
import TimePicker from "components/Locations/components/TimePicker";

const PriceComponent = ({
  initialFixedPrice = "",
  initialTimeBasedPrices = [{ price: "", startTime: "", endTime: "" }],
  onFixedPriceChange,
  onTimeBasedPricesChange,
  disabled = false,
}) => {
  const [fixedPrice, setFixedPrice] = useState(initialFixedPrice);
  const [isFixedPrice, setIsFixedPrice] = useState(!!initialFixedPrice);
  const [isTimeBasedPrice, setIsTimeBasedPrice] = useState(
    initialTimeBasedPrices.length > 0
  );
  const [timeBasedPrices, setTimeBasedPrices] = useState(
    initialTimeBasedPrices
  );

  useEffect(() => {
    onFixedPriceChange && onFixedPriceChange(fixedPrice);
  }, [fixedPrice]);

  useEffect(() => {
    onTimeBasedPricesChange && onTimeBasedPricesChange(timeBasedPrices);
  }, [timeBasedPrices]);

  const handleTimeBasedPriceChange = (index, field, value) => {
    const newPrices = [...timeBasedPrices];
    newPrices[index][field] = value;
    setTimeBasedPrices(newPrices);
  };

  const addTimeBasedPrice = () => {
    setTimeBasedPrices([
      ...timeBasedPrices,
      { price: "", startTime: "", endTime: "" },
    ]);
  };

  const removeTimeBasedPrice = (index) => {
    const newPrices = timeBasedPrices.filter((_, i) => i !== index);
    setTimeBasedPrices(newPrices);
  };

  return (
    <div className="flex items-start w-full mt-4">
      <p className="min-w-[200px] text-14 font-normal text-[#000000D9]">
        Price: <span className="text-red-500 text-16">*</span>
      </p>
      <div className="flex flex-col items-center w-full mb-4">
        <div className="flex flex-col w-full space-y-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isFixedPrice}
              onChange={() => {
                setIsFixedPrice(!isFixedPrice);
                if (!isFixedPrice) setFixedPrice(""); // Clear fixed price when unchecked
              }}
              className="w-4 h-4 mr-2"
              disabled={disabled}
            />
            <span className="text-14 font-normal text-[#000000D9]">
              Fixed price
            </span>
          </div>
          {isFixedPrice && (
            <InputFieldWithLanguage
              placeholder="Enter the price"
              language={"KWD"}
              value={fixedPrice}
              onChange={(e) => setFixedPrice(e.target.value)}
              className="w-full"
              labelClassName="!w-14"
              disabled={disabled}
            />
          )}
        </div>
        <div className="flex flex-col w-full mt-4 space-y-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isTimeBasedPrice}
              onChange={() => {
                setIsTimeBasedPrice(!isTimeBasedPrice);
                if (!isTimeBasedPrice) setTimeBasedPrices([]);
              }}
              className="w-4 h-4 mr-2"
              disabled={disabled}
            />
            <span className="text-14 font-normal text-[#000000D9]">
              Time based price
            </span>
          </div>
          {isTimeBasedPrice &&
            timeBasedPrices.map((price, index) => (
              <div key={index} className="flex items-center space-x-2">
                <InputFieldWithLanguage
                  placeholder="Enter the price"
                  language={"KWD"}
                  value={price.price}
                  onChange={(e) =>
                    handleTimeBasedPriceChange(index, "price", e.target.value)
                  }
                  className="w-1/2"
                  labelClassName="!w-14"
                  disabled={disabled}
                />
                <TimePicker
                  value={price.startTime}
                  onChange={(value) =>
                    handleTimeBasedPriceChange(index, "startTime", value)
                  }
                  className="w-1/4"
                  disabled={disabled}
                />
                <TimePicker
                  value={price.endTime}
                  onChange={(value) =>
                    handleTimeBasedPriceChange(index, "endTime", value)
                  }
                  className="w-1/4"
                  disabled={disabled}
                />
                <Button
                  icon={<FaTrash />}
                  className="w-10 h-10 text-red-500 border-none shadow-none"
                  onClick={() => removeTimeBasedPrice(index)}
                  disabled={disabled}
                />
              </div>
            ))}
          {isTimeBasedPrice && (
            <div className="flex items-center justify-end w-full">
              <Button
                onClick={addTimeBasedPrice}
                className={`h-4 rounded-none shadow-none w-fit ${
                  disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                icon={<FaPlus className="w-4 h-4 py-0.5" />}
                label={"Add Time"}
                labelStyle={"text-12 font-normal text-[#DE0607 "}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceComponent;
