import React from "react";
import EmptyCategory from "../assets/emptyCategoryIcon.svg";

const EmptyStateComponent = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <img src={EmptyCategory} alt="empty category" className="w-32 h-28" />
      <p className="text-16 font-medium text-[#000000D9] leading-6">{text}</p>
    </div>
  );
};

export default EmptyStateComponent;
