import api from "../apiConfig";

const subserviceServices = {
  getSubservices: async () => {
    try {
      const response = await api.get("/subservice");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBusinessSubservices: async (businessId) => {
    try {
      const response = await api.get(`/subservice?businessId=${businessId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSubservice: async (id, throwError) => {
    try {
      const response = await api.get(`/subservice/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addSubservice: async (subserviceData) => {
    try {
      const response = await api.post("/subservice", subserviceData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateSubservice: async (id, updateData) => {
    try {
      const response = await api.put(`/subservice/${id}`, updateData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  deleteSubservice: async (id) => {
    try {
      const response = await api.delete(`/subservice/${id}`);
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
};

export default subserviceServices;
