import React, { useEffect, useState } from "react";
import StatCard from "./OverviewComponents/StatCard";
import PieChart from "./OverviewComponents/PieChart";
import Table from "components/core/Table/TableComponent";
import LineChart from "./OverviewComponents/GuestChart";
import ProgressBar from "./Components/ProgressBar";

const SalesTab = ({ salesData }) => {
  const [bookingsByEachProvider, setBookingsByEachProvider] = useState([]);
  const [topBookingServices, setTopBookingServices] = useState([]);
  const [stats, setStats] = useState([]);
  const [salesPerformance, setSalesPerformance] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Revenue",
        data: [],
        backgroundColor: "#17D861",
        borderColor: "#17D861",
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 0.5,
      },
    ],
  });

  const convertObjectToArray = (obj) => {
    if (!obj) return;
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));
  };

  useEffect(() => {
    if (!salesData) return;
    setTopBookingServices(salesData.topBookingServices);
    setSalesPerformance(salesData.salesPerformance);
    setBookingsByEachProvider(salesData.bookingsByEachProvider);
    setStats([
      {
        title: "Total Revenue",
        value: salesData.totalRevenue,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ad406ca55386822c25e28099cc6c3ca6229d9f254f74b3c71e54459722137521?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-emerald-50",
      },
      {
        title: "App Revenue",
        value: salesData.appRevenue,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef11ddd6815ee4b97aecb670ca3a0e3bad78be374e6752c8ad5778e335cf8235?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-blue-50",
      },
      {
        title: "Link Revenue",
        value: salesData.linkRevenue,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3cbbb45adddbd7315be0a99b6b08d619439bbc01f9be029577102de6734c7ae3?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-rose-50",
      },
      {
        title: "Offline Revenue",
        value: salesData.offlineRevenue,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5abcb5962ae5fdce3b09cad05ea11b2fcc7921618eaa76612955ecd6d0e055e4?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-yellow-100",
      },
    ]);

    const salesPerformanceDataArray = convertObjectToArray(
      salesData.salesPerformance
    );
    if (salesPerformanceDataArray) {
      setSalesPerformance({
        labels: salesPerformanceDataArray.map((data) => data.name),
        datasets: [
          {
            label: "Total Revenue",
            data: salesPerformanceDataArray.map((data) => data.value),
            backgroundColor: "#17D861",
            borderColor: "#17D861",
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.5,
          },
        ],
      });
    }
  }, [salesData]);

  return (
    <div className="h-full mt-2 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        {stats.map((stat, index) => (
          <StatCard
            key={index}
            title={stat.title}
            value={stat.value}
            icon={stat.icon}
            iconBg={stat.iconBg}
          />
        ))}
      </div>
      <div className="h-full mt-5 max-md:max-w-full">
        <div className="flex h-full gap-0 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 space-y-5 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full gap-6 p-4 mb-4 border rounded-md shadow-xl h-fit max-md:flex-col max-md:gap-0">
              <p className="text-16 font-semibold text-[#000000D9]">
                Bookings by each provider
              </p>
              {Array.isArray(bookingsByEachProvider) &&
              bookingsByEachProvider.length !== 0 ? (
                bookingsByEachProvider.map((provider, index) => (
                  <ProgressBar
                    key={index}
                    label={provider.name}
                    value={provider.count}
                    max={provider.count + 1}
                  />
                ))
              ) : (
                <p>No data</p>
              )}
            </div>

            <div className="flex flex-col w-full h-full gap-6 p-4 overflow-hidden border rounded-md shadow-lg">
              <p className="text-16 font-semibold text-[#000000D9] ">
                Top Booking Services
              </p>
              {Array.isArray(topBookingServices) &&
              topBookingServices.length !== 0 ? (
                topBookingServices.map((service, index) => (
                  <ProgressBar
                    key={index}
                    label={`${service.name.en}
                      ${service.name.ar ? "-" : ""}
                      ${service.name.ar ? service.name.ar : ""}
                      `}
                    value={service.count}
                    max={service.count + 1}
                  />
                ))
              ) : (
                <p>No data</p>
              )}
            </div>
          </div>
          <div className="flex flex-col w-6/12 h-full ml-5 max-md:ml-0 max-md:w-full">
            <LineChart
              data={salesPerformance}
              title={"Sales Performance"}
              maxHeight="700px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTab;
