const onlineData = [
  {
    clientId: 1,
    clientName: "John Doe",
    phone: "08012345678",
    email: "johndoe@gmail",
    gender: "Male",
    age: 25,
    noOfVisits: 5,
    totalSpent: 5000,
  },
  {
    clientId: 2,
    clientName: "Jane Doe",
    phone: "08012345678",
    email: "janedoe@gmail",
    gender: "Female",
    age: 23,
    noOfVisits: 12,
    totalSpent: 10000,
  },
];

const offlineData = [
  {
    clientId: 3,
    clientName: "Marc Doe",
    phone: "08012345678",
    email: "marcdoe@gmail",
    gender: "Male",
    age: 37,
    noOfVisits: 23,
    totalSpent: 2000,
  },
  {
    clientId: 4,
    clientName: "Mary Doe",
    phone: "08012345678",
    email: "marydoe@gmail",
    gender: "Female",
    age: 32,
    noOfVisits: 8,
    totalSpent: 8000,
  },
];

const prevoiusVisits = [
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
  {
    date: "2021-10-10",
    location: "London",
    servicesDone: "Haircut",
    amountPaid: 1000,
    staffMember: "John Doe",
  },
];

export { onlineData, offlineData, prevoiusVisits };
