import React, { useEffect, useMemo, useState } from "react";
import Button from "../../core/Button";
import useServices from "utils/hooks/useServices";
import subserviceServices from "services/service/subserviceServices";
import { showError, showInfo, showSuccess } from "utils/helpers";
import providersService from "services/providers/providersService";
import { providerEditServicesSchema } from "utils/schemas/Provider/ProviderSchemas";

const ProviderServices = ({ data }) => {
  const [activeTab, setActiveTab] = useState("hairstyling");
  const { businessServices: services, businessSubservices: subservices } =
    useServices();
  const [subservicesData, setSubservicesData] = useState([]);
  const [selectedSubservices, setSelectedSubservices] = useState([]);

  useEffect(() => {
    if (!data) return;
    setSubservicesData(data.subservices);
    setSelectedSubservices(data.subservices);
  }, [data]);

  const tabs = useMemo(() => {
    // Subservices with no serviceId
    const standaloneSubservices = subservices.filter(
      (subservice) => !subservice.serviceId
    );

    // Subservices with serviceId
    const groupedSubservices = subservices.filter(
      (subservice) => subservice.serviceId
    );

    // Map services to tabs
    const serviceTabs = services.map((service) => ({
      id: service.id,
      title: service.name,
      value: service.slug,
      content: groupedSubservices
        .filter((subservice) => subservice.serviceId === service.id)
        .map((subservice) => {
          if (
            subservice.childSubservices &&
            subservice.childSubservices.length > 0
          ) {
            return {
              id: subservice.id,
              title: subservice.name,
              value: subservice.name,
              childSubservices: subservice.childSubservices.map((child) => ({
                id: child.id,
                title: child.name,
                value: child.name,
                time: child.duration,
                price: child.price,
              })),
            };
          }
          return {
            id: subservice.id,
            title: subservice.name,
            value: subservice.name,
            time: subservice.duration,
            price: subservice.price,
            childSubservices: [],
          };
        }),
    }));

    // Add standalone subservices as a separate tab
    if (standaloneSubservices.length > 0) {
      serviceTabs.push({
        id: "all",
        title: { en: "All", ar: "الكل" },
        value: "all",
        content: standaloneSubservices.map((subservice) => ({
          id: subservice.id,
          title: subservice.name,
          value: subservice.name,
          time: subservice.duration,
          price: subservice.price,
          childSubservices: subservice.childSubservices
            ? subservice.childSubservices.map((child) => ({
                id: child.id,
                title: child.name,
                value: child.name,
                time: child.duration,
                price: child.price,
              }))
            : [],
        })),
      });
    }

    return serviceTabs;
  }, [services, subservices]);

  const handleSave = async () => {
    let subserviceIds = selectedSubservices.map((sub) => sub.id);
    try {
      await providerEditServicesSchema.validate({ subserviceIds });
      const response = await providersService.updateProvider(data.id, {
        subserviceIds,
      });
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="h-full ">
      <div className="flex items-center justify-start w-full space-x-3 bg-white">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => setActiveTab(tab.value)}
            className={`px-8 py-1 cursor-pointer text-center rounded-xl min-w-24 ${
              activeTab === tab.value
                ? "bg-[#DE0607] text-white hover:bg-[#AE0607]"
                : "bg-white text-[#000000A6]  border hover:bg-[#DFD9D9] "
            }`}
          >
            <p
              className={`text-14 font-medium 
              ${activeTab === tab.value ? "text-white" : "text-[#000000A6]"}
              `}
            >
              {tab.title.en ?? ""} {tab.title.en ? "/" : ""}{" "}
              {tab.title.ar ?? ""}
            </p>
          </div>
        ))}
      </div>
      <div className="lg:min-h-[500px] mt-5">
        {tabs.find((tab) => tab.value === activeTab)?.content.length === 0 ? (
          <div className="flex items-center justify-center w-full h-full">
            <p className="text-14 font-medium text-[#000000D9]">
              No subservices available
            </p>
          </div>
        ) : (
          tabs
            .find((tab) => tab.value === activeTab)
            ?.content.flat()
            .map((service) => (
              <div
                key={service.id}
                className="flex items-center justify-between w-full py-3 px-4 bg-white border-b border-[#00000014]"
              >
                {service.childSubservices.length > 0 ? (
                  // if child subservices exist
                  <div className="flex flex-col items-start w-full">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
                        style={{ accentColor: "#DE0607" }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedSubservices((prev) => [
                              ...prev,
                              service,
                            ]);
                          } else {
                            setSelectedSubservices((prev) =>
                              prev.filter((item) => item.id !== service.id)
                            );
                          }
                        }}
                        checked={
                          selectedSubservices &&
                          selectedSubservices.some(
                            (item) => item.id === service.id
                          )
                        }
                      />
                      <div className="flex flex-col ml-4">
                        <p className="text-14 font-medium text-[#000000D9]">
                          {service.title.en ?? ""} {service.title.en ? "/" : ""}
                          {service.title.ar ?? ""}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-full mt-2">
                      {service.childSubservices.map((child) => (
                        <div className="flex items-start justify-between w-full px-12">
                          <div className="flex flex-col">
                            <p className="text-14 font-medium text-[#000000D9]">
                              {child.title.en ?? ""} {child.title.en ? "/" : ""}{" "}
                              {child.title.ar ?? ""}
                            </p>
                            <p className="text-12 font-normal text-[#00000073]">
                              {child.time ?? "Time Not Setted"}{" "}
                              {child.time ? "min" : ""}
                            </p>
                          </div>
                          <p className="text-14 font-medium text-[#000000D9]">
                            {child.price ? "KWD" : ""}{" "}
                            {child.price ?? "Price Not Setted"}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  // if no child subservices
                  <div className="flex items-center w-full">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
                      style={{ accentColor: "#DE0607" }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSubservices((prev) => [...prev, service]);
                        } else {
                          setSelectedSubservices((prev) =>
                            prev.filter((item) => item.id !== service.id)
                          );
                        }
                      }}
                      checked={
                        selectedSubservices &&
                        selectedSubservices.some(
                          (item) => item.id === service.id
                        )
                      }
                      disabled={
                        service.price === null ||
                        service.price === undefined ||
                        service.price === 0 ||
                        service.time === null ||
                        service.time === undefined ||
                        service.time === 0
                      }
                    />
                    <div className="flex justify-between w-full ml-4">
                      <div className="flex flex-col ml-4">
                        <p className="text-14 font-medium text-[#000000D9]">
                          {service.title.en ?? ""} {service.title.en ? "/" : ""}
                          {service.title.ar ?? ""}
                        </p>
                        <p className="text-12 font-normal text-[#00000073]">
                          {service.time ?? "Time Not Setted"}{" "}
                          {service.time ? "min" : ""}
                        </p>
                      </div>
                      <div className="flex items-start">
                        <p className="text-14 font-medium text-[#000000D9]">
                          {service.price ? "KWD" : ""}{" "}
                          {service.price ?? "Price Not Setted"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
                    style={{ accentColor: "#DE0607" }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSubservices((prev) => [...prev, service]);
                      } else {
                        setSelectedSubservices((prev) =>
                          prev.filter((item) => item.id !== service.id)
                        );
                      }
                    }}
                    checked={
                      selectedSubservices &&
                      selectedSubservices.some((item) => item.id === service.id)
                    }
                    disabled={
                      service.price === null ||
                      service.price === undefined ||
                      service.price === 0 ||
                      service.time === null ||
                      service.time === undefined ||
                      service.time === 0
                    }
                  />
                  <div className="flex flex-col ml-4">
                    <p className="text-14 font-medium text-[#000000D9]">
                      {service.title.en ?? ""} {service.title.en ? "/" : ""}
                      {service.title.ar ?? ""}
                    </p>
                    <p className="text-12 font-normal text-[#00000073]">
                      {service.time ?? "Time Not Setted"}{" "}
                      {service.time ? "min" : ""}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-14 font-medium text-[#000000D9]">
                    {service.price ? "KWD" : ""}{" "}
                    {service.price ?? "Price Not Setted"}
                  </p>
                </div> */}
              </div>
            ))
        )}
      </div>
      <div className="flex items-center justify-end p-4 bg-white ">
        <Button
          label="Cancel"
          className={
            "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
          }
          textColor="text-[#000000D9]"
        />
        <Button
          label="Save"
          className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
          textColor="text-white"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default ProviderServices;
