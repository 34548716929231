import api from "services/apiConfig";

const userService = {
  getUser: async (id) => {
    try {
      const response = await api.get(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUsers: async () => {
    try {
      const response = await api.get("/users?perPage=1000000");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (id, updateData) => {
    try {
      const response = await api.put(`/users/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (id) => {
    try {
      const response = await api.delete(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProfile: async () => {
    try {
      const response = await api.get("/users/profile");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateProfile: async (updateData) => {
    try {
      const response = await api.put("/users/profile", updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateProviderSettings: async (id, data) => {
    try {
      const response = await api.put(`/users/${id}/provider-settings`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userService;
