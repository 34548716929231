import api from "services/apiConfig";
import { showError } from "utils/helpers";

const locationManagerService = {
  getLocationManagers: async () => {
    try {
      const response = await api.get("/location-manager");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBusinessLocationManagers: async (businessId) => {
    try {
      const response = await api.get(
        `/location-manager?businessId=${businessId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getLocationManager: async (id) => {
    try {
      const response = await api.get(`/location-manager/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createLocationManager: async (locationManagerData) => {
    try {
      const response = await api.post("/location-manager", locationManagerData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateLocationManager: async (id, locationManagerData) => {
    try {
      const response = await api.put(
        `/location-manager/${id}`,
        locationManagerData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteLocationManager: async (id) => {
    try {
      const response = await api.delete(`/location-manager/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default locationManagerService;
