import { LuTrash } from "react-icons/lu";
import TimePicker from "./TimePicker";
import { GoDash } from "react-icons/go";
import { useEffect } from "react";

const TimeSlot = ({
  index,
  slot,
  isEnabled,
  removeTimeSlot,
  updateTimeSlot,
  roleDisabled = false,
}) => {
  return (
    <div
      className={`flex items-center space-x-4 my-2 ${
        !isEnabled && "opacity-50"
      }`}
    >
      <TimePicker
        value={slot.startsAt}
        onChange={(startsAt) => updateTimeSlot(startsAt, slot.finishesAt)}
        disabled={!isEnabled || roleDisabled}
      />
      <span className="mx-2">
        <GoDash />
      </span>
      <TimePicker
        value={slot.finishesAt}
        onChange={(finishesAt) => updateTimeSlot(slot.startsAt, finishesAt)}
        disabled={!isEnabled || roleDisabled}
      />
      {!roleDisabled && (
        <button
          onClick={removeTimeSlot}
          className={`ml-2  hover:bg-red-200 text-[#E66060] font-bold py-1 px-2 shadow-md rounded focus:outline-none ${
            !isEnabled && "bg-gray-500 text-white hover:bg-gray-600"
          }`}
          disabled={!isEnabled || roleDisabled}
        >
          <LuTrash />
        </button>
      )}
    </div>
  );
};

export default TimeSlot;
