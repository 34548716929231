import * as Yup from "yup";
import {
  requiredMessage,
  incorrectMessage,
  invalidMessage,
  fileSchema,
  cannotBeNullMessage,
} from "../schemaUtils";

// Add New Category validation schema
export const AddNewCategorySchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required(requiredMessage("Category English Name")),
    ar: Yup.string().required(requiredMessage("Category Arabic Name")),
  }),
  icon: fileSchema.required(requiredMessage("Category Icon")),
  subCategories: Yup.array().of(
    Yup.object().shape({
      name: Yup.object().shape({
        en: Yup.string().required(requiredMessage("English Subcategory Name")),
        ar: Yup.string().required(requiredMessage("Arabic Subcategory Name")),
      }),
      images: Yup.array()
        .of(fileSchema)
        .length(1, "You can only upload one image")
        .required(requiredMessage("Subcategory Image")),
    })
  ),
});

// Name validation schema
const nameSchema = Yup.object().shape({
  en: Yup.string().required(requiredMessage("English Subcategory Name")),
  ar: Yup.string().required(requiredMessage("Arabic Subcategory Name")),
});

// Subcategory validation schema
const subCategorySchema = Yup.object().shape({
  name: nameSchema,
  images: Yup.array()
    .of(
      Yup.mixed().test(
        "fileOrUrl",
        cannotBeNullMessage("Subcategory Image"),
        (value) => {
          if (typeof value === "string") {
            try {
              new URL(value);
              return true;
            } catch (e) {
              return false;
            }
          }
          return fileSchema.isValidSync(value);
        }
      )
    )
    .when("name", {
      is: (name) => name && name.en && name.ar,
      then: (schema) =>
        schema
          .min(1, requiredMessage("Image"))
          .max(1, "You can only upload one image"),
      otherwise: (schema) => schema,
    }),
});

export const EditCategorySchema = Yup.object().shape({
  name: nameSchema,
  icon: Yup.mixed().test("fileOrUrl", invalidMessage("Icon"), (value) => {
    if (typeof value === "string") {
      try {
        new URL(value);
        return true;
      } catch (e) {
        return false;
      }
    }
    return fileSchema.isValidSync(value);
  }),
  subCategories: Yup.array().of(subCategorySchema),
});
