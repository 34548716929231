import React from "react";
import Button from "../Button";

const ButtonGroup = ({ buttons, selectedButton, setSelectedButton }) => {
  return (
    <div className="flex flex-row space-x-2">
      {buttons.map((button) => (
        <Button
          key={button.id}
          label={button.label}
          onClick={() => setSelectedButton(button.id)}
          className={`bg-[#FAFAFA] text-[#00000073]  ${
            selectedButton === button.id
              ? " border-[#DE0607] border"
              : " border-[#00000073] border"
          }`}
          textColor="text-[#00000073]"
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
