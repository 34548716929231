import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import { locationSlice } from "./location/locationSlice";
import { listingSlice } from "./listings/listingSlice";
import { providerSlice } from "./provider/providerSlice";
import { settingsSlice } from "./settings/settingsSlice";
import { bookingsSlice } from "./bookings/bookingsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  location: locationSlice.reducer,
  listings: listingSlice.reducer,
  provider: providerSlice.reducer,
  settings: settingsSlice.reducer,
  bookings: bookingsSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
