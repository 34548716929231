import React, { useEffect, useState } from "react";
import ratingAndReviewData from "constants/data/rateAndReviewData";
import TableListings from "../../components/TableListings";

const Reviews = () => {
  const [ratingAndReviewList, setRatingAndReviewList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    setRatingAndReviewList(ratingAndReviewData);
  }, [ratingAndReviewData]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredData = ratingAndReviewData.filter((business) =>
      business.name.toLowerCase().includes(value)
    );
    setRatingAndReviewList(filteredData);
  };

  const handleSave = (businessName) => {
    setRatingAndReviewList((prev) => [...prev, { name: businessName }]);
    setIsModalOpen(false);
  };

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        {/* <TableListings
          data={[]}
          headers={["Date created", "User Name", "Reviews", "Rating"]}
          numberOfRows={10}
          setData={setRatingAndReviewList}
        /> */}

        <div className="flex items-center justify-center h-64">
          <p className="text-lg text-gray-400">No reviews available</p>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
