import React from 'react';

function PercentageBar({ current, total }) {
    const percentage = (current / total) * 100;

    return (
        <div className='w-[245px] h-[18px] pt-[6px] rounded-[100px]'>
            <div className='h-[6px] bg-[#F5222D] rounded-[100px]' style={{ width: `${percentage}%`, alignSelf: 'center'}}>
            </div>
        </div>
    );
}

export default PercentageBar;