import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

const renderStars = ({
  fullStars = 0,
  halfStar = false,
  emptyStars = 0,
  starColor = "text-yellow-400",
}) => {
  return (
    <div className="flex items-center justify-start space-x-1">
      {[...Array(fullStars)].map((_, i) => (
        <BsStarFill key={`full_${i}`} className={starColor} size={24} />
      ))}
      {halfStar && <BsStarHalf className={starColor} size={24} />}
      {[...Array(emptyStars)].map((_, i) => (
        <BsStar key={`empty_${i}`} className={starColor} size={24} />
      ))}
    </div>
  );
};

export default renderStars;
