import React, { useEffect, useState } from "react";
import Table from "components/core/Table/TableComponent";
import renderStars from "utils/RenderStar";
import Modal from "components/core/Modal";
import ButtonGroup from "components/core/Button/ButtonGroup";
import TextArea from "components/core/textArea";
import RatingStar from "components/core/RatingStar";
import Button from "components/core/Button";
import reviewsService from "services/reviews/reviewsService";
import { showError, showSuccess } from "utils/helpers";
import { ListingEditReviewsSchema } from "utils/schemas/Listings/ListingsSchemas";

const Reviews = ({ reviews, setReviews, refreshReviews }) => {
  const [ratingAndReviewList, setRatingAndReviewList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState({});
  const [selectedButton, setSelectedButton] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const headers = [
    {
      key: "dateCreated",
      value: "Date Created",
    },
    {
      key: "userName",
      value: "User Name",
    },
    {
      key: "reviews",
      value: "Reviews",
    },
    {
      key: "rating",
      value: "Rating",
    },
  ];

  // Functions
  const handleRatingChange = (value) => {
    setSelectedReview({
      ...selectedReview,
      rating: value,
      ratingValue: value,
    });
  };

  const handleSaveReview = async () => {
    let data = {
      text: {
        en: selectedReview.reviewEn,
        ar: selectedReview.reviewAr,
      },
      rating: selectedReview.ratingValue,
      locationId: selectedReview.locationId,
      userId: selectedReview.userId,
      name: selectedReview.userName,
    };

    try {
      await ListingEditReviewsSchema.validate(data).then(async () => {
        const response = await reviewsService.updateReview(
          selectedReview.id,
          data
        );
        switch (response.statusCode) {
          case 200:
            showSuccess(response.message);
            setIsModalOpen(false);
            setReviews(
              reviews.map((review) =>
                review.id === selectedReview.id ? response.review : review
              )
            );
            setSelectedReview({});
            break;
          default:
            showError(response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      const response = await reviewsService.deleteReview(id);
      switch (response.statusCode) {
        case 400:
          showError(response.message);
          break;
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess("Review deleted successfully");
          setReviews(reviews.filter((review) => review.id !== id));
          refreshReviews();
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    if (!reviews || (Array.isArray(reviews) && !reviews.length)) return;
    setRatingAndReviewList(
      reviews.map((review) => {
        let fullStars = Math.floor(review.rating);
        let halfStar = review.rating % 1 !== 0;
        let emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
        return {
          dateCreated: new Date(review.createdAt)
            .toUTCString()
            .split(" ")
            .slice(1, 4)
            .join(" "),
          userName: review.name,
          reviews: `
          ${review.text.en ? review.text.en : ""} 
          ${review.text.ar && review.text.en ? "\b / \b" : ""}
          ${review.text.ar ? review.text.ar : ""}
          `,
          rating: renderStars({
            fullStars: fullStars,
            halfStar: halfStar,
            emptyStars: emptyStars,
            starColor: "text-[#DE0607]",
          }),
          ratingValue: review.rating,
          locationId: review.locationId,
          userId: review.userId,
          id: review.id,
          reviewEn: review.text.en ? review.text.en : "",
          reviewAr: review.text.ar ? review.text.ar : "",
        };
      })
    );
  }, [reviews]);

  useEffect(() => {}, [selectedReview]);

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <Table
          data={ratingAndReviewList}
          headers={headers}
          numberOfRows={5}
          setData={setRatingAndReviewList}
          key={ratingAndReviewList}
          usePopover
          popoverActions={{
            onEdit: (item, index) => {
              setSelectedReview(item);
              setIsModalOpen(true);
            },
            onDelete: (item, index) => {
              setSelectedReview(item);
              setIsDeleteModalOpen(true);
            },
          }}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        headerText={"Edit Review and Rating"}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedReview({});
        }}
      >
        <div className="min-w-[500px]">
          <div className="flex justify-end w-full mt-4">
            <ButtonGroup
              buttons={[
                {
                  id: 1,
                  label: "En",
                },
                {
                  id: 2,
                  label: "Ar",
                },
              ]}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
            />
          </div>
          <div className="mt-3">
            <p className="text-12 font-bold text-[#00000073]">Edit Review</p>
            <TextArea
              value={
                selectedButton === 1
                  ? selectedReview?.reviewEn
                  : selectedReview?.reviewAr
              }
              onChange={(e) => {
                setSelectedReview({
                  ...selectedReview,
                  reviewEn:
                    selectedButton === 1
                      ? e.target.value
                      : selectedReview.reviewEn,
                  reviewAr:
                    selectedButton === 2
                      ? e.target.value
                      : selectedReview.reviewAr,
                });
              }}
              placeholder="Enter Review"
              isMaxLength={false}
              className="md:space-x-0"
              fieldHeight="260px"
            />
          </div>
          <div className="flex items-center justify-start mt-5 space-x-2">
            <p className="text-12 font-bold text-[#00000073]">Edit Rating</p>
            <RatingStar
              starCount={5}
              filledCount={selectedReview?.ratingValue}
              updateRatingFunction={(value) => handleRatingChange(value)}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-5">
            <Button
              label={"Cancel"}
              className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
              textColor="text-black"
              onClick={() => {
                setIsModalOpen(false);
                setSelectedReview({});
              }}
            />
            <Button
              label={"Save"}
              className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
              textColor="text-white"
              onClick={handleSaveReview}
            />
          </div>
        </div>
      </Modal>
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          headerText={"Delete Review"}
        >
          <div className="p-5 ">
            <h2 className="text-16 text-[#000000D9] font-medium">
              Are you sure you want to delete this review?
            </h2>
            <div className="flex justify-end mt-6 space-x-4">
              <Button
                label={"Cancel"}
                className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black hover:bg-[#FFA39E]"
                textColor="text-black"
                onClick={() => setIsDeleteModalOpen(false)}
                variant="outline"
              />
              <Button
                label={"Delete"}
                className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
                textColor="text-white"
                onClick={() => {
                  handleDeleteReview(selectedReview.id);
                  setIsDeleteModalOpen(false);
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Reviews;
