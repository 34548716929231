import * as Yup from "yup";

// General error messages
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;

// Settings Online Booknig Tab validation schema
export const OnlineBookingTabSchema = Yup.object({
  minTimeBeforeBooking: Yup.number()
    .min(1, "Minimum time before booking must be at least 1")
    .required(requiredMessage("Minimum time before booking"))
    .nonNullable(),
  maxDaysBeforeBooking: Yup.number()
    .min(1, "Maximum days before booking must be at least 1")
    .required(requiredMessage("Maximum days before booking"))
    .nonNullable(),
  timeSlotInterval: Yup.number()
    .min(1, "Time slot interval must be at least 1")
    .required(requiredMessage("Time slot interval"))
    .nonNullable(),
});

export const PaymentMethodTabSchema = Yup.object({
  acceptDeposits: Yup.boolean().required(requiredMessage("Accept deposits")),
  depositAmount: Yup.number()
    .typeError(invalidMessage("Deposit amount"))
    .when("acceptDeposits", {
      is: (value) => value === true,
      then: (depositAmount) =>
        depositAmount
          .min(3, "Deposit amount must be at least 3")
          .required(requiredMessage("Deposit amount")),
      otherwise: (depositAmount) => depositAmount.nullable(),
    }),
});

// Settings General Tab validation schema
export const GeneralTabSchema = Yup.object({
  phone: Yup.string()
    .matches(/^\+965[1-9]\d{7}$/, invalidMessage("Phone number"))
    .required(requiredMessage("Phone number")),
  website: Yup.string()
    .notRequired()
    .nullable()
    .url(invalidMessage("Website. Please include http:// or https://")),
});
