import React, { useState } from "react";
import DaySchedule from "./DaySchedule";

// Define days of the week for the schedule
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Define the WeeklySchedule component
const WeeklySchedule = () => {
  const [schedule, setSchedule] = useState({
    // Initialize with empty arrays or predefined slots as needed
    Sunday: [],
    Monday: [],
    Tuesday: [
      { startsAt: "10:00", finishesAt: "00:00" },
      { startsAt: "04:00", finishesAt: "20:00" },
    ],
    Wednesday: [],
    Thursday: [],
    Friday: [{ startsAt: "08:00", finishesAt: "22:00" }],
    Saturday: [],
  });

  const [isDayEnabled, setIsDayEnabled] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  const [error, setError] = useState(null);

  // Function to add a new time slot
  const addTimeSlot = (day) => {
    const newSlots = [...schedule[day], { startsAt: "", finishesAt: "" }];
    setSchedule({ ...schedule, [day]: newSlots });
  };

  // Function to remove a time slot
  const removeTimeSlot = (day, index) => {
    const newSlots = schedule[day].filter((_, i) => i !== index);
    setSchedule({ ...schedule, [day]: newSlots });
  };

  // Function to update a time slot
  const updateTimeSlot = (day, index, startsAt, finishesAt) => {
    const newSlots = schedule[day].map((slot, i) =>
      i === index ? { ...slot, startsAt, finishesAt } : slot
    );
    setSchedule({ ...schedule, [day]: newSlots });
  };

  const handleSaveSchedule = () => {
    // Add logic to save the schedule
    const enabledDays = Object.keys(isDayEnabled).filter(
      (day) => isDayEnabled[day]
    );
    const enabledSchedule = Object.keys(schedule).reduce((acc, day) => {
      if (enabledDays.includes(day)) {
        schedule[day].length > 0
          ? (acc[day] = schedule[day])
          : setError("Please add at least one time slot for each enabled day");
      }
      return acc;
    }, {});
  };

  return (
    <div className="container p-4 mx-auto my-6">
      <div className="hidden py-2 border-b-2 border-gray-300 md:flex md:w-full max-w-[600px]">
        <div className="min-w-32 w-fit">
          <h3 className="font-semibold text-left">Day</h3>
        </div>
        <div className="min-w-36 w-fit">
          <h3 className="font-semibold text-left">Starts at</h3>
        </div>
        <div className="min-w-36 w-fit">
          <h3 className="font-semibold text-left">Finishes at</h3>
        </div>
        <div className=""></div>
      </div>
      <div className="flex flex-col gap-4">
        {daysOfWeek.map((day) => (
          <DaySchedule
            key={day}
            dayName={day}
            slots={schedule[day]}
            addTimeSlot={() => addTimeSlot(day)}
            removeTimeSlot={(index) => removeTimeSlot(day, index)}
            updateTimeSlot={updateTimeSlot}
            setIsDayEnabled={() =>
              setIsDayEnabled((prev) => ({ ...prev, [day]: !prev[day] }))
            }
          />
        ))}
      </div>

      {/* Add a button to save the schedule */}
      <button
        className="p-2 mt-6 text-white bg-red-500 rounded-lg"
        onClick={handleSaveSchedule}
      >
        Save Schedule
      </button>
    </div>
  );
};

export default WeeklySchedule;
