import { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";

const LocationListItem = ({ location, isSelected, onSelect }) => {
  return (
    <div
      className={`flex items-center justify-between py-4 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
    >
      <span
        className={`ml-5 text-14 font-medium ${
          isSelected ? "text-[#DE0607]" : "text-[#000000D9]"
        }`}
      >
        {location.name.en} - {location.name.ar}
      </span>

      {/* Actions */}
      <div className="flex flex-row">
        <div className="h-6 mx-4 border-r border-gray-300" />
        {isSelected ? (
          <IoIosArrowForward
            className="w-6 h-6 text-[#DE0607] cursor-pointer"
            onClick={onSelect}
          />
        ) : (
          <HiDotsVertical
            className="w-6 h-6 text-black cursor-pointer"
            onClick={onSelect}
          />
        )}
      </div>
    </div>
  );
};

export default LocationListItem;
