import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Bookings from "../components/Bookings/Bookings";
import Locations from "../components/Locations/Locations";
import EditPage from "../components/Locations/EditLocationPages/EditPage";
import { AiOutlineCalendar, AiOutlineSetting } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { PiUsers } from "react-icons/pi";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineUsers } from "react-icons/hi2";
import { GrAnalytics } from "react-icons/gr";
import Clients from "../components/Clients/Clients";
import { BiWindows } from "react-icons/bi";
import ClientDetails from "../components/Clients/ClientDetails";
import Providers from "../components/Providers/Providers";
import EditProvider from "../components/Providers/EditProvider/EditProvider";
import Services from "../components/Services/Services";
import AddPage from "components/Locations/AddLocation/AddPage";
import AddProvider from "components/Providers/AddProvider/AddProvider";
import Settings from "components/Settings/Settings";
import Analytics from "components/Analytics/Analytics";
import useUsers from "utils/hooks/useUsers";
import Invoices from "components/Invoices/Invoice";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import LocationManager from "components/LocationManager/LocationManager";
import AddLM from "components/LocationManager/AddLM";
import EditLM from "components/LocationManager/EditLM";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import businessService from "services/business/businessService";
import { selectBusinessId } from "../redux/auth/authSlice";
import { useSelector } from "react-redux";

function VendorPage() {
  const [activeContent, setActiveContent] = useState("");
  const [subContent, setSubContent] = useState("");
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState("");
  const [businessData, setBusinessData] = useState(null);
  const userBusinessId = useSelector(selectBusinessId);
  const { fetchUserName, fetchProfile } = useUsers();

  const location = useLocation();

  const bottomSidebarItems = [
    {
      icon: <IoLocationOutline />,
      title: "Location",
      content: <Locations />,
      route: "locations",
      isShown: userRole === "ADMIN" || userRole === "LOCATION_MANAGER",
    },
    {
      icon: <AiOutlineSetting />,
      title: "Settings",
      content: <Settings />,
      route: "settings",
      isShown:
        userRole === "ADMIN" ||
        userRole === "LOCATION_MANAGER" ||
        userRole === "PROVIDER",
    },
  ];
  const sidebarItems = [
    {
      icon: <AiOutlineCalendar />,
      title: "Bookings",
      content: <Bookings />,
      route: "bookings",
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        "LOCATION_MANAGER",
        "PROVIDER",
      ],
    },
    {
      icon: <LuUser2 />,
      title: "Clients",
      content: <Clients />,
      route: "clients",
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        "LOCATION_MANAGER",
      ],
    },
    {
      icon: <LiaFileInvoiceSolid />,
      title: "Invoices",
      content: <Invoices />,
      route: "invoices",
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        "LOCATION_MANAGER",
      ],
    },
    {
      icon: <HiOutlineUsers />,
      title: "Users",
      contents: [
        {
          title: "Location Manager",
          content: <LocationManager />,
          route: "location-manager",
          icon: <IoLocationOutline />,
        },
        {
          title: "Providers",
          content: <Providers />,
          route: "providers",
          icon: <PiUsers />,
        },
      ],
      isCollapsible: true,
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        ,
        "LOCATION_MANAGER",
      ],
    },
    {
      icon: <BiWindows />,
      title: "Services",
      content: <Services />,
      route: "services",
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        "LOCATION_MANAGER",
      ],
    },
    {
      icon: <GrAnalytics />,
      title: "Analytics",
      content: <Analytics />,
      route: "analytics",
      hasAccess: [
        businessData && businessData.isBookingAvailable ? "ADMIN" : null,
        "LOCATION_MANAGER",
      ],
    },
  ];

  const getBusiness = async (businessId) => {
    const businessResponse = await businessService.getBusiness(businessId);
    switch (businessResponse.statusCode) {
      case 200:
        setBusinessData(businessResponse.business);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 1) {
      const id = pathParts[1];
      // Update setActiveContent based on the path
      setActiveContent(pathParts[0]);

      // Fetch and set the sub content based on ID
      if (pathParts[0] === "clients") {
        fetchUserName(id).then((name) => {
          setSubContent(name);
        });
      } else if (pathParts[0] === "providers" && pathParts[1] !== "add") {
        fetchUserName(id).then((name) => setSubContent(name));
      }
      // Add similar conditions for other entities
    } else {
      setActiveContent(pathParts[0].split("-").join(" "));
      setSubContent(""); // Clear sub content when not in a detail view
    }
  }, [location]);

  useEffect(() => {
    fetchProfile().then((data) => {
      setUser(data);
      setUserRole(data?.roles[0]);
    });
  }, []);

  useEffect(() => {
    userBusinessId && getBusiness(userBusinessId);
  }, [userBusinessId]);

  return (
    <LoaderWrapper loading={!userRole}>
      <div
        className="relative flex w-full h-screen min-h-screen"
        style={{
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Sidebar
          sidebarItems={sidebarItems}
          bottomSidebarItems={bottomSidebarItems}
          user={user}
          role={userRole}
        />

        <main
          className={`flex flex-col  h-full px-6 bg-[#00000026]
        w-full md:ml-52 transition-all duration-300 ease-in-out
      `}
        >
          <Header mainText={activeContent} subText={subContent} />

          <Routes>
            <Route path={"/*"} element={<Navigate to={"/bookings"} />} />
            <Route path={"bookings"} element={<Bookings />} />
            <Route path={"locations"} element={<Locations />} />
            <Route path={"locations/add"} element={<AddPage />} />
            <Route path={"locations/:id"} element={<EditPage />} />
            <Route path={"clients"} element={<Clients />} />
            <Route path={"clients/:id"} element={<ClientDetails />} />
            <Route path={"invoices"} element={<Invoices />} />
            <Route path={"location-manager"} element={<LocationManager />} />
            <Route path={"location-manager/add"} element={<AddLM />} />
            <Route path={"location-manager/:id"} element={<EditLM />} />
            <Route path={"providers"} element={<Providers />} />
            <Route path={"providers/:id"} element={<EditProvider />} />
            <Route path={"providers/add"} element={<AddProvider />} />
            <Route path={"services"} element={<Services />} />
            <Route path={"settings"} element={<Settings />} />
            <Route path={"analytics"} element={<Analytics />} />
          </Routes>
        </main>
      </div>
    </LoaderWrapper>
  );
}

export default VendorPage;
