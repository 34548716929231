import * as Yup from "yup";
import { fileSchema } from "../schemaUtils";

// General error messages
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;
const incorrectMessage = (field) => `Incorrect ${field}`;
const finishLaterThanStartMessage = "Finish time must be later than start time";
const noOverlappingTimeSlotsMessage = "No overlapping time slots allowed";

// Email regex pattern
const emailRegex =
  /^[A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Time range validation schema
const timeRangeSchema = Yup.object().shape({
  startTime: Yup.string().required(requiredMessage("Start time")),
  endTime: Yup.string()
    .required(requiredMessage("End time"))
    .test("is-greater", finishLaterThanStartMessage, function (value) {
      const { startTime } = this.parent;
      return value > startTime;
    }),
});

// Schedule validation schema
const scheduleSchema = Yup.array()
  .of(timeRangeSchema)
  .test("is-not-overlapping", noOverlappingTimeSlotsMessage, function (slots) {
    if (!slots) return true;

    const sortedSlots = slots
      .slice()
      .sort((a, b) => a.startTime.localeCompare(b.startTime));

    for (let i = 1; i < sortedSlots.length; i++) {
      if (sortedSlots[i].startTime < sortedSlots[i - 1].endTime) {
        return false;
      }
    }

    return true;
  });

// Availability validation schema
const availabilitySchema = Yup.object().shape({
  days: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(requiredMessage("Day Name")),
        schedule: scheduleSchema,
      })
    )
    .required(requiredMessage("Availability")),
});

// Listing Add Schema
export const ListingAddSchema = Yup.object().shape({
  name: Yup.object()
    .shape({
      en: Yup.string()
        .min(1, "En Name must have at least 1 character")
        .required(requiredMessage("name")),
      ar: Yup.string()
        .min(1, "Ar Name must have at least 1 character")
        .required(requiredMessage("name")),
    })
    .transform((value) => (value === "" ? {} : value))
    .required(requiredMessage("name")),
  categoryId: Yup.number().required(requiredMessage("category")),
  subcategoryIds: Yup.array()
    .of(Yup.number())
    .required(requiredMessage("subcategory"))
    .min(1, "Please select at least one subcategory"),
  cityId: Yup.number().required(requiredMessage("city")),
  email: Yup.string()
    .test("email-required", requiredMessage("email"), function (value) {
      return value !== "";
    })
    .matches(emailRegex, incorrectMessage("Email address"))
    .min(1, invalidMessage("Email address"))
    .max(75, invalidMessage("Email address")),
  phone: Yup.array()
    .of(
      Yup.string()
        .matches(/^\+965[1-9]\d{7}$/, invalidMessage("Phone number"))
        .required(requiredMessage("phone number"))
    )
    .test(
      "at-least-one-phone",
      requiredMessage("phone number"),
      (phoneArray) => phoneArray.length > 0
    ),
  whatsapp: Yup.string()
    .required(requiredMessage("Whatsapp Phone number"))
    .matches(/^(\+|\d|\(|\)|-| )+$/, invalidMessage("Whatsapp Phone number"))
    .min(9, "Whatsapp Phone number must have at least 9 symbols")
    .max(20, "Whatsapp Phone number can have at most 20 symbols"),
  website: Yup.string()
    .transform((value) => (value === "" ? null : value))
    .url(
      invalidMessage(
        "Website Format (http://example.com) or (https://example.com)"
      )
    )
    .matches(
      /^(http:\/\/|https:\/\/)([A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-])+$/,
      invalidMessage(
        "Website Format (http://example.com) or (https://example.com)"
      )
    )
    .nullable(),
  instagram: Yup.string()
    .nullable()
    .test(
      "is-valid-instagram",
      invalidMessage("Instagram URL or username"),
      (value) =>
        !value ||
        /^instagram\.com\/[A-Za-z0-9._]+$/.test(value) ||
        /^[A-Za-z0-9._]+$/.test(value)
    ),
  logo: fileSchema,
  images: Yup.array()
    .of(Yup.string())
    .max(30, "You can upload a maximum of 30 images")
    .nullable(),
  address: Yup.string()
    .required(requiredMessage("Address"))
    .min(1, "Address must have at least 1 character")
    .max(250, "Address can have at most 250 characters"),
  availability: availabilitySchema.required(requiredMessage("Availability")),
  priceRange: Yup.number()
    .required(requiredMessage("Price Range"))
    .typeError(invalidMessage("Price Range"))
    .oneOf([0, 1, 2], invalidMessage("Price Range")),
  amenities: Yup.array().of(Yup.string()).nullable(),
});

// Listing Edit Schema
export const ListingEditOverviewSchema = Yup.object().shape({
  name: Yup.object()
    .shape({
      en: Yup.string()
        .min(1, "En Name must have at least 1 character")
        .required(requiredMessage("name")),
      ar: Yup.string()
        .min(1, "Ar Name must have at least 1 character")
        .required(requiredMessage("name")),
    })
    .transform((value) => (value === "" ? {} : value))
    .required(requiredMessage("name")),
  categoryId: Yup.number().required(requiredMessage("category")),
  subcategoryIds: Yup.array()
    .of(Yup.number())
    .required(requiredMessage("subcategory"))
    .min(1, "Please select at least one subcategory"),
  cityId: Yup.number().required(requiredMessage("city")),
  email: Yup.string()
    .test("email-required", requiredMessage("email"), function (value) {
      return value !== "";
    })
    .matches(emailRegex, incorrectMessage("Email address"))
    .min(1, invalidMessage("Email address"))
    .max(75, invalidMessage("Email address")),
  phone: Yup.array()
    .of(
      Yup.string()
        .matches(/^\+965[1-9]\d{7}$/, invalidMessage("Phone number"))
        .required(requiredMessage("phone number"))
    )
    .test(
      "at-least-one-phone",
      requiredMessage("phone number"),
      (phoneArray) => phoneArray.length > 0
    ),
  whatsapp: Yup.string()
    .required(requiredMessage("Whatsapp Phone number"))
    .matches(/^(\+|\d|\(|\)|-| )+$/, invalidMessage("Whatsapp Phone number"))
    .min(9, "Whatsapp Phone number must have at least 9 symbols")
    .max(20, "Whatsapp Phone number can have at most 20 symbols"),
  website: Yup.string()
    .transform((value) => (value === "" ? null : value))
    .url(
      invalidMessage(
        "Website Format (http://example.com) or (https://example.com)"
      )
    )
    .matches(
      /^(http:\/\/|https:\/\/)([A-Za-z0-9\u0600-\u06FF.!#$%&'*+\/=?^_`{|}~-])+$/,
      invalidMessage(
        "Website Format (http://example.com) or (https://example.com)"
      )
    )
    .nullable(),
  instagram: Yup.string()
    .nullable()
    .test(
      "is-valid-instagram",
      invalidMessage("Instagram URL or username"),
      (value) =>
        !value ||
        /^instagram\.com\/[A-Za-z0-9._]+$/.test(value) ||
        /^[A-Za-z0-9._]+$/.test(value)
    ),
  logo: Yup.string().nullable(),
  images: Yup.array()
    .of(Yup.string())
    .max(30, "You can upload a maximum of 30 images")
    .nullable(),
  address: Yup.string()
    .required(requiredMessage("Address"))
    .min(1, "Address must have at least 1 character")
    .max(250, "Address can have at most 50 characters"),
  priceRange: Yup.number()
    .required(requiredMessage("Price Range"))
    .typeError(invalidMessage("Price Range"))
    .oneOf([0, 1, 2], invalidMessage("Price Range")),
  amenities: Yup.array().of(Yup.string()).nullable(),
});

// Availability validation schema
const editAvailabilitySchema = Yup.object().shape({
  days: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(requiredMessage("Day Name")),
        schedule: scheduleSchema,
      })
    )
    .required(requiredMessage("Availability")),
});

// Listing Edit Availability Schema
export const ListingEditAvailabilitySchema = Yup.object().shape({
  availability: editAvailabilitySchema,
});

export const ListingEditReviewsSchema = Yup.object().shape({
  rating: Yup.number().required(requiredMessage("Rating")),
  text: Yup.object()
    .shape({
      en: Yup.string(),
      ar: Yup.string(),
    })
    .test(
      "at-least-one",
      "Either Review (English) or Review (Arabic) is required",
      (value) =>
        (value.en && value.en.trim() !== "") ||
        (value.ar && value.ar.trim() !== "")
    ),
});
