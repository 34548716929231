import api from "./apiConfig";

const photoService = {
  uploadPhoto: async (formData) => {
    // Expect formData to be passed in
    try {
      // Set the appropriate headers for multipart/form-data
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await api.post("/upload", formData, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default photoService;
