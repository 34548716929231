import React from "react";
import { getAgeGroupString } from "utils/helpers";

const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts.map((part) => part[0]).join("");
  return initials.toUpperCase();
};

const PersonalInfo = ({ name, number, gender, age, email, avatar }) => {
  const userInfo = {
    number,
    gender,
    age: getAgeGroupString(age),
    email,
  };

  return (
    <div className="flex items-start justify-start w-full p-6 space-x-4 bg-white shadow-lg border border-[#00000026] rounded-md">
      {avatar ? (
        <img className="w-24 h-24 rounded-full" src={avatar} alt="Profile" />
      ) : (
        <div className="flex items-center justify-center w-24 h-24 text-2xl font-medium text-gray-700 bg-gray-300 rounded-full">
          {getInitials(name)}
        </div>
      )}
      <div className="flex flex-col space-y-2">
        <span className="text-xl font-medium text-gray-700">{name}</span>
        {Object.keys(userInfo).map((key, index) => (
          <div key={index} className="flex items-center justify-start">
            <span className="w-20 text-sm text-gray-500 capitalize">{key}</span>
            <span className="font-medium text-gray-700">{userInfo[key]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalInfo;
