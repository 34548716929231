import { createSlice } from "@reduxjs/toolkit";

export const providerSlice = createSlice({
  name: "provider",
  initialState: {
    logo: "",
    name: "",
    speciality: "",
    email: "",
    phone: "",
    availabilities: [],
    subserviceIds: [],
    businessId: 0,
  },
  reducers: {
    setSpecificProviderData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearProviderData: (state) => {
      state.logo = "";
      state.name = "";
      state.speciality = "";
      state.email = "";
      state.phone = "";
      state.locations = [];
      state.availabilities = [];
      state.subserviceIds = [];
      state.businessId = 0;
    },
    clearAvailabilities: (state) => {
      state.availabilities = [];
    },
    updateAvailabilitiesByLocation: (state, action) => {
      const { locationId, days } = action.payload;
      let availabilities = state.availabilities || [];
      let index = availabilities.findIndex(
        (availability) => availability.locationId === locationId
      );
      if (index === -1) {
        availabilities.push({ locationId, days });
      } else {
        availabilities[index].days = days;
      }
      state.availabilities = availabilities;
    },
    updateSubserviceIds: (state, action) => {
      const { subserviceId } = action.payload;
      let subserviceIds = state.subserviceIds || [];
      let isExist = subserviceIds.includes(subserviceId);
      if (isExist) {
        subserviceIds = subserviceIds.filter((id) => id !== subserviceId);
      } else {
        subserviceIds.push(subserviceId);
      }
      state.subserviceIds = subserviceIds;
    },
    clearSubserviceIds: (state) => {
      state.subserviceIds = [];
    },
  },
});

export const {
  setSpecificProviderData,
  clearProviderData,
  updateAvailabilitiesByLocation,
  clearAvailabilities,
  updateSubserviceIds,
  clearSubserviceIds,
} = providerSlice.actions;

export const selectProvider = (state) => state.provider;
export const selectAvailabilities = (state) => state.provider.availabilities;
export const selectSubserviceIds = (state) => state.provider.subserviceIds;
