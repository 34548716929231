import Button from "components/core/Button";
import SelectInput from "components/core/SelectInput";
import InputField from "components/core/inputField";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/auth/authSlice";
import businessService from "services/business/businessService";
import { showError, showSuccess } from "utils/helpers";
import { OnlineBookingTabSchema } from "utils/schemas/Settings/SettingsSchemas";

const OnlineTab = ({ data, setData }) => {
  const [minTime, setMinTime] = useState();
  const [minTimeUnit, setMinTimeUnit] = useState("minutes");
  const [daysCount, setDaysCount] = useState();
  const [daysCountUnit, setDaysCountUnit] = useState("days");
  const [timeSlot, setTimeSlot] = useState();
  const [timeSlotUnit, setTimeSlotUnit] = useState("minutes");

  const userRole = useSelector(selectRole);

  const minTimeOptions = [
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
  ];

  const daysCountOptions = [{ label: "Days", value: "days" }];

  const timeSlotOptions = [
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
  ];

  const saveOnlineBookingTab = async (businessId, obj) => {
    try {
      const response = await businessService.updateBusiness(businessId, obj);
      switch (response.statusCode) {
        case 200:
          setData(response.business);
          showSuccess(response.message);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async () => {
    let businessId = data.id;
    let minValue = minTimeUnit === "minutes" ? minTime : minTime * 60;
    let daysValue = daysCountUnit === "days" ? daysCount : daysCount * 24;
    let timeValue = timeSlotUnit === "minutes" ? timeSlot : timeSlot * 60;
    const obj = {
      minTimeBeforeBooking: parseInt(minValue),
      maxDaysBeforeBooking: parseInt(daysValue),
      timeSlotInterval: parseInt(timeValue),
    };

    try {
      if (businessId === undefined || businessId === null) {
        showError("Please select a business first.");
        return;
      }
      await OnlineBookingTabSchema.validate(obj);
      await saveOnlineBookingTab(businessId, obj);
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    if (data) {
      setMinTime(data.minTimeBeforeBooking);
      setDaysCount(data.maxDaysBeforeBooking);
      setTimeSlot(data.timeSlotInterval);
    } else {
      setMinTime("");
      setDaysCount("");
      setTimeSlot("");
    }
  }, [data]);

  const hasAccess = (roles) => {
    if (userRole === undefined) {
      return false;
    }
    return roles.includes(userRole);
  };
  return (
    <div className="w-full max-w-[600px] space-y-4">
      <div className="flex w-full space-x-2">
        <p className="text-[#000000D9] text-14 font-normal leading max-w-[180px] w-full">
          Min time before a booking can be booked:
        </p>
        <InputField
          placeholder={"30"}
          type="text"
          value={minTime}
          onChange={(e) => setMinTime(e.target.value)}
          inputClassName={"max-w-[176px]  h-9"}
          className="w-full"
          disabled={!hasAccess(["ADMIN"])}
        />
        <SelectInput
          options={minTimeOptions}
          onSelect={(value) => setMinTimeUnit(value)}
          selected={
            minTimeOptions.find((option) => option.value === minTimeUnit) ||
            minTimeUnit
          }
          inputClassName="w-44 h-9"
          className="w-full mt-1"
          isDisabled={!hasAccess(["ADMIN"])}
        />
      </div>
      <div className="flex w-full space-x-2">
        <p className="text-[#000000D9] text-14 font-normal leading-20 max-w-[180px] w-full">
          How many days in advance can a booking be made:{" "}
        </p>
        <InputField
          placeholder={"30"}
          type="number"
          value={daysCount}
          onChange={(e) => setDaysCount(e.target.value)}
          inputClassName={"max-w-[176px] w-full h-9"}
          className="w-full"
          disabled={!hasAccess(["ADMIN"])}
        />
        <SelectInput
          options={daysCountOptions}
          onSelect={(value) => setDaysCountUnit(value)}
          selected={
            daysCountOptions.find((option) => option.value === daysCountUnit) ||
            daysCountUnit
          }
          inputClassName="w-44 h-9"
          className="w-full mt-1"
          isDisabled={!hasAccess(["ADMIN"])}
        />
      </div>
      <div className="flex w-full space-x-2">
        <p className="text-[#000000D9] text-14 font-normal leading-20 max-w-[180px] w-full">
          Time slot for interval:
        </p>
        <InputField
          placeholder={"30"}
          type="text"
          value={timeSlot}
          onChange={(e) => setTimeSlot(e.target.value)}
          inputClassName={"max-w-[176px] h-9"}
          className="w-full"
          disabled={!hasAccess(["ADMIN"])}
        />

        <SelectInput
          options={timeSlotOptions}
          onSelect={(value) => setTimeSlotUnit(value)}
          selected={
            timeSlotOptions.find((option) => option.value === timeSlotUnit) ||
            timeSlotUnit
          }
          inputClassName="w-44 h-9"
          className="w-full mt-1"
          isDisabled={!hasAccess(["ADMIN"])}
        />
      </div>

      {
        // if user is admin, show the save button else hide it
        hasAccess(["ADMIN"]) && (
          <div
            className={`flex items-center justify-end w-full pt-6 bg-white `}
          >
            <Button
              label="Cancel"
              className={
                "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
              }
              textColor="text-[#000000D9]"
              onClick={() => {}}
            />
            <Button
              label="Save"
              className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
              textColor="text-white"
              onClick={handleSave}
            />
          </div>
        )
      }
    </div>
  );
};

export default OnlineTab;
