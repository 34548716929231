import React, { useEffect, useMemo, useState } from "react";
import TableComponent from "../core/Table/TableComponent";
import { offlineData, onlineData } from "../../constants/data/clientsData";
import InputField from "../core/inputField";
import { IoIosSearch } from "react-icons/io";
import { AiOutlineDownload } from "react-icons/ai";
import Button from "../core/Button";
import { useNavigate } from "react-router-dom";
import userService from "services/user/userService";
import { getAgeGroupString, showError } from "utils/helpers";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import businessService from "services/business/businessService";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";

const Clients = () => {
  const [onlineData, setOnlineData] = useState([]);
  const [userDatasToExport, setUserDatasToExport] = useState([]);
  const userBusinessId = useSelector(selectBusinessId);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await userService.getUsers();
      switch (response.statusCode) {
        case 200:
          let filteredData = response.users.filter(
            (user) => user.roles[0] === "USER" && user.bookings.length > 0
          );

          const business = await businessService.getBusiness(userBusinessId);
          const locationIds = business.business.locations.map(
            (location) => location.id
          );
          filteredData = filteredData.filter((user) =>
            user.bookings.some(
              (booking) =>
                locationIds.includes(booking.locationId) &&
                (booking.status === "COMPLETED" ||
                  booking.status === "CONFIRMED")
            )
          );

          let userAges = filteredData.map((user) =>
            user.bookings && user.bookings.length > 0
              ? user.bookings[0].userAge
              : user.ageGroup
          );
          setUserDatasToExport(
            filteredData.map((user) => ({
              id: user.id,
              clientName: `${user.name} ${user.surname || ""}`,
              phoneNumber: user.phone,
              email: user.email,
              gender: user.gender,
              age: getAgeGroupString(user.ageGroup),
              noOfVisits: user.bookingsCount,
              totalSpent: user.bookings.reduce(
                (acc, booking) => acc + booking.totalPrice,
                0
              ),
            }))
          );
          setOnlineData(
            filteredData.map((user) => {
              return {
                clientId: user.id,
                clientName: user.name,
                phone: user.phone,
                email: user.email,
                gender: user.gender,
                age: getAgeGroupString(
                  user.bookings && user.bookings.length > 0
                    ? user.bookings[0].userAgeGroup || user.ageGroup
                    : user.ageGroup
                ),
                noOfVisits: user.bookingsCount,
                totalSpent: user.bookings.reduce(
                  (acc, booking) => acc + booking.totalPrice,
                  0
                ),
              };
            })
          );
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  function arrayToCsv(data) {
    return data
      .map(
        (row) =>
          row
            .map(String) // convert every value to String
            .map((v) => v.replaceAll('"', '""')) // escape double quotes
            .map((v) => `"${v}"`) // quote it
            .join(",") // comma-separated
      )
      .join("\r\n"); // rows starting on new lines
  }

  const downloadCSV = (data, filename) => {
    const headers = Object.keys(data[0]);
    const csvData = [
      headers,
      ...data.map((row) => headers.map((header) => row[header])),
    ];
    const csvString = arrayToCsv(csvData);

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
  };

  const handleExport = () => {
    downloadCSV(userDatasToExport, "clients.csv");
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      key: "clientName",
      value: "Client Name",
    },
    {
      key: "phone",
      value: "Phone Number",
    },
    {
      key: "email",
      value: "Email",
    },
    {
      key: "gender",
      value: "Gender",
    },
    {
      key: "age",
      value: "Age",
    },
    {
      key: "noOfVisits",
      value: "No of Visits",
    },
    {
      key: "totalSpent",
      value: "Total Spent",
    },
  ];

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="px-4 py-6 mt-4 bg-white rounded-md">
        <div className="flex justify-end w-full space-x-6 bg-white">
          <Button
            label="Export"
            className="bg-[#DE0607] text-white text-14 font-normal space-x-2"
            textColor="text-white"
            icon={<AiOutlineDownload color="#fff" size={18} />}
            onClick={() => {
              handleExport();
            }}
          />
        </div>
        <div className="mt-6">
          {onlineData.length === 0 ? (
            <div className="flex items-center justify-center h-48">
              <span className="text-gray-400 text-14">No data available</span>
            </div>
          ) : (
            <TableComponent
              headers={columns}
              data={onlineData}
              numberOfRows={10}
              isClickable={true}
              onRowClick={(row) => {
                navigate(`/clients/${row.clientId}`);
              }}
            />
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default Clients;
