import React, { useState, useEffect } from "react";
import "./styles.css";
import usersService from "../../../../../services/users/usersService";
import { showError } from "../../../../../utils/helpers";

const AddUserModal = ({
  isOpen,
  setIsModalOpen,
  setUsers,
  setFilteredUsersListings,
  users,
  filteredUsersListings,
}) => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    age: "",
    // bookings: 0,
    // reviews: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    addUser(userInfo);
    setIsModalOpen(false);
  };

  const addUser = async (data) => {
    try {
      // let data = {
      //   name: userInfo.name,
      //   id: userInfo.id,
      //   email: userInfo.email,
      //   phoneNumber: userInfo.phone,
      //   gender: userInfo.gender,
      //   age: userInfo.age,
      // };
      const response = await usersService.addUser(data);
      setUsers([data, ...users]);
      setFilteredUsersListings([data, ...filteredUsersListings]);
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {}, []);

  if (isOpen) {
    return (
      <div className="modal-add-user">
        <div className="add-user-info-modal">
          <form onSubmit={handleSubmit}>
            <div className="add-user-info-modal-header">
              <h2 style={{ fontWeight: "bold" }}>Add User Info</h2>
              <button onClick={() => setIsModalOpen(false)}>
                <p style={{ color: "#8c8c8c", fontSize: 20 }}>x</p>
              </button>
            </div>
            <div className="add-user-input-container">
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={userInfo.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={userInfo.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                />
              </label>
              <label>
                Phone number:
                <input
                  type="tel"
                  name="phoneNumber"
                  value={userInfo.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                />
              </label>
              <label>
                Gender:
                <select
                  name="gender"
                  value={userInfo.gender}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </label>
              <label>
                Age:
                <input
                  type="number"
                  name="age"
                  value={userInfo.age}
                  onChange={handleChange}
                  placeholder="Enter age"
                />
              </label>
              <label>
                Bookings:
                <input
                  type="number"
                  name="bookings"
                  value={userInfo.bookings}
                  onChange={handleChange}
                  disabled
                />
              </label>
              <label>
                Reviews:
                <input
                  type="number"
                  name="reviews"
                  value={userInfo.reviews}
                  onChange={handleChange}
                  disabled
                />
              </label>
              <div className="buttons">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="cancel-add-user"
                  type="button"
                >
                  Cancel
                </button>
                <button className="submit-add-user" type="submit">
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default AddUserModal;
