import React, { useEffect, useState } from "react";
import StatCard from "./OverviewComponents/StatCard";
import PieChart from "./OverviewComponents/PieChart";
import Table from "components/core/Table/TableComponent";
import LineChart from "./OverviewComponents/GuestChart";

const OverviewTab = ({ analyticsData }) => {
  const [stats, setStats] = useState([]);
  const [pieChartData, setPieChartData] = useState({
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ["blue", "orange"],
      },
    ],
  });
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [topGuestsData, setTopGuestsData] = useState([]);
  const [guestChartData, setGuestChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "New guests",
        data: [],
        backgroundColor: "#17D861",
        borderColor: "#17D861",
        borderWidth: 1,
        borderRadius: 5,
      },
      {
        label: "Returning guests",
        data: [],
        backgroundColor: "#E9EAEF",
        borderColor: "#D9D9D9",
        borderWidth: 1,
        borderRadius: 5,
      },
    ],
  });

  const topGuestsHeaders = [
    {
      key: "name",
      value: "Name",
    },
    {
      key: "numberOfVisits",
      value: "# Visits",
    },
  ];

  const convertObjectToArray = (obj) => {
    if (!obj) return;
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));
  };

  useEffect(() => {
    if (!analyticsData) return;
    setStats([
      {
        title: "Total bookings",
        value: analyticsData.totalBookingsCount,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ad406ca55386822c25e28099cc6c3ca6229d9f254f74b3c71e54459722137521?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-emerald-50",
      },
      {
        title: "Total revenue",
        value: analyticsData.totalRevenue,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef11ddd6815ee4b97aecb670ca3a0e3bad78be374e6752c8ad5778e335cf8235?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-blue-50",
      },
      {
        title: "Returning guests",
        value: analyticsData.returningClientsCount,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3cbbb45adddbd7315be0a99b6b08d619439bbc01f9be029577102de6734c7ae3?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-rose-50",
      },
      {
        title: "New guests",
        value: analyticsData.newClientsCount,
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5abcb5962ae5fdce3b09cad05ea11b2fcc7921618eaa76612955ecd6d0e055e4?apiKey=644ff1d4dd224158826d52245b6f2496&",
        iconBg: "bg-yellow-100",
      },
    ]);

    setPieChartData({
      labels: ["Male", "Female"],
      datasets: [
        {
          data: [
            analyticsData.genders ? analyticsData.genders.MALE.count : 0,
            analyticsData.genders ? analyticsData.genders.FEMALE.count : 0,
          ],
          backgroundColor: ["#0E5FD9", "#FF9500"],
        },
      ],
    });

    setBarChartData({
      labels: ["Up to 18", "18 - 25", "26 - 35", "36 - 45", "46 - 60", "60+"],
      datasets: [
        {
          data: [
            analyticsData.ages ? analyticsData.ages["<18"].count : 0,
            analyticsData.ages ? analyticsData.ages["18-25"].count : 0,
            analyticsData.ages ? analyticsData.ages["26-35"].count : 0,
            analyticsData.ages ? analyticsData.ages["36-45"].count : 0,
            analyticsData.ages ? analyticsData.ages["46-60"].count : 0,
            analyticsData.ages ? analyticsData.ages[">60"].count : 0,
          ],
          backgroundColor: [
            "#22C55E",
            "#0E5FD9",
            "#EAB308",
            "#FF9500",
            "#E84646",
            "#8B5CF6",
          ],
        },
      ],
    });

    analyticsData.topClients && analyticsData.topClients.length > 0
      ? setTopGuestsData(
          analyticsData.topClients.map((client) => ({
            name: client.name,
            numberOfVisits: client.bookingsCount,
          }))
        )
      : setTopGuestsData([
          {
            name: "No data",
            numberOfVisits: 0,
          },
        ]);

    const newGuestsData = convertObjectToArray(analyticsData.clientsByMonths);
    if (newGuestsData && newGuestsData.length > 0) {
      let newData = convertObjectToArray(newGuestsData[0].value);
      setGuestChartData({
        labels: newGuestsData[0].value ? newData.map((data) => data.name) : [],
        datasets: [
          {
            label: "New guests",
            data: newGuestsData[0].value,
            backgroundColor: "#17D861",
            borderColor: "#17D861",
            borderWidth: 1,
            borderRadius: 5,
          },
          {
            label: "Returning guests",
            data: newGuestsData[1].value,
            backgroundColor: "#E9EAEF",
            borderColor: "#D9D9D9",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      });
    }
  }, [analyticsData]);

  return (
    <div className="h-full mt-2 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        {stats.map((stat, index) => (
          <StatCard
            key={index}
            title={stat.title}
            value={stat.value}
            icon={stat.icon}
            iconBg={stat.iconBg}
          />
        ))}
      </div>
      <div className="h-full mt-5 max-md:max-w-full">
        <div className="flex h-full gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 space-y-5 max-md:ml-0 max-md:w-full">
            <div className="flex w-full gap-5 h-fit max-md:flex-col max-md:gap-0">
              <div className="flex w-6/12 h-full">
                <PieChart
                  data={pieChartData}
                  showValues={false}
                  title={"Clients Gender"}
                >
                  <div className="flex flex-col justify-center ">
                    {pieChartData.labels &&
                      pieChartData.labels.map((label, index) => {
                        const total = pieChartData.datasets[0].data.reduce(
                          (acc, curr) => acc + curr
                        );
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between"
                          >
                            <div className="flex items-center w-1/3 space-x-2">
                              <div
                                className="w-2 h-2 "
                                style={{
                                  backgroundColor:
                                    pieChartData.datasets[0].backgroundColor[
                                      index
                                    ],
                                  borderRadius: "50%",
                                }}
                              ></div>
                              <p className="text-14 font-medium text-[#000000D9]">
                                {label}
                              </p>
                            </div>
                            <p className="w-1/3 text-14 text-center font-normal text-[#00000073]">
                              {pieChartData.datasets[0].data[index] === 0 ? (
                                <span className="text-red-500">No data</span>
                              ) : (
                                pieChartData.datasets[0].data[index]
                              )}
                            </p>
                            <p className="w-1/3 text-center text-14 font-medium text-[#000000D9]">
                              {pieChartData.datasets[0].data[index] === 0
                                ? "0.00"
                                : (
                                    (pieChartData.datasets[0].data[index] /
                                      total) *
                                    100
                                  ).toFixed(2)}
                              %
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </PieChart>
              </div>

              <div className="flex w-6/12 h-full">
                <PieChart
                  data={barChartData}
                  showValues={true}
                  title={"Clients age demographic"}
                >
                  <div className="grid grid-cols-3 gap-2 mt-2 text-xs text-center text-gray-500 ">
                    {barChartData.labels &&
                      barChartData.labels.map((label, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-center gap-1"
                        >
                          <div
                            className="w-2 h-2 "
                            style={{
                              backgroundColor:
                                barChartData.datasets[0].backgroundColor[index],
                              borderRadius: "50%",
                            }}
                          ></div>
                          <p className="text-14 w-14 font-medium text-[#000000D9]">
                            {label}
                          </p>
                        </div>
                      ))}
                  </div>
                </PieChart>
              </div>
            </div>
            <div className="flex flex-col w-full overflow-hidden border rounded-md shadow-lg h-fit">
              <p className="text-16 font-semibold text-[#000000D9] p-4 border-b">
                Top 5 Guests
              </p>
              <Table
                headers={topGuestsHeaders}
                data={topGuestsData}
                numberOfRows={5}
                showPagination={false}
              />
            </div>
          </div>
          <div className="flex flex-col w-6/12 h-full ml-5 max-md:ml-0 max-md:w-full">
            <LineChart
              data={guestChartData}
              title={"New Guest"}
              maxHeight={"800px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
