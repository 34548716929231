import TextArea from "components/core/textArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookings,
  selectTotalPrice,
  setSpecificBookingData,
} from "../../../../../redux/bookings/bookingsSlice";
import Button from "components/core/Button";
import {
  convertAgeToAgeGroup,
  convertToISOString,
  showError,
  showSuccess,
} from "utils/helpers";
import bookingService from "services/booking/bookingService";
import moment from "moment-timezone";
import useBusinesses from "utils/hooks/useBusiness";

const NotesTab = ({ hide }) => {
  const [notes, setNotes] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const totalPrice = useSelector(selectTotalPrice);
  const dispatch = useDispatch();
  const bookingsReduxData = useSelector(selectBookings);
  const { business } = useBusinesses();

  const getDeliveryFeeByCityId = (locationSettings, cityId) => {
    if (!locationSettings || !cityId) {
      return null;
    }
    for (const location of locationSettings) {
      if (Array.isArray(location.cities)) {
        const city = location.cities.find((city) => city.id === cityId);
        if (city) {
          return location.deliveryFee;
        }
      }
    }
    return null;
  };

  const calculateTotalPrice = () => {
    let result = totalPrice;
    if (bookingsReduxData.bookingDestination === "home") {
      let cityId = bookingsReduxData.city.value;
      const newDeliveryFee = getDeliveryFeeByCityId(
        bookingsReduxData.locationSettings,
        cityId
      );
      result += newDeliveryFee;
    }
    return result;
  };

  const getDeliveryFee = () => {
    if (bookingsReduxData.bookingDestination !== "home") {
      return 0;
    }
    let cityId = bookingsReduxData.city.value;
    const newDeliveryFee = getDeliveryFeeByCityId(
      bookingsReduxData.locationSettings,
      cityId
    );
    if (newDeliveryFee) {
      return newDeliveryFee;
    }
    return 0;
  };

  const convertToUTCPlus3 = (date) => {
    const offset = 3 * 60; // UTC+3 in minutes
    const localDate = moment(date);
    const utcDate = moment.utc(localDate).add(offset, "minutes");
    return utcDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  };

  const handleSaveBooking = async () => {
    let address = "";
    if (bookingsReduxData.bookingDestination === "home") {
      address = `
      ${bookingsReduxData.blockNumber ? bookingsReduxData.blockNumber : ""}
      ${bookingsReduxData.streetNumber ? bookingsReduxData.streetNumber : ""}
      ${bookingsReduxData.houseNumber ? bookingsReduxData.houseNumber : ""}
      ${bookingsReduxData.city.name.en ? bookingsReduxData.city.name.en : ""}
      ${bookingsReduxData.city.name.ar ? bookingsReduxData.city.name?.ar : ""}
    `;
    }
    let properties = bookingsReduxData.properties.map((property) => {
      if (
        property.service.childSubserviceId !== undefined &&
        property.service.childSubserviceId !== null
      ) {
        return {
          // subserviceId: parseInt(property.service.subserviceId),
          childSubserviceId: parseInt(property.service.childSubserviceId),
          date: convertToUTCPlus3(property.date),
          providerId: parseInt(property.staff.value),
          price: parseInt(property.service.price),
        };
      } else {
        return {
          subserviceId: parseInt(property.service.subserviceId),
          //childSubserviceId: parseInt(property.service.id),
          date: convertToUTCPlus3(property.date),
          providerId: parseInt(property.staff.value),
          price: parseInt(property.service.price),
        };
      }
    });

    let data = {
      userName: bookingsReduxData.userName,
      userEmail: bookingsReduxData.userEmail,
      userGender: bookingsReduxData.userGender?.value
        ? bookingsReduxData.userGender.value
        : null,
      userPhoneNumber: bookingsReduxData.userPhoneNumber,
      userAgeGroup: bookingsReduxData.userAge.value,
      // discountId: bookingsReduxData.discountId,
      type: bookingsReduxData.paymentLink.value,
      bookingDestination: bookingsReduxData.bookingDestination
        ? bookingsReduxData.bookingDestination.toUpperCase()
        : "VENDOR",
      address: address,
      notes: bookingsReduxData.notes,
      locationId: bookingsReduxData.locationId,
      cityId:
        bookingsReduxData.location && bookingsReduxData.location.cityId
          ? bookingsReduxData.location.cityId
          : bookingsReduxData.city && bookingsReduxData.city.id
          ? bookingsReduxData.city.id
          : null,
      totalPrice: bookingsReduxData.totalPrice,
      deliveryFee: getDeliveryFee(),
      properties: properties,
    };

    if (
      business.acceptDeposits &&
      bookingsReduxData.paymentLink.value !== "OFFLINE"
    ) {
      data.depositAmount = business.depositAmount;
    }

    if (bookingsReduxData.paymentLink.value === "LINK") {
      data.paymentMethod = bookingsReduxData.paymentMethod.value;
    } else {
      data.paymentMethod = "OFFLINE";
    }

    try {
      const response = await bookingService.createBooking(data);
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          hide();
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || error.message);
    }
  };

  useEffect(() => {
    if (bookingsReduxData.notes) {
      setNotes(bookingsReduxData.notes);
    }
  }, [bookingsReduxData.notes]);
  return (
    <div>
      <TextArea
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
          dispatch(
            setSpecificBookingData({ field: "notes", value: e.target.value })
          );
        }}
        placeholder="Enter Notes"
        fieldHeight="200px"
      />
      <div className="mt-4">
        <div className="flex space-x-2">
          <p className="w-full max-w-24">Total Price: </p>
          <p>KWD {calculateTotalPrice()}</p>
        </div>

        {bookingsReduxData.bookingDestination === "home" && (
          <div className="flex space-x-2 text-14">
            <p className="w-full max-w-24">Delivery Fee: </p>
            <p>KWD {getDeliveryFee()}</p>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 flex justify-end w-full h-20 mt-4 space-x-2 right-6 ">
        <Button
          label="Cancel"
          className={
            "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
          }
          textColor="text-black"
          onClick={hide}
        />
        <Button
          label="Save"
          className={"w-40 bg-[#DE0607] text-white rounded-lg"}
          textColor="text-white"
          onClick={handleSaveBooking}
        />
      </div>
    </div>
  );
};

export default NotesTab;
