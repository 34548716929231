import React, { useEffect, useState } from "react";
import ImageInput from "../../core/ImageInput";
import InputField from "../../core/inputField";
import Button from "../../core/Button";
import usersService from "services/users/usersService";
import photoService from "services/photoService";
import { showError, showSuccess } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import providersService from "services/providers/providersService";
import { providerEditProfileSchema } from "utils/schemas/Provider/ProviderSchemas";

const ProviderProfile = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      const newData = {
        ...data,
        name,
        speciality,
        avatarPath: selectedImage,
      };
      await providerEditProfileSchema.validate(newData).then(async () => {
        const response = await providersService.updateProvider(
          data.id,
          newData
        );
        switch (response.statusCode) {
          case 200:
            showSuccess(response.message);
            break;
          default:
            showError(response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };
  useEffect(() => {
    if (data) {
      setName(data.name);
      setSpeciality(data.speciality);
      setEmail(data.email);
      setPhone(data.phone);
      setSelectedImage(data.avatarPath);
    }
  }, [data]);

  return (
    <div className="flex flex-col w-1/2 ">
      <div className="flex items-center justify-center w-32 h-32 ">
        <ImageInput
          value={selectedImage}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          imageSize={"w-32 h-32"}
          className="w-32 h-32 border-0"
          isRounded
          supportedFormats={["image/jpg", "image/png", "image/svg+xml"]}
        />
      </div>
      <div className="flex flex-col mt-4 space-y-4 max-w-[500px]   h-full">
        <InputField
          label="Name"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputField
          label="Speciality"
          placeholder="Enter speciality"
          value={speciality}
          onChange={(e) => setSpeciality(e.target.value)}
        />
        <InputField
          label="Email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled
        />
        <InputField
          label="Phone Number"
          placeholder="Enter phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled
        />

        <div className="flex items-center justify-end w-full h-20 space-x-4">
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={() => navigate("/providers")}
          />
          <Button
            label="Save"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default ProviderProfile;
