import React, { useEffect, useState } from "react";
import PercentageBar from "./Components/PercentageBar/PercentageBar";
import CustomerBookingsTableList from "./Components/CustomerBookingsTableList/CustomerBookingsTableList";
import "./styles.css";
import analyticsService from "services/analytics/analyticsService";
import businessService from "services/business/businessService";
import { showError, showSuccess } from "utils/helpers";

const InfoVendorsPage = ({ imageUrl, altText, selectedVendorData }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [vendorAnalyticsData, setVendorAnalyticsData] = useState({});
  const [maxNumberOfStaffValue, setMaxNumberOfStaffValue] = useState(0);
  const [maxNumberOfLocationsValue, setMaxNumberOfLocationsValue] = useState(0);
  const [isBookingEnabled, setIsBookingEnabled] = useState(false);
  const [isIbanNumberFilled, setIsIbanNumberFilled] = useState(false);

  const toggleHomeService = () => {
    setIsEnabled(!isEnabled);
  };

  const fetchVendorAnalytics = async () => {
    if (selectedVendorData?.id != null) {
      const vendorAnalyticsData = await analyticsService.getVendorAnalytics(
        selectedVendorData?.admin.id
      );
      setVendorAnalyticsData(vendorAnalyticsData.data);
    }
  };

  const handleBusinessSettings = async () => {
    try {
      const response = await businessService.getBusiness(selectedVendorData.id);
      switch (response.statusCode) {
        case 200:
          let ibanNumber = response.business.ibanNumber;
          if (ibanNumber != null && ibanNumber != "") {
            setIsIbanNumberFilled(true);
          }
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    if (selectedVendorData?.id != null) {
      fetchVendorAnalytics();
      handleBusinessSettings();
    }
  }, [selectedVendorData]);

  const saveBookingSettings = async () => {
    const newSettings = {
      maxNumberOfStaff: maxNumberOfStaffValue,
      maxNumberOfLocations: maxNumberOfLocationsValue,
      homeServiceEnabled: isEnabled,
      isBookingAvailable: isBookingEnabled,
    };

    const response = await businessService.updateBusinessSettings(
      selectedVendorData.id,
      newSettings
    );
    switch (response.statusCode) {
      case 200:
        showSuccess("Settings updated successfully");
        setMaxNumberOfLocationsValue(response.business.maxNumberOfLocations);
        setMaxNumberOfStaffValue(response.business.maxNumberOfStaff);
        setIsEnabled(response.business.homeServiceEnabled);
        setIsBookingEnabled(response.business.isBookingAvailable);
        break;
      default:
        showError(response.message);
        break;
    }
  };

  // Mock data for demonstration purposes
  const [revenueData, setRevenueData] = useState([
    {
      id: 0,
      name: "Total bookings",
      value: vendorAnalyticsData?.totalBookingsCount,
    },
    { id: 1, name: "Total revenue", value: vendorAnalyticsData?.totalRevenue },
    { id: 2, name: "App revenue", value: vendorAnalyticsData?.appRevenue },
    { id: 3, name: "Link revenue", value: vendorAnalyticsData?.linkRevenue },
    {
      id: 4,
      name: "Offline revenue",
      value: vendorAnalyticsData?.offlineRevenue,
    },
  ]);

  const [channelOfBookingsData, setChannelOfBookingsData] = useState([
    {
      id: 0,
      name: "App",
      value: vendorAnalyticsData?.channelOfBookings?.appCount,
    },
    {
      id: 1,
      name: "Link",
      value: vendorAnalyticsData?.channelOfBookings?.linkCount,
    },
    {
      id: 2,
      name: "Offline",
      value: vendorAnalyticsData?.channelOfBookings?.offlineCount,
    },
  ]);

  const [totalBookings, setTotalBookings] = useState(
    revenueData.filter((item) => item.name === "Total bookings")[0].value
  );
  useEffect(() => {
    const newRevenue = [
      {
        id: 0,
        name: "Total bookings",
        value: vendorAnalyticsData?.totalBookingsCount,
      },
      {
        id: 1,
        name: "Total revenue",
        value: vendorAnalyticsData?.totalRevenue,
      },
      { id: 2, name: "App revenue", value: vendorAnalyticsData?.appRevenue },
      { id: 3, name: "Link revenue", value: vendorAnalyticsData?.linkRevenue },
      {
        id: 4,
        name: "Offline revenue",
        value: vendorAnalyticsData?.offlineRevenue,
      },
    ];

    setRevenueData(newRevenue);

    setChannelOfBookingsData([
      {
        id: 0,
        name: "App",
        value: vendorAnalyticsData?.channelOfBookings?.appCount,
      },
      {
        id: 1,
        name: "Link",
        value: vendorAnalyticsData?.channelOfBookings?.linkCount,
      },
      {
        id: 2,
        name: "Offline",
        value: vendorAnalyticsData?.channelOfBookings?.offlineCount,
      },
    ]);
    setIsEnabled(vendorAnalyticsData?.adminSettings?.homeService);
    setIsBookingEnabled(vendorAnalyticsData?.adminSettings?.bookingEnabled);
    setMaxNumberOfLocationsValue(
      vendorAnalyticsData?.adminSettings?.numberOfLocations
    );
    setMaxNumberOfStaffValue(vendorAnalyticsData?.adminSettings?.numberOfStaff);
    setTotalBookings(
      newRevenue.filter((item) => item.name === "Total bookings")[0].value
    );
  }, [vendorAnalyticsData]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        {revenueData.map((item, index) => {
          const firstItem = item.id === revenueData[0].id;
          const lastItem = item.id === revenueData.length - 1;
          const style = firstItem
            ? "flex-1 shadow-around p-5 pr-20 rounded-lg"
            : lastItem
            ? "flex-1 shadow-around p-5 mx-4 pr-20 rounded-lg"
            : "flex-1 shadow-around p-5 ml-5 pr-20 rounded-lg";
          return (
            <div key={index} className={style}>
              <div className="text-base leading-6 font-medium text-[#00000073]">
                {item.name}
              </div>
              <div className="mt-2 text-xl font-medium leading-8 ">
                {item.value}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-row justify-around mt-6">
        <div>
          <div className="px-5 pt-6 rounded-lg shadow-around pb-9">
            <div className="text-20 leading-7 font-medium text-[#000000D9]">
              Channel of bookings
            </div>
            {channelOfBookingsData.map((channel) => {
              if (channel?.value != null || channel?.value != undefined) {
                return (
                  <div
                    key={channel.id}
                    className="flex flex-row items-center mt-6"
                  >
                    <div className="font-normal text-14 leading-22">
                      {channel.name}
                    </div>
                    <div className="ml-5">
                      {PercentageBar(
                        channel?.value,
                        totalBookings ? totalBookings : 0
                      )}
                    </div>
                    <div className="ml-2 font-normal text-14 leading-22">
                      {channel.value}
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="flex-1 px-5 py-6 mt-6 rounded-lg shadow-around">
            <div className="font-medium text-20 leading-28">Admin settings</div>
            <div className="flex flex-row mt-6">
              <div className="text-14 leading-22 pr-[31px] font-normal text-[#000000D9]">
                Number of staff:
              </div>
              <div className="flex items-center ml-4 border-2 border-[#FFA39E] rounded-lg overflow-hidden">
                <input
                  type="number"
                  className="w-full p-2 text-lg text-red-500 outline-none"
                  value={maxNumberOfStaffValue}
                  min={0}
                  onChange={(e) => {
                    setMaxNumberOfStaffValue(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-center mt-6">
              <div className="text-14 leading-22 font-normal text-[#000000D9]">
                Number of locations:
              </div>
              <div className="flex items-center ml-4 border-2 border-[#FFA39E] rounded-lg overflow-hidden">
                <input
                  type="number"
                  className="w-full p-2 text-lg text-red-500 outline-none"
                  value={maxNumberOfLocationsValue}
                  min={0}
                  onChange={(e) => {
                    setMaxNumberOfLocationsValue(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-center mt-6">
              <div className="text-14 leading-22 font-normal text-[#000000D9] w-full max-w-32">
                Home service:
              </div>
              <input
                type="checkbox"
                className="w-4 h-4 ml-4 text-red-600 form-checkbox"
                checked={isEnabled}
                onChange={toggleHomeService}
              />
            </div>

            <div className="flex flex-row items-center mt-6">
              <div className="text-14 leading-22 font-normal text-[#000000D9]  w-full max-w-32">
                Booking enabled:
              </div>
              <input
                type="checkbox"
                className="w-4 h-4 ml-4 text-red-600 form-checkbox"
                checked={isBookingEnabled}
                onChange={() => setIsBookingEnabled(!isBookingEnabled)}
                disabled={!isIbanNumberFilled}
              />
            </div>

            <div className="flex justify-end mt-2 space-x-2">
              <button
                onClick={() => {
                  setMaxNumberOfLocationsValue(
                    vendorAnalyticsData?.adminSettings?.numberOfLocations
                  );
                  setMaxNumberOfStaffValue(
                    vendorAnalyticsData?.adminSettings?.numberOfStaff
                  );
                  setIsEnabled(vendorAnalyticsData?.adminSettings?.homeService);
                }}
                className="py-[4px] w-[109px] border border-[#DE0607] text-[#000000D9] rounded-[12px] bg-white shadow-button mt-6"
              >
                Cancel
              </button>
              <button
                onClick={() => saveBookingSettings()}
                className="py-[4px] w-[109px] text-white bg-[#DE0607] rounded-[12px] mt-6"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 p-5 ml-6 rounded-lg shadow-around">
          <div className="font-medium text-20 leading-28">
            List of customer bookings
          </div>
          <div className="mt-6">
            <CustomerBookingsTableList
              lastBookings={
                vendorAnalyticsData?.lastBookings
                  ? vendorAnalyticsData?.lastBookings
                  : []
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoVendorsPage;
