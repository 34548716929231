import * as Yup from "yup";
import {
  incorrectMessage,
  invalidMessage,
  requiredMessage,
} from "../schemaUtils";

// Define textSchema separately
const textSchema = Yup.object()
  .shape({
    en: Yup.string(),
    ar: Yup.string(),
  })
  .test(
    "oneOfRequired",
    `${requiredMessage("Review (English or Arabic)")}`,
    function (value) {
      return !!value.en || !!value.ar;
    }
  );

// Review Add Schema
export const ReviewAddSchema = Yup.object().shape({
  text: textSchema,
  rating: Yup.number()
    .required(requiredMessage("Rating"))
    .min(1, incorrectMessage("Rating"))
    .max(5, incorrectMessage("Rating")),
  locationId: Yup.number().required(requiredMessage("Location")),
  userId: Yup.number().required(requiredMessage("User")),
  name: Yup.string().required(requiredMessage("Name")),
});
