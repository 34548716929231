import React, { useState, useEffect } from "react";
import Pagination from "./PaginationBannerPopUp.js";
import BannerUpload from "./BannerUpload.js";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import PopUpUpload from "./PopUpUpload.js";
import bannerService from "services/banner/bannerService.js";
import { HiOutlinePlusSm } from "react-icons/hi";
import Button from "components/core/Button.js";
import locationService from "services/location/locationService.js";
import { showError, showSuccess } from "utils/helpers.js";
import popupService from "services/popup/popupService.js";
import "./styles.css";

function BannerAndPopUp() {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagePopups, setCurrentPagePopups] = useState(1);
  const [itemsPerPage] = useState(3);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [currentData, setCurrentData] = useState([]);
  const [currentPopupData, setCurrentPopupData] = useState([]);
  const [initializeBanners, setInitializeBanners] = useState([]);
  const [banners, setBanners] = useState([]);
  const [initializePopups, setInitializePopups] = useState([]);
  const [popups, setPopups] = useState([]);
  const [listings, setListings] = useState([]);

  const fetchBanners = async () => {
    const bannerTemp = await bannerService.getBanners();
    const sortedBanners = [...bannerTemp.banners].sort((a, b) => {
      if (a.active === b.active) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return a.active ? -1 : 1;
    });
    setBanners(sortedBanners);
    setInitializeBanners(bannerTemp.banners);
  };

  const fetchPopUps = async () => {
    const popupTemp = await popupService.getPopups();
    if (popupTemp?.popUps?.length <= 0) {
      const tempPopups = [
        {
          image: null,
          locationId: null,
          active: true,
          status: "created",
        },
      ];
      setPopups(tempPopups);
      setInitializePopups(tempPopups);
    } else {
      setPopups(popupTemp.popUps);
      setInitializePopups(popupTemp.popUps);
    }
  };

  useEffect(() => {
    const last = currentPage * rowsPerPage;
    const first = last - rowsPerPage;
    setCurrentData(banners.slice(first, last));
  }, [currentPage, rowsPerPage, banners]);

  useEffect(() => {
    const last = currentPagePopups * rowsPerPage;
    const first = last - rowsPerPage;
    setCurrentPopupData(popups.slice(first, last));
  }, [currentPagePopups, rowsPerPage, popups]);

  const fetchListings = async () => {
    const locations = await locationService.getLocations();
    setListings(locations.locations);
  };

  useEffect(() => {
    fetchListings();
    fetchBanners();
    fetchPopUps();
  }, []);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(banners.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const pageNumbersPopups = [];
  for (let i = 1; i <= Math.ceil(popups.length / rowsPerPage); i++) {
    pageNumbersPopups.push(i);
  }

  const nextPagePopUps = () => {
    if (currentPagePopups < pageNumbersPopups.length) {
      setCurrentPagePopups(currentPagePopups + 1);
    }
  };

  const prevPagePopups = () => {
    if (currentPagePopups > 1) {
      setCurrentPagePopups(currentPagePopups - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [banners]);

  useEffect(() => {
    setCurrentPagePopups(1);
  }, [popups]);

  const handleCancel = async () => {
    setBanners(initializeBanners);
    setPopups(initializePopups);
  };

  const handleSave = async () => {
    const createdPopups = popups.filter((item) => item?.status === "created");
    const updatedPopups = popups.filter((item) => item?.status === "updated");
    const createdBanners = banners.filter((item) => item?.status === "created");
    const updatedBanners = banners.filter((item) => item?.status === "updated");

    const createPopupRequests = createdPopups.map((item) => {
      if (item?.image == null) {
        return Promise.reject(
          new Error("Please fill all fields for new popup!")
        );
      }
      return popupService.createPopup({
        image: item.image,
        locationId: item.locationId,
        active: item.active,
      });
    });

    const updatePopupRequests = updatedPopups.map((item) => {
      if (item?.image == null) {
        return Promise.reject(new Error("Please fill all fields for popups!"));
      }
      if (item.image === "" || item.image === null) {
        return popupService.deletePopup(item.id);
      }
      const payload = {
        image: item.image,
        url: item.url,
      };
      return popupService.updatePopup(item.id, payload);
    });

    const createBannerRequests = createdBanners.map((item) => {
      if (item?.image == null || item?.locationId == null) {
        return Promise.reject(
          new Error("Please fill all fields for new banner!")
        );
      }
      return bannerService.createBanner({
        image: item.image,
        locationId: item.locationId,
        active: item.active,
      });
    });

    const updateBannerRequests = updatedBanners.map((item) => {
      if (item?.image == null || item?.locationId == null) {
        return Promise.reject(new Error("Please fill all fields for banners!"));
      }
      return bannerService.updateBanner(item.id, {
        image: item.image,
        locationId: item.locationId,
        active: item.active,
      });
    });

    try {
      await Promise.allSettled([
        ...createPopupRequests,
        ...updatePopupRequests,
        ...createBannerRequests,
        ...updateBannerRequests,
      ]);

      // Fetch banners and popups again to refresh the data
      await fetchBanners();
      await fetchPopUps();

      showSuccess("Updated successfully!");
    } catch (error) {
      showError("An error occurred while saving. Please try again.");
    }
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]"
              : "text-[#000000D9] border-[#D9D9D9]"
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  const renderPopupPageNumbers = pageNumbersPopups.map((number) => {
    if (
      number === 1 ||
      number === pageNumbersPopups.length ||
      (number >= currentPagePopups - 1 && number <= currentPagePopups + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPagePopups(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPagePopups === number
              ? "text-[#DE0607] border-[#DE0607]"
              : "text-[#000000D9] border-[#D9D9D9]"
          }`}
        >
          {number}
        </button>
      );
    } else if (
      number >= currentPagePopups - 2 &&
      number <= currentPagePopups + 2
    ) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  const setItemImageDetails = (imageUrl, index) => {
    const globalIndex = (currentPage - 1) * rowsPerPage + index;
    const updatedItems = [...banners];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      image: imageUrl,
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setBanners(updatedItems);
  };

  const setItemActive = (toggleValue, index) => {
    const globalIndex = (currentPage - 1) * rowsPerPage + index;
    const updatedItems = [...banners];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      active: toggleValue,
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setBanners(updatedItems);
  };

  const setItemLocationDetails = (locationId, index) => {
    const globalIndex = (currentPage - 1) * rowsPerPage + index;
    const updatedItems = [...banners];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      locationId: parseInt(locationId),
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setBanners(updatedItems);
  };

  const setPopUpItemImageDetails = (imageUrl, index) => {
    const globalIndex = (currentPagePopups - 1) * rowsPerPage + index;
    const updatedItems = [...popups];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      image: imageUrl,
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setPopups(updatedItems);
  };

  const setPopUpItemActive = (toggleValue, index) => {
    const globalIndex = (currentPagePopups - 1) * rowsPerPage + index;
    const updatedItems = [...popups];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      active: toggleValue,
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setPopups(updatedItems);
  };

  const setPopUpItemLocationDetails = (locationId, index) => {
    const globalIndex = (currentPagePopups - 1) * rowsPerPage + index;
    const updatedItems = [...popups];
    updatedItems[globalIndex] = {
      ...updatedItems[globalIndex],
      locationId: parseInt(locationId),
      status: updatedItems[globalIndex]?.status
        ? updatedItems[globalIndex].status
        : "updated",
    };
    setPopups(updatedItems);
  };

  const addNewBanner = () => {
    if (banners.length >= 30) {
      showError("You can only create 30 banners total.");
      return;
    }

    const newBanners = [
      {
        image: null,
        locationId: null,
        active: false,
        status: "created",
      },
      ...banners,
    ];
    setBanners(newBanners);
    setCurrentPage(1);
  };

  return (
    <div className="px-4 mt-5 bg-white rounded-md">
      <div className="flex items-center justify-between w-full pt-6">
        <h1 className="text-20 font-medium text-[#000000D9] leading-[28px]">
          BANNERS
        </h1>
        <Button
          label="Add Banner"
          icon={<HiOutlinePlusSm />}
          onClick={addNewBanner}
        />
      </div>
      <div style={{ flexDirection: "row", display: "flex" }}>
        {currentData.map((item, index) => (
          <BannerUpload
            key={index}
            listings={listings}
            setItemImageDetails={(value) => setItemImageDetails(value, index)}
            setItemActive={(value) => setItemActive(value, index)}
            setItemLocationDetails={(value) =>
              setItemLocationDetails(value, index)
            }
            item={item}
            index={index}
          />
        ))}
      </div>
      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>
      </div>
      <div className="flex items-center justify-between w-full p-4">
        <h1>POP UPS</h1>
      </div>
      <div style={{ flexDirection: "row", display: "flex" }}>
        {currentPopupData?.map((item, index) => (
          <PopUpUpload
            key={index}
            listings={listings}
            setItemImageDetails={(value) =>
              setPopUpItemImageDetails(value, index)
            }
            setItemActive={(value) => setPopUpItemActive(value, index)}
            setItemLocationDetails={(value) =>
              setPopUpItemLocationDetails(value, index)
            }
            item={item}
            index={index}
          />
        ))}
      </div>
      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPagePopups}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPopupPageNumbers}</div>
        <button
          onClick={nextPagePopUps}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <button
          onClick={() => handleCancel()}
          className="cancel-edit-vendor"
          type="button"
          style={{ padding: 8 }}
        >
          Cancel
        </button>
        <button
          style={{ padding: 8 }}
          onClick={() => handleSave()}
          className="submit-edit-vendor"
          type="submit"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default BannerAndPopUp;
