import React, { useEffect, useState } from "react";
import { ArcElement, Chart as ChartJS, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ showValues, data, children, title }) => {
  const options = {
    layout: {
      padding: {
        top: 30,
        bottom: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const value = dataset.data[context.dataIndex];
            const total = dataset.data.reduce((acc, curr) => acc + curr);
            const percentage = ((value / total) * 100).toFixed(2);
            return `${context.label} ${
              showValues ? `${value} ` : ""
            }${percentage}%`;
          },
        },
      },
    },
  };

  const doughnutSliceLabel = {
    id: "doughnutSliceLabel",
    beforeDatasetsDraw(chart, args, plugings) {
      const { ctx, data } = chart;
      chart.getDatasetMeta(0).data.forEach((element, index) => {
        ctx.save();
        const xCenter = chart.getDatasetMeta(0).data[index].x;
        const yCenter = chart.getDatasetMeta(0).data[index].y;
        const endAngle = chart.getDatasetMeta(0).data[index].endAngle;
        const startAngle = chart.getDatasetMeta(0).data[index].startAngle;
        const centerAngle = (startAngle + endAngle) / 2;
        const outerRadius =
          chart.getDatasetMeta(0).data[index].outerRadius + 15;

        const xCoordinate = 0 + outerRadius * Math.cos(centerAngle);
        const yCoordinate = 0 + outerRadius * Math.sin(centerAngle);

        ctx.beginPath();
        ctx.translate(xCenter, yCenter);
        ctx.fillStyle = data.datasets[0].backgroundColor[index];
        ctx.font = "bold 14px sans-serif";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const value = data.datasets[0].data[index];
        const total = data.datasets[0].data.reduce((acc, curr) => acc + curr);
        const percentage = ((value / total) * 100).toFixed(0);
        ctx.fillText(`${percentage}%`, xCoordinate, yCoordinate);

        ctx.restore();
      });
    },
  };
  const plugins = [doughnutSliceLabel];

  // Function to check if all data points are zero and update data accordingly
  const getAdjustedData = (data) => {
    if (
      data.datasets.some((dataset) =>
        dataset.data.every((value) => value === 0)
      )
    ) {
      // Cloning the original dataset and storing original data for accurate tooltips
      const adjustedData = JSON.parse(JSON.stringify(data));
      adjustedData.datasets[0].data = adjustedData.datasets[0].data.map(
        () => 1
      ); // Placeholder value for visual
      adjustedData.datasets[0].originalData = [...data.datasets[0].data];
      return adjustedData;
    }
    return data;
  };

  const adjustedData = getAdjustedData(data);

  // Check if the adjustedData object has the necessary properties
  const hasData =
    adjustedData &&
    adjustedData.datasets &&
    adjustedData.datasets.length &&
    adjustedData.labels &&
    adjustedData.labels.length;
  if (!hasData) {
    return <p className="text-center text-red-500">No data available.</p>;
  }

  return (
    <div className="flex flex-col w-full h-full p-4 bg-white rounded-lg shadow-md">
      <p>{title ?? ""}</p>
      <Doughnut
        width={150}
        height={150}
        data={adjustedData}
        options={options}
        plugins={showValues ? plugins : {}}
      />
      {children}
    </div>
  );
};

export default DoughnutChart;
