import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

import Cities from "../components/SuperAdmin/Cities";
import Category from "../components/SuperAdmin/Category";
import Listings from "../components/SuperAdmin/Listings";
import Users from "../components/SuperAdmin/Users/Users";
import ReviewsAndRatings from "../components/SuperAdmin/ReviewsAndRatings/ReviewsAndRatings";
import Vendors from "../components/SuperAdmin/Vendors/Vendors";

import {
  AiOutlinePartition,
  AiOutlineBell,
  AiOutlineHome,
} from "react-icons/ai";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { LuUser2 } from "react-icons/lu";
import { FaRegEnvelope } from "react-icons/fa6";
import VendorsDetails from "../components/SuperAdmin/Vendors/Components/VendorsDetails/VendorsDetails";
import AddListing from "components/SuperAdmin/Listings/AddListing/AddListing";
import EditListing from "components/SuperAdmin/Listings/EditListing/EditListing";
import BusinessForum from "components/SuperAdmin/BusinessForum/BusinessForum";
import BannerAndPopUp from "components/SuperAdmin/BannerAndPopUp/BannerAndPopUp";
import useLocations from "utils/hooks/useLocations";
import AdminDashboard from "components/SuperAdmin/Dashboard/AdminDashboard";
import Notification from "components/SuperAdmin/Notification/Notification";
import useUsers from "utils/hooks/useUsers";

function SuperAdmin() {
  const [activeContent, setActiveContent] = useState(null);
  const [subContent, setSubContent] = useState("");
  const { fetchLocationName } = useLocations();
  const { fetchProfile } = useUsers();
  const [headerTitle, setHeaderTitle] = useState("");
  const [user, setUser] = useState({});

  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    setHeaderTitle(
      sidebarItems.find((item) => {
        let itemRoute = item.route.split("/").filter(Boolean);
        return itemRoute[0] == pathParts[0];
      })?.title
    );
    if (pathParts.length > 1) {
      const id = pathParts[1];
      setActiveContent(pathParts[0]);

      if (pathParts[0] === "listings" && pathParts[1] !== "add") {
        fetchLocationName(id).then((name) => setSubContent(name.en || name.ar));
      }
    } else {
      setActiveContent(pathParts[0]);
      setSubContent("");
    }

    fetchProfile().then((data) => {
      setUser(data);
    });
  }, [location]);

  const sidebarItems = [
    {
      icon: <MdOutlineSpaceDashboard />,
      title: "Dashboard",
      content: <AdminDashboard />,
      route: "/analytics",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <AiOutlineHome />,
      title: "Cities",
      content: <Cities />,
      route: "/cities",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <AiOutlinePartition transform="rotate(90)" />,
      title: "Categories",
      content: <Category />,
      route: "/categories",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <AiOutlinePartition transform="rotate(90)" />,
      title: "Listings",
      content: <Listings />,
      route: "/listings",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <LuUser2 />,
      title: "Users",
      content: <Users />,
      route: "/users",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <FaRegEnvelope />,
      title: "Reviews and Ratings",
      content: <ReviewsAndRatings />,
      route: "/reviewsandratings",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <FaRegEnvelope />,
      title: "Banner and Pop Up",
      content: <BannerAndPopUp />,
      route: "/bannerandpopup",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <FaRegEnvelope />,
      title: "Business forum",
      content: <BusinessForum />,
      route: "/businessforum",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <AiOutlineBell />,
      title: "Notification",
      content: <Notification />,
      route: "/notification",
      hasAccess: ["SUPERADMIN"],
    },
    {
      icon: <FaRegEnvelope />,
      title: "Vendors",
      content: <Vendors />,
      route: "/vendors",
      hasAccess: ["SUPERADMIN"],
    },
  ];

  return (
    <div
      className="relative flex w-full h-screen min-h-screen"
      style={{
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <Sidebar sidebarItems={sidebarItems} role={"SUPERADMIN"} user={user} />

      <main
        className="flex flex-col h-full px-6 bg-[#00000026]
        w-full md:ml-52 transition-all duration-300 ease-in-out"
      >
        <Header mainText={headerTitle} subText={subContent} />

        <Routes>
          <Route path={"/analytics"} element={<AdminDashboard />} />
          <Route path={"/cities"} element={<Cities />} />
          <Route path={"/categories"} element={<Category />} />
          <Route path={"/listings"} element={<Listings />} />
          <Route path={"listings/:id"} element={<EditListing />} />
          <Route path={"/listings/add"} element={<AddListing />} />
          <Route path={"/users"} element={<Users />} />
          <Route path={"/reviewsandratings"} element={<ReviewsAndRatings />} />
          <Route path={"/bannerandpopup"} element={<BannerAndPopUp />} />
          <Route path={"/notification"} element={<Notification />} />
          <Route path={"/vendors"} element={<Vendors />} />
          <Route path={"/vendorsInfo"} element={<VendorsDetails />} />
          <Route path={"/businessforum"} element={<BusinessForum />} />
          <Route path={"*"} element={<Navigate to={"/analytics"} replace />} />
        </Routes>
      </main>
    </div>
  );
}

export default SuperAdmin;
