import React, { useState } from "react";

const PhoneNumberInput = ({ className = "" }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (number) => {
    // Basit bir telefon numarası regex'i. Daha karmaşık bir pattern gerekiyorsa güncellenmelidir.
    const regex = /^[0-9]+$/;
    return regex.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Sayısal değer girilmişse veya input boşsa geçerli, değilse geçersiz.
    const isValidNumber = value === "" || validatePhoneNumber(value);
    setIsValid(isValidNumber);
    setPhoneNumber(value);
  };

  return (
    <div
      className={`flex flex-col md:flex-row items-start md:items-center md:space-x-2 space-y-2 md:space-y-0 ${className}`}
    >
      <label
        htmlFor="phone_number"
        className="text-sm font-bold text-left text-gray-700 md:text-right min-w-32 md:w-1/5"
      >
        Phone number:
      </label>
      <input
        id="phone_number"
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        className={`mt-1 block w-full p-2 border rounded-md shadow-sm sm:text-sm focus:outline-none ${
          isValid
            ? "border-gray-300 focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            : "border-red-500 focus:ring-1 focus:ring-red-500 focus:border-red-500"
        }`}
        placeholder="Enter your phone number"
      />
      {!isValid && (
        <span className="text-sm text-red-500">
          Please enter a valid phone number.
        </span>
      )}
    </div>
  );
};

export default PhoneNumberInput;
