import LocationListItem from "./LocationListItem";

const LocationList = ({ fetchedLocations, selectedLocation, onSelect }) => {
  return (
    <div className="mt-6 ">
      {fetchedLocations.map((location) => (
        <LocationListItem
          key={location.id}
          location={location}
          isSelected={selectedLocation?.id === location.id}
          onSelect={() => onSelect(location)}
        />
      ))}
    </div>
  );
};

export default LocationList;
