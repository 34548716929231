import { useEffect, useState } from "react";
import businessForumService from "services/business/businessForumService";
import { showError } from "utils/helpers";

const useBusinessForum = () => {
  const [businessForums, setBusinessForums] = useState([]);

  const fetchBusinessForums = async () => {
    try {
      const response = await businessForumService.getBusinessForums();
      switch (response.statusCode) {
        case 200:
          setBusinessForums(response.businessForum);
          break;
        default:
          showError(response.message);
          setBusinessForums([]);
          break;
      }
    } catch (error) {
      showError("Failed to fetch business forums. Please try again.");
    }
  };

  useEffect(() => {
    fetchBusinessForums();
  }, []);

  const refresh = () => {
    fetchBusinessForums();
  };

  return { businessForums, refresh };
};

export default useBusinessForum;
