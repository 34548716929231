import { useEffect, useState } from "react";
import TimeSlot from "./TimeSlot";

const DaySchedule = ({
  dayName,
  slots,
  addTimeSlot,
  removeTimeSlot,
  updateTimeSlot,
  setIsDayEnabled,
  isDayEnabled,
  roleDisabled = false,
}) => {
  const [slotsData, setSlotsData] = useState([]);

  useEffect(() => {
    setSlotsData([]);
    if (slots) {
      let newSlots = slots.slots;
      setSlotsData(newSlots);
    }
  }, [slots]);

  return (
    <div className="flex flex-col items-start md:w-full md:items-center md:flex-row">
      <div className="flex items-center min-w-40 w-fit">
        <input
          type="checkbox"
          className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
          checked={isDayEnabled}
          style={{ accentColor: "#DE0607" }}
          onChange={() => setIsDayEnabled(!isDayEnabled)}
          onClick={setIsDayEnabled}
          disabled={roleDisabled}
        />
        <span
          className={`ml-2 text-13 md:text-14 capitalize font-semibold ${
            !isDayEnabled && "text-gray-400"
          }`}
        >
          {dayName.charAt(0).toUpperCase() + dayName.slice(1).toLowerCase()}
        </span>
      </div>
      <div className=" min-w-72 w-fit">
        {slotsData &&
          slotsData.length > 0 &&
          slotsData.map((slot, index) => (
            <TimeSlot
              key={index}
              index={index}
              slot={slot}
              isEnabled={isDayEnabled}
              removeTimeSlot={() => removeTimeSlot(index)}
              updateTimeSlot={(startsAt, finishesAt) =>
                updateTimeSlot(dayName, index, startsAt, finishesAt)
              }
              roleDisabled={roleDisabled}
            />
          ))}
      </div>
      <div className={`w-fit min-w-28 flex items-center  justify-center`}>
        <button
          onClick={addTimeSlot}
          className={`font-normal  text-12 md:text-16 ${
            isDayEnabled && !roleDisabled ? "text-[#DE0607]" : "text-gray-400"
          }`}
          disabled={!isDayEnabled || roleDisabled}
        >
          + Add time
        </button>
      </div>
    </div>
  );
};

export default DaySchedule;
