import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { LuTrash } from "react-icons/lu";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useUsers from "utils/hooks/useUsers";
import usersService from "services/users/usersService";
import { showError, showSuccess } from "utils/helpers";
import Modal from "components/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { clearProviderData } from "../../redux/provider/providerSlice";
import LocationManagerCard from "./LocationManagerCard";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import locationManagerService from "services/locationManager/locationManagerService";
import { selectBusinessId, selectRole } from "../../redux/auth/authSlice";

const LocationManager = () => {
  const [usersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userRole = useSelector(selectRole);
  const businessId = useSelector(selectBusinessId);

  const fetchLocationManagers = async () => {
    setLoading(true);
    try {
      const response = await locationManagerService.getBusinessLocationManagers(
        businessId
      );
      switch (response.statusCode) {
        case 200:
          setUsersData(response.locationManagers);
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.response?.data?.error);
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (userRole === "LOCATION_MANAGER") {
      showError("You do not have access to this feature");
      return;
    }
    try {
      const response = await locationManagerService.deleteLocationManager(id);
      switch (response.statusCode) {
        case 404:
          showError(response.message);
          break;
        case 400:
          showError(response.message);
          break;
        default:
          showSuccess("Provider deleted successfully");
          fetchLocationManagers();
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const hasAccess = (roles) => {
    if (userRole === undefined) {
      return false;
    }
    return roles.includes(userRole);
  };

  useEffect(() => {
    fetchLocationManagers();
  }, []);

  const actions = [
    {
      id: "edit",
      icon: <CiEdit color="#DE0607" size={15} />,
      onClick: (id) => navigate(`/location-manager/${id}`),
    },
    {
      id: "delete",
      icon: (
        <LuTrash
          color={userRole !== "LOCATION_MANAGER" ? "#DE0607" : "#C3C9CF"}
          size={15}
        />
      ),
      onClick: (id) =>
        userRole !== "LOCATION_MANAGER" &&
        (setIsOpen(true), setSelectedUser(id)),
    },
  ];

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="px-4 py-6 mt-5 bg-white">
        {userRole !== "LOCATION_MANAGER" && (
          <div className="flex justify-end w-full ">
            <Button
              label="Add Location Manager"
              onClick={() => {
                dispatch(clearProviderData());
                navigate("/location-manager/add");
              }}
              icon={<HiOutlinePlusSm />}
            />
          </div>
        )}

        <div className=" overflow-y-scroll bg-white h-fit mt-8 max-h-[600px] md:max-h-[600px] lg:max-h-[700px]">
          {usersData.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-16 text-[#000000D9]">
                No location managers found
              </p>
            </div>
          ) : (
            usersData.map((user) => (
              <LocationManagerCard
                key={user.id}
                data={user}
                actions={actions}
              />
            ))
          )}
        </div>

        <Modal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="p-5">
            <h2 className="text-16 text-[#000000D9] font-medium">
              Are you sure you want to delete this provider?
            </h2>
            <div className="flex justify-end mt-6 space-x-4">
              <Button
                label="Cancel"
                onClick={() => setIsOpen(false)}
                variant="outline"
              />
              <Button
                label="Delete"
                onClick={() => {
                  deleteUser(selectedUser);
                  setIsOpen(false);
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </LoaderWrapper>
  );
};

export default LocationManager;
