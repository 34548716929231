import React, { useEffect, useState } from "react";
import EmptyStateComponent from "../EmptyStateComponents";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import ServiceCategoryList from "./ServicesTab/ServiceCategoryList";
import ServiceCategoryFormWrapper from "./CategoryTabComponents/ServiceCategoryFormWrapper";
import serviceServices from "services/service/serviceServices";
import subserviceServices from "services/service/subserviceServices";
import { showError, showSuccess } from "utils/helpers";
import ServiceFormWrapper from "./ServicesTab/ServiceFormWrapper";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import {
  SubServiceAddSchema,
  SubServiceEditSchema,
} from "utils/schemas/Services/ServiceSchema";
import useLocations from "utils/hooks/useLocations";
import { selectBusinessId, selectRole } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ServicesTab = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [formMode, setFormMode] = useState("");
  const [serviceCategories, setServiceCategories] = useState([]);
  const [subserviceCategories, setSubserviceCategories] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const { fetchVendorLocations } = useLocations();
  const businessId = useSelector(selectBusinessId);
  const userRole = useSelector(selectRole);

  const fetchServiceCategories = async () => {
    setLoading(true);
    try {
      const response = await serviceServices.getBusinessServices(businessId);
      switch (response.statusCode) {
        case 200:
          setServiceCategories(response.services);
          setLoading(false);
          break;
        default:
          showError("No services found");
          setServiceCategories([]);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const fetchSubserviceCategories = async () => {
    setLoading(true);
    try {
      const response = await subserviceServices.getBusinessSubservices(
        businessId
      );
      switch (response.statusCode) {
        case 200:
          setSubserviceCategories(response.subservices);
          setLoading(false);
          break;
        default:
          showError("No subservices found");
          setSubserviceCategories([]);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServiceCategories();
    fetchSubserviceCategories();
    businessId &&
      fetchVendorLocations(businessId).then((res) => setFetchedLocations(res));
  }, [businessId]);

  useEffect(() => {
    let merged =
      serviceCategories.length > 0
        ? serviceCategories.map((service) => {
            return {
              id: service.id,
              serviceCategoryName: service.name,
              subservices: service.subservices,
            };
          })
        : [];

    // If there are subservices without a service category, add them under 'All' category
    if (subserviceCategories.length > 0) {
      let allCategory = {
        id: 0,
        serviceCategoryName: { en: "All", ar: "الكل" },
        subservices: [],
      };

      subserviceCategories.forEach((subservice) => {
        // Check if subservice is not already in the merged array
        let isSubserviceInMerged = merged.some((service) =>
          service.subservices.some((sub) => sub.id === subservice.id)
        );

        if (!isSubserviceInMerged) {
          allCategory.subservices.push(subservice);
        }
      });

      // If 'All' category has subservices, add it to the beginning of merged array
      if (allCategory.subservices.length > 0) {
        merged.unshift(allCategory);
      }
    }
    setMergedData(merged);
  }, [serviceCategories, subserviceCategories]);

  useEffect(() => {
    if (formMode === null) {
      setSelectedService({});
    }
  }, [formMode]);

  const addSubservice = async (data) => {
    try {
      const response = await subserviceServices.addSubservice(data);
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          fetchServiceCategories();
          fetchSubserviceCategories();
          setFormMode("");
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleAdd = async (data) => {
    try {
      await SubServiceAddSchema.validate(data);
      await addSubservice(data);
    } catch (error) {
      showError(error.message);
    }
  };

  const editSubservice = async (data) => {
    try {
      const response = await subserviceServices.updateSubservice(
        selectedService.id,
        data
      );
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          fetchServiceCategories();
          fetchSubserviceCategories();
          setSelectedService({});
          setFormMode("");
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async (data) => {
    try {
      await SubServiceEditSchema.validate(data);
      await editSubservice(data);
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await subserviceServices.deleteSubservice(id);

      if (response.statusCode === 400) {
        showError(response.message);
        return;
      }
      if (response.statusCode === 401) {
        showError("You are not authorized to delete this subservice");
        return;
      }
      showSuccess("Subservice deleted successfully");
      fetchServiceCategories();
      fetchSubserviceCategories();
      setSelectedService({});
      setFormMode("");
    } catch (error) {
      showError("Failed to delete subservice. Please try again.");
    }
  };

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="h-screen">
        {mergedData ? (
          <div className="flex flex-row mt-4 space-x-4 bg-white">
            <div className="w-2/5 h-lvh">
              <header className="flex flex-row items-center justify-between">
                <p className="text-16 font-medium leading-6 text-[#000000D9]">
                  Service Category
                </p>

                <Button
                  label={"Add New"}
                  icon={<HiOutlinePlusSm />}
                  onClick={() => {
                    setSelectedService({});
                    setFormMode("add");
                  }}
                  className={`
                    ${userRole === "PROVIDER" ? "hidden" : ""}
                  `}
                />
              </header>
              <div className="w-full mt-6 border-b" />
              {mergedData.length > 0 ? (
                <ServiceCategoryList
                  fetchedCategories={mergedData}
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                  setFormMode={setFormMode}
                  formMode={formMode}
                  onDelete={(service) => handleDelete(service.id)}
                />
              ) : (
                <p className="text-16 font-medium leading-6 text-[#000000D9]">
                  No Service found
                </p>
              )}
            </div>

            <div className="w-3/5 h-full">
              <ServiceFormWrapper
                formMode={formMode}
                selectedService={selectedService}
                handleSave={handleSave}
                handleAdd={handleAdd}
                setFormMode={setFormMode}
                locations={fetchedLocations}
              />
            </div>
          </div>
        ) : (
          <EmptyStateComponent
            text={"Select a category to view sub-categories"}
          />
        )}
      </div>
    </LoaderWrapper>
  );
};

export default ServicesTab;
