import api from "services/apiConfig";

const bookingService = {
  createBooking: async (data) => {
    try {
      const response = await api.post(`/booking`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBooking: async (id) => {
    try {
      const response = await api.get(`/booking/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookingWQuery: async (query) => {
    try {
      const response = await api.get(`/booking?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookings: async () => {
    try {
      const response = await api.get("/booking");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookingsWDate: async (date) => {
    try {
      const response = await api.get(`/booking?date=${date}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookingsWDateLocation: async (date, locationId) => {
    try {
      const response = await api.get(
        `/booking?date=${date}&?locationId=${locationId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookingsWProviderIdLocation: async (providerId, locationId) => {
    try {
      const response = await api.get(
        `/booking?providerId=${providerId}&locationId=${locationId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBookingsWLocation: async (locationId) => {
    try {
      const response = await api.get(`/booking/${locationId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateBooking: async (id, updateData) => {
    try {
      const response = await api.put(`/booking/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteBooking: async (id) => {
    try {
      const response = await api.delete(`/booking/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  checkAvailability: async (
    providerId,
    serviceDuration,
    date,
    locationId,
    type
  ) => {
    try {
      const response = await api.get(
        `/booking/check-availability?providerId=${providerId}&serviceDuration=${serviceDuration}&date=${date}&locationId=${locationId}&type=${type}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default bookingService;
