import React, { useState } from "react";

/**
 * Textarea component.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the textarea field.
 * @param {string} props.placeholder - The placeholder text for the textarea.
 * @param {Function} props.onChange - The callback function for value change.
 * @param {string} [props.value] - The value of the textarea field.
 * @param {string} [props.className] - Extra CSS classes.
 */
const TextArea = ({
  label,
  placeholder,
  onChange,
  value,
  className = "",
  isMaxLength = true,
  fieldHeight = "100px",
  dir = "ltr",
}) => {
  const [length, setLength] = useState(0);

  const handleLength = (e) => {
    setLength(e.target.value.length);
  };

  return (
    <div
      className={`relative flex-col md:flex-row  flex items-start md:items-center space-y-2 md:space-x-2  ${className}`}
    >
      {label && (
        <label className="text-sm font-bold text-left text-gray-700 md:text-right min-w-32 md:w-1/5">
          {label}:
        </label>
      )}
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        maxLength={isMaxLength ? 100 : null}
        onKeyDown={handleLength}
        className={`w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent`}
        style={{ minHeight: fieldHeight }}
        dir={dir}
      />
      <p
        className={`absolute text-sm  bottom-1 right-3
            ${length > 99 ? "text-red-500" : "text-gray-500"}
            ${isMaxLength ? "" : "hidden"}
          `}
      >
        {length}/100
      </p>
    </div>
  );
};

export default TextArea;
