import React from "react";
import "./styles.css";

const EditModal = ({ isOpen, modalPosition, children }) => {
  if (!isOpen) return null;

  const topPosition = modalPosition.top - 6.5;
  const leftPosition = modalPosition.left - 6.75;

  return (
    <div
      className="flex flex-row fixed mr-2 z-10 rounded-[6px]"
      style={{
        top: `${topPosition}px`,
        left: `${leftPosition}px`,
      }}
    >
      <div className="shadow-around w-236 h-auto rounded-[6px] shadow-md">{children}</div>
      <div className="triangle-right"></div>
    </div>
  );
};

export default EditModal;
