import * as Yup from "yup";
import {
  invalidMessage,
  requiredMessage,
  incorrectMessage,
  emailRegex,
  phoneRegex,
} from "../schemaUtils";

export const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, invalidMessage("Name"))
    .max(50, invalidMessage("Name"))
    .required(requiredMessage("Name")),
  phone: Yup.string()
    .matches(phoneRegex, incorrectMessage("Phone number"))
    .required(requiredMessage("Phone number")),
  email: Yup.string()
    .matches(emailRegex, incorrectMessage("Email address"))
    .max(75, invalidMessage("Email address"))
    .required(requiredMessage("Email address")),
  gender: Yup.string()
    .required(requiredMessage("Gender"))
    .oneOf(["MALE", "FEMALE"], invalidMessage("Gender")),
  ageGroup: Yup.string()
    .required(requiredMessage("Age group"))
    .oneOf(
      [
        "UNDER_18",
        "AGE_18_25",
        "AGE_26_35",
        "AGE_36_45",
        "AGE_46_60",
        "AGE_60_PLUS",
      ],
      invalidMessage("Age group")
    ),
});
