import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ icon, title, onClick, isSelected, route }) => {
  return (
    <NavLink
      to={route}
      onClick={() => onClick(title)}
      className={`flex items-center w-full h-12  cursor-pointer ${
        isSelected ? "bg-white text-red-500" : ""
      }`}
    >
      <div className={`flex items-center justify-center w-10 h-10  `}>
        {icon}
      </div>
      <div>{title}</div>
    </NavLink>
  );
};

export default SidebarItem;
