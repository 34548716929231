// src/components/LoginForm.js
import React, { useState } from "react";
import Link from "./Link";
import Checkbox from "../core/Checkbox";
import RocketIcon from "assets/Icons/rocket.png";
import Button from "../core/Button";
import FormField from "./FormField";
import { HiOutlineMail, HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../services/auth/authService";
import { login } from "../../redux/auth/authSlice";
import { showError, showSuccess } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const saveUser = (data) => {
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", data.accessToken);
  };

  const handleLogin = async (event) => {
    setLoading(true);
    try {
      event.preventDefault();
      const response = await authService.loginWithEmail(email, password);
      switch (response.statusCode) {
        case 200:
          dispatch(login(response.data));
          saveUser(response.data);
          showSuccess(response.message);
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  return (
    <div className="w-full 2xl:px-8 xl:max-2xl:px-2 md:px-0 md:w-1/2 rounded-md border-[#00000026] shadow-2xl h-[680px] flex flex-col justify-center">
      <h2 className="font-medium text-center text-38 leading-[46px]">Log in</h2>
      <h3 className="font-medium flex mx-auto mt-5 text-center text-20 leading-7 text-[#000000D9]">
        Grow Your Business
        <img src={RocketIcon} alt="rocket" className="w-8 h-8 ml-2" />
      </h3>

      <form
        className="p-4 space-y-4"
        onSubmit={handleLogin}
        onLoadStart={() => {
          setLoading(true);
        }}
        onLoadedData={() => {
          setLoading(false);
        }}
      >
        <FormField
          label="Email"
          icon={<HiOutlineMail size={16} />}
          name="email"
          placeholder="Email"
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormField
          label="Password"
          icon={<MdOutlineLock size={15} />}
          name="password"
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          endIcon={
            showPassword ? (
              <HiOutlineEyeOff
                size={16}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <HiOutlineEye
                size={16}
                onClick={() => setShowPassword(!showPassword)}
              />
            )
          }
        />
        <Checkbox label="Remember me" />
        <Button
          label="Log in"
          className="w-full mt-5 text-white bg-[#DE0607] rounded-xl"
          textColor="text-white"
          loading={loading}
        />
        <div className="flex justify-center mt-7">
          <Link
            text="Forgot password?"
            href="/restore-password"
            className={"text-[#DE0607] text-16 font-bold leading-6"}
          />
        </div>
      </form>
    </div>
  );
};

export default Signin;
