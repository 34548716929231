import React from "react";
import { LuTrash } from "react-icons/lu";
import { useSelector } from "react-redux";
import { selectRole } from "../../../redux/auth/authSlice";
import locationService from "services/location/locationService";
import subserviceServices from "services/service/subserviceServices";
import { showError, showSuccess } from "utils/helpers";

const ServiceItem = ({
  name,
  price,
  duration,
  service,
  onDelete,
  userRole,
}) => (
  <tr className="h-10 border-b border-gray-200 hover:bg-gray-100" key={name}>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {name}
      </div>
    </td>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {price}
      </div>
    </td>
    <td className=" whitespace-nowrap">
      <div className="text-left text-14 font-normal text-[#000000D9]">
        {duration}
      </div>
    </td>
    <td className=" whitespace-nowrap">
      <button
        className={`
            ${
              userRole !== "PROVIDER"
                ? "ml-2 text-red-500 rounded hover:bg-red-100"
                : "text-gray-300 cursor-not-allowed"
            }
            `}
        onClick={() => {
          userRole !== "PROVIDER" && onDelete(service);
        }}
      >
        <LuTrash size={12} />
      </button>
    </td>
  </tr>
);

const ServicesList = ({
  category,
  services,
  subserviceIds,
  locationId,
  refreshData,
}) => {
  let newServices = [];
  const userRole = useSelector(selectRole);
  if (services) {
    services.map((service) => {
      if (service.childSubservices && service.childSubservices.length > 0) {
        service.childSubservices.map((childService) => {
          newServices.push({
            id: childService.id,
            name: childService.name.en,
            price: childService.price,
            duration: childService.duration,
            isChild: true,
            subserviceId: childService.subserviceId,
          });
        });
      } else {
        newServices.push({
          id: service.id,
          name: service.name.en,
          price: service.price,
          duration: service.duration,
          isChild: false,
        });
      }
    });
  }

  const deleteChildService = async (service) => {
    try {
      let filteredSubservices = subserviceIds.filter(
        (id) => id !== service.subserviceId
      );
      const response = await locationService.updateLocation(locationId, {
        subserviceIds: filteredSubservices,
      });
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          refreshData();
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error);
    }
  };

  const deleteSubservice = async (service) => {
    try {
      let filteredSubservices = subserviceIds.filter((id) => id !== service.id);
      const response = await locationService.updateLocation(locationId, {
        subserviceIds: filteredSubservices,
      });
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          refreshData();
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error);
    }
  };

  const onDelete = (service) => {
    if (service.isChild) {
      deleteChildService(service);
    } else {
      deleteSubservice(service);
    }
  };

  return (
    <div className="max-w-md mt-4 overflow-hidden bg-white rounded-xl md:max-w-2xl md:mt-0">
      <div className="md:flex-col md:flex md:w-1/2">
        <div className="text-16 font-bold tracking-wide flex text-[#00000073] capitalize ">
          Service category:
          <p className="text-[#000000D9] ml-8">{category}</p>
        </div>
        <table className="w-full leading-normal ">
          <thead>
            <tr>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Service
              </th>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Price
              </th>
              <th className="py-3 font-medium tracking-wider text-left text-[#000000D9] capitalize border-b-2 border-gray-200 text-14 ">
                Duration
              </th>
              <th className="border-b-2 border-gray-200 "></th>
            </tr>
          </thead>
          <tbody>
            {newServices.map((service, index) => (
              <ServiceItem
                key={index}
                name={service.name ?? "No name"}
                price={service.price ?? "No price"}
                duration={service.duration ?? "No duration"}
                service={service}
                onDelete={onDelete}
                userRole={userRole}
              />
            ))}
            {newServices.length === 0 && (
              <tr>
                <td colSpan="4" className="text-center py-3 text-[#000000D9]">
                  No services found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServicesList;
