import React, { useState, useEffect } from "react";
import "./styles.css";
import { CiCalendar, CiCircleInfo } from "react-icons/ci";
import { BsPeople } from "react-icons/bs";
import subserviceServices from "services/service/subserviceServices";
import bookingService from "services/booking/bookingService";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import InfoCircle from "assets/Icons/info-circle.svg";
import PersonIcon from "assets/Icons/personIcon.svg";
import CalendarIcon from "assets/Icons/calendarIcon.svg";
import Button from "components/core/Button";
import { LuTrash } from "react-icons/lu";
import { selectRole } from "../../../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ListViewCalendar = ({ bookings, openListModal, fetchBookings }) => {
  const [subservices, setSubservices] = useState([]);
  const userRole = useSelector(selectRole);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
      timeZone: "UTC",
    })
      .format(date)
      .replace(",", "");

    return formattedDate;
  };

  const cancelBooking = async (booking) => {
    const body = {
      status: "CANCELED",
      address: "",
      notes: "",
    };

    const response = await bookingService.updateBooking(booking.id, body);

    fetchBookings();
  };
  useEffect(() => {
    const fetchSubservices = async () => {
      const promises = bookings.map(async (booking) => {
        if (
          booking?.status == "CANCELED" ||
          booking == undefined ||
          booking == null
        ) {
          return;
        }
        if (booking.subserviceIds.length > 0) {
          const response = await subserviceServices.getSubservice(
            booking.subserviceIds[0],
            false
          );

          return response?.subservice?.name;
        }
        if (booking.childSubserviceIds.length > 0) {
          const response = await childSubserviceServices.getSubservice(
            booking.childSubserviceIds[0]
          );
          return response?.subservice?.name;
        }
      });

      const subserviceNames = await Promise.all(promises);
      setSubservices(subserviceNames);
    };

    fetchSubservices();
  }, [bookings]);

  return (
    <div style={{ flex: 1 }}>
      {bookings.map((booking, index) => {
        if (booking?.status == "CANCELED") {
          return;
        }
        if (booking == undefined || booking == null) {
          return;
        }

        return (
          <div key={index} className="booking-card">
            <div className="list-view-details-container">
              <div className="name-container">
                <h2>{booking.userName}</h2>
                <div className="list-view-phone-container">
                  <p className="list-view-phone-number">
                    <span class="phone-number-title">Phone number:</span>{" "}
                    <span class="phone-number">{booking.userPhoneNumber}</span>
                  </p>
                </div>
              </div>
              <div style={{ flex: 1 }} className="space-y-3">
                <div className="detail-texts">
                  <img
                    src={CalendarIcon}
                    style={{ marginRight: 12 }}
                    color="#8c8c8c"
                    size={22}
                  />
                  <p>{formatDate(booking.date)}</p>
                </div>
                <div className="detail-texts">
                  <img
                    src={PersonIcon}
                    style={{ marginRight: 12 }}
                    color="#8c8c8c"
                    size={22}
                  />
                  <p>{booking.provider.name}</p>
                </div>
                <div className="detail-texts">
                  <img
                    src={InfoCircle}
                    style={{ marginRight: 12 }}
                    color="#8c8c8c"
                    size={22}
                  />
                  <p
                    style={{
                      marginRight: 5,
                      width: "auto",
                    }}
                  >
                    {subservices[index] && subservices[index] !== undefined ? (
                      subservices[index].en
                    ) : (
                      <span style={{ color: "red" }}>Subservice not found</span>
                    )}
                  </p>
                  <p> {(booking.duration / 60).toFixed(1)}h</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-between h-full space-x-2">
              <div>
                <LuTrash
                  className={
                    userRole !== "PROVIDER"
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }
                  color={userRole !== "PROVIDER" ? "#DE0607" : "#8c8c8c"}
                  onClick={() =>
                    userRole !== "PROVIDER" ? cancelBooking(booking) : null
                  }
                />
              </div>
              {userRole !== "PROVIDER" ? (
                <div className="flex justify-between mt-4 space-x-2">
                  <Button
                    label="Cancel"
                    className={
                      "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
                    }
                    textColor="text-black"
                    onClick={() => cancelBooking(booking)}
                  />
                  <Button
                    label="Checkout"
                    className={"w-40 bg-[#DE0607] rounded-lg"}
                    labelStyle={"!text-white"}
                    onClick={() => {
                      openListModal(booking);
                    }}
                  />
                </div>
              ) : (
                <div className="flex justify-between mt-4 space-x-2">
                  <Button
                    label="Checkout"
                    className={"w-40 bg-[#DE0607] rounded-lg"}
                    labelStyle={"!text-white"}
                    onClick={() => {
                      openListModal(booking);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListViewCalendar;
