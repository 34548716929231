import React, { useState, useEffect } from "react";
import "./styles.css";
import businessService from "services/business/businessService";
import categoryService from "services/category/categoryService";
import { VendorAddSchema } from "utils/schemas/Vendor/VendorSchemas";
import { showError, showSuccess } from "utils/helpers";

const AddVendorModal = ({ isOpen, onClose, fetchVendors }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const fetchCategories = async () => {
    const response = await categoryService.getCategories();
    if (response.categories && response.categories.length > 0) {
      setCategories(response.categories);
      setSelectedCategory(response.categories[0].id);
    }
  };

  useEffect(() => {
    fetchCategories();
    setVendorInfo({
      name: "",
      email: "",
      businessType: "",
      phoneNumber: "",
      businessWebsite: "",
      // booking: false,
    });
    setPhoneCount(1);
  }, []);

  const [vendorInfo, setVendorInfo] = useState({
    name: "",
    email: "",
    businessType: "",
    phoneNumber: "",
    businessWebsite: "",
    // booking: false,
  });
  const [phoneCount, setPhoneCount] = useState(1);

  const addVendor = async () => {
    const data = {
      name: vendorInfo.name,
      phone: [vendorInfo.phoneNumber],
      email: vendorInfo.email,
      locationIds: [],
      serviceIds: [],
      subserviceIds: [],
      providerIds: [],
      whatsapp: "",
      images: [],
      website: "wwww.google.com",
      categoryId: parseInt(selectedCategory),
      // isBookingAvailable: true,
    };
    await VendorAddSchema.validate(data)
      .then(async () => {
        const response = await businessService.addBusiness(data);
        switch (response.statusCode) {
          case 201:
            showSuccess(response.message);
            fetchVendors();
            onClose();
            break;
          default:
            showError(response.response.data.message || response.message);
            break;
        }
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "businessType") {
      setSelectedCategory(value);
    }
    if (name === "booking") {
      setVendorInfo((prevState) => ({
        ...prevState,
        [name]: !vendorInfo.booking,
      }));
    }
    setVendorInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addVendor();
  };

  const phoneFieldStyle =
    phoneCount > 1
      ? "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9] mt-2 focus:outline-none"
      : "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9] focus:outline-none";
  const phoneFieldStyleFirst =
    "w-full pl-4 pr-3 py-[5px] border border-[#D9D9D9] rounded-md text-[#000000D9] focus:outline-none focus:border-[#D9D9D9] focus:outline-none";

  const PhoneNumber = Array.from({ length: phoneCount }, (_, index) => {
    const firstItem = index === 0;
    return (
      <label>
        Phone number:
        <input
          type="tel"
          name="phoneNumber"
          value={vendorInfo.phoneNumber}
          onChange={handleChange}
          placeholder="Enter phone number"
          className={firstItem ? phoneFieldStyleFirst : phoneFieldStyle}
        />
      </label>
    );
  });

  if (isOpen) {
    return (
      <div className="modal-add-vendor">
        <div className="add-vendor-info-modal">
          <div className="add-vendor-info-modal-header">
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              Add Vendor Info
            </h2>
            <button onClick={onClose} className="w-[16px] h-[16px] text-right">
              <p style={{ color: "#8c8c8c", fontSize: 20 }}>x</p>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="add-vendor-input-container">
              <label>
                Business Name:
                <input
                  type="text"
                  name="name"
                  value={vendorInfo.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  className="focus:outline-none text-[#000000D9] "
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={vendorInfo.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  className="focus:outline-none text-[#000000D9] "
                />
              </label>
              <label>
                Business Type:
                <select
                  name="businessType"
                  value={selectedCategory}
                  onChange={handleChange}
                  className="focus:outline-none text-[#000000D9] "
                >
                  <option value="other" disabled>
                    Business Type:
                  </option>
                  {categories.map((item) => {
                    return (
                      <option value={parseInt(item.id)}>
                        {item?.name?.en ? item?.name?.en : item?.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              {PhoneNumber}
              <div className="buttons">
                <button
                  onClick={onClose}
                  className="cancel-add-vendor"
                  type="button"
                >
                  Cancel
                </button>
                <button className="submit-add-vendor" type="submit">
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default AddVendorModal;
