import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PiCalendarBlank } from "react-icons/pi";

const SimpleDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  className,
  showTimeSelect = false,
  showEndDate = true,
  startDateDisabled = false,
  endDateDisabled = false,
}) => {
  const pickerRef = useRef(null);

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <div
      className={`flex items-center justify-between overflow-hidden border border-gray-300 rounded-md ${className}`}
    >
      <DatePicker
        ref={pickerRef}
        selected={startDate}
        onChange={handleDateChange}
        selectsStart
        showYearDropdown
        showMonthDropdown
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        dropdownMode="select"
        startDate={startDate}
        endDate={endDate}
        isClearable
        className={`w-full px-2 py-2 text-16 text-[#000000D9] font-normal ${
          startDateDisabled && "bg-gray-100 cursor-not-allowed"
        }`}
        placeholderText="Start date"
        dateFormat={showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
        disabled={startDateDisabled}
      />
      {showEndDate && (
        <>
          <span className="mx-2 text-gray-500">→</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            showYearDropdown
            showMonthDropdown
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            dropdownMode="select"
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            isClearable
            className={`w-full px-2 py-2 text-16 text-[#000000D9] font-normal ${
              endDateDisabled && "bg-gray-100 cursor-not-allowed"
            }`}
            placeholderText="End date"
            dateFormat={
              showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"
            }
            disabled={endDateDisabled}
          />
        </>
      )}
      <span className={`px-2 text-gray-500`}>
        <PiCalendarBlank
          size={24}
          onClick={() => pickerRef.current.setOpen(true)}
        />
      </span>
    </div>
  );
};

export default SimpleDatePicker;
