import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputField from "../../core/inputField";
import TextArea from "../../core/textArea";
import ImagesInput from "../../core/ImagesInput";
import SelectInput from "../../core/SelectInput";
import AddressSelector from "../../core/AddressSelector";
import PhoneNumberInput from "../../core/PhoneNumberInput";
import EditHeader from "../../Header/EditHeader";
import RestaurantImage from "../../../assets/restaurant.svg";
const GOOGLE_MAPS_API_KEY = 'AIzaSyCqNCVTXAdYyepHySFOxI-WcLVKb-MttNc';

const InfoLocationPage = ({ imageUrl, altText }) => {
  const { id } = useParams();
  const [restaurantName, setRestaurantName] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  
  // Mock data for demonstration purposes
  const location = {
    name: "East Coast Pizzeria",
    rating: 4.5,
    location: "Downtown NY",
    address: "1234 Pizza Street",
    openHours: "Today 8:00 - 22:00",
  };

  const handleAddressSelect = (address, lat, lng) => {
    setAddress(address);
    // Handle other location-related updates if needed
  };

  return (
    <div>
      <EditHeader location={location} image={RestaurantImage} />
      <div className="flex flex-col items-start justify-start w-full mt-4 duration-300 ease-in-out md:mt-0 md:p-4 md:w-3/4 lg:w-1/2 transition-width">
        <InputField
          label="Restaurant Name"
          placeholder="Enter restaurant name"
          type="text"
          value={restaurantName}
          onChange={(e) => setRestaurantName(e.target.value)}
          className="w-full mb-6"
        />
        <SelectInput
          label="Category"
          placeholder="Choose your Category"
          options={[
            { value: "restaurant", label: "Restaurant" },
            { value: "health", label: "Health and Beauty" },
            { value: "sports", label: "Sports" },
          ]}
          onSelect={(selected) => setCategory(selected.value)}
          className="w-full mb-6"
        />
        <SelectInput
          label="Subcategory"
          placeholder="Choose your Subcategory"
          options={[
            { value: "chinese", label: "Chinese" },
            { value: "burgers", label: "Burgers" },
            { value: "mexican", label: "Mexican" },
          ]}
          onSelect={(selected) => setSubcategory(selected.value)}
          className="w-full mb-6"
        />
        <InputField
          label="Email"
          placeholder="email@gmail.com"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full mb-6"
        />
        <PhoneNumberInput
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          className="w-full mb-6"
        />
        <TextArea
          label="Description"
          placeholder="Enter description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full mb-6"
        />
        <SelectInput
          label="Location"
          placeholder="Choose your city"
          options={[
            { value: "New York", label: "New York" },
            { value: "Los Angeles", label: "Los Angeles" },
            { value: "Chicago", label: "Chicago" },
          ]}
          onSelect={(selected) => setCity(selected.value)}
          className="w-full mb-6"
        />
        <AddressSelector
          className="w-full"
          value={address}
          apiKey={GOOGLE_MAPS_API_KEY}
          onAddressSelect={(address, lat, lng) => setAddress(address)}
        />
        <ImagesInput
          label={"Upload Photo"}
          className="w-full mb-6"
          imageData={images}
          onImagesChange={(newImages) => setImages(newImages)}
        />
        <div className="flex items-center justify-end w-full p-4">
          <button className="flex items-center justify-center w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl">
            <span>Cancel</span>
          </button>
          <button className="flex items-center justify-center w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl">
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoLocationPage;
