import api from "../apiConfig";

const serviceServices = {
  getServices: async () => {
    try {
      const response = await api.get("/service");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBusinessServices: async (businessId) => {
    try {
      const response = await api.get(`/service?businessId=${businessId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getService: async (id) => {
    try {
      const response = await api.get(`/service/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addService: async (serviceData) => {
    try {
      const response = await api.post("/service", serviceData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateService: async (id, updateData) => {
    try {
      const response = await api.put(`/service/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteService: async (id) => {
    try {
      const response = await api.delete(`/service/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default serviceServices;
