import React, { useEffect, useState } from "react";
import ImageUpload from "../core/ImageInput";
import InputField from "../core/inputField";
import Button from "../core/Button";
import { LuTrash } from "react-icons/lu";
import { HiOutlinePlusSm } from "react-icons/hi";
import InputFieldWithLanguage from "components/Services/ServicesTab/InputFieldWithLanguage";

const CategoryForm = (props) => {
  const [category, setCategory] = useState("");
  const [icon, setIcon] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.category) {
      setCategory(props.category.name);
      setId(props.category.id);
    }
    if (props.icon) setIcon(props.icon);
    if (props.subcategories) setSubcategories(props.subcategories);
  }, [props.category, props.icon, props.subcategories]);

  const onCategoryEdit = (value, language) => {
    setCategory((prev) => ({
      ...prev,
      [language]: value,
    }));
  };

  const onIconUpdate = (file) => {
    setIcon(file);
  };

  const onSubcategoryEdit = (index, value, language) => {
    setSubcategories((prevSubcategories) => {
      const updatedSubcategories = [...prevSubcategories];
      updatedSubcategories[index] = {
        ...updatedSubcategories[index],
        name: {
          ...updatedSubcategories[index].name,
          [language]: value,
        },
      };
      return updatedSubcategories;
    });
  };

  const onSubcategoryDelete = (index) => {
    let updatedSubcategories = [...subcategories];
    updatedSubcategories.splice(index, 1);
    setSubcategories(updatedSubcategories);
  };

  const addSubcategory = () => {
    let updatedSubcategories = [...subcategories];
    updatedSubcategories.push({
      name: {
        en: "",
        ar: "",
      },
      images: [],
    });
    setSubcategories(updatedSubcategories);
  };

  const onSubcategoryImageUpdate = (index, file) => {
    setSubcategories((prevSubcategories) => {
      const updatedSubcategories = [...prevSubcategories];
      updatedSubcategories[index] = {
        ...updatedSubcategories[index],
        images: [file],
      };
      return updatedSubcategories;
    });
  };

  const handleSave = () => {
    props.onSave({
      id: id,
      name: category,
      icon: icon,
      subCategories: subcategories,
    });
  };

  const renderSubcategories = () => {
    return subcategories.map((subcategory, index) => (
      <div key={index} className="flex flex-col items-start justify-start mt-4">
        <div className="flex justify-between w-full">
          <div className="flex items-center justify-start w-full mt-4 space-x-4">
            <ImageUpload
              showLabel={false}
              className="w-20 h-20 "
              value={subcategory.images ? subcategory.images[0] : null}
              onUpload={(file) => onSubcategoryImageUpdate(index, file)}
              onDeleted={() => {
                onSubcategoryImageUpdate(index, null);
              }}
            />
            <p className="flex flex-col space-y-2">
              <span className="text-14 font-normal leading-6 text-[#000000D9]">
                Icon
              </span>
              <span className="text-12 font-normal leading-3 text-[#000000D9] ">
                Upload a PNG or SVG image file
              </span>
            </p>
          </div>
          <button onClick={() => onSubcategoryDelete(index)} className="ml-2">
            <LuTrash className="text-red-500" size={18} />
          </button>
        </div>
        <div className="flex items-center justify-start w-full mt-4 space-x-4">
          <label className="text-14 font-normal text-left w-[100px] leading-6 text-[#000000D9]">
            Subcategory
          </label>
          <div className="flex flex-col items-center justify-start w-2/3 space-y-4">
            <InputFieldWithLanguage
              placeholder="Enter the name"
              language={"En"}
              onChange={(e) => {
                onSubcategoryEdit(index, e.target.value, "en");
              }}
              value={subcategory.name.en || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
            />
            <InputFieldWithLanguage
              placeholder="أدخل الاسم"
              language={"Ar"}
              onChange={(e) => {
                onSubcategoryEdit(index, e.target.value, "ar");
              }}
              value={subcategory.name.ar || ""}
              className={"w-full"}
              labelClassName={"!w-14"}
              dir="rtl"
            />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md">
      <h2 className="mb-4 font-medium leading-6 text-16">
        {props.category.name
          ? props.category.name.en
          : "Add a new parent category"}
      </h2>
      <div className="w-full mt-8 border-b" />
      <div className="flex items-center justify-start w-full mt-4 space-x-4">
        <ImageUpload
          className="w-20 h-20 mt-4"
          showLabel={false}
          value={icon}
          onUpload={(file) => onIconUpdate(file)}
          imageSize={"w-7 h-7"}
          supportedFormats={["image/svg+xml"]}
        />
        <p className="flex flex-col space-y-2">
          <span className="text-14 font-normal leading-6 text-[#000000D9]">
            Icon
          </span>
          <span className="text-12 font-normal leading-3 text-[#000000D9] ">
            Upload a SVG image file
          </span>
        </p>
      </div>

      <div className="flex items-center justify-start w-full mt-4">
        <label className="text-14 font-normal text-left text-[#000000D9] md:text-left min-w-28">
          Category
        </label>
        <div className="flex flex-col items-center justify-start w-2/3 space-y-4">
          <InputFieldWithLanguage
            placeholder="Enter the name"
            language={"En"}
            onChange={(e) => {
              onCategoryEdit(e.target.value, "en");
            }}
            value={category.en || ""}
            className={"w-full"}
            labelClassName={"!w-14"}
          />
          <InputFieldWithLanguage
            placeholder="أدخل الاسم"
            language={"Ar"}
            onChange={(e) => {
              onCategoryEdit(e.target.value, "ar");
            }}
            value={category.ar || ""}
            className={"w-full"}
            labelClassName={"!w-14"}
            dir="rtl"
          />
        </div>
      </div>
      <div className="w-full mt-8 border-b" />

      {renderSubcategories()}

      <div className="flex items-center justify-end mt-6">
        <Button
          label="Add Subcategory"
          onClick={() => {
            addSubcategory();
          }}
          icon={<HiOutlinePlusSm />}
        />
      </div>
      <div className="flex justify-end space-x-4" style={{ marginTop: 10 }}>
        <Button
          label="Cancel"
          onClick={() => props.onCancel()}
          className="w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
          textColor="black"
        />
        <Button
          label="Save"
          onClick={() => handleSave()}
          className="w-40 p-2 text-[#FFFFFF] bg-red-500 border border-[#FFA39E] rounded-xl"
          textColor="white"
        />
      </div>
    </div>
  );
};

export default CategoryForm;
