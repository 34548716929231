import React, { useEffect } from "react";
import ServiceCategoryListItem from "./ServiceCategoryListItem";

const ServiceCategoryList = ({
  fetchedCategories,
  selectedService,
  setSelectedService,
  setFormMode,
  onDelete,
}) => {
  return (
    <div className="mt-6 ">
      {fetchedCategories.map((service) => (
        <ServiceCategoryListItem
          key={service.id}
          service={service}
          isSelected={selectedService?.id === service.id}
          onSelect={() => {
            setSelectedService(service);
            setFormMode("edit");
          }}
          onDelete={(service) => onDelete(service)}
        />
      ))}
    </div>
  );
};

export default ServiceCategoryList;
