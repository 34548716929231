import api from "services/apiConfig";

const businessForumService = {
  getBusinessForums: async () => {
    try {
      const response = await api.get("/business-forum");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  updateBusinessForum: async (id, data) => {
    try {
      const response = await api.put(`/business-forum/${id}`, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  deleteBusinessForum: async (id) => {
    try {
      const response = await api.delete(`/business-forum/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};

export default businessForumService;
