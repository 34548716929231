import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const CollapsibleSidebarItem = ({
  title,
  contents,
  icon,
  onClick,
  selectedTitle,
  isClicked,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (contents.some((content) => content.title === selectedTitle)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
      setIsOpen(false);
    }
  }, [selectedTitle, contents]);

  const handleTitleStyle = () => {
    if (!isOpen) {
      if (isSelected) {
        return "bg-white text-[#DE0607]";
      } else {
        return "text-[#8C8C8C] ";
      }
    } else {
      if (isSelected) {
        return " text-[#DE0607] bg-white";
      }
      return "text-[#DE0607] bg-white";
    }
  };

  return (
    <div
      className={`flex flex-col w-full cursor-pointer select-none h-fit 
             ${handleTitleStyle()}
              `}
    >
      <div
        className={`flex items-center justify-between  w-full cursor-pointer
                ${handleTitleStyle()}
              `}
        onClick={() => {
          setIsOpen(!isOpen);
          isClicked(!isOpen);
        }}
      >
        <div className="flex items-center justify-center">
          <div className={`flex items-center justify-center w-10 h-10  `}>
            {icon}
          </div>
          <span>{title}</span>
        </div>

        {isOpen ? (
          <IoIosArrowUp
            size={10}
            className="mr-5"
            color={isSelected ? "#DE0607" : "#8C8C8C"}
          />
        ) : (
          <IoIosArrowDown
            size={10}
            className="mr-5"
            color={isSelected ? "#DE0607" : "#8C8C8C"}
          />
        )}
      </div>
      {isOpen && (
        <div
          className={`flex flex-col w-full text-[#000000D9] 
              `}
        >
          {Array.isArray(contents) &&
            contents.map((content) => (
              <NavLink
                to={content.route}
                onClick={() => onClick(content.title)}
                className={`flex items-center w-full h-full  cursor-pointer ${
                  selectedTitle === content.title
                    ? " text-[#DE0607]"
                    : "text-[#8C8C8C]"
                }`}
              >
                <div className={`flex items-center justify-center w-10 h-10  `}>
                  {content.icon}
                </div>
                <div>{content.title}</div>
              </NavLink>
            ))}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSidebarItem;
