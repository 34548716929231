import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/auth/authSlice";
import locationService from "services/location/locationService";
import providersService from "services/providers/providersService";
import userService from "services/user/userService";

const { default: usersService } = require("services/users/usersService");
const { showError } = require("utils/helpers");

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const userRole = useSelector(selectRole);

  const fetchUsers = async () => {
    try {
      const response = await usersService.getUsers();
      switch (response.statusCode) {
        case 200:
          setUsers(response.users);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchUser = async (id) => {
    try {
      const response = await usersService.getUser(id);
      switch (response.statusCode) {
        case 200:
          return response.user;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchUserName = async (id) => {
    try {
      const response = await usersService.getUser(id);
      switch (response.statusCode) {
        case 200:
          return response.user.name;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await userService.getProfile();
      switch (response.statusCode) {
        case 200:
          return response.user;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchProviders = async () => {
    try {
      const businessId = await userService.getProfile().then((data) => {
        if (data.user.roles[0] === "ADMIN") {
          return data.user.adminBusinessId;
        } else if (data.user.roles[0] === "PROVIDER") {
          return data.user.businessId;
        }
      });
      const locationIds = await locationService
        .getLocationWQuery(`businessId=${businessId}`)
        .then((data) => data.locations.map((location) => location.id));
      const newProviders = await Promise.all(
        locationIds.map(
          async (locationId) =>
            await providersService
              .getProvider(locationId)
              .then((data) => data.providers)
        )
      );
      return newProviders.flat();
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    userRole !== "PROVIDER" && fetchUsers();
  }, []);

  return {
    users,
    fetchUsers,
    fetchUser,
    fetchProfile,
    fetchUserName,
    fetchProviders,
  };
};

export default useUsers;
