import * as Yup from "yup";
import {
  invalidMessage,
  requiredMessage,
  incorrectMessage,
  emailRegex,
  phoneRegex,
} from "../schemaUtils";

export const NotificationSchema = Yup.object()
  .shape({
    en: Yup.string()
      .notRequired()
      .max(100, invalidMessage("max 100 characters")),
    ar: Yup.string()
      .notRequired()
      .max(100, invalidMessage("max 100 characters")),
  })
  .test(
    "at-least-one-value",
    "English or Arabice Notification field must be filled",
    function (value) {
      return !!value.en || !!value.ar;
    }
  );
