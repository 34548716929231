import { useEffect } from "react";
import categoryService from "../../../services/category/categoryService";
import subcategoryServices from "../../../services/subcategory/subcategoryServices";
import { showError, showSuccess } from "../../../utils/helpers";
import CategoryListItem from "./CategoryListItem";

const CategoryList = ({
  fetchedCategories,
  selectedCategory,
  setSelectedCategory,
  setFormMode,
  onDelete,
}) => {
  return (
    <div className="mt-6 ">
      {fetchedCategories.map((category) => (
        <CategoryListItem
          key={category.id}
          category={category}
          isSelected={selectedCategory?.id === category.id}
          onSelect={() => {
            setSelectedCategory(category);
            setFormMode("edit");
          }}
          onDelete={(category) => onDelete(category)}
        />
      ))}
    </div>
  );
};

export default CategoryList;
