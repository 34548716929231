import React, { useMemo, useState } from "react";
import Overview from "./Overview";
import Availability from "./Availability";
import Reviews from "./Reviews";
import Button from "components/core/Button";
import { useSelector } from "react-redux";
import { selectListing } from "../../../../redux/listings/listingSlice";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "utils/helpers";
import locationService from "services/location/locationService";
import { ListingAddSchema } from "utils/schemas/Listings/ListingsSchemas";

const AddListing = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const listingData = useSelector(selectListing);
  const availabilityData = useSelector(
    (state) => state.listings.listingAvailability
  );
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        name: "Overview",
        component: <Overview />,
      },
      {
        name: "Availability",
        component: <Availability />,
      },
      {
        name: "Rating and Reviews",
        component: <Reviews />,
      },
    ],
    []
  );

  const handleSave = async () => {
    let data = {
      name: listingData.name,
      categoryId: listingData.category.id,
      subcategoryIds: listingData.subcategories.map((sub) => sub.id),
      cityId: listingData.city.id,
      description: listingData.description,
      logo: listingData.logo ? listingData.logo : "",
      images: listingData.images,
      email: listingData.email,
      phone: listingData.phoneNumbers.map((phone) => phone.number),
      whatsapp: listingData.whatsapp,
      instagram: listingData.instagram,
      website: listingData.website,
      address: listingData.address ? listingData.address : "",
      priceRange: parseInt(listingData.priceRange),
      amenities: listingData.amenities
        ? listingData.amenities.map((amenity) => amenity.value)
        : [],
      businessId: listingData.vendor.id,
      subserviceIds: [],
    };

    let tmpAvailabilityData = !Array.isArray(availabilityData)
      ? null
      : availabilityData.map((day) => {
          return {
            name: day.day.toUpperCase(),
            schedule: day.slots.map((slot) => {
              return {
                startTime: slot.startsAt,
                endTime: slot.finishesAt,
              };
            }),
          };
        });

    let newAvailabilityData = {
      days: tmpAvailabilityData,
    };

    try {
      let mergedData = { ...data, availability: newAvailabilityData };
      await ListingAddSchema.validate(mergedData).then(async () => {
        const response = await locationService.addLocation(mergedData);
        switch (response.statusCode) {
          case 201:
            showSuccess(response.message);
            navigate("/listings");
            break;
          default:
            showError(response.response.data.message || response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message || "Something went wrong!");
    }
  };

  return (
    <div className="h-full mt-4 bg-white rounded-md">
      <div className="flex p-5 space-x-4 bg-white border-b ">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`p-2 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="p-5">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
      <div className="flex items-center justify-end w-full p-4">
        <Button
          label={"Cancel"}
          className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
          textColor="text-black"
          onClick={() => {
            navigate("/listings");
          }}
        />
        <Button
          label={"Save"}
          className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
          textColor="text-white"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default AddListing;
