import api from "../apiConfig";

const businessService = {
  // GET request to retrieve businesses
  getBusinesses: async () => {
    try {
      const response = await api.get("/business");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  getBusiness: async (id) => {
    try {
      const response = await api.get(`/business/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new business
  addBusiness: async (data) => {
    try {
      const response = await api.post("/business", data);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing business
  updateBusiness: async (id, updateData) => {
    try {
      const response = await api.put(`/business/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing business status
  updateBusinessStatus: async (id, updateData) => {
    try {
      const response = await api.put(
        `/business/${id}/update-status`,
        updateData
      );
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  updateBusinessSettings: async (id, updateData) => {
    try {
      const response = await api.put(`/business/${id}/settings`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  updateBusinessPaymentMethods: async (id, updateData) => {
    try {
      const response = await api.put(`/business/${id}/settings/pm`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a business
  deleteBusiness: async (id) => {
    try {
      const response = await api.delete(`/business/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default businessService;
