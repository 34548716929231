import PopoverThreeDot from "components/Popover/PopoverThreeDot";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LuTrash } from "react-icons/lu";

const Table = ({
  headers,
  data,
  numberOfRows,
  showPagination = true,
  setData,
  isClickable = false,
  onDelete,
  actions,
  sm,
  onRowClick = () => {},
  usePopover = false,
  popoverActions = [],
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [lastRowIndex, setLastRowIndex] = useState();
  const [firstRowIndex, setFirstRowIndex] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [openOptionsModal, setOpenOptionsModal] = useState(-1);

  useEffect(() => {
    setRowsPerPage(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    setCurrentData(data.slice(first, last));
  }, [currentPage, rowsPerPage, data, rowsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const deleteRow = (index) => {
    let id = data[index];
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleOptionsModal = (index) => {
    setOpenOptionsModal((prev) => (prev === index ? -1 : index));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  const renderHeaders = () => (
    <tr className="items-center justify-between flex-1 ">
      {headers.map((header, index) => (
        <th
          key={index}
          className={`w-${
            headers.length > 1 ? `1/${headers.length - 1}` : "full"
          } 
          px-5 py-3 text-nowrap max-xl:text-10 max-xl:px-2 max-xl:py-1 text-14 font-medium tracking-normal text-left text-[#000000D9] capitalize bg-[#FAFAFA]`}
        >
          {header.value}
        </th>
      ))}
      {(actions || usePopover) && (
        <th className="px-5 py-3 text-14 font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"></th>
      )}
    </tr>
  );
  const renderActionIcons = (item, index) => {
    if (usePopover) {
      return (
        <PopoverThreeDot
          isOpen={openOptionsModal === index}
          setIsOpen={setOpenOptionsModal}
          index={index}
          handleOptionsModal={() => handleOptionsModal(index)}
          onEdit={() =>
            popoverActions.onEdit(item, index, () => setOpenOptionsModal(-1))
          }
          onDelete={() => popoverActions.onDelete(item, index)}
          status={item.status}
        />
      );
    } else {
      return actions.map((action, idx) => (
        <button
          key={idx}
          className="text-red-500 hover:text-red-700"
          onClick={() => {
            setOpenOptionsModal(-1);
            action.onClick(item, index);
          }}
        >
          {action.icon}
        </button>
      ));
    }
  };

  const getClassName = (item, idx) => {
    if (item === "PENDING") {
      return (
        <td key={idx} className="text-center bg-white border-b border-gray-200">
          <div
            style={{
              paddingBlock: "3.5px",
              paddingInline: "8px",
              backgroundColor: "#FFF7E6",
              borderColor: "#FFD591",
              borderWidth: "1px",
              borderStyle: "solid",
              color: "#FA8C16",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            Unread
          </div>
        </td>
      );
    }
    if (item === "READ") {
      return (
        <td key={idx} className="text-center bg-white border-b border-gray-200">
          <div
            style={{
              paddingBlock: "3.5px",
              paddingInline: "8px",
              backgroundColor: "#E6F7FF",
              borderColor: "#91D5FF",
              borderWidth: "1px",
              borderStyle: "solid",
              color: "#1890FF",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            Read
          </div>
        </td>
      );
    }
    if (item === "UNREAD") {
      return (
        <td key={idx} className="text-center bg-white border-b border-gray-200">
          <div
            style={{
              paddingBlock: "3.5px",
              paddingInline: "8px",
              backgroundColor: "#FFF7E6",
              borderColor: "#FFD591",
              borderWidth: "1px",
              borderStyle: "solid",
              color: "#FA8C16",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            Unread
          </div>
        </td>
      );
    }

    if (item === "APPROVED") {
      return (
        <td
          key={idx}
          className="py-[3.5px] px-[8px] bg-white border-b border-gray-200 text-center"
        >
          <div
            style={{
              paddingBlock: "3.5px",
              paddingInline: "8px",
              backgroundColor: "#E6F7FF",
              borderColor: "#1890FF",
              borderWidth: "1px",
              borderStyle: "solid",
              color: "#1890FF",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            Read
          </div>
        </td>
      );
    } else {
      return (
        <td
          key={idx}
          className={` text-sm font-normal text-gray-900 bg-white border-b border-gray-200
            ${sm ? "p-2" : "p-5"}
          `}
        >
          {item}
        </td>
      );
    }
  };

  return (
    <div className="bg-white">
      <table className="w-full leading-normal">
        <thead>{renderHeaders()}</thead>

        <tbody>
          {currentData.map((item, index) => (
            <tr
              key={index}
              onClick={() => onRowClick(item)}
              className={`${isClickable ? "cursor-pointer" : ""}
              `}
            >
              {headers.map((header, idx) =>
                getClassName(item[header.key], idx)
              )}
              {(actions || usePopover) && (
                <td className="p-4 text-sm font-normal text-gray-900 bg-white border-b border-gray-200">
                  {renderActionIcons(item, index)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className={` items-center justify-end mt-4 space-x-2
      ${showPagination ? "flex" : "hidden"}
        `}
      >
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>

        <div className={`${data.length == 0 ? "pt-2" : ""}`}>
          <select
            className=" w-full h-8 form-select text-14 text-[#000000D9] leading-6 border rounded-md border-[#D9D9D9] flex justify-center items-center"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setCurrentPage(1);
            }}
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}/page
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Table;
