import React, { useEffect, useRef, useState } from "react";
import usersService from "../../../../../services/users/usersService";
import { UserSchema } from "utils/schemas/Users/UserSchemas";
import {
  convertAgeGroupStringToEnum,
  getAgeGroupString,
  showError,
  showSuccess,
} from "utils/helpers";
import InputField from "components/core/inputField";
import SelectInput from "components/core/SelectInput";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useUsers from "utils/hooks/useUsers";

const EditUserModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  selectedUser,
  fetchUsers,
  currentData,
}) => {
  const modalRef = useRef(null);
  const { fetchUser } = useUsers();
  useOutsideClick(modalRef, onClose);
  const [bookingCount, setBookingCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const ageOptions = [
    { value: "UNDER_18", label: "Up to 18 years old" },
    { value: "AGE_18_25", label: "18-25 years old" },
    { value: "AGE_26_35", label: "26-35 years old" },
    { value: "AGE_36_45", label: "36-45 years old" },
    { value: "AGE_46_60", label: "46-60 years old" },
    { value: "AGE_60_PLUS", label: "60+ years old" },
  ];

  const genderOptions = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHER", label: "Other" },
  ];

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      setUserInfo({
        name: `${selectedUser.name} ${selectedUser.surname || ""}`,
        email: selectedUser.email,
        phone: selectedUser.phoneNumber,
        ageGroup: convertAgeGroupStringToEnum(selectedUser.age),
        gender: selectedUser.gender,
      });
      fetchUser(selectedUser.id).then((user) => {
        setBookingCount(user.bookingsCount);
        setReviewCount(user.reviewsCount);
      });
    }
  }, [selectedUser]);

  const handleClose = () => {
    setUserInfo({
      name: `${selectedUser.name} ${selectedUser.surname || ""}`,
      email: selectedUser.email,
      phone: selectedUser.phoneNumber,
      ageGroup: convertAgeGroupStringToEnum(selectedUser.age),
      gender: selectedUser.gender,
    });
    fetchUser(selectedUser.id).then((user) => {
      setBookingCount(user.bookingsCount);
      setReviewCount(user.reviewsCount);
    });
    setIsModalOpen(false);
  };
  const handleChange = (value, field) => {
    setUserInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let splittedName = userInfo.name.trim().split(/\s+/);
    let name = splittedName[0] || "";
    let surname = splittedName[1] || "";

    const body = {
      email: userInfo.email,
      password: selectedUser.password,
      name,
      surname,
      phone: userInfo.phone,
      avatarPath: selectedUser.avatarPath,
      gender: userInfo.gender.value || userInfo.gender,
      ageGroup: userInfo.ageGroup.value || userInfo.ageGroup,
      roles: selectedUser.roles,
    };

    try {
      await UserSchema.validate(body);
      const response = await usersService.updateUser(selectedUser.id, body);
      if (response.statusCode === 200) {
        showSuccess(response.message);
        setIsModalOpen(false);
        fetchUsers();
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-1000">
      <div
        className="bg-white rounded-lg w-[500px] shadow-md overflow-hidden"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-between p-4 bg-gray-100">
            <h2 className="text-lg font-bold">Edit User Info</h2>
            <button type="button" onClick={() => handleClose()}>
              <span className="text-xl text-gray-500">×</span>
            </button>
          </div>
          <div className="w-full mt-4">
            <div className="flex flex-col items-center w-full space-y-1">
              <InputField
                label="Name"
                value={userInfo.name}
                onChange={(e) => handleChange(e.target.value, "name")}
                name="name"
                placeholder="Enter name"
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[40px] border border-[#D9D9D9] rounded-md"
              />
              <InputField
                label="Email"
                value={userInfo.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                name="email"
                placeholder="Enter email"
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[40px] border border-[#D9D9D9] rounded-md"
              />
              <InputField
                label="Phone number"
                value={userInfo.phone}
                onChange={(e) => handleChange(e.target.value, "phone")}
                name="phone"
                placeholder="Enter phone number"
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[44px] border border-[#D9D9D9] rounded-md"
              />
              <SelectInput
                label="Gender"
                selected={genderOptions.find(
                  (option) => option.value === userInfo.gender
                )}
                onSelect={(value) =>
                  setUserInfo((prevState) => ({ ...prevState, gender: value }))
                }
                options={genderOptions}
                labelClassName="text-14 h-[44px] text-[#00000073]  font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[44px] border border-[#D9D9D9] rounded-md"
              />
              <SelectInput
                label="Age Group"
                selected={ageOptions.find(
                  (option) => option.value === userInfo.ageGroup
                )}
                onSelect={(value) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    ageGroup: value,
                  }))
                }
                options={ageOptions}
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] space-x-0 h-[44px] border border-[#D9D9D9] rounded-md"
              />
              <InputField
                label="Bookings"
                value={bookingCount || 0}
                disabled
                name="bookings"
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[44px] border border-[#D9D9D9] rounded-md"
              />
              <InputField
                label="Reviews"
                value={reviewCount || 0}
                disabled
                name="reviews"
                labelClassName="text-14 h-[44px] text-[#00000073] font-bold max-w-[156px]"
                inputClassName="!w-[280px] h-[44px] border border-[#D9D9D9] rounded-md"
              />
            </div>
            <div className="flex justify-end my-8 mr-6 space-x-4">
              <button
                type="button"
                onClick={() => {
                  handleClose();
                }}
                className="bg-white border border-[#FFA39E] text-black w-[180px] rounded-md px-4 py-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#DE0607] border border-[#FFA39E] w-[180px] text-white rounded-md px-4 py-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
