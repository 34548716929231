import React, { useEffect, useState } from "react";
import EmptyStateComponent from "../EmptyStateComponents";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import ServiceCategoryList from "./CategoryTabComponents/ServiceCategoryList";
import ServiceCategoryFormWrapper from "./CategoryTabComponents/ServiceCategoryFormWrapper";
import serviceServices from "../../services/service/serviceServices";
import { showError, showSuccess } from "../../utils/helpers";
import { postService } from "./utils/serviceFunctions";
import locationService from "../../services/location/locationService";
import {
  ServiceAddSchema,
  ServiceEditSchema,
} from "utils/schemas/Services/ServiceSchema";
import useLocations from "utils/hooks/useLocations";
import { selectBusinessId, selectRole } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ServiceCategoryTab = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [formMode, setFormMode] = useState("");
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchVendorLocations } = useLocations();
  const businessId = useSelector(selectBusinessId);
  const userRole = useSelector(selectRole);

  const fetchLocations = async () => {
    setLoading(true);
    try {
      // Fetch locations
      const response = await locationService.getLocationWQuery(
        `businessId=${businessId}`
      );
      switch (response.statusCode) {
        case 200:
          setFetchedLocations(response.locations);
          setLoading(false);
          break;
        default:
          showError(response.message);
          setFetchedLocations([]);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      // Fetch services
      const response = await serviceServices.getBusinessServices(businessId);
      switch (response.statusCode) {
        case 200:
          setFetchedCategories(response.services);
          setLoading(false);
          break;
        default:
          showError("No services found");
          setFetchedCategories([]);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const putService = async ({ id, updateData }) => {
    try {
      const response = await serviceServices.updateService(id, updateData);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async (category) => {
    let serviceId = selectedService.id;
    let selectedLocationIds = category.locations.map((location) => location.id);
    let updatedService = {};
    updatedService = {
      name: category.name,
      locationIds: selectedLocationIds,
    };
    try {
      await ServiceEditSchema.validate(updatedService);
      putService({
        id: serviceId,
        updateData: updatedService,
      });
      fetchServices();
      fetchLocations();
      setFormMode("");
      setSelectedService({});
    } catch (error) {
      showError(error.message);
    }
  };

  const handleAdd = async (category) => {
    let newService = {
      name: category.name,
      locationIds: category.locations.map((location) => location.id),
      businessId: businessId,
    };
    try {
      await ServiceAddSchema.validate(newService);
      await postService(newService);
      fetchServices();
      fetchLocations();
      setSelectedService({});
      setFormMode("");
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDelete = (id) => {
    try {
      serviceServices.deleteService(id).then((response) => {
        showSuccess("Service deleted successfully");
        fetchServices();
        setSelectedService({});
        setFormMode("");
      });
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    businessId &&
      fetchVendorLocations(businessId).then((res) => {
        setFetchedLocations(res);
      });
    fetchServices();
  }, [selectedService, formMode, businessId]);

  return (
    // <LoaderWrapper isLoading={loading}>
    <div className="h-screen">
      {fetchedCategories ? (
        <div className="flex flex-row mt-4 space-x-4 bg-white">
          <div className="w-2/5 h-lvh">
            <header className="flex flex-row items-center justify-between">
              <p className="text-16 font-medium leading-6 text-[#000000D9]">
                Service Category
              </p>

              <Button
                label={"Add Category"}
                icon={<HiOutlinePlusSm />}
                onClick={() => {
                  setSelectedService({});
                  setFormMode("add");
                }}
                className={`
                    ${userRole === "PROVIDER" ? "hidden" : ""}
                  `}
              />
            </header>
            <div className="w-full mt-6 border-b" />
            {fetchedCategories.length > 0 ? (
              <ServiceCategoryList
                fetchedCategories={fetchedCategories}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                setFormMode={setFormMode}
                onDelete={(service) => handleDelete(service.id)}
              />
            ) : (
              <p className="text-16 font-medium leading-6 text-[#000000D9]">
                No Parent Category found
              </p>
            )}
          </div>

          <div className="w-3/5 h-full">
            <ServiceCategoryFormWrapper
              formMode={formMode}
              selectedService={selectedService}
              handleSave={handleSave}
              handleAdd={handleAdd}
              locations={fetchedLocations || []}
            />
          </div>
        </div>
      ) : (
        <EmptyStateComponent
          text={"Select a category to view sub-categories"}
        />
      )}
    </div>
    // </LoaderWrapper>
  );
};

export default ServiceCategoryTab;
