import api from "services/apiConfig";

const bannerService = {
  createBanner: async (data) => {
    try {
      const response = await api.post(`/banner`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBanner: async (id) => {
    try {
      const response = await api.get(`/banner/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBanners: async () => {
    try {
      const response = await api.get("/banner");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBannersWDate: async (date) => {
    try {
      const response = await api.get(`/banner?date=${date}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBannersWDateLocation: async (date, locationId) => {
    try {
      const response = await api.get(`/banner?date=${date}&?locationId=${locationId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBannersWProviderIdLocation: async (providerId, locationId) => {
    try {
      const response = await api.get(`/banner?providerId=${providerId}&locationId=${locationId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBannersWLocation: async (locationId) => {
    try {
      const response = await api.get(`/banner/${locationId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateBanner: async (id, updateData) => {
    try {
      const response = await api.put(`/banner/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteBanner: async (id) => {
    try {
      const response = await api.delete(`/banner/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default bannerService;
