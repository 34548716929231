import api from "services/apiConfig";

const popupService = {
  createPopup: async (data) => {
    try {
      const response = await api.post(`/popup`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getPopup: async (id) => {
    try {
      const response = await api.get(`/popup/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getPopups: async () => {
    try {
      const response = await api.get("/popup");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updatePopup: async (id, updateData) => {
    try {
      const response = await api.put(`/popup/${id}`, updateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deletePopup: async (id) => {
    try {
      const response = await api.delete(`/popup/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default popupService;
