import React, { useState } from "react";
import { HiOutlineMail, HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../services/auth/authService";
import { showError, showSuccess } from "../../utils/helpers";
import FormField from "./FormField";
import Button from "../core/Button";

const CreatePassword = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const getPasswordErrors = (password) => {
    const errors = [];
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("must contain at least one uppercase letter (A-Z)");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("must contain at least one lowercase letter (a-z)");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("must contain at least one numeric character (0-9)");
    }
    if (!/(?=.*[\^$*.{}\[\]()?"!@#%&/\\,><':;|_~`+=-])/.test(password)) {
      errors.push("must contain at least one special character");
    }
    if (!/[\S\s]{8,}/.test(password)) {
      errors.push("must be at least 8 characters long");
    }
    return errors;
  };

  const handleCreatePassword = async (event) => {
    event.preventDefault();
    const passwordErrors = getPasswordErrors(newPassword);
    if (passwordErrors.length > 0) {
      showError(`Password ${passwordErrors.join(", ")}`);
      return;
    }
    if (newPassword !== confirmPassword) {
      showError("Passwords do not match");
      return;
    }
    try {
      const response = await authService.resetPassword(id, newPassword);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          navigate("/login");
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || "An error occurred");
    }
  };

  return (
    <div className="px-[58px] relative w-full md:w-1/2 rounded-md border-[#00000026] shadow-2xl h-[680px] flex flex-col justify-start">
      <div
        onClick={() => navigate("/login")}
        className="absolute flex items-center space-x-2 cursor-pointer top-6 left-6"
      >
        <FaArrowLeft size={14} className="text-[#000000D9]" />
        <p className="font-medium text-center text-[#000000D9] text-16">Back</p>
      </div>
      <h2 className="mt-20 font-medium text-center leading-[46px] text-38 text-[#000000D9]">
        Restore Password
      </h2>
      <form className="mt-24 space-y-8" onSubmit={handleCreatePassword}>
        <FormField
          label="Create new password"
          icon={<MdOutlineLock size={16} />}
          name="newPassword"
          placeholder="Create new password"
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          endIcon={
            showPassword ? (
              <HiOutlineEyeOff
                size={16}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <HiOutlineEye
                size={16}
                onClick={() => setShowPassword(!showPassword)}
              />
            )
          }
        />
        <FormField
          label="Confirm password"
          icon={<MdOutlineLock size={15} />}
          name="confirmPassword"
          placeholder="Confirm password"
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          endIcon={
            showConfirmPassword ? (
              <HiOutlineEyeOff
                size={16}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <HiOutlineEye
                size={16}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )
          }
        />
        <Button
          label="Save"
          className="w-full text-white bg-[#DE0607] rounded-xl"
          textColor="text-white"
        />
      </form>
    </div>
  );
};

export default CreatePassword;
