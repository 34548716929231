import React, { useEffect, useMemo, useState } from "react";
import ProviderProfile from "./ProviderProfile";
import ProviderLocations from "./ProviderLocations";
import ProviderServices from "./ProviderServices";
import ProviderSettings from "./ProviderSettings";
import { useParams } from "react-router-dom";
import useUsers from "utils/hooks/useUsers";
import useProvider from "utils/hooks/useProvider";

const EditProvider = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const [providerData, setProviderData] = useState(null);
  const { id } = useParams();
  const { fetchUser } = useUsers();
  const { fetchProvider } = useProvider();

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await fetchProvider(id);
      setProviderData(userData);
    };
    fetchUserData();
  }, [id, activeTab]);

  const tabs = useMemo(
    () => [
      {
        name: "Profile",
        component: <ProviderProfile data={providerData} />,
      },
      {
        name: "Locations",
        component: (
          <ProviderLocations data={providerData} setData={setProviderData} />
        ),
      },
      {
        name: "Services",
        component: <ProviderServices data={providerData} />,
      },
      {
        name: "Settings",
        component: (
          <ProviderSettings data={providerData} setData={setProviderData} />
        ),
      },
    ],
    [id, activeTab, providerData]
  );

  if (!providerData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full mt-4 bg-white rounded-md">
      <div className="flex p-5 space-x-4 bg-white border-b ">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`p-2 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="mt-10 ml-4">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
    </div>
  );
};

export default EditProvider;
