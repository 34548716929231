import React, { useEffect, useMemo, useState } from "react";
import GeneralTab from "./GeneralTab";
import OnlineTab from "./OnlineTab";
import HomeServiceTab from "./HomeServiceTab";
import Button from "components/core/Button";
import { AiOutlineLogout } from "react-icons/ai";
import useUsers from "utils/hooks/useUsers";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  selectBusinessId,
  selectRole,
} from "../../redux/auth/authSlice";
import businessService from "services/business/businessService";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import PaymentMethodsTab from "./PaymentMethodsTab";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("General");
  const [user, setUser] = useState(null);
  const [category, setCategory] = useState(null);
  const { fetchProfile } = useUsers();
  const dispatch = useDispatch();
  const userBusinessId = useSelector(selectBusinessId);
  const userRole = useSelector(selectRole);
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchProfile().then(async (user) => {
      setUser(user);
    });

    if (userBusinessId) {
      getBusiness(userBusinessId);
      handleCategory(userBusinessId);
    }
    setLoading(false);
  }, []);

  const getBusiness = async (businessId) => {
    const businessResponse = await businessService.getBusiness(businessId);
    switch (businessResponse.statusCode) {
      case 200:
        setBusinessData(businessResponse.business);
        break;
      default:
        break;
    }
  };

  const handleCategory = async (businessId) => {
    setLoading(true);
    const businessResponse = await businessService.getBusiness(businessId);
    let category = businessResponse.business.category;
    setCategory({
      id: category?.id,
      label: category?.name?.en,
      value: category?.slug,
    });
    setLoading(false);
  };

  useEffect(() => {
    userBusinessId && handleCategory(userBusinessId);
  }, [userBusinessId]);

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        name: "General",
        component: (
          <GeneralTab
            data={user}
            setData={setUser}
            category={category}
            businessData={businessData}
            setBusinessData={setBusinessData}
          />
        ),
      },
    ];

    if (
      businessData &&
      businessData.homeServiceEnabled &&
      userRole !== "PROVIDER"
    ) {
      baseTabs.splice(2, 0, {
        // Insert the Home Service tab at the third position
        name: "Home Service",
        component: (
          <HomeServiceTab
            data={businessData}
            setBusinessData={setBusinessData}
          />
        ),
      });
    }

    if (
      user?.roles?.includes("ADMIN") ||
      (businessData?.isBookingAvailable && userRole !== "PROVIDER")
    ) {
      baseTabs.splice(1, 0, {
        // Insert the Online Booking tab at the second position
        name: "Online Booking",
        component: <OnlineTab data={businessData} setData={setBusinessData} />,
      });
      // insert the Payment Methods tab at the last position
      baseTabs.push({
        name: "Deposits",
        component: (
          <PaymentMethodsTab data={businessData} setData={setBusinessData} />
        ),
      });
    }
    return baseTabs;
  }, [user, activeTab, category, businessData, handleCategory]);

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="h-full mt-4 bg-white rounded-md">
        <div className="flex items-center justify-between w-full p-5 border-b">
          <div className="flex space-x-4 bg-white ">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={`p-2 ${
                  activeTab === tab.name
                    ? " text-[#DE0607] border-b border-[#DE0607]"
                    : "text-[#000000d9]"
                }`}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </div>

          <Button
            onClick={() => {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              dispatch(logout());
            }}
            className="bg-white border-[#DE0607] border text-[#000000D9] w-40 justify-evenly"
            textColor="text-[#000000D9]"
            label={"Logout"}
            labelStyle={"text-16 font-normal"}
            icon={<AiOutlineLogout className="text-[#000000D9]" size={16} />}
          />
        </div>
        <div className="p-5">
          {tabs.find((tab) => tab.name === activeTab)?.component}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default Settings;
