// src/Login.js
import React from "react";
import fullBgImage from "../assets/fullbgImage.svg";
import { Navigate, Route, Routes } from "react-router";
import Signin from "../components/Login/Signin";
import Signup from "../components/Login/Signup";
import RestorePassword from "../components/Login/RestorePassword";
import CreatePassword from "components/Login/CreatePassword";

const LoginPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen md:flex-row">
      <div className="relative flex items-center justify-center w-full h-20 md:w-1/2 md:min-h-screen">
        <div
          className="absolute top-0 left-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${fullBgImage})`,
          }}
        ></div>
      </div>
      <div className="flex items-center justify-center w-full min-h-screen bg-white md:w-1/2">
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/restore-password" element={<RestorePassword />} />
          <Route path="/reset-password" element={<CreatePassword />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default LoginPage;
