import EmptyStateComponent from "../../EmptyStateComponents";
import ServiceCategoryForm from "./ServiceCategoryForm";

const ServiceCategoryFormWrapper = ({
  formMode,
  selectedService,
  handleSave,
  handleAdd,
  locations,
}) => {
  return formMode === "edit" ? (
    <ServiceCategoryForm
      formMode={formMode}
      selectedService={selectedService}
      onSave={handleSave}
      locations={locations}
    />
  ) : formMode === "add" ? (
    <ServiceCategoryForm
      formMode={formMode}
      onSave={handleAdd}
      locations={locations}
    />
  ) : (
    <EmptyStateComponent text={"Select a category to view sub-categories"} />
  );
};

export default ServiceCategoryFormWrapper;
