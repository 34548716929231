import React, { useEffect, useState } from "react";
import ratingAndReviewData from "constants/data/rateAndReviewData";
import AddCityModal from "../AddCityModal";
import TableListings from "../components/TableListings";

const RatingAndReviews = () => {
  const [ratingAndReviewList, setRatingAndReviewList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    setRatingAndReviewList(ratingAndReviewData);
  }, [ratingAndReviewData]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredData = ratingAndReviewData.filter((business) =>
      business.name.toLowerCase().includes(value)
    );
    setRatingAndReviewList(filteredData);
  };

  const handleSave = (businessName) => {
    setRatingAndReviewList((prev) => [...prev, { name: businessName }]);
    setIsModalOpen(false);
  };

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <TableListings
          data={ratingAndReviewList}
          headers={["Date created", "User Name", "Reviews", "Rating"]}
          numberOfRows={10}
          setData={setRatingAndReviewList}
        />
      </div>
      <AddCityModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
      />
    </div>
  );
};

export default RatingAndReviews;
