import React, { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem";
import Button from "../core/Button";
import { logout } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import CollapsibleSidebarItem from "./CollapsibleSidebarItem";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import logo from "assets/logo.svg";

const Sidebar = ({ sidebarItems, bottomSidebarItems, role, user }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCollapsibleClicked, setIsCollapsibleClicked] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(logout());
  };

  const handleItemClick = (title) => {
    setSelectedItem(title);
  };

  useEffect(() => {
    if (selectedItem === null && sidebarItems.length > 0) {
      setSelectedItem(sidebarItems[0].title);
    }
  }, [sidebarItems, selectedItem]);

  return (
    <div
      className={`fixed z-50 flex flex-col h-screen py-7 font-medium whitespace-nowrap w-full shadow-sm bg-[#262626] max-w-[208px] transition-width duration-300 ease-in-out"`}
    >
      <div
        className={`self-center text-3xl tracking-widest uppercase text-zinc-100`}
      >
        <object
          type="image/svg+xml"
          data={logo}
          className="w-[120px] h-[28px]"
        ></object>
      </div>
      <div
        className={`flex flex-col justify-start items-start mt-12 text-sm leading-5 w-full text-neutral-400
          ${
            useWindowDimensions().height < 650
              ? "h-4/6 overflow-y-scroll hide-scrollbar"
              : "h-fit"
          }
        `}
      >
        {sidebarItems
          .filter((item) => item.hasAccess.includes(role))
          .map((item) =>
            item.isCollapsible ? (
              <CollapsibleSidebarItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                contents={item.contents}
                onClick={handleItemClick}
                selectedTitle={selectedItem}
                isClicked={setIsCollapsibleClicked}
              />
            ) : (
              <SidebarItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                isSelected={
                  isCollapsibleClicked == false && selectedItem === item.title
                }
                route={item.route}
                onClick={(title) => {
                  setIsCollapsibleClicked(false);
                  handleItemClick(title);
                }}
              />
            )
          )}
      </div>

      {role === "SUPERADMIN" ? (
        <div
          className={`absolute border-t border-[#434343] bottom-8 w-full pl-2 h-fit
        `}
        >
          <Button
            icon={<AiOutlineLogout color="#A3A3A3" />}
            label={"Logout"}
            className="items-start justify-start space-x-3 border-none shadow-none text-neutral-400"
            textColor="text-neutral-400"
            onClick={() => {
              handleLogout();
            }}
          />
        </div>
      ) : (
        <div
          className={`absolute border-t border-[#434343] bottom-0 w-full text-center text-sm leading-5 text-neutral-400`}
        >
          {bottomSidebarItems &&
            bottomSidebarItems.map(
              (item) =>
                item.isShown && (
                  <SidebarItem
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    isSelected={selectedItem === item.title}
                    route={item.route}
                    onClick={handleItemClick}
                  />
                )
            )}
          <div className="flex items-center justify-center w-full h-12">
            <img
              src={
                user && user.avatarPath && user.avatarPath !== "null"
                  ? user.avatarPath
                  : "https://static.vecteezy.com/system/resources/thumbnails/008/442/086/small_2x/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg"
              }
              alt="avatar"
              className="object-cover border rounded-full w-9 h-9 border-neutral-400 "
            />
            <div className="ml-2 text-14 font-medium text-[#D9D9D9] text-wrap">
              {user?.name ? user.name : "User"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
