import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import InfoVendorsPage from "../InfoVendorsPage/InfoVendorsPage";
import EditVendorPage from "../EditVendorPage/EditVendorPage";

const VendorsDetails = ({ route }) => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [selectedVendor, setSelectedVendor] = useState(null);
  let location = useLocation();
  let state = location.state;

  useEffect(() => {
    setActiveTab(state.tab);
    setSelectedVendor(state.selectedVendor);
  }, []);

  const tabs = useMemo(
    () => [
      {
        name: "Overview",
        component: <InfoVendorsPage selectedVendorData={selectedVendor} />,
      },
      {
        name: "Edit",
        component: (
          <EditVendorPage
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
          />
        ),
      },
    ],
    [selectedVendor, activeTab]
  );

  return (
    <div className="h-full px-4 pb-2 mt-5 bg-white rounded-md">
      <div className="flex pt-6 space-x-4 bg-white">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`py-3 px-5 ${
              activeTab === tab.name
                ? " text-[#DE0607] border-b-2 border-[#DE0607]"
                : "text-[#000000d9]"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="pt-6">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
    </div>
  );
};

export default VendorsDetails;
