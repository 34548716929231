import React, { useEffect, useRef, useState } from "react";
import { FaSquareWhatsapp } from "react-icons/fa6";
import MailIcon from "assets/Icons/mailIcon.svg";
import ImageInput from "components/core/ImageInput";
import InputField from "components/core/inputField";
import Button from "components/core/Button";
import photoService from "services/photoService";
import { showError, showInfo, showSuccess } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import SelectInput from "components/core/SelectInput";
import useCategory from "utils/hooks/useCategory";
import businessService from "services/business/businessService";
import userService from "services/user/userService";
import { GeneralTabSchema } from "utils/schemas/Settings/SettingsSchemas";
import { FiCopy } from "react-icons/fi";

const GeneralTab = ({
  data,
  setData,
  category,
  businessData,
  setBusinessData,
}) => {
  const { fetchedCategories } = useCategory();
  const [id, setId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uniqueLink, setUniqueLink] = useState("");
  const [businnessName, setBusinnessName] = useState("");
  const [email, setEmail] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    if (data) {
      setUserRole(data.roles[0]);
      setId(data.id);
      setSelectedImage(data.avatarPath);
      setBusinnessName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
    }

    if (businessData) {
      setWhatsappNumber(businessData.phone);
      setUniqueLink(`${businessData.slug}.tryspots.net`);
      setIbanNumber(businessData.ibanNumber);
      setWebsite(businessData.website);
    }
  }, [data, businessData]);

  useEffect(() => {
    if (category) {
      setBusinessType(category);
    }
  }, [category]);

  const handleCancel = () => {
    // reset all fields to their initial values
    if (data) {
      setUserRole(data.roles[0]);
      setId(data.id);
      setSelectedImage(data.avatarPath);
      setBusinnessName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
    }

    if (businessData) {
      setWhatsappNumber(businessData.phone);
      setUniqueLink(`${businessData.slug}.tryspots.net`);
      setIbanNumber(businessData.ibanNumber);
      setWebsite(businessData.website);
    }
    if (category) {
      setBusinessType(category);
    }
  };

  const saveGeneralTab = async () => {
    const businessUpdatedData = {
      website: website,
      categoryId: businessType.id,
      iban: ibanNumber,
    };

    const profileUpdatedData = {
      avatarPath: selectedImage,
      phone,
      name: businnessName,
      email,
    };

    try {
      const response = await businessService.updateBusiness(
        data.businessId ? data.businessId : data.adminBusinessId,
        businessUpdatedData
      );
      switch (response.statusCode) {
        case 200:
          showSuccess("Business updated successfully");
          setBusinessData((data) => ({ ...data, ...response.business }));
          break;
        default:
          showError("Failed to update business");
          break;
      }

      const profileResponse = await userService.updateUser(
        id,
        profileUpdatedData
      );
      switch (profileResponse.statusCode) {
        case 200:
          showSuccess("Profile updated successfully");
          setData((data) => ({ ...data, ...profileResponse.user }));
          break;
        default:
          showError("Failed to update profile");
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async () => {
    try {
      await GeneralTabSchema.validate({
        phone,
        website,
      });

      await saveGeneralTab();
    } catch (error) {
      showError(error.message);
    }
  };

  const copyToClipboard = () => {
    if (uniqueLink === "" || uniqueLink === null) {
      showInfo("No business to copy link");
      return;
    }
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      showSuccess("Link copied to clipboard");
    }
  };

  const handleWhatsappClick = () => {
    const userAgent = navigator.userAgent || window.opera;

    // Whatsapp number
    if (/android/i.test(userAgent)) {
      // Android device
      window.open(`https://wa.me/96555101830`, "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS device
      window.open(`https://wa.me/96555101830`, "_blank");
    } else {
      // Any other device
      window.open(`https://web.whatsapp.com/send?phone=96555101830`, "_blank");
    }
  };

  const hasAccess = (roles) => {
    return roles.includes(userRole);
  };

  return (
    <div className="flex flex-col w-1/2 h-full ">
      <div className="flex items-center justify-center w-32 h-32 ">
        <ImageInput
          value={selectedImage}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          imageSize={"w-32 h-32"}
          className="w-32 h-32 border-0"
          isRounded
          supportedFormats={["image/jpg", "image/png", "image/svg+xml"]}
        />
      </div>
      <div className="flex flex-col mt-4 space-y-4 w-full max-w-[600px] h-full">
        <div
          className={`flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0
            ${hasAccess(["ADMIN", "LOCATION_MANAGER"]) ? "block" : "hidden"}
          `}
        >
          <label className="text-sm font-bold text-left text-gray-700 md:text-left min-w-32 md:w-1/5">
            Unique Link:
          </label>
          <div className="flex items-center w-full border border-gray-300 rounded-md h-fit">
            <input
              ref={inputRef}
              type="text"
              value={`${
                uniqueLink === null || uniqueLink === ""
                  ? "No Business"
                  : uniqueLink
              }`}
              readOnly
              onClick={() => {
                if (uniqueLink !== "" && uniqueLink !== null) {
                  window.open(`http://${uniqueLink}`, "_blank");
                }
              }}
              className="w-full px-4 py-2 text-black bg-[#FAFAFA] border-r rounded-md rounded-r-none shadow-sm cursor-pointer"
            />
            <button
              onClick={copyToClipboard}
              className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <FiCopy size={20} />
            </button>
          </div>
        </div>

        <InputField
          label="Business Name"
          placeholder="Business Name"
          value={businnessName}
          onChange={(e) => setBusinnessName(e.target.value)}
          disabled
        />
        <InputField
          label="IBAN Number"
          placeholder="Enter IBAN Number"
          value={ibanNumber}
          onChange={(e) => setIbanNumber(e.target.value)}
          disabled={!hasAccess(["ADMIN"])}
        />
        <InputField
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled
        />
        <SelectInput
          label="Business Type"
          selected={businessType}
          onSelect={(value) => setBusinessType(value)}
          options={fetchedCategories.map((category) => ({
            id: category.id,
            label: category.name.en,
            value: category.slug,
          }))}
          className="w-full text-left cursor-not-allowed "
          isDisabled
        />

        <InputField
          label="Phone Number"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={!hasAccess(["ADMIN"])}
        />
        <InputField
          label="Business Website"
          placeholder="Website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          disabled={!hasAccess(["ADMIN"])}
        />

        <div className="flex items-center justify-start w-full h-20 space-x-4">
          <label className="text-sm font-bold text-left text-gray-700 md:text-right md:w-1/5 min-w-32">
            Contact Spots:
          </label>
          <div className="flex items-center h-20 space-x-5">
            <FaSquareWhatsapp
              className="text-[#25D366] cursor-pointer"
              style={{ fontSize: "32px", width: "36px", height: "36px" }}
              onClick={handleWhatsappClick}
            />
            <img
              src={MailIcon}
              alt="mail"
              style={{
                width: "32px",
                height: "32px",
                fontSize: "32px",
              }}
              className="cursor-pointer"
              onClick={() => {
                window.open(`mailto:support@tryspots.net`);
              }}
            />
          </div>
        </div>

        <div
          className={`flex items-center justify-end w-full h-20 space-x-4
        ${hasAccess(["ADMIN"]) ? "block" : "hidden"}
        `}
        >
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={handleCancel}
          />
          <Button
            label="Save"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralTab;
