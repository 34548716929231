const ratingAndReviewData = [
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",   
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",

    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    },
    {
        name: "Business name",
        category: "Restaurant",
        subCategory: "Chinese",
        city: "Kuwait City",
    }]

    export default ratingAndReviewData;
