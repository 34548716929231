import InputField from "components/core/inputField";
import SelectInput from "components/core/SelectInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/auth/authSlice";
import businessService from "services/business/businessService";
import { showError, showSuccess } from "utils/helpers";
import { PaymentMethodTabSchema } from "utils/schemas/Settings/SettingsSchemas";
import Button from "components/core/Button";

const PaymentMethodsTab = ({ data, setData }) => {
  const [paymentMethod, setPaymentMethod] = useState({
    label: "None",
    value: false,
  });
  const [depositAmount, setDepositAmount] = useState(0);
  const userRole = useSelector(selectRole);
  const paymentMethodOptions = [
    {
      label: "Disable",
      value: false,
    },
    {
      label: "Enable",
      value: true,
    },
  ];

  const saveDepositSettings = async (businessId, obj) => {
    try {
      const response = await businessService.updateBusinessPaymentMethods(
        businessId,
        obj
      );
      switch (response.statusCode) {
        case 200:
          setData(response.business);
          showSuccess(response.message);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async () => {
    const businessId = data.id;
    const settingsObj = {
      acceptDeposits: paymentMethod.value,
    };

    if (paymentMethod.value === true) {
      settingsObj.depositAmount = parseInt(depositAmount);
    }

    try {
      if (businessId === undefined || businessId === null) {
        showError("Please select a business first.");
        return;
      }
      await PaymentMethodTabSchema.validate(settingsObj);
      await saveDepositSettings(businessId, settingsObj);
    } catch (error) {
      showError(error.message);
    }
  };

  const hasAccess = (roles) => {
    if (userRole === undefined) {
      return false;
    }
    return roles.includes(userRole);
  };

  useEffect(() => {
    if (data) {
      data.acceptDeposits === true
        ? setPaymentMethod({
            label: "Enable",
            value: true,
          })
        : setPaymentMethod({
            label: "Disable",
            value: false,
          });
      setDepositAmount(data.depositAmount);
    }
  }, [data]);

  return (
    <div className="w-full max-w-[600px] space-y-4">
      <div className="flex w-full space-x-2">
        <p className="text-[#000000D9] pt-1 text-14 font-normal leading-20 max-w-[180px] w-full">
          Deposits:
        </p>
        <SelectInput
          options={paymentMethodOptions}
          onSelect={(value) => setPaymentMethod(value)}
          selected={paymentMethod}
          className="w-full mt-1"
          isDisabled={!hasAccess(["ADMIN"])}
        />
      </div>
      {paymentMethod.value === true && (
        <div className="flex w-full space-x-2">
          <div className="flex flex-col max-w-[180px] w-full">
            <p className="text-[#000000D9] text-14 font-normal leading-20 ">
              Deposit amount:
            </p>
            <p className="text-[#000000D9] text-12 font-normal leading-16 max-w-[180px] w-full text-gray-400">
              Min deposit amount is 3 KWD.
            </p>
          </div>
          <InputField
            placeholder={"30"}
            type="text"
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            inputClassName={"h-10"}
            className="w-full"
            disabled={!hasAccess(["ADMIN"])}
          />
        </div>
      )}
      {hasAccess(["ADMIN"]) && (
        <div className={`flex items-center justify-end w-full pt-6 bg-white `}>
          <Button
            label="Cancel"
            className={
              "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
            }
            textColor="text-[#000000D9]"
            onClick={() => {}}
          />
          <Button
            label="Save"
            className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
            textColor="text-white"
            onClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentMethodsTab;
