import React, { useState } from "react";

const InputField = ({
  label,
  placeholder,
  onChange,
  value,
  type = "text",
  className = "",
  icon,
  labelClassName,
  min,
  max,
  inputClassName,
  disabled = false,
  isRequired = false,
  dir = "ltr",
}) => {
  const [error, setError] = useState(""); // Error state to manage validation messages

  // Handle change without blocking input
  const handleChange = (e) => {
    const { value } = e.target;
    // validateInput(value); // Validate but don't block
    onChange(e); // Always execute onChange to update the state
  };

  // Get input-specific properties
  const getInputProps = () => {
    switch (type) {
      case "tel":
        return {
          pattern: "[+]?[0-9]+",
          title: "Phone number (e.g., +1234567890)",
        };
      case "email":
        return {
          pattern: "[^@\\s]+@[^@\\s]+\\.[^@\\s]+",
          title: "Email address (e.g., user@example.com)",
        };
      case "number":
        return {
          min: min || "0",
          max: max || "100",
          step: "1",
        };
      default:
        return {};
    }
  };

  return (
    <div
      className={`flex flex-col md:flex-row items-start md:items-center md:space-x-2 space-y-2 md:space-y-0 ${className}`}
    >
      {label && (
        <label
          className={`text-sm font-bold justify-start text-left text-gray-700 md:text-left min-w-32 md:w-1/5 ${labelClassName}`}
        >
          {label}:{isRequired && <span className="text-red-500 ">*</span>}
        </label>
      )}
      <div className="relative w-full">
        <input
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          dir={dir}
          className={`w-full ${inputClassName} px-4 py-2 border ${
            error ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
          {...getInputProps()}
        />
        {icon && (
          <div className="absolute inset-y-0 flex items-center pl-2 pointer-events-none right-3">
            {icon}
          </div>
        )}
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default InputField;
