import React, { useEffect, useState } from "react";
import { LuTrash } from "react-icons/lu";
import { IoEyeOutline, IoCloseOutline } from "react-icons/io5";

const ImageInput = ({
  className = "",
  onImagesChange,
  imageData = [],
  disabled = false,
}) => {
  const [images, setImages] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    // Process initial imageData to handle both files and URLs
    const processImageData =
      imageData &&
      Array.isArray(imageData) &&
      imageData.map((item) => {
        if (typeof item === "string") {
          // Already a URL
          return { src: item };
        } else if (item instanceof File) {
          // File needs to be read as data URL
          const fileReader = new FileReader();
          fileReader.readAsDataURL(item);
          return new Promise((resolve) => {
            fileReader.onload = () =>
              resolve({ file: item, src: fileReader.result });
          });
        }
        return null;
      });

    Promise.all(processImageData).then((results) => {
      setImages(results.filter(Boolean)); // Filter out any nulls that might have been added accidentally
    });
  }, [imageData]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const newImages = [...images, { file, src: fileReader.result }];
      setImages(newImages);
      onImagesChange && onImagesChange(newImages);
    };
    fileReader.readAsDataURL(file);
  };

  const deleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    onImagesChange && onImagesChange(newImages);
  };

  const viewImage = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div
      className={`container flex flex-col md:flex-row space-y-2 md:space-y-0 items-start md:items-center md:space-x-2 mt-4 ${className}`}
    >
      <label className="text-sm font-bold text-left text-gray-700 md:text-right min-w-32 md:w-1/5">
        Upload photo:
      </label>
      <div className="grid w-full grid-cols-2 gap-2 md:grid-cols-5">
        {images &&
          Array.isArray(images) &&
          images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              className="items-center justify-center border w-28 h-28"
            >
              <div className="relative flex items-center justify-center w-full h-full">
                <img
                  src={image.src}
                  alt={`Uploaded ${index}`}
                  className="w-20 h-20 border rounded-md object-fit"
                />
                {!disabled && (
                  <div className="absolute flex items-center justify-center w-20 h-20 transition-opacity bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                    <button
                      onClick={() => viewImage(image.src)}
                      className="transition-colors rounded-md hover:bg-black hover:bg-opacity-50"
                      title="View Image"
                    >
                      <IoEyeOutline color="white" />
                    </button>
                    <button
                      onClick={() => deleteImage(index)}
                      className="transition-colors rounded-md hover:bg-black hover:bg-opacity-50"
                      title="Delete Image"
                    >
                      <LuTrash color="white" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        {!disabled && (
          <div className="flex items-center justify-center border-2 border-gray-300 border-dashed rounded-md w-28 h-28">
            <label className="flex flex-col items-center justify-center cursor-pointer">
              <span className="text-base leading-normal">+</span>
              <span className="text-base leading-normal">Upload</span>
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                disabled={disabled}
              />
            </label>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="relative max-w-2xl max-h-full p-4 overflow-auto bg-white rounded-lg">
            <img src={currentImage} alt="Enlarged view" />
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 mt-4 mr-4"
            >
              <IoCloseOutline
                className="w-6 h-6 text-white bg-black bg-opacity-50 rounded-full"
                title="Close Modal"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
