import React, { useEffect, useState } from "react";
import Button from "../../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import Table from "components/core/Table/TableComponent";
import renderStars from "utils/RenderStar";
import Modal from "components/core/Modal";
import ButtonGroup from "components/core/Button/ButtonGroup";
import TextArea from "components/core/textArea";
import RatingStar from "components/core/RatingStar";
import useReviews from "utils/hooks/useReviews";
import InputField from "components/core/inputField";
import SelectInput from "components/core/SelectInput";
import useLocations from "utils/hooks/useLocations";
import reviewsService from "services/reviews/reviewsService";
import { showError, showSuccess } from "utils/helpers";
import useUsers from "utils/hooks/useUsers";
import { ReviewAddSchema } from "utils/schemas/Reviews/ReviewSchemas";

function ReviewsAndRatings() {
  const { reviews, fetchReviews } = useReviews();
  const { fetchProfile } = useUsers();
  const { locations: locationData } = useLocations();
  const [reviewsData, setReviewsData] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState({});
  const [selectedButtonId, setSelectedButtonId] = useState(1);
  const [selectedLang, setSelectedLang] = useState("en");
  const [currentReview, setCurrentReview] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false);

  useEffect(() => {
    if (!reviews) return;
    setReviewsData(
      reviews.map((review) => {
        let fullStars = Math.floor(review.rating);
        let halfStar = review.rating % 1 !== 0;
        let emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
        return {
          dateCreated: new Date(review.createdAt)
            .toUTCString()
            .split(" ")
            .slice(1, 4)
            .join(" "),
          userName: review.name,
          reviews: `
          ${review.text.en ? review.text.en : ""} 
          ${review.text.ar && review.text.en ? "\b / \b" : ""}
          ${review.text.ar ? review.text.ar : ""}
          `,
          rating: renderStars({
            fullStars: fullStars,
            halfStar: halfStar,
            emptyStars: emptyStars,
            starColor: "text-[#DE0607]",
          }),
          ratingValue: review.rating,
          locationId: {
            value: review.locationId,
            label: review?.location?.name?.en || review?.location?.name?.ar,
          },
          userId: review.userId,
          id: review.id,
          location: review?.location?.name?.en || review?.location?.name?.ar,
          reviewEn: review.text.en ? review.text.en : "",
          reviewAr: review.text.ar ? review.text.ar : "",
        };
      })
    );
  }, [reviews]);

  const handleSaveReview = async () => {
    let data = {
      text: {
        en: selectedReview.reviewEn,
        ar: selectedReview.reviewAr,
      },
      rating: selectedReview.ratingValue,
      locationId: selectedReview.locationId.value,
      userId: selectedReview.userId,
      name: selectedReview.userName,
    };

    try {
      await ReviewAddSchema.validate(data).then(async () => {
        const response = await reviewsService.updateReview(
          selectedReview.id,
          data
        );
        switch (response.statusCode) {
          case 200:
            showSuccess(response.message);
            setIsEditOpen(false);
            setSelectedReview({
              reviewEn: "",
              reviewAr: "",
              ratingValue: 0,
              locationId: null,
              userName: "",
            });
            fetchReviews();
            break;
          default:
            showError(response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const handeAddReview = async () => {
    let profileResponse = await fetchProfile();
    let data = {
      text: {
        en: selectedReview.reviewEn,
        ar: selectedReview.reviewAr,
      },
      rating: selectedReview.ratingValue,
      locationId: selectedReview.locationId?.value || null,
      userId: selectedReview.userId
        ? selectedReview.userId
        : profileResponse.id,
      name: selectedReview.userName,
    };
    try {
      await ReviewAddSchema.validate(data).then(async () => {
        const response = await reviewsService.addReview(data);
        switch (response.statusCode) {
          case 201:
            showSuccess(response.message);
            setIsAddOpen(false);
            setSelectedReview({
              reviewEn: "",
              reviewAr: "",
              ratingValue: 0,
              locationId: null,
              userName: "",
            });
            fetchReviews();
            break;
          default:
            showError(response.response.data.message || response.message);
            break;
        }
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      const response = await reviewsService.deleteReview(id);
      switch (response.statusCode) {
        case 400:
          showError(response.message);
          break;
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess("Review deleted successfully");
          setReviewsData(reviewsData.filter((review) => review.id !== id));
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSelectedButton = async (id) => {
    setSelectedButtonId(id);
    if (id == 1) {
      setSelectedLang("en");
      setCurrentReview(selectedReview?.reviews?.en);
    } else {
      setSelectedLang("ar");
      setCurrentReview(selectedReview?.reviews?.ar);
    }
  };

  const renderEditModal = ({
    isEditOpen,
    setIsEditOpen,
    selectedReview,
    setSelectedReview,
    selectedButtonId,
    handleSelectedButton,
    handleSaveReview,
  }) => {
    return (
      <Modal
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        headerText={"Edit Review and Rating"}
        onClose={() => {
          setSelectedReview({
            reviewEn: "",
            reviewAr: "",
            ratingValue: 0,
            locationId: null,
            userName: "",
          });
          setIsEditOpen(false);
        }}
      >
        <div className="min-w-[500px]">
          <div className="flex justify-end w-full mt-4">
            <ButtonGroup
              setSelectedButton={(id) => handleSelectedButton(id)}
              selectedButton={selectedButtonId}
              buttons={[
                {
                  id: 1,
                  label: "En",
                },
                {
                  id: 2,
                  label: "Ar",
                },
              ]}
            />
          </div>

          {/* Review */}
          <div className="mt-3">
            <p className="text-12 font-bold text-[#00000073]">Review</p>
            <TextArea
              value={
                selectedButtonId === 1
                  ? selectedReview?.reviewEn || ""
                  : selectedReview?.reviewAr || ""
              }
              onChange={(e) => {
                setSelectedReview({
                  ...selectedReview,
                  reviewEn:
                    selectedButtonId === 1
                      ? e.target.value
                      : selectedReview.reviewEn,
                  reviewAr:
                    selectedButtonId === 2
                      ? e.target.value
                      : selectedReview.reviewAr,
                });
              }}
              placeholder="Enter Review"
              isMaxLength={false}
              className="md:space-x-0"
              fieldHeight="260px"
            />
          </div>

          {/* Rating */}
          <div className="flex items-center justify-start mt-5 space-x-2">
            <p className="text-12 font-bold text-[#00000073]">Edit Rating</p>
            <RatingStar
              starCount={5}
              filledCount={selectedReview?.ratingValue}
              updateRatingFunction={(value) => {
                setSelectedReview({
                  ...selectedReview,
                  ratingValue: value,
                });
              }}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-5">
            <Button
              label={"Cancel"}
              className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
              textColor="text-black"
              onClick={() => {
                setIsEditOpen(false);
                setSelectedReview({
                  reviewEn: "",
                  reviewAr: "",
                  ratingValue: 0,
                  locationId: null,
                  userName: "",
                });
              }}
            />
            <Button
              label={"Save"}
              className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
              textColor="text-white"
              onClick={handleSaveReview}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderAddModal = ({
    isAddOpen,
    setIsAddOpen,
    selectedReview,
    setSelectedReview,
    selectedButtonId,
    selectedLang,
    locationData,
    handleSelectedButton,
    handeAddReview,
  }) => {
    return (
      <Modal
        isOpen={isAddOpen}
        setIsOpen={setIsAddOpen}
        headerText={"Add Review and Rating"}
        onClose={() => {
          setSelectedReview({
            reviewEn: "",
            reviewAr: "",
            ratingValue: 0,
            locationId: null,
            userName: "",
          });
          setIsAddOpen(false);
        }}
      >
        <div className="min-w-[500px]">
          <div className="flex justify-end w-full mt-4">
            <ButtonGroup
              setSelectedButton={(id) => handleSelectedButton(id)}
              selectedButton={selectedButtonId}
              buttons={[
                {
                  id: 1,
                  label: "En",
                },
                {
                  id: 2,
                  label: "Ar",
                },
              ]}
            />
          </div>

          {/* Name */}
          <div className="flex items-center justify-between w-full mt-3">
            <p className="text-12 font-bold min-w-32 md:w-1/5 text-[#00000073]">
              Name:
            </p>
            <InputField
              placeholder="Enter Name"
              value={selectedReview?.userName}
              onChange={(e) => {
                setSelectedReview({
                  ...selectedReview,
                  userName: e.target.value,
                });
              }}
              className="w-full mt-3"
              type="text"
            />
          </div>

          {/* Location */}
          <div className="flex items-center justify-between w-full mt-3">
            <p className="text-12 font-bold min-w-32 md:w-1/5 text-[#00000073]">
              Location
            </p>
            <SelectInput
              placeholder="Select a location"
              selected={selectedReview?.locationId}
              onSelect={(selectedOption) => {
                setSelectedReview({
                  ...selectedReview,
                  locationId: selectedOption,
                });
              }}
              options={locationData.map((location) => ({
                value: location.id,
                label: location.name?.[selectedLang],
              }))}
              className="w-full mt-3"
            />
          </div>

          {/* Review */}
          <div className="mt-3">
            <p className="text-12 font-bold text-[#00000073]">Review</p>
            <TextArea
              value={
                selectedButtonId === 1
                  ? selectedReview.reviewEn || ""
                  : selectedReview.reviewAr || ""
              }
              onChange={(e) => {
                setSelectedReview({
                  ...selectedReview,
                  reviewEn:
                    selectedButtonId === 1
                      ? e.target.value
                      : selectedReview.reviewEn,
                  reviewAr:
                    selectedButtonId === 2
                      ? e.target.value
                      : selectedReview.reviewAr,
                });
              }}
              placeholder="Enter Review"
              isMaxLength={false}
              className="md:space-x-0"
              fieldHeight="260px"
            />
          </div>

          {/* Rating */}
          <div className="flex items-center justify-start mt-5 space-x-2">
            <p className="text-12 font-bold text-[#00000073]">Rating</p>
            <RatingStar
              starCount={5}
              filledCount={selectedReview?.ratingValue}
              updateRatingFunction={(value) => {
                setSelectedReview({
                  ...selectedReview,
                  ratingValue: value,
                });
              }}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-5">
            <Button
              label={"Cancel"}
              className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
              textColor="text-black"
              onClick={() => {
                setIsAddOpen(false);
                setSelectedReview({
                  reviewEn: "",
                  reviewAr: "",
                  ratingValue: 0,
                  locationId: null,
                  userName: "",
                });
              }}
            />
            <Button
              label={"Save"}
              className="w-40 ml-4 bg-[#DE0607] rounded-xl text-white"
              textColor="text-white"
              onClick={handeAddReview}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <div
          style={{ justifyContent: "end" }}
          className="flex items-center justify-between w-full p-4"
        >
          <Button
            label="Add New"
            icon={<HiOutlinePlusSm />}
            onClick={() => {
              setIsAddOpen(true);
            }}
          />
        </div>
        <Table
          data={reviewsData}
          headers={[
            {
              key: "dateCreated",
              value: "Date created",
            },
            {
              key: "userName",
              value: "User Name",
            },
            {
              key: "location",
              value: "Location",
            },
            {
              key: "reviews",
              value: "Reviews",
            },
            {
              key: "rating",
              value: "Rating",
            },
          ]}
          numberOfRows={5}
          setData={setReviewsData}
          usePopover
          popoverActions={{
            onEdit: (item, index) => {
              setSelectedReview(item);
              setIsEditOpen(true);
            },
            onDelete: (item, index) => {
              setSelectedReview(item);
              setDeleteItemId(item.id);
              setDeleteConfirmModalVisible(true);
            },
          }}
        />
      </div>

      {isEditOpen &&
        renderEditModal({
          isEditOpen,
          setIsEditOpen,
          selectedReview,
          setSelectedReview,
          selectedButtonId,
          selectedLang,
          handleSelectedButton,
          handleSaveReview,
        })}
      {isAddOpen &&
        renderAddModal({
          isAddOpen,
          setIsAddOpen,
          selectedReview,
          setSelectedReview,
          selectedButtonId,
          setSelectedButtonId,
          selectedLang,
          setSelectedLang,
          locationData,
          handleSelectedButton,
          handeAddReview,
        })}
        <Modal
        isOpen={deleteConfirmModalVisible}
        onClose={() => {
          setDeleteConfirmModalVisible(false);
        }}
        headerText={"Delete Review"}
      >
        <div className="p-5">
          <h2 className="text-16 text-[#000000D9] font-medium">
            Are you sure you want to delete this review?
          </h2>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              className="px-4 py-2 text-black rounded hover:bg-red-600"
              style={{borderWidth:1, borderColor:'#DC2626'}}
              onClick={() => setDeleteConfirmModalVisible(false)}
              variant="outline"
            >
              <p>Cancel</p>
            </button>
            <button 
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400"
              onClick={()=>{ handleDeleteReview(deleteItemId); setDeleteConfirmModalVisible(false);}} 
            >
              <p>Delete</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ReviewsAndRatings;
