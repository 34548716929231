import { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";

const LocationListItem = ({ location, isSelected, onSelect, locationData }) => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (locationData && locationData.days.length > 0) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [locationData]);
  return (
    <div
      className={`flex items-center justify-between p-4 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
    >
      <div className="flex items-center">
        <input
          type="checkbox"
          className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
          checked={isEnabled}
          style={{ accentColor: "#DE0607" }}
          onClick={() => {
            setIsEnabled(!isEnabled);
            onSelect();
          }}
          onChange={() => {}}
        />
        <span
          className={`ml-5 text-14 font-medium ${
            isSelected ? "text-[#DE0607]" : "text-[#000000D9]"
          }`}
        >
          {location.name.en ?? ""} {location.name.en ? "/" : ""}
          {location.name.ar ?? ""}
        </span>
      </div>

      {/* Actions */}
      <div className="flex flex-row">
        <div className="h-6 mx-4 border-r border-gray-300" />
        {isSelected ? (
          <IoIosArrowForward className="w-6 h-6 text-[#DE0607] cursor-pointer" />
        ) : (
          <HiDotsVertical
            className="w-6 h-6 text-black cursor-pointer"
            onClick={onSelect}
          />
        )}
      </div>
    </div>
  );
};

export default LocationListItem;
