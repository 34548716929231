import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBusinessId } from "../../redux/auth/authSlice";
import servicesService from "services/service/serviceServices";
import subserviceServices from "services/service/subserviceServices";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";

const useServices = () => {
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [businessServices, setBusinessServices] = useState([]);
  const [businessSubservices, setBusinessSubservices] = useState([]);
  const businessId = useSelector(selectBusinessId);

  const fetchSubservice = async (serviceId) => {
    try {
      const response = await subserviceServices.getSubservice(serviceId);
      switch (response.statusCode) {
        case 200:
          return response.subservice;
        default:
          return [];
      }
    } catch (error) {
      return [];
    }
  };

  const fetchChildSubservice = async (serviceId) => {
    try {
      const response = await childSubserviceServices.getSubservice(serviceId);
      switch (response.statusCode) {
        case 200:
          return response.subservice;
        default:
          return [];
      }
    } catch (error) {
      return [];
    }
  };

  const fetchService = async (serviceId) => {
    try {
      const response = await servicesService.getService(serviceId);
      switch (response.statusCode) {
        case 200:
          return response.service;
        default:
          return [];
      }
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await servicesService.getServices();
        switch (response.statusCode) {
          case 200:
            setServices(response.services);
            break;
          default:
            console.error("No services found");
            setServices([]);
            break;
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    const fetchBusinessServices = async (businessId) => {
      try {
        const response = await servicesService.getBusinessServices(businessId);
        switch (response.statusCode) {
          case 200:
            setBusinessServices(response.services);
            break;
          default:
            console.error("No business services found");
            setBusinessServices([]);
            break;
        }
      } catch (error) {
        console.error("Error fetching business services:", error);
      }
    };

    const fetchBusinessSubservices = async (businessId) => {
      try {
        const response = await subserviceServices.getBusinessSubservices(
          businessId
        );
        switch (response.statusCode) {
          case 200:
            setBusinessSubservices(response.subservices);
            break;
          default:
            console.error("No business subservices found");
            setBusinessSubservices([]);
            break;
        }
      } catch (error) {
        console.error("Error fetching business subservices:", error);
      }
    };

    const fetchSubservices = async () => {
      try {
        const response = await subserviceServices.getSubservices();
        switch (response.statusCode) {
          case 200:
            setSubservices(response.subservices);
            break;
          default:
            console.error("No subservices found");
            setSubservices([]);
            break;
        }
      } catch (error) {
        console.error("Error fetching subservices:", error);
      }
    };
    fetchServices();
    fetchSubservices();
    businessId && fetchBusinessServices(businessId);
    businessId && fetchBusinessSubservices(businessId);
  }, []);

  return {
    services,
    subservices,
    fetchSubservice,
    fetchChildSubservice,
    businessServices,
    businessSubservices,
    fetchService,
  };
};

export default useServices;
