import React, { useEffect, useMemo, useState } from "react";
import Overview from "./Overview";
import Availability from "./Availability";
import Reviews from "./Reviews";
import { useNavigate, useParams } from "react-router-dom";
import locationService from "services/location/locationService";
import { showError } from "utils/helpers";
import Button from "components/core/Button";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";

const EditListing = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [location, setLocation] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchListing = async (id) => {
    setLoading(true);
    try {
      const res = await locationService.getLocation(id);
      switch (res.statusCode) {
        case 200:
          setBusinessId(res.location.businessId);
          setLocation(res.location);
          setAvailability(res.location.availability?.days);
          setReviews(res.location.reviews);
          setLoading(false);
          break;
        default:
          showError(res.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListing(id);
  }, [id]);

  const tabs = useMemo(
    () => [
      {
        name: "Overview",
        component: (
          <Overview locationData={location} setLocationData={setLocation} />
        ),
      },
      {
        name: "Availability",
        component: (
          <Availability
            availabilityData={availability}
            setAvailabilityData={setAvailability}
            businessId={businessId}
          />
        ),
      },
      {
        name: "Rating and Reviews",
        component: (
          <Reviews
            reviews={reviews}
            setReviews={setReviews}
            refreshReviews={() => fetchListing(id)}
          />
        ),
      },
    ],
    [location, availability, reviews]
  );

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="h-full mt-4 overflow-y-scroll bg-white rounded-md hide-scrollbar">
        <div className="flex p-5 space-x-4 bg-white border-b ">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`p-2 ${
                activeTab === tab.name
                  ? " text-[#DE0607] border-b border-[#DE0607]"
                  : "text-[#000000d9]"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="p-5">
          {tabs.find((tab) => tab.name === activeTab)?.component}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default EditListing;
