import React, { useEffect, useState } from "react";
import { LuTrash } from "react-icons/lu";
import { IoEyeOutline, IoCloseOutline } from "react-icons/io5";
import { showError } from "utils/helpers";

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB

const ImageInput = ({
  className = "",
  showLabel = true,
  value,
  onUpload,
  imageSize,
  isRounded,
  onDeleted,
  supportedFormats = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"],
}) => {
  const [image, setImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    setImage(value);
  }, [value]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!supportedFormats.includes(file.type)) {
        showError(`Supported Image formats: ${supportedFormats.join(", ")}`);
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        showError("File size must be less than 3MB");
        return;
      }
      setImage(file);
      onUpload(file);
    }
  };

  const deleteImage = () => {
    setImage("");
    onDeleted && onDeleted();
  };

  const viewImage = (file) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentImage(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
    } else {
      setCurrentImage(file);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div
      className={`container flex flex-col md:flex-row space-y-2 md:space-y-0 items-start md:items-center md:space-x-2 ${className}`}
    >
      {showLabel && (
        <label className="text-sm font-bold text-left text-gray-700 md:text-right min-w-32 md:w-1/5">
          Upload photo:
        </label>
      )}
      <div className="flex w-full">
        {image && (
          <div className={`items-center justify-center border ${className}`}>
            <div className="relative flex items-center justify-center w-full h-full">
              <img
                src={image instanceof File ? URL.createObjectURL(image) : image}
                alt="Uploaded Image"
                className={`object-fit ${imageSize} ${
                  isRounded ? "rounded-full" : "rounded-md"
                }`}
              />
              <div
                className={`absolute flex items-center rounded-md justify-center w-full h-full transition-opacity bg-black bg-opacity-50 opacity-0 hover:opacity-100`}
              >
                <button
                  onClick={() => viewImage(image)}
                  className="transition-colors rounded-md hover:bg-black hover:bg-opacity-50"
                  title="View Image"
                >
                  <IoEyeOutline color="white" />
                </button>
                <button
                  onClick={() => deleteImage()}
                  className="transition-colors rounded-md hover:bg-black hover:bg-opacity-50"
                  title="Delete Image"
                >
                  <LuTrash color="white" />
                </button>
              </div>
            </div>
          </div>
        )}

        {!image && (
          <div
            className={`flex items-center justify-center border-2 border-gray-300 border-dashed ${
              isRounded ? "rounded-full" : "rounded-md"
            } ${className}`}
          >
            <label className="flex flex-col items-center justify-center cursor-pointer">
              <span className="text-base leading-normal">+</span>
              <span className="text-base leading-normal">Upload</span>
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
              />
            </label>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="relative p-4 bg-white rounded-lg w-52 h-52">
            <img
              src={currentImage}
              alt="Enlarged view"
              className="block w-full h-full"
            />
            <button onClick={closeModal} className="absolute top-0 right-0 ">
              <IoCloseOutline
                className="w-6 h-6 text-black"
                title="Close Modal"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
