import { useEffect, useState } from "react";
import categoryService from "../../services/category/categoryService";
import { showError } from "../helpers";
import subcategoryServices from "services/subcategory/subcategoryServices";

const useCategory = () => {
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [fetchedSubcategories, setFetchedSubcategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await categoryService.getCategories();
        switch (data.statusCode) {
          case 200:
            setFetchedCategories(data.categories);
            break;
          default:
            showError(data.message);
            setFetchedCategories([]);
            break;
        }
      } catch (error) {
        showError(error.message);
      }
    };

    const fetchSubcategories = async () => {
      try {
        const data = await subcategoryServices.getSubcategories();
        switch (data.statusCode) {
          case 200:
            setFetchedSubcategories(data.subcategories);
            break;
          default:
            showError(data.message);
            setFetchedSubcategories([]);
            break;
        }
      } catch (error) {
        showError(error.message);
      }
    };

    fetchData();
    fetchSubcategories();
  }, []);

  return { fetchedCategories, fetchedSubcategories };
};

export default useCategory;
