import React, { useEffect, useState } from "react";
import usersService from "services/users/usersService";
import photoService from "services/photoService";
import { showError, showSuccess } from "utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import ImageInput from "components/core/ImageInput";
import InputField from "components/core/inputField";
import Button from "components/core/Button";
import SelectInput from "components/core/SelectInput";
import useLocations from "utils/hooks/useLocations";
import locationManagerService from "services/locationManager/locationManagerService";
import { locationManagerEditSchema } from "utils/schemas/validationSchemas";
import { useSelector } from "react-redux";
import { selectBusinessId, selectRole } from "../../redux/auth/authSlice";

const EditLM = () => {
  const { fetchVendorLocations } = useLocations();
  const [locations, setLocations] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [assignedLocations, setAssignedLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const businessId = useSelector(selectBusinessId);
  const userRole = useSelector(selectRole);

  const handleSave = async () => {
    setLoading(true);

    try {
      const newData = {
        name,
        avatarPath: selectedImage,
        locationIds: assignedLocations.map((location) => location.value),
        businessId: businessId,
      };
      await locationManagerEditSchema.validate(newData);
      const response = await locationManagerService.updateLocationManager(
        id,
        newData
      );
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setLoading(false);
          navigate("/location-manager");
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };

  const fetchLocationManager = async (id) => {
    try {
      const response = await locationManagerService.getLocationManager(id);
      switch (response.statusCode) {
        case 200:
          setName(response.locationManager.name);
          setEmail(response.locationManager.email);
          setPhone(response.locationManager.phone);
          setSelectedImage(response.locationManager.avatarPath);
          setAssignedLocations(
            response.locationManager.managedLocations.map((location) => ({
              value: location.id,
              label: location.name.en,
            }))
          );
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    id && fetchLocationManager(id);
    businessId &&
      fetchVendorLocations(businessId).then((res) => {
        setLocations(res);
      });
  }, [id, businessId]);

  return (
    <div className="flex flex-col w-full h-full p-4 my-4 overflow-hidden bg-white rounded-md ">
      <div className="flex items-center justify-center w-32 h-32 ">
        <ImageInput
          value={selectedImage}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          imageSize={"w-32 h-32"}
          className="w-32 h-32 bg-gray-100 border-0 rounded-full"
          isRounded
          supportedFormats={["image/jpg", "image/png", "image/svg+xml"]}
        />
      </div>
      <div className="flex flex-col mt-4 space-y-4 max-w-[500px]   h-full">
        <InputField
          label="Name"
          placeholder="Enter name"
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <InputField
          label="Email"
          placeholder="Enter email"
          value={email}
          type="email"
          disabled
        />
        <InputField
          label="Phone Number"
          placeholder="Enter phone"
          value={phone}
          type="tel"
          disabled
        />
        <SelectInput
          label="Assign Location"
          options={locations.map((location) => ({
            value: location.id,
            label: location.name.en,
          }))}
          selected={assignedLocations}
          onSelect={(value) => {
            setAssignedLocations(value);
          }}
          isMulti
          isDisabled={userRole === "LOCATION_MANAGER"}
        />

        <div className="flex items-center justify-end w-full h-20 space-x-4">
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={() => navigate("/location-manager")}
          />
          <Button
            label="Save"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={handleSave}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default EditLM;
