import * as Yup from "yup";
import {
  invalidMessage,
  requiredMessage,
  incorrectMessage,
  emailRegex,
  phoneRegex,
} from "../schemaUtils";

export const VendorAddSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[A-Za-z\s]+$/,
      incorrectMessage("Business Name. Only letters allowed.")
    )
    .required(requiredMessage("Business Name")),
  email: Yup.string()
    .matches(emailRegex, invalidMessage("Email"))
    .required(requiredMessage("Email")),
  phone: Yup.array()
    .of(Yup.string().matches(phoneRegex, invalidMessage("Phone number")))
    .required(requiredMessage("Phone number")),
});

export const VendorEditSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[A-Za-z\u0600-\u06FF0-9^$*.[\]{}()?\\/"!@#%&/,><':;|_~`+= -]+$/,
      incorrectMessage("Business Name. Only specific characters allowed.")
    )
    .min(1, "Business Name must be at least 1 character")
    .max(75, "Business Name cannot exceed 75 characters")
    .required(requiredMessage("Business Name")),
  // email: Yup.string()
  //   .matches(emailRegex, invalidMessage("Email"))
  //   .required(requiredMessage("Email")),
  phone: Yup.array()
    .of(
      Yup.string().matches(
        phoneRegex,
        invalidMessage(
          "Phone number. Must be a valid phone number format (e.g. +96512345678)"
        )
      )
    )
    .required(requiredMessage("Phone number")),
  website: Yup.string().url(
    invalidMessage(
      "Website URL. Must be a valid URL format (e.g. https://www.example.com)"
    )
  ),
});
