import React from "react";
import HandClap from "assets/Icons/handclap.png";

const Header = ({ mainText, subText }) => {
  return (
    <div className="flex flex-col justify-start items-start mt-4 py-4  pl-3.5 bg-white rounded-md ">
      <div className="flex items-center ">
        <p className="text-20 flex leading-7 font-medium capitalize text-[#000000D9]">
          {mainText}
          {mainText === "bookings" && (
            <img src={HandClap} alt="Hand Clap" className="w-6 h-6 ml-2" />
          )}
        </p>
        {subText && (
          <p className="text-14 leading-6 mx-2 font-normal capitalize text-[#00000073]">
            /
          </p>
        )}
        {subText && (
          <p className="text-20 leading-6 font-medium capitalize text-[#000000D9]">
            {subText}
          </p>
        )}
      </div>
      {mainText === "bookings" && (
        <p className="text-12 leading-[14px] font-normal capitalize text-[#00000073]">
          Let's check your business today
        </p>
      )}
    </div>
  );
};

export default Header;
