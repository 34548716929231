import React, { useEffect, useState, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";

import EditModal from "../../../../EditModal/EditModal";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { MdBlockFlipped } from "react-icons/md";
import businessService from "services/business/businessService";
import { showInfo, showSuccess } from "utils/helpers";
import Modal from "components/core/Modal";

const VendorTableList = ({
  headers,
  data,
  numberOfRows,
  setData,
  setIsModalOpen,
  setModalPosition,
  modalPosition,
  isModalOpen,
  setIsApproveOpen,
  setSelectedItem,
  fetchVendors,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [lastRowIndex, setLastRowIndex] = useState();
  const [firstRowIndex, setFirstRowIndex] = useState();
  const [isOpen, setIsOpen] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [modalIndex, setModalIndex] = useState(null);
  const buttonRefs = useRef([]);
  const navigate = useNavigate();

  const handleClick = async (status, item) => {
    if (status === "approve") {
      setIsApproveOpen(true);
      setIsModalOpen(false);
      setModalPosition({ top: 0, left: 0 });
    }
    if (status === "reject") {
      const response = await businessService.updateBusinessStatus(item.id, {
        status: "BLOCKED",
      });
      switch (response.statusCode) {
        case 400:
          showInfo(response.message);
          break;
        case 404:
          showInfo(response.message);
          break;
        default:
          showSuccess(response.message);
          fetchVendors();
          setIsModalOpen(false);
          break;
      }
      return;
    }
    if (status === "edit") {
      setSelectedItem(item);
      navigate("/vendorsInfo", {
        state: {
          tab: "Edit",
          selectedVendor: item,
        },
      });
      setIsModalOpen(false);
      setModalPosition({ top: 0, left: 0 });
    }
    if (status === "blocked") {
      const response = await businessService.updateBusinessStatus(item.id, {
        status: "BLOCKED",
      });
      fetchVendors();
      setIsModalOpen(false);
      return;
    }
    if (status === "unblocked") {
      const response = await businessService.updateBusinessStatus(item.id, {
        status: "PENDING",
      });
      switch (response.statusCode) {
        case 400:
          showInfo(response.message);
          break;
        case 404:
          showInfo(response.message);
          break;
        default:
          showSuccess(response.message);
          fetchVendors();
          setIsModalOpen(false);
          break;
      }
      return;
    }
    if (status === "delete") {
      if (item.status === "BLOCKED") {
        showInfo("User already blocked. Cannot delete.");
        setIsModalOpen(false);
        return;
      }
      const response = await businessService.updateBusinessStatus(item.id, {
        status: "BLOCKED",
      });
      switch (response.statusCode) {
        case 400:
          showInfo("Business already blocked.");
          setIsModalOpen(false);
          break;
        case 404:
          showInfo(response.message);
          break;
        default:
          showSuccess(response.message);
          fetchVendors();
          setIsModalOpen(false);
          break;
      }
      return;
    }
  };

  const EditModalChildren = ({ isOpen, closeModal, status, item }) => (
    <EditModal
      isOpen={isOpen}
      closeModal={closeModal}
      modalPosition={modalPosition}
    >
      <div
        className="shadow-around bg-white py-[4px] rounded-[6px] box-shadow;"
        style={{
          top: modalPosition.top,
          left: modalPosition.left,
          width: modalWidth,
        }}
      >
        <div className="shadow-around bg-white py-[4px] rounded-[6px] box-shadow;">
          <div className="py-[5px] pl-[16px] text-base leading-6 font-medium">
            Action
          </div>
          {status === "PENDING" && (
            <div>
              <div
                role="button"
                onClick={() => handleClick("approve", item)}
                className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 flex flex-row items-center justify-between"
              >
                Approve
                <AiOutlinePlusCircle className="w-4 h-4 mr-4 text-[#00000073]" />
              </div>
            </div>
          )}
          {status === "PENDING" && (
            <div>
              <div
                role="button"
                onClick={() => handleClick("reject", item)}
                className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 flex flex-row items-center justify-between"
              >
                Reject
                <AiOutlineMinusCircle className="w-4 h-4 mr-4 text-[#00000073]" />
              </div>
            </div>
          )}
          {status === "APPROVED" && (
            <div>
              <div
                role="button"
                onClick={() => handleClick("edit", item)}
                className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 flex flex-row items-center justify-between"
              >
                Edit
                <AiOutlineEdit className="w-4 h-4 mr-4 text-[#00000073]" />
              </div>
            </div>
          )}
          {status === "BLOCKED" && (
            <div>
              <div
                role="button"
                onClick={() => handleClick("unblocked", item)}
                className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 flex flex-row items-center justify-between"
              >
                Unblock
                <AiOutlinePlusCircle className="w-4 h-4 mr-4 text-[#00000073]" />
              </div>
            </div>
          )}
          {status === "APPROVED" && (
            <div>
              <div
                role="button"
                onClick={() => handleClick("blocked", item)}
                className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 flex flex-row items-center justify-between"
              >
                Block
                <MdBlockFlipped className="w-4 h-4 mr-4 text-[#00000073]" />
              </div>
            </div>
          )}
          <div>
            <div
              role="button"
              onClick={() => { setDeleteItem(item); setIsOpen(true);}}
              className="my-[2px] py-[4px] ml-[16px] mr-[8px] font-normal text-sm leading-22 text-[#FF4D4F] flex flex-row items-center justify-between"
            >
              Delete
              <AiOutlineDelete className="w-4 h-4 mr-4 text-[#DE0607]" />
            </div>
          </div>
        </div>
      </div>
    </EditModal>
  );

  useEffect(() => {
    setRowsPerPage(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    const currentData = data.slice(first, last);
    setCurrentData(currentData);
  }, [currentPage, rowsPerPage, data]);

  useEffect(() => {
    buttonRefs.current = currentData.map(
      (_, index) => buttonRefs.current[index] ?? createRef()
    );
  }, [currentData]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }
  const modalWidth = 236;

  const openOptionsModal = (index) => {
    if (buttonRefs.current[index] && buttonRefs.current[index].current) {
      if (isModalOpen && modalIndex === index) {
        setIsModalOpen(false);
        setModalIndex(null);
        setModalPosition({ top: 0, left: 0 });
        return;
      }

      const buttonRect =
        buttonRefs.current[index].current.getBoundingClientRect();
      const modalHeight = 100; // Adjust this to the actual height of your modal
      const modalWidth = 236; // Adjust this to the actual width of your modal

      let adjustedTop = buttonRect.top;
      let adjustedLeft = buttonRect.left - modalWidth;

      // Check if there is enough space below the button
      if (window.innerHeight - buttonRect.bottom < modalHeight) {
        // If not enough space below, position the modal above the button
        adjustedTop = buttonRect.top - modalHeight;
      }

      setModalPosition({ top: adjustedTop, left: adjustedLeft });
      setModalIndex(index);
      setIsModalOpen(true);
    }
  };

  const deleteVendors = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const handleResizeOrScroll = () => {
      if (
        isModalOpen &&
        modalIndex !== null &&
        buttonRefs.current[modalIndex]
      ) {
        const buttonRect =
          buttonRefs.current[modalIndex].current.getBoundingClientRect();
        const modalHeight = 100; // Adjust this to the actual height of your modal
        const modalWidth = 236; // Adjust this to the actual width of your modal

        let adjustedTop = buttonRect.top;
        let adjustedLeft = buttonRect.left - modalWidth;

        // Check if there is enough space below the button
        if (window.innerHeight - buttonRect.bottom < modalHeight) {
          // If not enough space below, position the modal above the button
          adjustedTop = buttonRect.top - modalHeight;
        }

        setModalPosition({ top: adjustedTop, left: adjustedLeft });
      }
    };

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
  }, [isModalOpen, modalIndex]);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  return (
    <div className="bg-white">
      <table className="w-full leading-normal">
        <thead>
          <tr>
            {headers.map((header, index) => {
              let additionalClass = "";
              if (header.toLowerCase() === "status") {
                additionalClass = "status-header";
              }
              return (
                <th
                  key={index}
                  className={`relative px-3 py-3 text-14 font-medium leading-22 tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA] ${additionalClass}`}
                >
                  <div
                    className="absolute inset-y-0 right-0 w-0.5 bg-[#0000000F]"
                    style={{ height: "47%", top: "26.5%", opacity: 1 }}
                  />
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => {
            if (!buttonRefs.current[index]) {
              buttonRefs.current[index] = React.createRef();
            }
            return (
              <tr key={index}>
                {Object.keys(item)
                  .filter((key) => key !== "id")
                  .filter((key) => key !== "email")
                  .filter((key) => key !== "phoneNumber")
                  .filter((key) => key !== "admin")
                  .filter((key) => key !== "categoryId")
                  .filter((key) => key !== "website")
                  .filter((key) => key !== "slug")
                  .map((key, idx) => {
                    let tdStyle =
                      "px-[12.5px] py-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200";
                    let divStyle =
                      "text-12 border px-[8px] py-[3.5px] rounded-[4px]";
                    let text = "";

                    if (key === "status") {
                      if (item[key] === "APPROVED") {
                        divStyle += " border-[#B7EB8F] text-[#52C41A]";
                        text = "Approved";
                      } else if (item[key] === "BLOCKED") {
                        divStyle += " border-[#FFA39E] text-[#F5222D]";
                        text = "Blocked";
                      } else if (item[key] === "PENDING") {
                        divStyle += " border-[#ADC6FF] text-[#2F54EB]";
                        text = "Pending approval";
                      }
                    }

                    if (key === "status") {
                      return (
                        <td className={tdStyle}>
                          <div className="flex justify-center">
                            <div className={divStyle}>{text}</div>
                          </div>
                        </td>
                      );
                    } else
                      return (
                        <td key={idx} className={tdStyle}>
                          {item[key]}
                        </td>
                      );
                  })}
                <td className="text-sm bg-white border-b border-gray-200">
                  <div className="button-container">
                    <button
                      ref={buttonRefs.current[index]}
                      className="flex items-center justify-center w-full h-full text-red-500 hover:text-red-700"
                      onClick={() => {
                        openOptionsModal(index);
                        setSelectedItem(currentData[index]);
                      }}
                    >
                      <BsThreeDotsVertical size={20} color="#000000D9" />
                    </button>
                    {isModalOpen && modalIndex === index && (
                      <EditModalChildren
                        item={currentData[index]}
                        isOpen={isModalOpen}
                        closeModal={() => {
                          setIsModalOpen(false);
                          setModalIndex(null);
                        }}
                        status={item.status}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>

        <div className="inline-block">
          <select
            className=" w-full h-8 form-select text-14 text-[#000000D9] leading-6 border rounded-md border-[#D9D9D9] flex justify-center items-center"
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}/page
              </option>
            ))}
          </select>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        headerText={"Delete Vendor"}
      >
        <div className="p-5">
          <h2 className="text-16 text-[#000000D9] font-medium">
            Are you sure you want to delete this vendor?
          </h2>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              className="px-4 py-2 text-black rounded hover:bg-red-600"
              style={{borderWidth:1, borderColor:'#DC2626'}}
              onClick={() => setIsOpen(false)}
              variant="outline"
            >
              <p>Cancel</p>
            </button>
            <button 
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400"
              onClick={()=>{handleClick("delete", deleteItem); setIsOpen(false)}} 
            >
              <p>Delete</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VendorTableList;
