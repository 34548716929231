import React, { useRef, useEffect } from "react";
import {
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  FloatingFocusManager,
} from "@floating-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiPencilLine, RiDeleteBin6Line } from "react-icons/ri";
import { arrow } from "@floating-ui/dom";
import { AiOutlineCheckCircle } from "react-icons/ai";

function PopoverThreeDot({
  isOpen,
  setIsOpen,
  handleOptionsModal,
  index,
  onEdit,
  onDelete,
  status,
}) {
  const arrowRef = useRef(document.querySelector("#arrow"));

  const {
    x,
    y,
    strategy,
    refs,
    update,
    context,
    styles,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({ crossAxis: -130, mainAxis: -25 }),
      flip({
        fallbackPlacements: ["right", "top", "left"],
        fallbackStrategy: "initialPlacement",
        flipAlignment: false,
      }),
      shift({ padding: 8 }),
      arrow({ element: document.querySelector("#arrow") }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[placement.split("-")[0]];

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
    useDismiss(context),
  ]);

  return (
    <>
      <button
        {...getReferenceProps({ ref: refs.setReference })}
        className="flex items-center justify-center w-full h-full text-red-500 hover:text-red-700"
        onClick={(e) => {
          handleOptionsModal(index);
          e.stopPropagation();
        }}
      >
        <BsThreeDotsVertical size={18} color="gray" />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            {...getFloatingProps({ ref: refs.setFloating })}
            style={{
              ...styles,
              position: strategy,
              top: y ?? "",
              left: x ?? "",
            }}
            id="actions"
            className="absolute z-10 w-56 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div>
              <h1
                style={{ fontWeight: "bold" }}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black-bold text-bold"
              >
                Action
              </h1>
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  width: "100%",
                  height: 1,
                }}
              />
            </div>
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {status ? (
                <button
                  onClick={() => onEdit(index)}
                  style={{ width: "100%", justifyContent: "space-between" }}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Make {status === "READ" ? "unread" : "read"}
                  <AiOutlineCheckCircle className="w-5 h-5 mr-3 text-[#00000073]" />
                </button>
              ) : (
                <button
                  onClick={() => onEdit(index)}
                  style={{ width: "100%", justifyContent: "space-between" }}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Edit
                  <RiPencilLine className="w-4 h-4 mr-3" aria-hidden="true" />
                </button>
              )}
              <button
                onClick={() => onDelete(index)}
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  color: "red",
                }}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                Delete
                <RiDeleteBin6Line className="w-5 h-5 mr-3" aria-hidden="true" />
              </button>
            </div>
            <div
              ref={arrowRef.current}
              style={{
                position: "absolute",
                width: "10px",
                height: "10px",
                background: "#fff",
                borderRightWidth: 1,
                borderTopWidth: 1,
                borderColor: "rgba(0, 0, 0, .1)",
                left: arrowX != null ? `${arrowX + 5.5}px` : "",
                top: arrowY != null ? `${arrowY}px` : "",
                [staticSide]: "8px",
                transform: "rotate(45deg)",
              }}
              id="arrow"
            />
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
}

export default PopoverThreeDot;
