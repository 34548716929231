import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../core/ToggleSwitch";
import Button from "components/core/Button";
import { showError, showSuccess } from "utils/helpers";
import providersService from "services/providers/providersService";

const ProviderSettings = ({ data, setData }) => {
  const [isHomeServiceAvailable, setHomeServiceAvailable] = useState(false);
  const [isOnlineBookingAvailable, setOnlineBookingAvailable] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleHomeServiceToggle = () => {
    setHomeServiceAvailable(!isHomeServiceAvailable);
  };

  const handleOnlineBookingToggle = () => {
    setOnlineBookingAvailable(!isOnlineBookingAvailable);
  };

  const handleSave = async () => {
    setLoading(true);
    if (
      data === null ||
      data.providerSettings === undefined ||
      data.providerSettings === null
    ) {
      showError("Business data not found. Please fill the business details.");
      setLoading(false);
      return;
    }
    let newData = {
      homeService: isHomeServiceAvailable,
      onlineBooking: isOnlineBookingAvailable,
    };

    try {
      const response = await providersService.updateProviderSettings(
        data.id,
        newData
      );
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setData({
            ...data,
            providerSettings: response.provider.providerSettings,
          });
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data === null) return;
    if (data.providerSettings !== undefined && data.providerSettings !== null) {
      setHomeServiceAvailable(data.providerSettings.homeService);
      setOnlineBookingAvailable(data.providerSettings.onlineBooking);
    }
  }, [data]);

  return (
    <div className="flex flex-col items-start justify-start mt-10 ml-4 space-y-5">
      <div className="flex items-center justify-start">
        <span className="text-14 font-normal text-[#000000D9] min-w-[200px] max-w-[200px]">
          Available for home services:
        </span>
        <div onClick={handleHomeServiceToggle}>
          <ToggleSwitch enabled={isHomeServiceAvailable} />
        </div>
      </div>
      <div className="flex items-center justify-start">
        <span className="text-14 font-normal text-[#000000D9] min-w-[200px] max-w-[200px]">
          Available for online booking:
        </span>
        <div onClick={handleOnlineBookingToggle}>
          <ToggleSwitch enabled={isOnlineBookingAvailable} />
        </div>
      </div>
      <div className="flex items-center justify-end ">
        <Button
          label="Cancel"
          className={
            "w-40 p-2 text-[#000000D9] bg-white border border-[#FFA39E] rounded-xl"
          }
          textColor="text-[#000000D9]"
        />
        <Button
          label="Save"
          className={"w-40 p-2 ml-4 text-white bg-[#DE0607] rounded-xl"}
          textColor="text-white"
          onClick={handleSave}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ProviderSettings;
