const AgeGroup = {
  UNDER_18: "UNDER_18",
  AGE_18_25: "AGE_18_25",
  AGE_26_35: "AGE_26_35",
  AGE_36_45: "AGE_36_45",
  AGE_46_60: "AGE_46_60",
  AGE_60_PLUS: "AGE_60_PLUS",
};

export default AgeGroup;
