const ToggleSwitch = ({ enabled, disabled }) => {
  return (
    <div
      className={`w-7 h-4 flex items-center rounded-full p-1 duration-300 cursor-pointer ${
        enabled ? "bg-[#DE0607]" : "bg-gray-300"
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      <div
        className={`bg-white w-3 h-3 rounded-full shadow-md transform duration-300 ${
          enabled ? "translate-x-2.5" : ""
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
