import { createSlice } from "@reduxjs/toolkit";

export const listingSlice = createSlice({
  name: "listings",
  initialState: {
    listingInfo: {
      vendor: "",
      id: null,
      name: "",
      category: "",
      subcategories: [],
      priceRange: "",
      city: "",
      description: "",
      amenities: [],
      phoneNumbers: [],
      email: "",
      whatsapp: "",
      logo: "",
      images: [],
      instagram: "",
      website: "",
      address: "",
    },
    listingAvailability: {
      days: [],
    },
    uploadedImageFile: null,
  },
  reducers: {
    setlistingInfo: (state, action) => {
      state.listingInfo = action.payload.listingInfo;
    },
    setListingAvailability: (state, action) => {
      state.listingAvailability = action.payload;
    },
    setSpecificListingAvailability: (state, action) => {
      state.listingAvailability[action.payload.key] = action.payload.value;
    },
    updateSpecificListingData: (state, action) => {
      const { field, value } = action.payload;
      state.listingInfo = { ...state.listingInfo, [field]: value };
    },
    setUploadedImageFile: (state, action) => {
      const file = action.payload;
      const fileMetaData = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      };
      state.uploadedImageFile = fileMetaData;
    },
    setUploadedImageFileAs64: (state, action) => {
      state.uploadedImageFile = action.payload;
    },
    clearListingData: (state) => {
      state.listingInfo = {
        vendor: "",
        id: null,
        name: "",
        category: "",
        subcategories: [],
        priceRange: "",
        city: "",
        description: "",
        amenities: [],
        phoneNumbers: [],
        email: "",
        whatsapp: "",
        instagram: "",
        website: "",
        address: "",
        logo: "",
        images: [],
      };
      state.listingAvailability = {
        days: [],
      };
      state.uploadedImageFile = null;
    },
  },
});

export const {
  setlistingInfo,
  setListingAvailability,
  setSpecificListingAvailability,
  updateSpecificListingData,
  setUploadedImageFile,
  setUploadedImageFileAs64,
  clearListingData,
} = listingSlice.actions;

export const selectListing = (state) => state.listings.listingInfo;
export const selectListingAvailability = (state) =>
  state.listings.listingAvailability;
export const selectUploadedImageFile = (state) =>
  state.listings.uploadedImageFile;
