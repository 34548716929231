import React, { useEffect, useState } from "react";
import ImageInput from "../../core/ImageInput";
import InputField from "../../core/inputField";
import photoService from "services/photoService";
import { showError, showSuccess } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProvider,
  setSpecificProviderData,
} from "../../../redux/provider/providerSlice";

const AddProfile = () => {
  const profileData = useSelector(selectProvider);
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("files", image);
      const response = await photoService.uploadPhoto(formData);
      setSelectedImage(response[0]);
      dispatch(setSpecificProviderData({ field: "logo", value: response[0] }));
      showSuccess("Image uploaded successfully");
    } catch (error) {
      showError(error.message);
    }
  };

  const handleChanges = (field, value, setter) => {
    setter(value);
    dispatch(setSpecificProviderData({ field, value }));
  };

  useEffect(() => {
    if (profileData) {
      setSelectedImage(profileData.logo);
      setName(profileData.name);
      setSpeciality(profileData.speciality);
      setEmail(profileData.email);
      setPhone(profileData.phone);
    }
  }, [profileData]);

  return (
    <div className="flex flex-col w-1/2 ">
      <div className="flex items-center justify-center w-32 h-32 ">
        <ImageInput
          value={selectedImage}
          onUpload={(image) => uploadImage(image)}
          showLabel={false}
          imageSize={"w-32 h-32"}
          className="w-32 h-32 border-0"
          isRounded
          supportedFormats={["image/jpg", "image/png", "image/svg+xml"]}
        />
      </div>
      <div className="flex flex-col mt-4 space-y-4 max-w-[500px]   h-full">
        <InputField
          label="Name"
          placeholder="Enter name"
          value={name}
          onChange={(e) => handleChanges("name", e.target.value, setName)}
        />
        <InputField
          label="Speciality"
          placeholder="Enter speciality"
          value={speciality}
          onChange={(e) =>
            handleChanges("speciality", e.target.value, setSpeciality)
          }
        />
        <InputField
          label="Email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => handleChanges("email", e.target.value, setEmail)}
        />
        <InputField
          label="Phone Number"
          placeholder="Enter phone"
          value={phone}
          onChange={(e) => handleChanges("phone", e.target.value, setPhone)}
        />
      </div>
    </div>
  );
};

export default AddProfile;
