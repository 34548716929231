import React from "react";
import RadioButton from "./RadioButton"; // Adjust the import path as necessary

// RadioButtonGroup component
const RadioButtonGroup = ({
  options,
  name,
  selectedValue,
  onChange,
  className,
  disabled,
}) => {
  return (
    <div className={`flex flex-row ${className}`}>
      {options.map((option) => (
        <div key={option.value}>
          <RadioButton
            key={option.value}
            name={name}
            value={option.value}
            checked={option.value === selectedValue}
            onChange={onChange}
            label={option.label}
            disabled={disabled}
          />
          {selectedValue === option.value && option.content && (
            <div className="w-full mt-2">{option.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
