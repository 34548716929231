import api from "services/apiConfig";

export const childSubserviceServices = {
  async getSubservices() {
    try {
      const response = await api.get("/child-subservice");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async getSubservice(id) {
    try {
      const response = await api.get(`/child-subservice/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async addSubservice(data) {
    try {
      const response = await api.post("/child-subservice", data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async editSubservice(id, data) {
    try {
      const response = await api.put(`/child-subservice/${id}`, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async deleteSubservice(id) {
    try {
      const response = await api.delete(`/child-subservice/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};
