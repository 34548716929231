import { useEffect, useState } from "react";
import providersService from "services/providers/providersService";
import usersService from "services/users/usersService";
import { showError } from "utils/helpers";

const useProvider = () => {
  const [providers, setProviders] = useState([]);

  const fetchProvider = async (id) => {
    try {
      const response = await providersService.getProviderById(id);
      switch (response.statusCode) {
        case 200:
          return response.provider;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await usersService.getUsersWQuery("role=PROVIDER");
        switch (response.statusCode) {
          case 200:
            setProviders(response.users);
            break;
          default:
            showError(response.message);
            setProviders([]);
            break;
        }
      } catch (error) {
        showError("Failed to fetch providers. Please try again.");
      }
    };

    fetchProviders();
  }, []);

  return { providers, fetchProvider };
};

export default useProvider;
