import { useState } from "react";
import TimeSlot from "./TimeSlot";

const DaySchedule = ({
  dayName,
  slots,
  addTimeSlot,
  removeTimeSlot,
  updateTimeSlot,
  setIsDayEnabled,
}) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const toggleDay = () => {
    setIsEnabled(!isEnabled);
  };

  return (
    <div className="flex flex-col items-start md:w-full md:items-center md:flex-row">
      <div className="flex items-center min-w-32 w-fit">
        <input
          type="checkbox"
          className="w-4 h-4 text-red-600 md:w-5 md:h-5 form-checkbox"
          checked={isEnabled}
          onChange={toggleDay}
          onClick={() => setIsDayEnabled(dayName, !isEnabled)}
        />
        <span
          className={`ml-2 text-13 md:text-16 font-semibold ${
            !isEnabled && "text-gray-400"
          }`}
        >
          {dayName}
        </span>
      </div>
      <div className=" min-w-72 w-fit">
        {slots.map((slot, index) => (
          <TimeSlot
            key={index}
            index={index}
            slot={slot}
            isEnabled={isEnabled}
            removeTimeSlot={() => removeTimeSlot(index)}
            updateTimeSlot={(startsAt, finishesAt) =>
              updateTimeSlot(dayName, index, startsAt, finishesAt)
            }
          />
        ))}
      </div>
      <div className={`w-fit min-w-28 flex items-center  justify-center`}>
        <button
          onClick={addTimeSlot}
          className={`font-normal  text-12 md:text-16 ${
            isEnabled ? "text-[#DE0607]" : "text-gray-400"
          }`}
          disabled={!isEnabled}
        >
          + Add time
        </button>
      </div>
    </div>
  );
};

export default DaySchedule;
