import Table from "components/core/Table/TableComponent";
import React, { useEffect, useState } from "react";
import DiscountData from "constants/data/discountData";
import { HiDotsVertical } from "react-icons/hi";
import MultiSelectInput from "components/core/select/MultiSelectInput";
import Checkbox from "components/core/Checkbox";
import DiscountForm from "./DiscountCodeComponents/DiscountForm";
import useLocations from "utils/hooks/useLocations";
import useDiscount from "utils/hooks/useDiscount";
import discountServices from "services/discount/discountServices";
import { showError, showSuccess } from "utils/helpers";
import { DiscountCodeAddSchema } from "utils/schemas/Services/ServiceSchema";
import { selectBusinessId } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import Modal from "components/core/Modal";

const DiscountCodesTab = ({ isAddClicked, setIsAddClicked }) => {
  const { locations: locationData, fetchVendorLocations } = useLocations();
  const { discounts } = useDiscount();
  const [discountData, setDiscountData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [locations, setLocations] = useState([]);
  const userBusinessId = useSelector(selectBusinessId);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const headers = [
    {
      value: "Discount Title",
      key: "title",
    },
    {
      value: "Validity",
      key: "validity",
    },
    {
      value: "Number of Uses",
      key: "alreadyUsed",
    },
  ];

  useEffect(() => {
    let data = [];
    if (discounts) {
      data = discounts.map((discount) => ({
        id: discount.id,
        title: discount.title,
        validity: discount.totalUsages,
        alreadyUsed: discount.alreadyUsed,
      }));
    }
    setDiscountData(data);
  }, [discounts]);

  useEffect(() => {
    if (isAddClicked) {
      setSelectedId(null);
      setSelectedRow(null);
      setIsRowSelected(true);
    }
  }, [isAddClicked]);

  useEffect(() => {
    fetchVendorLocations(userBusinessId).then((data) => {
      setLocations(data);
    });
  }, [userBusinessId]);

  const fetchDiscount = async (id) => {
    try {
      const response = await discountServices.getDiscount(id);
      switch (response.statusCode) {
        case 200:
          return response.discount;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || error.message);
    }
  };

  const handleRowClick = (item) => {
    setSelectedId(item.id);
    fetchDiscount(item.id).then((data) => {
      setSelectedRow(data);
    });
    setIsRowSelected(true);
  };

  const addDiscount = async (newData) => {
    try {
      const response = await discountServices.createDiscount(newData);
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          setIsRowSelected(false);
          setSelectedId(null);
          isAddClicked && setIsAddClicked(false);
          setDiscountData([
            ...discountData,
            {
              id: response.discount.id,
              title: response.discount.title,
              validity: response.discount.totalUsages,
              alreadyUsed: response.discount.alreadyUsed,
            },
          ]);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || error.message);
    }
  };

  const saveDiscount = async (newData) => {
    try {
      const response = await discountServices.updateDiscount(
        selectedId,
        newData
      );
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setDiscountData(
            discountData.map((discount) => {
              if (discount.id === selectedId) {
                return {
                  id: response.discount.id,
                  title: response.discount.title,
                  validity: response.discount.totalUsages,
                  alreadyUsed: response.discount.alreadyUsed,
                };
              }
              return discount;
            })
          );
          setIsRowSelected(false);
          setSelectedId(null);
          setSelectedRow(null);
          setSelectedLocations([]);

          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async (data) => {
    try {
      if (!isAddClicked) {
        let newData = data;
        newData.locationIds = selectedLocations.map((location) => location.id);
        await DiscountCodeAddSchema.validate(newData);
        await saveDiscount(newData);
      } else {
        let newData = data;
        newData.locationIds = selectedLocations.map((location) => location.id);
        await DiscountCodeAddSchema.validate(newData);
        await addDiscount(newData);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await discountServices.deleteDiscount(selectedId);
      switch (response.statusCode) {
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess("Discount code deleted successfully.");
          setDiscountData(
            discountData.filter((discount) => discount.id !== selectedId)
          );
          setIsDeleteModalOpen(false);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    if (selectedRow) {
      let locationIds = selectedRow.locationIds;
      let newLocations = locationData
        .filter((location) => locationIds.includes(location.id))
        .map((location) => ({
          id: location.id,
          image: location.logo,
          label: location.name.en,
          value: location.slug,
        }));
      setSelectedLocations(newLocations);
    }
  }, [selectedRow, locationData]);

  const renderEditPage = () => {
    return (
      <div className="flex flex-row pl-2 pr-6 space-x-4 bg-white">
        <div className="flex flex-col items-start justify-start w-2/5 h-full space-y-4 ">
          <Checkbox
            label={"Select All"}
            onChange={() => {
              if (selectedLocations.length === locations.length) {
                setSelectedLocations([]);
              } else {
                setSelectedLocations(
                  locations.map((location) => {
                    return {
                      id: location.id,
                      image: location.logo,
                      label: location.name.en,
                      value: location.slug,
                    };
                  })
                );
              }
            }}
            checked={selectedLocations.length === locations.length}
          />
          <MultiSelectInput
            options={locations.map((location) => ({
              id: location.id,
              image: location.logo,
              label: location.name.en,
              value: location.slug,
            }))}
            onChange={(values) => {
              setSelectedLocations(values);
            }}
            selectedValues={selectedLocations}
            checkboxClassName={"space-y-4"}
          />
        </div>
        <div className="w-3/5 h-screen rounded-md shadow shadow-inherit ">
          <DiscountForm
            data={selectedRow ? selectedRow : {}}
            onClose={() => {
              setIsRowSelected(false);
              setSelectedId(null);
              setSelectedRow(null);
              setSelectedLocations([]);
              isAddClicked && setIsAddClicked(false);
            }}
            locations={locations}
            onSave={handleSave}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full">
      {isRowSelected ? (
        renderEditPage()
      ) : (
        <Table
          headers={headers}
          numberOfRows={5}
          data={discountData ? discountData : []}
          popoverActions={{
            onEdit: (item, index) => {
              handleRowClick(item);
            },
            onDelete: (item, index) => {
              setSelectedId(item.id);
              setIsDeleteModalOpen(true);
            },
          }}
          usePopover
        />
      )}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        headerText={"Delete Discount Code"}
      >
        <div className="flex flex-col items-center justify-center space-y-4">
          <div>Are you sure you want to delete this discount code?</div>
          <div className="flex items-center justify-center space-x-4">
            <button
              className="px-4 py-2 text-white bg-red-500 rounded-md"
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 text-white bg-green-500 rounded-md"
              onClick={() => {
                handleDelete();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DiscountCodesTab;
