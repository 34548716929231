import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";

const TableListings = ({ headers, data, numberOfRows, setData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [lastRowIndex, setLastRowIndex] = useState();
  const [firstRowIndex, setFirstRowIndex] = useState();
  const [currentData, setCurrentData] = useState([]);

  // const lastRowIndex = currentPage * rowsPerPage;
  // const firstRowIndex = lastRowIndex - rowsPerPage;
  // const currentData = data.slice(firstRowIndex, lastRowIndex);


const TargetModal = ({ show, children, targetRef }) => {
    if (!show) {
      return null;
    }
  
    const style = {
      position: 'absolute',
      top: `${targetRef.current.offsetTop}px`,
      left: `${targetRef.current.offsetLeft + targetRef.current.offsetWidth}px`,
      backgroundColor: 'white',
      border: '1px solid #ccc',
      padding: '10px',
      zIndex: 1000,
    };
  
    return (
      <div style={style}>
        {children}
      </div>
    );
  };

  
  useEffect(() => {
    setRowsPerPage(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    const currentData = data.slice(first, last)
    setCurrentData(currentData);
  }, [currentPage, rowsPerPage, data, rowsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const openOptionsModal = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });
  const [showModal, setShowModal] = useState(null);
  const buttonTargetRef = useRef(null);

  return (
    <div className="bg-white">
      <table className="w-full leading-normal">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="w-full px-5 py-3 text-14  font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"
              >
                {header}
              </th>
            ))}
            <th className="w-full px-5 py-3 text-14  font-medium tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]"></th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => {
            return(<tr key={index}>
            {Object.values(item).map((val, idx) => (
                <td
                key={idx}
                className="p-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200"
                >
                {val}
                </td>
            ))}
            <td className="p-4 text-sm bg-white border-b border-gray-200">
                <button
                    ref={buttonTargetRef}
                    className="flex items-center justify-center w-full h-full text-red-500 hover:text-red-700"
                    onClick={() => openOptionsModal(index)}
                >
                <BsThreeDotsVertical size={18} color="gray"/>
                </button>
            </td>
                <TargetModal show={showModal==index} targetRef={buttonTargetRef}>
                    <p>This is a modal!</p>
                    <button onClick={() => setShowModal(index)}>Close</button>
                </TargetModal>
            </tr>)

          }
          )}
        </tbody>
      </table>
      
      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>

        <div className="inline-block">
          <select
            className=" w-full h-8 form-select text-14 text-[#000000D9] leading-6 border rounded-md border-[#D9D9D9] flex justify-center items-center"
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}/page
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default TableListings;
