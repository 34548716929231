import React, { useState } from "react";
import EditHeader from "../../Header/EditHeader";
import WeeklySchedule from "../components/WeeklySchedule";

const AvailabilityPage = () => {
  // Mock data for demonstration purposes
  const location = {
    name: "East Coast Pizzeria",
    rating: 4.5,
    location: "Downtown NY",
    address: "1234 Pizza Street",
    openHours: "Today 8:00 - 22:00",
  };

  return (
    <div>
      <EditHeader location={location} />
      <div className="w-full xl:w-1/2 ">
        <WeeklySchedule />
      </div>
    </div>
  );
};

export default AvailabilityPage;
