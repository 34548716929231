import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Options for the bar chart
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
    },
  },
};

const BarChart = ({ data, title, maxHeight = "500px" }) => {
  if (!data) {
    return (
      <div className="flex items-center justify-center h-full text-red-500">
        No data available.
      </div>
    );
  }
  return (
    <div className="h-full">
      {
        // Title
        title && (
          <p className="text-16 font-semibold text-[#000000D9] mb-11">
            {title}
          </p>
        )
      }
      <div className={`h-screen max-h-[${maxHeight}]`}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
