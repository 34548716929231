import React, { useEffect, useState } from "react";
import InputField from "../core/inputField";
import { CiSearch } from "react-icons/ci";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import AddCityModal from "./AddCityModal";
import cityService from "../../services/city/cityService";
import { showError, showSuccess } from "../../utils/helpers";
import TableComponent from "../core/Table/TableComponent";
import { LuTrash } from "react-icons/lu";
import Modal from "../core/Modal";
import EditCityModal from "./EditCityModal";
import { CityAddSchema, CityEditSchema } from "utils/schemas/City/CitySchemas";
import DeleteCityModal from "./DeleteCityModal";

function Cities() {
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedCity, setSelectedCity] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteCityModalOpen, setIsDeleteCityModalOpen] = useState(false);

  const headers = [
    {
      key: "name",
      value: "Name",
    },
  ];

  const fetchCities = async () => {
    try {
      const response = await cityService.getCities();
      switch (response.statusCode) {
        case 200:
          let data = response.cities.map((city) => {
            return {
              name: `${city.name.en}/${city.name.ar}`,
              id: city.id,
              slug: city.slug,
            };
          });
          setCities(data);
          setFilteredCities(data);
          break;
        default:
          showError("No cities found");
          setCities([]);
          setFilteredCities([]);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredData = cities.filter((city) =>
      city.name.toLowerCase().includes(value)
    );
    setFilteredCities(filteredData);
  };

  const handleSave = async (cityName) => {
    try {
      await CityAddSchema.validate({ name: cityName });
      await addNewCity(cityName);
      setIsModalOpen(false);
    } catch (error) {
      showError(error.message);
    }
  };

  const handleEditSave = async (cityName, id) => {
    try {
      await CityEditSchema.validate({ name: cityName });
      await editCityName(cityName, id);
      setIsEditModalOpen(false);
    } catch (error) {
      showError(error.message);
    }
  };

  const addNewCity = async (cityName) => {
    try {
      let data = { name: cityName };
      const response = await cityService.addCity(data);
      switch (response.statusCode) {
        case 201:
          showSuccess("City added successfully");
          fetchCities();
          return true;
        case 400:
          showError("City not created!");
          return false;
        default:
          showError("Something went wrong");
          return false;
      }
    } catch (error) {
      showError(error.message);
      return false;
    }
  };

  const editCityName = async (cityName, id) => {
    try {
      let data = { name: cityName };
      const response = await cityService.updateCity(id, data);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          fetchCities();
          break;
        case 400:
          showError(response.message);
          break;
        default:
          fetchCities();
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const deleteCity = async (id) => {
    try {
      const response = await cityService.deleteCity(id);
      switch (response.statusCode) {
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess("City deleted successfully");
          fetchCities();
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const actions = [
    {
      key: "delete",
      value: "Delete",
      onClick: (index) => {
        setIsDeleteModalOpen(true);
        setSelectedId(index.id);
      },
      icon: <LuTrash size={18} />,
    },
    {
      key: "delete",
      value: "Delete",
      onClick: (index) => {
        setIsDeleteModalOpen(true);
        setSelectedId(index.id);
      },
      icon: <LuTrash size={18} />,
    },
  ];

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <div className="flex items-center justify-between w-full p-4">
          <InputField
            placeholder="Search"
            onChange={handleSearch}
            icon={<CiSearch size={20} color="#00000099" />}
          />
          <Button
            label="Add City"
            icon={<HiOutlinePlusSm />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </div>
        {!filteredCities ? (
          <div className="flex items-center justify-center h-32">
            <p className="text-16 text-[#00000099]">No cities found</p>
          </div>
        ) : (
          <TableComponent
            data={filteredCities}
            headers={headers}
            numberOfRows={10}
            onDelete={deleteCity}
            actions={actions}
            usePopover={true}
            onRowClick={() => {}}
            popoverActions={{
              onEdit: (item, index) => {
                setSelectedCity(item);
                setIsEditModalOpen(true);
              },
              onDelete: (item, index) => {
                setSelectedId(item.id);
                setIsDeleteModalOpen(true);
              },
            }}
          />
        )}
      </div>
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          headerText={"Delete City"}
        >
          <div className="p-5">
          <h2 className="text-16 text-[#000000D9] font-medium">
            Are you sure you want to delete this city?
          </h2>
          <div className="flex justify-end mt-6 space-x-4">
              <button
                className="px-4 py-2 text-black rounded hover:bg-red-600"
                style={{borderWidth:1, borderColor:'#DC2626'}}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <p >Cancel</p>
              </button>
              <button className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400" onClick={()=>{deleteCity(selectedId);
                                setIsDeleteModalOpen(false);}} 
              >
                <p style={{color:'white'}}>Delete</p>
              </button>
            </div>
          </div>
        </Modal>
      )}

      <AddCityModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
      />
      <EditCityModal
        isOpen={isEditModalOpen}
        onClose={() => {setIsEditModalOpen(false);}}
        onSave={handleEditSave}
        cityData={selectedCity}
      />
    </div>
  );
}

export default Cities;
