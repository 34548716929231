import { useEffect, useState } from "react";
import cityService from "services/city/cityService";
import { showError } from "utils/helpers";

const useCity = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await cityService.getCities();
        switch (response.statusCode) {
          case 200:
            setCities(response.cities);
            break;
          default:
            showError(response.message);
            setCities([]);
            break;
        }
      } catch (error) {
        showError(error.message);
      }
    };

    fetchCities();
  }, []);

  return { cities };
};

export default useCity;
