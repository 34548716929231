import AuthAPI from "../AuthAPI";

const authService = {
  loginWithEmail: async (email, password) => {
    const response = await AuthAPI.post("/loginEmail", {
      email,
      password,
    });

    return response.data;
  },
  logout: async () => {
    const response = await AuthAPI.post("/logout");

    return response.data;
  },
  register: async (email, password, role) => {
    const response = await AuthAPI.post("/register", {
      email,
      password,
      role,
    });

    return response.data;
  },
  refreshToken: async (refreshToken) => {
    const response = await AuthAPI.post("/refresh", {
      refreshToken,
    });

    return response.data;
  },
  resetPassword: async (id, newPassword) => {
    const response = await AuthAPI.post("/reset-password", {
      id,
      newPassword,
    });
    return response.data;
  },
  forgetPassword: async (email) => {
    const response = await AuthAPI.post("/forget-password", {
      email,
    });
    return response.data;
  },
  forgetPasswordAdmin: async (email) => {
    const response = await AuthAPI.post("/forget-password-admin", {
      email,
    });
    return response.data;
  },
};

export default authService;
