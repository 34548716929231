import React, { useEffect, useState } from "react";
import WeeklySchedule from "../components/WeeklySchedule";
import { useParams } from "react-router-dom";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLocationAvailability,
  setLocationAvailability,
} from "../../../redux/location/locationSlice";

const AvailabilityPage = () => {
  const availabilityData = useSelector(selectLocationAvailability);
  const [schedule, setSchedule] = useState([]);
  const [isDayEnabledData, setIsDayEnabledData] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLocationAvailability(schedule));
  }, [schedule]);

  useEffect(() => {
    if (!availabilityData) return;
    if (Array.isArray(availabilityData) && availabilityData.length > 0) {
      setSchedule(availabilityData);
      availabilityData.forEach((day) => {
        setIsDayEnabledData((prev) => {
          return {
            ...prev,
            [day.day]: true,
          };
        });
      });
    }
  }, []);

  return (
    <div>
      <div className="w-full xl:w-1/2 ">
        <WeeklySchedule
          scheduleData={schedule}
          setScheduleData={setSchedule}
          isDayEnabledData={isDayEnabledData}
          setIsDayEnabledData={setIsDayEnabledData}
        />
      </div>
    </div>
  );
};

export default AvailabilityPage;
