import React, { useEffect, useState } from "react";
import { LuTrash } from "react-icons/lu";
import { useSelector } from "react-redux";
import { selectRole } from "../../../redux/auth/authSlice";
import { getInitials } from "utils/helpers";

const ProvidersCard = ({ data, onDelete }) => {
  const [workingTimes, setWorkingTimes] = useState([]);
  const userRole = useSelector(selectRole);
  useEffect(() => {
    if (!data.workingTimes || !data.workingTimes.length) return;
    let workingTimes = data.workingTimes[0].map((day) => {
      return {
        day: day.name,
        schedule:
          day.schedule &&
          day.schedule.map((time) => {
            return {
              startTime: time.startTime,
              endTime: time.endTime,
            };
          }),
      };
    });
    setWorkingTimes(workingTimes);
  }, [data]);

  return (
    <div className="flex items-center justify-between p-4 border-t border-b border-[#0000000F] rounded-md">
      {/* First Column */}
      <div className="flex items-center space-x-4">
        {data.image ? (
          <img
            src={data.image}
            alt={data.name}
            className="w-16 h-16 rounded-full"
          />
        ) : (
          <div className="flex items-center justify-center w-16 h-16 text-2xl font-medium text-gray-700 bg-gray-300 rounded-full">
            {getInitials(data.name)}
          </div>
        )}
        <div className="font-medium leading-6">
          <h2 className="text-16  text-[#000000D9]">{data.name}</h2>
          <p className="text-14 text-[#00000073] ">{data.speciality}</p>
        </div>
      </div>

      {/* Divider */}
      <div className="h-10 border-l border-[#0000002F]"></div>

      {/* Second Column */}
      <div className="flex items-center space-x-4 text-14 text-[#00000073]">
        <p className="text-14 font-normal text-[#00000073]">Working Times: </p>
        {workingTimes.map((time) => (
          <div key={time.day} className="text-[#000000D9] ">
            <p className="font-medium capitalize text-14 ">
              {time.day.charAt(0) + time.day.slice(1).toLowerCase()}
            </p>
            {time.schedule.map((schedule) => (
              <p key={schedule.startTime} className="text-14 ">
                {schedule.startTime} - {schedule.endTime}
              </p>
            ))}
          </div>
        ))}
      </div>

      {/* Divider */}
      <div className="h-10 border-l border-[#0000002F]"></div>

      {/* Third Column */}
      <div>
        <button
          onClick={() => {
            userRole !== "PROVIDER" && onDelete(data);
          }}
          className={`
              ${
                userRole === "PROVIDER"
                  ? "text-gray-300 hover:text-gray-500 cursor-not-allowed"
                  : "text-red-500 hover:text-red-700 "
              }
            `}
        >
          <LuTrash size={16} />
        </button>
      </div>
    </div>
  );
};

export default ProvidersCard;
