import React, { useState, useEffect, useMemo } from "react";
import "./styles.css";
import { BsPeople } from "react-icons/bs";
import CardIcon from "../../../../../assets/card-icon.svg";
import CashIcon from "../../../../../assets/cash-icon.svg";
import InternetPaymentIcon from "../../../../../assets/internet-banking-icon.svg";
import { showError } from "../../../../../utils/helpers";
import bookingService from "services/booking/bookingService";
import subserviceServices from "services/service/subserviceServices";
import "react-datepicker/dist/react-datepicker.css";
import DetailsTab from "./DetailsTab";
import ServicesTab from "./ServicesTab";
import NotesTab from "./NotesTab";
import Button from "components/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveTab,
  setSpecificBookingData,
} from "../../../../../redux/bookings/bookingsSlice";
import { IoChevronBack } from "react-icons/io5";

const ModalContent = ({
  isCreate,
  hide,
  selectedLocationId,
  employees,
  fetchBookings,
  bookingDetails,
}) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [duration, setDuration] = useState();

  const fetchService = async (value) => {
    try {
      let response;
      if (value == null) {
        if (
          bookingDetails.subserviceIds &&
          bookingDetails.subserviceIds.length > 0
        ) {
          response = await subserviceServices.getSubservice(
            bookingDetails.subserviceIds[0]
          );
        } else {
          throw new Error("No subserviceIds found in bookingDetails");
        }
      } else {
        response = await subserviceServices.getSubservice(value);
      }

      if (response && response.subservice) {
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Server error:", error.response.data);
        showError(
          `Error: ${error.response.data.message || error.response.data}`
        );
      } else if (error.request) {
        // Request was made but no response received
        console.error("Network error:", error.request);
        showError("Network error. Please try again.");
      } else {
        // Something else happened in setting up the request
        console.error("Error:", error.message);
        showError(error.message);
      }
    }
  };

  const setDatas = () => {
    if (!isCreate) {
      fetchService(null);
      setTotalPrice(bookingDetails.totalPrice);
      dispatch(
        setSpecificBookingData({
          field: "userId",
          value: bookingDetails.userId,
        })
      );
      setDuration(bookingDetails.duration / 60);
    }
  };

  const confirmBooking = async (bookingId) => {
    const body = {
      status: "CONFIRMED",
      address: "",
      notes: "",
    };

    const response = await bookingService.updateBooking(bookingId, body);

    fetchBookings();
    hide();
  };

  useEffect(() => {
    setDatas();
  }, [selectedLocationId]);

  const tabs = useMemo(() => {
    return [
      {
        name: "Details",
        key: "details",
        component: <DetailsTab hide={hide} />,
      },
      {
        name: "Services",
        key: "services",
        component: <ServicesTab hide={hide} />,
      },
      {
        name: "Notes",
        key: "notes",
        component: <NotesTab hide={hide} />,
      },
    ];
  }, [activeTab]);

  const handleBack = () => {
    switch (activeTab) {
      case "Details":
        hide();
        break;
      case "Services":
        dispatch(
          setSpecificBookingData({ field: "activeTab", value: "Details" })
        );
        break;
      case "Notes":
        dispatch(
          setSpecificBookingData({ field: "activeTab", value: "Services" })
        );
        break;
      default:
        break;
    }
  };
  return (
    <div className=" modal-layout">
      {isCheckedOut ? (
        <div>
          <h2
            style={{
              borderBottomWidth: 1,
              padding: 5,
              paddingBottom: 10,
              fontWeight: "400",
              textAlign: "left",
              fontSize: 22,
            }}
          >
            Hair coloring
          </h2>
          <div className="service-details">
            <div style={{ flexDirection: "row", display: "flex", padding: 5 }}>
              <BsPeople
                color="#8c8c8c"
                size={18}
                style={{ alignSelf: "center" }}
              />
              {isCreate ? (
                <input
                  style={{
                    textAlign: "center",
                    marginLeft: 10,
                    textAlignLast: "left",
                  }}
                  value={isCreate ? "" : bookingDetails.provider.name}
                />
              ) : (
                <span style={{ marginLeft: 10 }}>
                  {bookingDetails.provider.name}
                </span>
              )}
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 5 }}>
              <BsPeople
                color="#8c8c8c"
                size={18}
                style={{ alignSelf: "center" }}
              />
              {isCreate ? (
                <input
                  style={{
                    textAlign: "center",
                    marginLeft: 10,
                    textAlignLast: "left",
                  }}
                  value={duration + "h"}
                />
              ) : (
                <span style={{ marginLeft: 10 }}>{duration + "h"}</span>
              )}
            </div>
            <div style={{ flexDirection: "row", display: "flex", padding: 5 }}>
              <BsPeople
                color="#8c8c8c"
                size={18}
                style={{ alignSelf: "center" }}
              />
              {isCreate ? (
                <input
                  style={{
                    textAlign: "center",
                    marginLeft: 10,
                    textAlignLast: "left",
                  }}
                  value={"KWD " + totalPrice}
                />
              ) : (
                <span style={{ marginLeft: 10 }}>{"KWD " + totalPrice}</span>
              )}
            </div>
          </div>
          <div className="total-amount">
            <p style={{ fontWeight: "bold", fontSize: 14 }}>Total amount:</p>
            {isCreate ? (
              <p
                style={{ borderWidth: 1, borderRadius: 5, marginLeft: 5 }}
                placeholder="total amount"
              >
                KWD {totalPrice}
              </p>
            ) : (
              <span style={{ marginLeft: 5 }}>KWD {totalPrice}</span>
            )}
          </div>
          <div className="payment-method">
            <h3>Payment method</h3>

            <div className="payment-cards-container">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginRight: 5,
                }}
                className={
                  selectedPaymentMethod == 0
                    ? "method card selected"
                    : "method card"
                }
              >
                <button
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginRight: 5,
                  }}
                  onClick={() => setSelectedPaymentMethod(0)}
                >
                  <img style={{ paddingRight: 5 }} src={CardIcon}></img>Card
                </button>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginRight: 5,
                }}
                className={
                  selectedPaymentMethod == 1
                    ? "method cash selected"
                    : "method cash"
                }
              >
                <button
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginRight: 5,
                  }}
                  onClick={() => setSelectedPaymentMethod(1)}
                >
                  <img style={{ paddingRight: 5 }} src={CashIcon}></img>Cash
                </button>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginRight: 5,
                }}
                className={
                  selectedPaymentMethod == 2
                    ? "method online selected"
                    : "method online"
                }
              >
                <button
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginRight: 5,
                  }}
                  onClick={() => setSelectedPaymentMethod(2)}
                >
                  <img
                    style={{ paddingRight: 5 }}
                    src={InternetPaymentIcon}
                  ></img>
                  Online
                </button>
              </div>
            </div>
          </div>
          <div className="services-actions">
            <button
              onClick={() => setIsCheckedOut(false)}
              className="cancel-services"
            >
              Cancel
            </button>
            <button
              onClick={() => confirmBooking(bookingDetails.id)}
              className="confirm"
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <div className="h-screen bg-white rounded-md ">
          <div className="flex space-x-4 bg-white border-b h-[5vh]">
            <Button
              label={"Back"}
              onClick={handleBack}
              className="text-black bg-transparent border-none shadow-none"
              textColor="text-black"
              icon={<IoChevronBack color="black" />}
            />
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={`p-2 cursor-default ${
                  activeTab === tab.name
                    ? "text-[#DE0607] border-b border-[#DE0607]"
                    : "text-[#000000d9]"
                }`}
                onClick={() => {}}
              >
                {tab.name}
              </button>
            ))}
          </div>
          <div className="justify-between mt-5 space-y-5 h-[90vh]">
            {tabs.find((tab) => tab.name === activeTab)?.component}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalContent;
