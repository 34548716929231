import * as Yup from "yup";
import { emailRegex, phoneRegex } from "../schemaUtils";

// General error messages
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;

// Add Booking validation schema
export const BookingSchema = Yup.object({
  customerName: Yup.string()
    .min(1, requiredMessage("Customer Name"))
    .required(requiredMessage("Customer Name")),
  customerNumber: Yup.string()
    .matches(
      /^\+\d+$/,
      "Phone number must start with + and include only numbers"
    )
    .required(requiredMessage("Customer Number")),
  customerEmail: Yup.string()
    .email(invalidMessage("Email"))
    .required(requiredMessage("Email")),
  customerGender: Yup.string()
    .oneOf(["male", "female", "other"], invalidMessage("Gender"))
    .required(requiredMessage("Gender")),
  customerAge: Yup.number()
    .min(1, requiredMessage("Age"))
    .required(requiredMessage("Age")),
  bookingDate: Yup.date().required(requiredMessage("Booking Date")),
  bookingTime: Yup.string().required(requiredMessage("Booking Time")),
  selectedServiceId: Yup.number().required(requiredMessage("Service")),
  selectedStaffId: Yup.number().required(requiredMessage("Staff Member")),
  selectedServiceType: Yup.string()
    .oneOf(["HOME", "VENDOR"], invalidMessage("Service Type"))
    .required(requiredMessage("Service Type")),
  totalPrice: Yup.number().min(0, invalidMessage("Total Price")).nullable(),
  paymentStatus: Yup.string().nullable(),
  notes: Yup.string().nullable(),
  address: Yup.string().nullable(),
});

// Details Tab validation schema
export const DetailsTabSchema = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, invalidMessage("Name. Only letters are allowed"))
    .min(1, requiredMessage("Customer Name"))
    .required(requiredMessage("Customer Name")),
  phoneNumber: Yup.string()
    .matches(phoneRegex, "Phone number. Must be in the format +965XXXXXXXX")
    .required(requiredMessage("Customer Number")),
  email: Yup.string()
    .required(requiredMessage("Email"))
    .matches(
      emailRegex,
      "Wrong email format. Please enter a valid email address"
    ),

  gender: Yup.object()
    .nullable()
    .notRequired()
    .shape({
      value: Yup.string()
        .oneOf(["MALE", "FEMALE", "OTHER"], invalidMessage("Gender"))
        .nullable(),
      label: Yup.string().nullable(),
    }),
  age: Yup.object()
    .shape({
      value: Yup.string().required(requiredMessage("Age")),
      label: Yup.string().required(requiredMessage("Age")),
    })
    .required(requiredMessage("Age")),

  paymentLink: Yup.object()
    .shape({
      value: Yup.string().required(requiredMessage("Payment Method")),
      label: Yup.string().required(requiredMessage("Payment Method")),
    })
    .required(requiredMessage("Payment Method")),
  paymentMethod: Yup.object().nullable().notRequired().shape({
    value: Yup.string().nullable(),
    label: Yup.string().nullable(),
  }),
  location: Yup.object()
    .shape({
      value: Yup.string().required(requiredMessage("Location")),
      label: Yup.string().required(requiredMessage("Location")),
    })
    .required(requiredMessage("Location")),
});

// Services Tab validation schema
export const ServicesTabSchema = Yup.object({
  serviceType: Yup.object({
    value: Yup.string().required(requiredMessage("Service Type")),
    label: Yup.string().required(requiredMessage("Service Type")),
  }).required(requiredMessage("Service Type")),
  city: Yup.mixed().when("serviceType.value", {
    is: "home",
    then: Yup.object()
      .shape({
        value: Yup.string().required(requiredMessage("City")),
        label: Yup.string().required(requiredMessage("City")),
      })
      .required(requiredMessage("City")),
    otherwise: Yup.mixed().nullable(),
  }),
  blockNumber: Yup.string().when("serviceType.value", {
    is: "home",
    then: Yup.string().required(requiredMessage("Block Number")),
    otherwise: Yup.string().nullable(),
  }),
  streetNumber: Yup.string().when("serviceType.value", {
    is: "home",
    then: Yup.string().required(requiredMessage("Street Number")),
    otherwise: Yup.string().nullable(),
  }),
  houseNumber: Yup.string().when("serviceType.value", {
    is: "home",
    then: Yup.string().required(requiredMessage("House Number")),
    otherwise: Yup.string().nullable(),
  }),
  properties: Yup.array()
    .of(
      Yup.object().shape({
        service: Yup.object()
          .shape({
            id: Yup.number().required(),
            value: Yup.number().required(),
            label: Yup.string().required(),
          })
          .required(requiredMessage("Service")),
        date: Yup.string().required(requiredMessage("Date")),
        staff: Yup.object()
          .shape({
            value: Yup.number().required(),
            label: Yup.string().required(),
          })
          .required(requiredMessage("Staff")),
      })
    )
    .min(1, "At least one service, staff, and date must be selected"),
});
