import React, { useEffect, useState } from "react";
import LocationCard from "./LocationCard";
import { HiOutlinePlus } from "react-icons/hi"; // Removed unused HiOutlinePlusSm
import Header from "../Header/Header"; // Ensure Header is used or remove if not needed
import locationService from "../../services/location/locationService";
import { showError } from "../../utils/helpers"; // Ensure correct path
import Button from "../core/Button"; // Ensure correct path
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearLocationData } from "../../redux/location/locationSlice";
import userService from "services/user/userService";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import {
  selectBusinessId,
  selectRole,
  selectUser,
} from "../../redux/auth/authSlice";
import locationManagerService from "services/locationManager/locationManagerService";

function Locations() {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const userBusinessId = useSelector(selectBusinessId);
  const userRole = useSelector(selectRole);
  const user = useSelector(selectUser);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(locations.length / itemsPerPage);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const getLocations = async () => {
    setIsLoading(true);
    try {
      const queryResponse = await locationService.getLocationWQuery(
        `businessId=${userBusinessId}`
      );
      switch (queryResponse.statusCode) {
        case 200:
          const locationData = queryResponse.locations;
          setLocations(locationData);
          setIsLoading(false);
          break;
        default:
          showError(queryResponse.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const getLocationManagerLocations = async () => {
    setIsLoading(true);
    try {
      const queryResponse = await locationManagerService.getLocationManager(
        user.id
      );
      switch (queryResponse.statusCode) {
        case 200:
          const locationIds =
            queryResponse.locationManager.managedLocations.map(
              (location) => location.id
            );

          const fetchedLocations = await Promise.all(
            locationIds.map(async (locationId) => {
              const locationResponse = await locationService.getLocation(
                locationId
              );
              if (locationResponse.statusCode === 200) {
                return locationResponse.location;
              } else {
                showError(locationResponse.message);
                return null;
              }
            })
          );

          // Filter out null values and avoid duplicates
          const uniqueLocations = fetchedLocations
            .filter((location) => location !== null)
            .filter(
              (location, index, self) =>
                self.findIndex((l) => l.id === location.id) === index
            );

          setLocations(uniqueLocations);
          setIsLoading(false);
          break;
        default:
          showError(queryResponse.message);
          setIsLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setIsLoading(false);
    }
  };

  const refreshLocations = () => {
    if (userRole === undefined || !userRole) {
      return;
    }
    switch (userRole) {
      case "LOCATION_MANAGER":
        getLocationManagerLocations();
        break;
      default:
        getLocations();
        break;
    }
  };

  const hasAccess = (roles) => {
    if (userRole === undefined || !userRole) {
      return false;
    }
    return roles.includes(userRole);
  };

  useEffect(() => {
    refreshLocations();
  }, [userRole]);

  const currentPageLocations = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return locations.slice(startIndex, startIndex + itemsPerPage);
  };

  const goToNextPage = () =>
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages));

  const goToPrevPage = () =>
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full h-full mt-4 bg-white">
        {hasAccess(["ADMIN", "SUPERADMIN"]) && (
          <div className="flex items-center justify-end w-full p-4">
            <Button
              label="Add Location"
              icon={<HiOutlinePlus size={16} color="#DE0607" />}
              onClick={() => {
                dispatch(clearLocationData());
                navigate("/locations/add");
              }}
              className="w-36"
            />
          </div>
        )}
        <div className="m-4">
          {currentPageLocations().length > 0 ? (
            currentPageLocations().map((location, index) => (
              <LocationCard
                key={location.id}
                id={location.id}
                data={location}
                onDelete={refreshLocations}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-[500px]">
              <p className="text-lg font-medium">No locations found</p>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between px-4 py-2 mt-4">
          <button
            onClick={goToPrevPage}
            disabled={currentPage === 1}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-sm font-medium">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default Locations;
