import React, { useState, useEffect } from "react";
import InputFieldWithLanguage from "./InputFieldWithLanguage";
import SelectInput from "components/core/SelectInput";
import InputField from "components/core/inputField";
import ToggleSwitch from "components/core/ToggleSwitch";
import MultiSelectInput from "components/core/select/MultiSelectInput";
import Checkbox from "components/core/Checkbox";
import useServices from "utils/hooks/useServices";
import useProvider from "utils/hooks/useProvider";
import { showError } from "utils/helpers";
import Button from "components/core/Button";
import locationService from "services/location/locationService";
import providersService from "services/providers/providersService";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import OptionSelector from "./OptionSelector";
import PriceComponent from "./PriceComponent";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import businessService from "services/business/businessService";
import { selectBusinessId, selectRole } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const ServiceForm = ({
  formMode,
  setFormMode,
  selectedService,
  onSave,
  locations = [],
}) => {
  // states
  const userBusinessId = useSelector(selectBusinessId);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [serviceName, setServiceName] = useState({ en: "", ar: "" });
  const [serviceCategory, setServiceCategory] = useState({});
  const [category, setCategory] = useState({});
  const [description, setDescription] = useState({ en: "", ar: "" });
  const [duration, setDuration] = useState(0);
  const [durationUnit, setDurationUnit] = useState({});
  const [price, setPrice] = useState(0);
  const [timeBasedPrices, setTimeBasedPrices] = useState([]);
  const [enabledOnlineBooking, setEnabledOnlineBooking] = useState(false);
  const [enabledHomeService, setEnabledHomeService] = useState(false);
  const [providerOptions, setProviderOptions] = useState([]);
  const [childsubservices, setChildSubservices] = useState([]);
  const [optionsType, setOptionsType] = useState("single");
  const [options, setOptions] = useState([
    {
      en: "",
      ar: "",
      duration: "",
      durationType: {
        value: "minute",
        label: "Minute",
      },
      price: "",
    },
  ]);
  const userRole = useSelector(selectRole);

  // hooks and datas
  const { businessServices: serviceCategoryOptions } = useServices();
  const { providers: providerData } = useProvider();
  const [allSelectedProviders, setAllSelectedProviders] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const durationUnits = [
    { value: "minute", label: "Minute" },
    { value: "hours", label: "Hours" },
  ];

  // handlers
  const getServiceCategory = (id) => {
    let service = serviceCategoryOptions.find((service) => service.id === id);
    return service && service.id
      ? { id: service.id, value: service.slug, label: service.name.en }
      : null;
  };

  const handleCategory = async (businessId) => {
    const businessResponse = await businessService.getBusiness(businessId);
    let category = businessResponse.business.category;
    if (category) {
      setCategory({
        id: category.id,
        label: category.name.en,
        value: category.slug,
      });
    } else {
      setCategory(null);
    }
  };

  const getSubserviceLocations = async (subserviceId) => {
    try {
      const response = await locationService.getLocationWQuery(
        `subserviceIds=[${subserviceId}]`
      );
      switch (response.statusCode) {
        case 200:
          setSelectedLocations(
            response.locations.map((location) => {
              return {
                id: location.id,
                value: location.slug,
                label: location.name.en,
              };
            })
          );
          break;
        default:
          break;
      }
    } catch (error) {
      showError(error.message);
      return [];
    }
  };

  const getLocationsProviders = async (locationIds) => {
    if (locationIds.length === 0 || !locationIds) {
      setProviderOptions([]);
      return;
    }
    let formattedLocationIds = locationIds.map((location) => location.id);
    try {
      const providerResponses = formattedLocationIds.map(async (locationId) => {
        const response = await providersService.getProvider(locationId);
        switch (response.statusCode) {
          case 200:
            return response.providers;
          default:
            showError("No providers found");
            break;
        }
      });
      const newProviderOptions = await Promise.all(providerResponses);
      const flattenedProviders = newProviderOptions.flat();
      const filteredProviders = flattenedProviders.filter(
        (provider, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.id === provider.id &&
              t.name === provider.name &&
              t.avatarPath === provider.avatarPath
          )
      );

      setProviderOptions(filteredProviders);
    } catch (error) {
      showError(error.message);
      return [];
    }
  };

  const postSubservice = async () => {
    let data = {
      name: serviceName,
      images: [],
      businessId: userBusinessId,
      serviceId:
        serviceCategory && serviceCategory.id !== null
          ? serviceCategory.id
          : null,
      description: {
        en: description.en,
        ar: description.ar,
      },
      locationIds:
        selectedLocations.length > 0
          ? selectedLocations.map((location) => location.id)
          : [],
      enabledOnlineBooking: enabledOnlineBooking,
      enabledHomeService: enabledHomeService,
      providerIds:
        selectedProviders.length > 0
          ? selectedProviders
              .filter((provider) => provider.id)
              .map((provider) => provider.id)
          : [],
    };
    if (category && category.value === "sports") {
      data.duration =
        durationUnit.value === "minute"
          ? parseInt(duration)
          : parseInt(duration * 60);
      data.price = parseInt(price);
      data.timeBasedPrices = timeBasedPrices.map((price) => {
        return {
          ...price,
          price: parseInt(price.price),
        };
      });
      data.childSubserviceIds = [];
    } else if (category && category.value === "health-and-beauty") {
      switch (optionsType) {
        case "single":
          data.childSubserviceIds = [];
          data.duration = parseInt(options[0].duration);
          data.price = parseInt(options[0].price);
          break;
        case "multiple":
          data.childSubserviceIds =
            options.map((option) => option.id).filter((id) => id) || [];
          data.duration = null;
          data.price = null;
          break;
        default:
          break;
      }

      data.timeBasedPrices = [];
    } else {
      data.price = parseInt(price) > 0 ? parseInt(price) : null;
      data.duration = parseInt(duration) > 0 ? parseInt(duration) : null;
      data.timeBasedPrices = [];
      data.childSubserviceIds = [];
    }
    onSave(data);
  };

  const renderPriceFieldBasedOnCategory = () => {
    if (category && category.id !== null) {
      if (category.value === "sports") {
        return (
          <div className="flex flex-col items-center justify-center w-full mt-4">
            <div className="flex items-center justify-center w-full mt-4">
              <p className="w-[250px] text-14 font-normal text-[#000000D9]">
                Duration: <span className="text-red-500 text-16">*</span>
              </p>
              <div className="flex items-center justify-between w-full space-x-4">
                <InputField
                  placeholder="30"
                  className="w-1/2"
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  value={duration}
                  disabled={userRole === "PROVIDER"}
                />
                <SelectInput
                  options={durationUnits}
                  className={"w-1/2"}
                  placeholder="Minute(s)"
                  onSelect={(option) => {
                    setDurationUnit(option);
                  }}
                  selected={durationUnit}
                  disabled={userRole === "PROVIDER"}
                  isRequired
                />
              </div>
            </div>
            <PriceComponent
              initialFixedPrice={price}
              initialTimeBasedPrices={timeBasedPrices}
              onFixedPriceChange={(value) => {
                setPrice(value);
              }}
              onTimeBasedPricesChange={(value) => {
                setTimeBasedPrices(value);
              }}
              disabled={userRole === "PROVIDER"}
            />
          </div>
        );
      } else if (category.value === "health-and-beauty") {
        return (
          <div className="flex flex-col w-full">
            <div className="w-full my-5 border-b" />
            <p className="mb-2 text-lg font-semibold">Option Info</p>
            <p className="text-sm text-gray-500">
              Add an option, pricing and duration
            </p>
            <div className="mt-7">
              <OptionSelector
                optionsType={optionsType}
                setOptionsType={setOptionsType}
                options={options}
                setOptions={setOptions}
                disabled={userRole === "PROVIDER"}
              />
            </div>
            <div className="w-full my-5 border-b" />
          </div>
        );
      } else {
        return (
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-center w-full mt-4">
              <label className="w-[250px] text-14 font-normal text-[#000000D9]">
                Duration: <span className="text-red-500 text-16">*</span>
              </label>
              <div className="flex items-center justify-between w-full space-x-4">
                <InputField
                  placeholder="30"
                  className="w-1/2"
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  value={duration}
                  disabled={userRole === "PROVIDER"}
                />
                <SelectInput
                  options={durationUnits}
                  className={"w-1/2"}
                  placeholder="Minute(s)"
                  onSelect={(option) => {
                    setDurationUnit(option);
                  }}
                  isDisabled={userRole === "PROVIDER"}
                />
              </div>
            </div>

            <div className="flex items-center justify-center w-full mt-4">
              <label className="w-[250px] text-14 font-normal text-[#000000D9]">
                Price: <span className="text-red-500 text-16">*</span>
              </label>
              <InputFieldWithLanguage
                placeholder="Enter the price"
                language={"KWD"}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                value={price}
                className={"w-full"}
                labelClassName={"!w-14"}
                isDisabled={userRole === "PROVIDER"}
              />
            </div>
          </div>
        );
      }
    }
  };

  // useEffects
  useEffect(() => {
    if (selectedService) {
      setServiceName(selectedService.name);
      setServiceCategory(getServiceCategory(selectedService.serviceId));
      setDescription(selectedService.description);
      setDuration(selectedService.duration);
      setDurationUnit({
        value: "minute",
        label: "Minute",
      });
      setPrice(selectedService.price);
      setTimeBasedPrices(selectedService.timeBasedPrices);
      setChildSubservices(selectedService.childSubservices);
      if (
        selectedService.childSubservices &&
        selectedService.childSubservices.length > 0
      ) {
        setOptionsType("multiple");
        setOptions(
          selectedService.childSubservices.map((subservice) => {
            return {
              id: subservice.id,
              name: {
                en: subservice.name.en ? subservice.name.en : "",
                ar: subservice.name.ar ? subservice.name.ar : "",
              },
              duration: subservice.duration,
              durationType: { value: "minute", label: "Minute" },
              price: subservice.price,
            };
          })
        );
      } else {
        setOptionsType("single");
        setOptions([
          {
            name: {
              en: "",
              ar: "",
            },
            duration: selectedService.duration ? selectedService.duration : "",
            durationType: {
              value: "minute",
              label: "Minute",
            },
            price: selectedService.price ? selectedService.price : "",
          },
        ]);
      }
      setEnabledOnlineBooking(selectedService.enabledOnlineBooking);
      setEnabledHomeService(selectedService.enabledHomeService);
      setSelectedLocations([]);
      getSubserviceLocations(selectedService.id);
      setSelectedProviders(
        selectedService.providers.map((provider) => {
          return {
            ...provider,
            label: provider.name,
            value: provider.id,
            image: provider.avatarPath,
            speciality: provider.speciality,
          };
        })
      );
    } else {
      setServiceName({ en: "", ar: "" });
      setServiceCategory(null);
      setDescription({ en: "", ar: "" });
      setDuration();
      setPrice();
      setTimeBasedPrices([]);
      setEnabledOnlineBooking(false);
      setEnabledHomeService(false);
      setSelectedLocations([]);
      setSelectedProviders([]);
      setOptionsType("single");
      setOptions([
        {
          name: {
            en: "",
            ar: "",
          },
          duration: "",
          durationType: {
            value: "minute",
            label: "Minute",
          },
          price: "",
        },
      ]);
    }
  }, [selectedService, locations, serviceCategoryOptions]);

  useEffect(() => {
    getLocationsProviders(selectedLocations);
  }, [selectedLocations]);

  useEffect(() => {
    userBusinessId && handleCategory(userBusinessId);
  }, [userBusinessId]);

  return (
    <div className="flex flex-col h-full px-5 bg-white border rounded-md shadow-md">
      {/* Header */}
      <div className="mt-4">
        <p className="text-16 font-medium leading-6 text-[#000000D9]">
          {formMode === "add" ? "Add new service" : "Edit service"}
        </p>
      </div>

      <div className="w-full mt-6 border-b" />

      <div className="mt-6">
        <h2 className="mb-2 text-14 font-bold text-[#000000D9]">Basic info</h2>
        <p className="text-12 font-normal text-[#00000073]">
          Add a service and key features
        </p>
      </div>

      {/* Service Name */}
      <div className={`flex w-full mt-5`}>
        <label className="mt-2 w-[250px] justify-start text-14 font-normal text-[#000000D9]">
          Service name: <span className="text-red-500">*</span>
        </label>
        <div className="flex flex-col w-full space-y-2">
          <InputFieldWithLanguage
            placeholder="Enter the name"
            language={"En"}
            onChange={(e) => {
              setServiceName({ ...serviceName, en: e.target.value });
            }}
            value={serviceName.en}
            className={""}
            isDisabled={userRole === "PROVIDER"}
          />
          <InputFieldWithLanguage
            placeholder="أدخل الاسم"
            language={"Ar"}
            dir="rtl"
            onChange={(e) => {
              setServiceName({ ...serviceName, ar: e.target.value });
            }}
            value={serviceName.ar}
            isDisabled={userRole === "PROVIDER"}
          />
        </div>
      </div>

      {/* Service Category */}
      <div className="flex items-center justify-center w-full mt-4">
        <label className="w-[250px] text-14 font-normal text-[#000000D9]">
          Service category:
        </label>
        <SelectInput
          options={serviceCategoryOptions.map((service) => {
            return {
              id: service.id,
              value: service.slug,
              label: service.name.en,
            };
          })}
          className={"w-full"}
          placeholder="Choose service category"
          onSelect={(option) => {
            setServiceCategory(option);
          }}
          selected={serviceCategory}
          isDisabled={userRole === "PROVIDER"}
        />
      </div>

      {/* Description */}
      <div className="flex w-full mt-5">
        <label className="mt-2 w-[250px] text-14 font-normal text-[#000000D9]">
          Description:
        </label>
        <div className="flex flex-col w-full space-y-2">
          <InputFieldWithLanguage
            placeholder="Add a short description"
            language={"En"}
            onChange={(e) => {
              setDescription({ ...description, en: e.target.value });
            }}
            className={"h-24"}
            value={description.en}
            isDisabled={userRole === "PROVIDER"}
          />
          <InputFieldWithLanguage
            placeholder="أضف وصفًا قصيرًا"
            language={"Ar"}
            dir="rtl"
            onChange={(e) => {
              setDescription({ ...description, ar: e.target.value });
            }}
            className={"h-24"}
            value={description.ar}
            isDisabled={userRole === "PROVIDER"}
          />
        </div>
      </div>

      {/* Price */}
      <div className="flex w-full mt-4 ">
        {renderPriceFieldBasedOnCategory()}
      </div>

      {/* ToggleSwitchs */}
      <div className="flex items-center justify-start w-full mt-4">
        <label className="w-[200px] text-14 font-normal text-[#000000D9]">
          Enable online booking:
        </label>
        <div
          className="cursor-pointer w-fit"
          onClick={() => {
            userRole !== "PROVIDER" &&
              setEnabledOnlineBooking(!enabledOnlineBooking);
          }}
        >
          <ToggleSwitch
            enabled={enabledOnlineBooking}
            disabled={userRole === "PROVIDER"}
          />
        </div>
      </div>
      <div className="flex items-center justify-start w-full mt-4">
        <label className="w-[200px] text-14 font-normal text-[#000000D9]">
          Enable home service:
        </label>
        <div
          className="cursor-pointer w-fit"
          onClick={() => {
            userRole !== "PROVIDER" &&
              setEnabledHomeService(!enabledHomeService);
          }}
        >
          <ToggleSwitch
            enabled={enabledHomeService}
            disabled={userRole === "PROVIDER"}
          />
        </div>
      </div>

      {/* Locations */}
      <div className="w-full my-5 border-b" />
      <p className="mb-2 text-lg font-semibold">Locations</p>
      <p className="mb-5 text-sm text-gray-500">
        Choose location where this service provided
      </p>
      <Checkbox
        label={"All"}
        checked={allSelected}
        onChange={() => {
          setAllSelected(!allSelected);
          setSelectedLocations(
            allSelected ? [] : locations.map((option) => option)
          );
        }}
        disabled={userRole === "PROVIDER"}
      />
      <MultiSelectInput
        options={locations.map((location) => {
          return {
            ...location,
            label: location.name.en,
            value: location.slug,
          };
        })}
        selectedValues={
          allSelected
            ? locations.map((option) => {
                return {
                  ...option,
                  label: option.name.en,
                  value: option.slug,
                };
              })
            : selectedLocations
        }
        checkboxClassName={
          "grid grid-cols-2 space-y-0 gap-4 h-fit items-center justify-start"
        }
        className={"w-full space-x-2 h-fit mt-4"}
        onChange={setSelectedLocations}
        disabled={userRole === "PROVIDER"}
      />

      <div className="w-full my-5 border-b" />

      {/* Providers */}
      <p className="mb-2 text-lg font-semibold">Providers</p>
      <p className="mb-5 text-sm text-gray-500">
        Choose providers that can do this service.
      </p>

      <Checkbox
        label={"Select All"}
        checked={allSelectedProviders}
        onChange={() => {
          const allData = providerOptions.map((provider) => {
            return {
              ...provider,
              label: provider.name,
              value: provider.id,
              image: provider.avatarPath,
              speciality: provider.speciality,
            };
          });
          setSelectedProviders(
            !allSelectedProviders
              ? allData
              : providerData.map((option) => option)
          );
          setAllSelectedProviders(!allSelectedProviders);
        }}
        disabled={userRole === "PROVIDER"}
      />
      <MultiSelectInput
        options={providerOptions.map((provider) => {
          return {
            ...provider,
            label: provider.name,
            value: provider.id,
            image: provider.avatarPath,
            speciality: provider.speciality,
          };
        })}
        selectedValues={selectedProviders}
        checkboxClassName={
          "grid grid-cols-2 space-y-0 gap-4 h-fit items-center justify-start"
        }
        className={"w-full space-x-2 h-fit mt-4"}
        onChange={(e) => {
          setSelectedProviders(e);
        }} //setSelectedProviders
        disabled={userRole === "PROVIDER"}
      />

      {/* Footer with actions */}
      <div className="flex items-center justify-end w-full p-4">
        <Button
          label={"Cancel"}
          className="w-40 bg-white border border-[#FFA39E] rounded-xl text-black"
          textColor="text-black"
          onClick={() => {
            setFormMode(null);
          }}
        />
        <Button
          label={"Save"}
          className={`w-40 ml-4  rounded-xl  ${
            userRole === "PROVIDER"
              ? "!cursor-not-allowed bg-[#E0E0E0] text-[#BDBDBD]"
              : "bg-[#DE0607] text-white"
          }`}
          textColor="text-white"
          onClick={userRole === "PROVIDER" ? null : postSubservice}
        />
      </div>
    </div>
  );
};

export default ServiceForm;
