import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CustomerBookingsTableList = ({ lastBookings }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [lastRowIndex, setLastRowIndex] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  const headers = ["Customer Name", "Booking date", "Price"];
  const pageNumbers = [];

  useEffect(() => {
    let last = currentPage * rowsPerPage;
    let first = last - rowsPerPage;
    setLastRowIndex(last);
    setFirstRowIndex(first);
    const currentData = lastBookings.slice(first, last);
    setCurrentData(currentData);
  }, [lastBookings, currentPage, rowsPerPage]);

  for (let i = 1; i <= Math.ceil(lastBookings.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [lastBookings]);

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === pageNumbers.length ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      return (
        <button
          key={number}
          id={number}
          onClick={() => setCurrentPage(number)}
          className={`w-8 h-8 text-14 border bg-white rounded-md hover:bg-gray-200 ${
            currentPage === number
              ? "text-[#DE0607] border-[#DE0607]  "
              : "text-[#000000D9] border-[#D9D9D9] "
          }`}
        >
          {number}
        </button>
      );
    } else if (number >= currentPage - 2 && number <= currentPage + 2) {
      return <span key={number}>...</span>;
    } else {
      return null;
    }
  });

  return (
    <div className="bg-white">
      <table className="w-full leading-normal">
        <thead>
          <tr>
            {headers.map((header, index) => {
              const isLastItem = index === headers.length - 1;
              return (
                <th
                  key={index}
                  className={`relative w-[201px] px-3 py-3 text-14 font-medium text-sm leading-22 tracking-wider text-left text-[#000000D9] uppercase bg-[#FAFAFA]`}
                >
                  {header}
                  {!isLastItem && (
                    <div
                      className="absolute inset-y-0 right-0 w-0.5 bg-[#0000000F]"
                      style={{ height: "47%", top: "26.5%", opacity: 1 }}
                    />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => {
            return item.bookings.map((booking, idx) => (
              <tr key={idx}>
                <td className="px-4 py-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200">
                  {booking.userName}
                </td>
                <td className="px-4 py-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200">
                  {new Date(booking.date).toLocaleDateString()}
                </td>
                <td className="px-4 py-4 text-14 font-normal text-[#000000D9] bg-white border-b border-gray-200">
                  {booking.totalPrice}
                </td>
              </tr>
            ));
          })}
        </tbody>
      </table>

      <div className="flex items-center justify-end mt-4 space-x-2">
        <button
          onClick={prevPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowBack width={7} height={10} color="#000000D9" />
        </button>
        <div className="flex space-x-2">{renderPageNumbers}</div>
        <button
          onClick={nextPage}
          className="flex items-center justify-center w-8 h-8 text-sm bg-white border border-[#D9D9D9] rounded-md hover:bg-gray-300"
        >
          <IoIosArrowForward width={7} height={10} color="#000000D9" />
        </button>
      </div>
    </div>
  );
};

export default CustomerBookingsTableList;
