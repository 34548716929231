import api from "../apiConfig";

const invoicesService = {
  // GET request to retrieve businesses
  getInvoices: async (dateStart, dateEnd) => {
    try {
      const response = await api.get(`/invoice?dates=["${dateStart}","${dateEnd}"]`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  getInvoice: async (id) => {
    try {
      const response = await api.get(`/invoice/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new business
  addInvoices: async (data) => {
    try {
      const response = await api.post("/invoices", data);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing business
  updateInvoices: async (id, updateData) => {
    try {
      const response = await api.put(`/invoices/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing invoices status
  updateInvoicesStatus: async (id, updateData) => {
    try {
      const response = await api.put(`/invoices/${id}/update-status`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a invoice
  deleteInvoices: async (id) => {
    try {
      const response = await api.delete(`/invoices/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default invoicesService;
