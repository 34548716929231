import React, { useEffect, useState, useMemo } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import plus_icon from "../../assets/Icons/plus_icon.png";
import BookingDetails from "./components/BookingDetails/BookingDetails";
import ListViewCalendar from "./components/ListView/ListViewCalendar";
import ListViewComplatedCalendar from "./components/ListView/ListViewComplatedCalendar";
import BookingDetailsHalfScreen from "./components/BookingDetailsHalfScreen/BookingDetailsHalfScreen";
import usersService from "services/users/usersService";
import bookingService from "services/booking/bookingService";
import locationService from "services/location/locationService";
import subserviceServices from "services/service/subserviceServices";
import SelectInput from "components/core/SelectInput";
import useUsers from "utils/hooks/useUsers";
import providersService from "services/providers/providersService";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBookingData,
  setSpecificBookingData,
} from "../../redux/bookings/bookingsSlice";
import { showError, showInfo } from "utils/helpers";
import {
  selectBusinessId,
  selectRole,
  selectUser,
} from "../../redux/auth/authSlice";
import locationManagerService from "services/locationManager/locationManagerService";
import BookingDetailsBig from "./components/BookingDetailsBig/BookingDetailsBig";
import useProvider from "utils/hooks/useProvider";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";

const localizer = momentLocalizer(moment);

const getInitials = (text) => {
  if (!text) return "";
  const words = text.split(" ");
  let initials = "";

  for (let i = 0; i < Math.min(2, words.length); i++) {
    if (words[i].length > 0) {
      initials += words[i][0];
    }
  }

  return initials.toUpperCase();
};

const BookingTabHeader = ({
  selectedTab,
  setSelectedTab,
  showModal,
  setIsCreate,
  locations,
  setSelectedLocation,
  fetchEmployees,
  fetchBookings,
  selectedLocation,
}) => {
  const dispatch = useDispatch();
  const handleLocationChange = (e) => {
    dispatch(setSpecificBookingData({ field: "locationId", value: e.value }));
    setSelectedLocation(e.value);
    fetchBookings();
  };
  const userRole = useSelector(selectRole);
  return (
    <header className="header">
      <nav className="navigation">
        <ul className="nav-list">
          <li
            onClick={() => setSelectedTab("calendarView")}
            className={
              selectedTab === "calendarView" ? "nav-item active" : "nav-item"
            }
          >
            <a href="#">Calendar view</a>
          </li>
          <li
            onClick={() => setSelectedTab("listView")}
            className={
              selectedTab === "listView" ? "nav-item active" : "nav-item"
            }
          >
            <a href="#">List view</a>
          </li>
          <li
            onClick={() => setSelectedTab("completedView")}
            className={
              selectedTab === "completedView" ? "nav-item active" : "nav-item"
            }
          >
            <a href="#">Completed</a>
          </li>
        </ul>
      </nav>
      <div className="dropdown">
        <SelectInput
          options={locations.map((item) => {
            return {
              value: item.id,
              label: item.name.en,
            };
          })}
          onSelect={(e) => handleLocationChange(e)}
          selected={locations.map((item) => {
            if (
              Object.keys(selectedLocation).length === 0 &&
              item.id === selectedLocation
            ) {
              return {
                value: item.id,
                label: item.name.en,
              };
            }
            if (item.id === selectedLocation.value) {
              return {
                value: item.id,
                label: item.name.en,
              };
            }
          })}
          placeholder="Select location"
          inputClassName="w-[300px]"
          isClearable={false}
        />
      </div>

      {userRole && userRole !== "PROVIDER" ? (
        <div className="actions-container-booking">
          <button
            onClick={() => {
              setIsCreate(true);
              showModal();
            }}
            className="add-booking"
          >
            <img className="plus-icon" src={plus_icon} alt="Add booking" />
            Add Booking
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </header>
  );
};

const CalendarViewHeader = ({
  selectedTab,
  employees,
  changeDate,
  currentDate,
  selectedEmployee,
  bookings,
  userRole,
  userProfile,
}) => {
  let filteredBookings =
    userRole === "PROVIDER"
      ? bookings.filter(
          (booking) =>
            booking.status !== "CANCELED" &&
            booking.status !== "COMPLETED" &&
            booking.status !== "NO_SHOW" &&
            booking.provider.id === userProfile.id
        )
      : bookings.filter(
          (booking) =>
            booking.status !== "CANCELED" &&
            booking.status !== "COMPLETED" &&
            booking.status !== "NO_SHOW"
        );

  return (
    <div className="calendar-header">
      <h1>
        {selectedTab === "calendarView" || selectedTab === "listView"
          ? filteredBookings && filteredBookings.length
            ? filteredBookings.length
            : 0
          : bookings.length}{" "}
        Customers
      </h1>
      {selectedEmployee == null && (
        <div className="date-picker-calendar">
          <input
            onChange={(e) => changeDate(e.target.value)}
            value={currentDate}
            type="date"
          />
        </div>
      )}
    </div>
  );
};

const CustomHeader = ({
  resource: { resourceId, resourceTitle },
  label,
  ...props
}) => {
  return (
    <div className="custom-header-container">
      <div className="employeeAvatarContainer">
        <p className="avatarEmployeeName">{getInitials(resourceTitle)}</p>
      </div>
      <p className="custom-hd-label">{resourceTitle}</p>
    </div>
  );
};

const CustomEvent = ({
  event: { title, bgColor, textColor, lineColor, customerName, start, end },
  label,
  ...props
}) => {
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const titleText =
    typeof title === "object" && title !== null ? title.en : title;

  return (
    <div
      style={{ backgroundColor: bgColor, borderLeftColor: lineColor }}
      className="custom-event-container"
    >
      <p style={{ color: textColor }} className="custom-event-time">
        {formatAMPM(start)} - {formatAMPM(end)}
      </p>
      <p style={{ color: textColor }} className="custom-event-customer-name">
        {customerName}
      </p>
      <p style={{ color: textColor }} className="custom-event-service-name">
        {titleText}
      </p>
    </div>
  );
};

const CustomHeaderSpecificPerson = ({
  resource: { resourceId, resourceTitle },
  label,
  ...props
}) => {
  if (resourceTitle != null) {
    return (
      <div className="custom-header-container">
        <div className="employeeAvatarContainer">
          <p className="avatarEmployeeName">{getInitials(resourceTitle)}</p>
        </div>
        <p className="custom-hd-label">{resourceTitle}</p>
      </div>
    );
  }
};

const Bookings = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const userBusinessId = useSelector(selectBusinessId);
  const userProfile = useSelector(selectUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBig, setIsModalOpenBig] = useState(false);
  const [selectedTab, setSelectedTab] = useState("calendarView");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [bookings, setBookings] = useState([]);
  const dispatch = useDispatch();
  const { fetchProfile } = useUsers();

  const [employees, setEmployees] = useState([]);
  const [resourceMap, setResourceMap] = useState([]);
  const [resourceSpecificEmployee, setResourceSpecificEmployee] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [profile, setProfile] = useState({});
  const userId = useSelector(selectUser).id;
  const userRole = useSelector(selectRole);

  const setLocationEmployees = async (selectedLocation) => {
    if (userRole === "PROVIDER") {
      const response = await providersService.getProviderById(userId);
      const resourceMapTemp = {
        resourceId: parseInt(response.provider.id),
        resourceTitle: response.provider.name,
      };
      const resourceSpecificEmployeeTemp = {
        resourceId: parseInt(response.provider.id),
        resourceTitle: response.provider.name,
      };
      setResourceMap([resourceMapTemp]);
      setResourceSpecificEmployee([resourceSpecificEmployeeTemp]);
      return;
    }
    const response = await providersService.getProvider(selectedLocation);

    if (response.providers && response.providers.length > 0) {
      setEmployees(response.providers);

      const resourceMapTemp = response.providers.map((employee) => {
        return {
          resourceId: parseInt(employee?.id),
          resourceTitle: employee?.name,
        };
      });

      const resourceSpecificEmployeeTemp = response.providers
        .filter((employee, index) => index === selectedEmployee)
        .map((employee, index) => {
          return {
            resourceId: parseInt(employee?.id),
            resourceTitle: employee?.name,
          };
        });

      setResourceMap(resourceMapTemp);
      setResourceSpecificEmployee(resourceSpecificEmployeeTemp);
    } else {
      setEmployees([]);
      setResourceMap([]);
      setResourceSpecificEmployee([]);
    }
  };

  const calculateDuration = (date, duration) => {
    if (date === undefined || duration === undefined) {
      return;
    }
    const utcDate = moment(date);
    const localISODate = utcDate.toISOString();
    const startDate = moment(localISODate);
    const addedHours = duration / 60;
    const addedMinutes = (duration % 60) / 60;
    const startDateClone = moment(startDate);
    startDateClone.add(addedHours, "hours");
    startDateClone.add(addedMinutes, "minutes");
    return startDateClone;
  };

  useEffect(() => {
    fetchProfile().then((response) => {
      setProfile(response);
    });
  }, []);

  const updateBookingsSpecificEmployee = async (employee) => {
    const response = await providersService.getProvider(selectedLocation);

    if (response.providers && response.providers.length > 0) {
      setEmployees(response.providers);

      const resourceSpecificEmployeeTemp = response.providers
        .filter((item, index) => item.id === employee.id)
        .map((employee, index) => {
          return {
            resourceId: parseInt(employee?.id),
            resourceTitle: employee?.name,
          };
        });

      setResourceSpecificEmployee(resourceSpecificEmployeeTemp);
      getBookingsWEmployee(resourceSpecificEmployeeTemp[0].resourceId);
    }
  };

  const getBookingsWEmployee = async (employeeId) => {
    setBookings([]);
    const response = await bookingService.getBookingsWProviderIdLocation(
      employeeId,
      selectedLocation
    );
    if (employeeId === undefined || selectedLocation === undefined) {
      return;
    }
    if (
      response?.bookings?.length <= 0 ||
      response.bookings === undefined ||
      response.statusCode === 404
    ) {
      setBookings([]);
      return null;
    }

    const bookingPromises = response.bookings.map(async (booking) => {
      if (booking.locationId !== selectedLocation) {
        return null;
      }
      const utcDate = moment(booking.date).utc(false);
      const localISODate = utcDate.toISOString();
      const startDate = localISODate;
      const endDate = calculateDuration(startDate, booking.duration);
      const dateStart = moment(booking.date).utc(false);

      const exectStart = new Date(
        dateStart.year(),
        dateStart.month(),
        dateStart.date(),
        dateStart.hours(),
        dateStart.minutes()
      );
      const exectEnd = new Date(
        endDate.year(),
        endDate.month(),
        endDate.date(),
        endDate.hours(),
        endDate.minutes()
      );

      let responsesub = null;
      try {
        if (booking.subserviceIds.length > 0) {
          responsesub = await subserviceServices.getSubservice(
            booking.subserviceIds[0],
            false
          );
        }
        if (booking.childSubserviceIds.length > 0) {
          responsesub = await childSubserviceServices.getSubservice(
            booking.childSubserviceIds[0],
            false
          );
        }
      } catch (e) {
        showError(e.message || "An error occurred while fetching subservice");
      }

      let lineColor;
      let textColor;
      let bgColor;
      if (booking.status === "PENDING") {
        lineColor = "#FADB14";
        textColor = "#D4B106";
        bgColor = "#FEFFE6";
      } else if (
        booking.status === "COMPLETED" ||
        booking.status === "CONFIRMED"
      ) {
        lineColor = "#389E0D";
        textColor = "#52C41A";
        bgColor = "#F6FFED";
      } else {
        lineColor = "#722ED1";
        textColor = "#9254DE";
        bgColor = "#F9F0FF";
      }
      return {
        ...booking,
        lineColor,
        textColor,
        bgColor,
        resourceId: booking.provider.id,
        start: new Date(exectStart),
        end: new Date(exectEnd),
        customerName: booking.userName,
        title:
          responsesub?.subservice?.name?.en || responsesub?.subservice?.name,
      };
    });

    const filteredBookings = await Promise.all(bookingPromises);
    setBookings(filteredBookings.filter(Boolean));
  };

  const getBookings = async (date) => {
    setBookings([]);
    const queryDate = date ? date : currentDate;
    if (queryDate === undefined || selectedLocation === undefined) {
      return;
    }
    const response = await bookingService.getBookingsWDateLocation(
      queryDate,
      selectedLocation
    );
    if (
      response?.bookings?.length <= 0 ||
      response.bookings === undefined ||
      response.statusCode === 404
    ) {
      setBookings([]);
      showInfo("No bookings found for the selected date and location");
      return;
    }
    const bookingPromises = response.bookings.map(async (booking) => {
      if (booking.locationId !== selectedLocation) {
        return null;
      }

      const utcDate = moment(booking.date).utc(false);
      const localISODate = utcDate.toISOString();
      const startDate = new Date(localISODate);
      const endDate = moment(
        calculateDuration(startDate, booking.duration)
      ).utc(false);
      const dateStart = moment(booking.date).utc(false);
      const exectStart = new Date(
        dateStart.year(),
        dateStart.month(),
        dateStart.date(),
        dateStart.hours(),
        dateStart.minutes()
      );
      const exectEnd = new Date(
        endDate.year(),
        endDate.month(),
        endDate.date(),
        endDate.hours(),
        endDate.minutes()
      );

      let responsesub = null;
      try {
        if (booking.subserviceIds.length > 0) {
          responsesub = await subserviceServices.getSubservice(
            booking.subserviceIds[0],
            false
          );
        }
        if (booking.childSubserviceIds.length > 0) {
          responsesub = await childSubserviceServices.getSubservice(
            booking.childSubserviceIds[0],
            false
          );
        }
      } catch (e) {
        showError(e.message || "An error occurred while fetching subservice");
      }

      let lineColor;
      let textColor;
      let bgColor;
      if (booking.status === "PENDING") {
        lineColor = "#FADB14";
        textColor = "#D4B106";
        bgColor = "#FEFFE6";
      } else if (booking.status === "CONFIRMED") {
        lineColor = "#389E0D";
        textColor = "#52C41A";
        bgColor = "#F6FFED";
      } else if (booking.status === "COMPLETED") {
        lineColor = "#389E0D";
        textColor = "#52C41A";
        bgColor = "#F6FFED";
      } else {
        lineColor = "#722ED1";
        textColor = "#9254DE";
        bgColor = "#F9F0FF";
      }
      return {
        ...booking,
        lineColor,
        textColor,
        bgColor,
        resourceId: booking.provider.id,
        start: exectStart,
        end: exectEnd,
        customerName: booking.userName,
        title:
          responsesub?.subservice?.name?.en || responsesub?.subservice?.name,
      };
    });
    const filteredBookings = await Promise.all(bookingPromises);
    setBookings(filteredBookings.filter(Boolean));
  };

  const fetchLocationsProvider = async () => {
    try {
      const providerResponse = await providersService.getProviderById(userId);
      switch (providerResponse.statusCode) {
        case 200:
          if (providerResponse.provider.activeLocations.length === 0) {
            setLocations([]);
            setSelectedLocation(null);
            break;
          }
          setLocations(providerResponse.provider.activeLocations);
          setSelectedLocation(providerResponse.provider.activeLocations[0].id);
          dispatch(
            setSpecificBookingData({
              field: "locationId",
              value: providerResponse.provider.activeLocations[0].id,
            })
          );
          break;
        case 404:
          setLocations([]);
          break;
        default:
          break;
      }
    } catch (e) {
      showError(e.message || "An error occurred while fetching locations");
    }
  };

  const fetchLocations = async () => {
    if (userRole === "PROVIDER") {
      fetchLocationsProvider();
      return;
    }
    const response = await locationService.getLocationWQuery(
      `businessId=${userBusinessId}`
    );
    switch (response.statusCode) {
      case 200:
        if (
          response.locations.length === 0 ||
          userBusinessId == null ||
          userBusinessId === undefined
        ) {
          setLocations([]);
          setSelectedLocation(null);
          break;
        }
        setLocations(response.locations);
        setSelectedLocation(response.locations[0].id);
        dispatch(
          setSpecificBookingData({
            field: "locationId",
            value: response.locations[0].id,
          })
        );
        break;
      case 404:
        setLocations([]);
        break;
      default:
        break;
    }
  };

  const fetchLocationManagerLocations = async () => {
    const response = await locationManagerService.getLocationManager(
      userProfile.id
    );
    switch (response.statusCode) {
      case 200:
        setLocations(response.locationManager.managedLocations);
        setSelectedLocation(response.locationManager.managedLocations[0].id);
        dispatch(
          setSpecificBookingData({
            field: "locationId",
            value: response.locationManager.managedLocations[0].id,
          })
        );
        break;
      case 404:
        setLocations([]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedLocation != null) {
      setBookings([]);
      setLocationEmployees(selectedLocation);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
      if (selectedEmployee != null) {
        getBookingsWEmployee(selectedEmployee);
      } else {
        getBookings(date);
      }
      setCurrentDate(new Date().toISOString().split("T")[0]);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (profile.roles && profile.roles.includes("LOCATION_MANAGER")) {
      fetchLocationManagerLocations();
    } else {
      fetchLocations();
    }
  }, [profile]);

  const PageContent = ({ selectedTab, updateSelectedTab, showModal }) => {
    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 7);

    if (selectedTab === "calendarView") {
      return (
        <div className="calendar-container">
          {selectedEmployee == null ? (
            <Calendar
              onDoubleClickEvent={(event) => openModal(event)}
              localizer={localizer}
              events={bookings.filter(
                (booking) =>
                  booking.status !== "CANCELED" &&
                  booking.status !== "COMPLETED" &&
                  booking.status !== "NO_SHOW"
              )}
              resources={resourceMap}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
              startAccessor="start"
              endAccessor="end"
              style={{ height: "100vh", borderWidth: 0 }}
              defaultView={Views.DAY}
              date={currentDate}
              toolbar={false}
              components={{
                resourceHeader: CustomHeader,
                event: CustomEvent,
              }}
            />
          ) : (
            <Calendar
              onDoubleClickEvent={(event) => openModal(event)}
              localizer={localizer}
              events={bookings.filter(
                (booking) =>
                  booking.status !== "CANCELED" &&
                  booking.status !== "COMPLETED" &&
                  booking.status !== "NO_SHOW"
              )}
              resources={resourceSpecificEmployee}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
              startAccessor="start"
              endAccessor="end"
              style={{ height: "100vh", borderWidth: 0 }}
              defaultView={Views.WEEK}
              toolbar={false}
              components={{
                resourceHeader: CustomHeaderSpecificPerson,
                event: CustomEvent,
              }}
            />
          )}
        </div>
      );
    } else if (selectedTab === "listView") {
      return (
        <ListViewCalendar
          fetchBookings={() => getBookings()}
          openListModal={(value) => openListModal(value)}
          bookings={bookings.filter(
            (booking) =>
              booking.status !== "CANCELED" &&
              booking.status !== "COMPLETED" &&
              booking.status !== "NO_SHOW"
          )}
        />
      );
    } else if (selectedTab === "completedView") {
      return <ListViewComplatedCalendar bookings={bookings} />;
    }
  };

  const [bookingDetails, setBookingDetails] = useState({});
  const openModal = (e) => {
    setBookingDetails(e);
    setIsModalOpen(true);
  };
  const openListModal = (booking) => {
    setIsCreate(false);
    setBookingDetails(booking);
    //setIsBookingDetailsHalfModalShowing(true);
    //setIsModalOpen(true);
    setIsModalOpenBig(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpenBig(false);
  };

  const updateSelectedEmployee = (index) => {
    setSelectedEmployee(index);
  };

  const updateSelectedTab = (value) => {
    setSelectedTab(value);
  };

  const [
    isBookingDetailsHalfModalShowing,
    setIsBookingDetailsHalfModalShowing,
  ] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const handleIsCreate = (value) => {
    setIsCreate(value);
  };

  const showModal = () => {
    setIsBookingDetailsHalfModalShowing(true);
  };

  const hideModal = () => {
    setIsBookingDetailsHalfModalShowing(false);
    dispatch(clearBookingData());
  };

  const updateSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const changeDate = (value) => {
    getBookings(value);
    setCurrentDate(new Date(value).toISOString().split("T")[0]);
  };

  return (
    <div className="app">
      <BookingDetails
        fetchBookings={() => getBookings()}
        isCreate={isCreate}
        isOpen={isModalOpen}
        onClose={closeModal}
        bookingDetails={bookingDetails}
      />
      <BookingDetailsBig
        fetchBookings={() => getBookings()}
        isCreate={isCreate}
        isOpen={isModalOpenBig}
        onClose={closeModal}
        bookingDetails={bookingDetails}
      />
      <BookingDetailsHalfScreen
        employees={employees}
        bookingDetails={bookingDetails}
        selectedLocation={selectedLocation}
        fetchBookings={() => getBookings()}
        selectedLocationId={selectedLocation}
        isCreate={isCreate}
        isShowing={isBookingDetailsHalfModalShowing}
        hide={hideModal}
      />
      <BookingTabHeader
        selectedLocation={selectedLocation}
        fetchEmployees={() => setLocationEmployees()}
        fetchBookings={() => getBookings()}
        setSelectedLocation={(value) => updateSelectedLocation(value)}
        locations={locations}
        setIsCreate={(value) => handleIsCreate(value)}
        showModal={() => showModal()}
        selectedTab={selectedTab}
        setSelectedTab={(value) => updateSelectedTab(value)}
      />
      <CalendarViewHeader
        selectedEmployee={selectedEmployee}
        currentDate={currentDate}
        changeDate={(value) => changeDate(value)}
        employees={employees}
        selectedTab={selectedTab}
        updateSelectedTab={(value) => updateSelectedTab(value)}
        bookings={
          selectedTab === "completedView"
            ? bookings.filter((booking) => booking.status !== "CONFIRMED")
            : bookings
        }
        userRole={userRole}
        userProfile={userProfile}
      />
      <div className="app-container">
        <Sidebar
          updateBookingsSpecificEmployee={(employee) =>
            updateBookingsSpecificEmployee(employee)
          }
          selectedEmployee={selectedEmployee}
          updateSelectedEmployee={(index) => updateSelectedEmployee(index)}
          employees={employees}
        />
        <PageContent
          showModal={() => showModal()}
          selectedTab={selectedTab}
          updateSelectedTab={(value) => updateSelectedTab(value)}
        />
      </div>
    </div>
  );
};

const Sidebar = ({
  updateBookingsSpecificEmployee,
  employees,
  updateSelectedEmployee,
  selectedEmployee,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const filteredEmployees = useMemo(() => {
    if (!searchTerm) return employees;
    return employees.filter((employee) =>
      employee.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, employees]);

  return (
    <div className="sidebar">
      <p
        className="cursor-pointer viewAllBt "
        onClick={() => {
          updateSelectedEmployee(null);
        }}
      >
        View all
      </p>
      <div className="search-bar">
        <form className="search-bar-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button type="submit">🔍</button>
        </form>
      </div>

      {filteredEmployees.map((employee, index) => {
        return (
          <Employee
            index={index}
            updateSelectedEmployee={(index) => updateSelectedEmployee(index)}
            selectedEmployee={selectedEmployee}
            key={employee.id}
            employee={employee}
            updateBookingsSpecificEmployee={(employee) =>
              updateBookingsSpecificEmployee(employee)
            }
          />
        );
      })}
    </div>
  );
};

const Employee = ({
  employee,
  selectedEmployee,
  updateSelectedEmployee,
  index,
  updateBookingsSpecificEmployee,
}) => {
  return (
    <button
      onClick={() => {
        if (selectedEmployee !== index) {
          updateSelectedEmployee(index);
          updateBookingsSpecificEmployee(employee);
        } else {
          updateSelectedEmployee(null);
        }
      }}
      className={index === selectedEmployee ? "employeeSelected" : "employee"}
    >
      <div className="employeeAvatarContainer">
        <p className="avatarEmployeeName">{getInitials(employee.name)}</p>
      </div>
      <div>
        <div className="nameTextAvatar">{employee.name}</div>
        <div className="specialty">{employee.specialty}</div>
      </div>
    </button>
  );
};

export default Bookings;
