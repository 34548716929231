import InputFieldWithLanguage from "components/Services/ServicesTab/InputFieldWithLanguage";
import Button from "components/core/Button";
import SelectInput from "components/core/SelectInput";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { LuTrash } from "react-icons/lu";
import { IoMdAdd } from "react-icons/io";
import locationService from "services/location/locationService";
import { showError, showSuccess } from "utils/helpers";

const DeliveryFeeItem = ({
  cityOptions,
  onSave,
  onCancel,
  onDelete,
  onEdit,
  fee,
}) => {
  const [selectedCities, setSelectedCities] = useState(fee?.cities || []);
  const [deliveryFee, setDeliveryFee] = useState(fee?.deliveryFee || "");
  const [editedFee, setEditedFee] = useState("");
  const [editedCities, setEditedCities] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setSelectedCities(fee?.cities || []);
    setDeliveryFee(fee?.deliveryFee || "");
    setEditedFee(fee?.deliveryFee || "");
    setEditedCities(fee?.cities || []);
  }, [fee]);

  const handleSave = () => {
    onSave(fee.id, selectedCities, deliveryFee, fee.isNew);
    setIsEditing(false);
  };

  const handleCancel = (id) => {
    if (isEditing) {
      onCancel(id);
      setIsEditing(false);
      setSelectedCities(editedCities);
      setDeliveryFee(editedFee);
    }
  };

  return (
    <div className="flex mt-5">
      <p className="min-w-[200px] h-8 text-14 font-medium leading-6 text-[#000000D9]">
        Delivery Fee:
      </p>
      <div className="flex flex-col w-full ">
        <SelectInput
          options={cityOptions}
          selected={selectedCities}
          onSelect={setSelectedCities}
          className={"w-full"}
          labelClassName={"!w-14"}
          isMulti
          isDisabled={!isEditing}
        />
        <InputFieldWithLanguage
          placeholder="Enter the price"
          language={"KWD"}
          onChange={(e) => setDeliveryFee(e.target.value)}
          value={deliveryFee}
          className={"w-full mt-2"}
          labelClassName={"!w-14"}
          isDisabled={!isEditing}
        />
        <div className="flex items-center justify-end w-full pt-4 bg-white ">
          <Button
            label="Cancel"
            className={`w-40 p-2  rounded-xl
            ${
              isEditing
                ? "text-[#000000D9] bg-white border border-[#FFA39E]"
                : "text-[#00000040] bg-[#F5F5F5] border border-[#D9D9D9] cursor-default"
            }

                `}
            textColor="text-[#000000D9]"
            onClick={() => handleCancel(fee.id)}
          />
          <Button
            label="Save"
            className={`w-40 p-2 ml-4 rounded-xl
            ${
              isEditing
                ? "text-white bg-[#DE0607]"
                : "text-[#00000040] bg-[#F5F5F5] border border-[#D9D9D9] cursor-default"
            }
            `}
            textColor="text-white"
            onClick={() => (isEditing ? handleSave() : null)}
          />
        </div>
      </div>
      <div className="ml-2 w-fit">
        <Button
          icon={<CiEdit size={20} color="#DE0607" />}
          className={"w-10 h-10 border-none shadow-none"}
          onClick={() => {
            setIsEditing(!isEditing);
            onEdit(fee.id);
          }}
        />
        <Button
          icon={
            <LuTrash
              size={18}
              color={isEditing ? "#DE0607" : "#DE060740"}
              className={`${isEditing ? "cursor-pointer" : "cursor-default"}`}
            />
          }
          className={`w-10 h-10 border-none shadow-none
          ${isEditing ? "cursor-pointer" : "cursor-default"}
          `}
          onClick={() =>
            isEditing
              ? onDelete(fee.id, selectedCities, deliveryFee, fee.isNew)
              : null
          }
        />
      </div>
    </div>
  );
};

const DeliveryFeeList = ({
  cityOptions,
  feesData,
  locationId,
  setData,
  onSave,
}) => {
  const [fees, setFees] = useState([
    { id: Date.now(), cities: [], deliveryFee: "", isNew: true },
  ]);

  const postNewFee = async (data) => {
    try {
      const response = await locationService.addLocationSettings(data);
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const putFee = async (id, data) => {
    try {
      const response = await locationService.updateLocationSettings(id, data);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          let newFee = {
            id: response.locationSettings.id,
            cities: response.locationSettings.cities.map((city) => {
              return { id: city.id, value: city.slug, label: city.name.en };
            }),
            deliveryFee: response.locationSettings.deliveryFee,
            isNew: false,
          };
          setFees((prev) => {
            let newFees = prev.map((fee) => {
              if (fee.id === id) {
                return newFee;
              }
              return fee;
            });
            return newFees;
          });
          break;
        default:
          showError(response.message);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSave = async (id, cities, deliveryFee, isNew) => {
    let settingId = id;
    let data = {
      locationId: locationId,
      cityIds: cities.map((city) => city.id),
      deliveryFee: parseInt(deliveryFee),
    };
    if (isNew) {
      postNewFee(data);
    } else {
      putFee(settingId, data);
    }
  };

  const handleCancel = (id) => {};

  const handleDelete = async (id) => {
    let settingId = id;
    try {
      const response = await locationService.deleteLocationSettings(settingId);
      switch (response.statusCode) {
        case 400:
          showError(response.message);
          break;
        default:
          showSuccess("Fee deleted successfully");
          setData((prev) => {
            let newFees = prev.settings.filter((fee) => fee.id !== settingId);
            return { ...prev, settings: newFees };
          });
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleEdit = (id) => {};

  const handleAddFee = () => {
    setFees([
      ...fees,
      { id: Date.now(), cities: [], deliveryFee: "", isNew: true },
    ]);
  };

  useEffect(() => {
    if (feesData) {
      setFees(feesData.map((item) => ({ ...item, isNew: false })));
    } else {
      setFees([{ id: Date.now(), cities: [], deliveryFee: "", isNew: true }]);
    }
  }, [feesData]);
  return (
    <div>
      {fees.map((fee) => (
        <div>
          <DeliveryFeeItem
            key={fee.id}
            cityOptions={cityOptions}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onEdit={handleEdit}
            fee={fee}
          />
          <div
            className={`border-b border-[#00000026] mt-5 ${
              fees[fees.length - 1].id === fee.id ? "hidden" : ""
            }`}
          ></div>
        </div>
      ))}
      <Button
        label="Add Fee"
        icon={<IoMdAdd size={13} color="#DE0607" />}
        className="bg-white shadow-none w-fit"
        labelStyle={"!text-12 font-medium"}
        textColor="#DE0607"
        onClick={handleAddFee}
      />
    </div>
  );
};

export default DeliveryFeeList;
