import React, { useEffect, useMemo, useState } from "react";
import ServiceCategoryTab from "./ServiceCategoryTab";
import ServicesTab from "./ServicesTab";
import DiscountCodesTab from "./DiscountCodesTab";
import Button from "components/core/Button";
import { HiOutlinePlus } from "react-icons/hi";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/auth/authSlice";

const Services = () => {
  const [activeTab, setActiveTab] = useState("Service Category");
  const [isAddClicked, setIsAddClicked] = useState(false);
  const userRole = useSelector(selectRole);

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        name: "Service Category",
        component: <ServiceCategoryTab />,
      },
      {
        name: "Services",
        component: <ServicesTab />,
      },
    ];

    if (userRole !== "PROVIDER" && userRole !== "LOCATION_MANAGER") {
      baseTabs.push({
        name: "Discount Codes",
        component: (
          <DiscountCodesTab
            isAddClicked={isAddClicked}
            setIsAddClicked={setIsAddClicked}
          />
        ),
        action: "add",
      });
    }

    return baseTabs;
  }, [isAddClicked, userRole]);

  useEffect(() => {
    setIsAddClicked(false);
  }, [activeTab]);

  return (
    <div className="pl-4 mt-4 bg-white rounded-md">
      <div className="flex justify-between w-full">
        <div className="flex justify-start w-full px-2 py-6 bg-white">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`p-2 ${
                activeTab === tab.name
                  ? " text-[#DE0607] border-b border-[#DE0607]"
                  : "text-[#000000d9]"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        {tabs.find((tab) => tab.name === activeTab)?.action === "add" &&
          !isAddClicked && (
            <Button
              label="Add New"
              className="w-28 p-2 mt-4 mr-4 text-[#DE0607] bg-white"
              icon={<HiOutlinePlus />}
              textColor="text-[#DE0607]"
              onClick={() => setIsAddClicked(true)}
            />
          )}
      </div>
      <div className="mt-4">
        {tabs.find((tab) => tab.name === activeTab)?.component}
      </div>
    </div>
  );
};

export default Services;
