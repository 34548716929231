import * as Yup from "yup";

// General error messages
const requiredMessage = (field) => `Please input ${field}`;
const invalidMessage = (field) => `Invalid ${field}`;

// Add Service validation schema
export const ServiceAddSchema = Yup.object({
  name: Yup.object({
    en: Yup.string()
      .min(1, "Service Category English Name must be at least 1 character")
      .required(requiredMessage("Service Category English Name"))
      .nonNullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  locationIds: Yup.array()
    .of(Yup.number().required(invalidMessage("Location ID")))
    .nullable(),
});

// Edit Service validation schema
export const ServiceEditSchema = Yup.object({
  name: Yup.object({
    en: Yup.string()
      .min(1, "Service Category English Name must be at least 1 character")
      .required(requiredMessage("Service Category English Name"))
      .nonNullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  locationIds: Yup.array()
    .of(Yup.number().required(invalidMessage("Location ID")))
    .nullable(),
});

// Add SubService validation schema
export const SubServiceAddSchema = Yup.object({
  name: Yup.object({
    en: Yup.string()
      .min(1, "Service Name En must be at least 1 character")
      .required(requiredMessage("Service English Name"))
      .nonNullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  locationIds: Yup.array()
    .of(Yup.number().required(invalidMessage("Location ID")))
    .nullable(),
  description: Yup.object({
    en: Yup.string().notRequired().nullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  duration: Yup.number()
    .nullable()
    .when("childSubserviceIds", {
      is: (val) => !val || val.length === 0,
      then: () =>
        Yup.number()
          .required(requiredMessage("Duration"))
          .positive("Duration must be a positive number")
          .min(1, "Duration must be at least 1"),
      otherwise: () =>
        Yup.number()
          .nullable()
          .notRequired()
          .positive("Duration must be a positive number")
          .min(1, "Duration must be at least 1"),
    }),
  price: Yup.number()
    .nullable()
    .when("childSubserviceIds", {
      is: (val) => !val || val.length === 0,
      then: () =>
        Yup.number()
          .required(requiredMessage("Price"))
          .typeError("Price must be a number")
          .positive("Price must be a positive number")
          .min(1, "Price must be at least 1"),
      otherwise: () =>
        Yup.number()
          .nullable()
          .notRequired()
          .typeError("Price must be a number")
          .positive("Price must be a positive number")
          .min(1, "Price must be at least 1"),
    }),
  childSubserviceIds: Yup.array().of(Yup.number()).nullable(),
});

// Edit SubService validation schema
export const SubServiceEditSchema = Yup.object({
  name: Yup.object({
    en: Yup.string()
      .min(1, "Service Name En must be at least 1 character")
      .required(requiredMessage("Service English Name"))
      .nonNullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  locationIds: Yup.array()
    .of(Yup.number().required(invalidMessage("Location ID")))
    .nullable(),
  description: Yup.object({
    en: Yup.string().notRequired().nullable(),
    ar: Yup.string().notRequired().nullable(),
  }),
  duration: Yup.number()
    .nullable()
    .when("childSubserviceIds", {
      is: (val) => !val || val.length === 0,
      then: () =>
        Yup.number()
          .required(requiredMessage("Duration"))
          .positive("Duration must be a positive number")
          .min(1, "Duration must be at least 1"),
      otherwise: () =>
        Yup.number()
          .nullable()
          .notRequired()
          .positive("Duration must be a positive number")
          .min(1, "Duration must be at least 1"),
    }),
  price: Yup.number()
    .nullable()
    .when("childSubserviceIds", {
      is: (val) => !val || val.length === 0,
      then: () =>
        Yup.number()
          .required(requiredMessage("Pricesss"))
          .typeError("Price must be a number")
          .positive("Price must be a positive number")
          .min(1, "Price must be at least 1"),
      otherwise: () =>
        Yup.number()
          .nullable()
          .notRequired()
          .typeError("Price must be a number")
          .positive("Price must be a positive number")
          .min(1, "Price must be at least 1"),
    }),
  childSubserviceIds: Yup.array().of(Yup.number()).nullable(),
});

// Add Discount Code validation schema
export const DiscountCodeAddSchema = Yup.object({
  title: Yup.string()
    .min(1, "Title must be at least 1 character")
    .required(requiredMessage("Title"))
    .nonNullable(),
  code: Yup.string()
    .min(1, "Code must be at least 1 character")
    .required(requiredMessage("Code"))
    .nonNullable(),
  subserviceIds: Yup.array().of(Yup.number()),
  locationIds: Yup.array()
    .of(Yup.number())
    .nonNullable(requiredMessage("Location")),
  totalUsages: Yup.number()
    .min(0, "Total Usages must be at least 1")
    .required(requiredMessage("Total Usages"))
    .nonNullable(),
  percentage: Yup.number()
    .min(0, "Percentage must be at least 1")
    .required(requiredMessage("Percentage"))
    .nullable(),
  fixPrice: Yup.number()
    .min(0, "Fixed Price must be at least 1")
    .required(requiredMessage("Fixed Price"))
    .nullable(),
});
