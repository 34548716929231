import React, { useEffect, useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import InputFieldWithLanguage from "./InputFieldWithLanguage";
import InputField from "components/core/inputField";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import SelectInput from "components/core/SelectInput";
import Button from "components/core/Button";
import { CiEdit } from "react-icons/ci";
import { LuTrash } from "react-icons/lu";
import { childSubserviceServices } from "services/childSubservice/childSubserviceServices";
import { showError } from "utils/helpers";

const OptionSelector = ({
  optionsType,
  setOptionsType,
  options,
  setOptions,
  disabled = false,
}) => {
  const [editingIndices, setEditingIndices] = useState([]);
  const [backupOptions, setBackupOptions] = useState([]);
  const [singleOption, setSingleOption] = useState({
    name: {
      en: "",
      ar: "",
    },
    duration: "",
    durationType: {
      value: "minute",
      label: "Minute",
    },
    price: "",
  });
  const [multipleOptions, setMultipleOptions] = useState([
    {
      name: {
        en: "",
        ar: "",
      },
      duration: "",
      durationType: {
        value: "minute",
        label: "Minute",
      },
      price: "",
    },
  ]);

  useEffect(() => {
    if (optionsType === "single") {
      setSingleOption(
        options[0] || {
          name: {
            en: "",
            ar: "",
          },
          duration: "",
          durationType: {
            value: "minute",
            label: "Minute",
          },
          price: "",
        }
      );
    } else if (optionsType === "multiple") {
      setMultipleOptions(
        options.length > 0
          ? options
          : [
              {
                name: {
                  en: "",
                  ar: "",
                },
                duration: "",
                durationType: {
                  value: "minute",
                  label: "Minute",
                },
                price: "",
              },
            ]
      );
    }
  }, [options, optionsType]);

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
    if (optionsType === "single") {
      setSingleOption(newOptions[0]);
    } else if (optionsType === "multiple") {
      setMultipleOptions(newOptions);
    }
  };

  const addOption = () => {
    const newOption = {
      name: {
        en: "",
        ar: "",
      },
      duration: "",
      durationType: {
        value: "minute",
        label: "Minute",
      },
      price: "",
    };
    const newOptions = [...multipleOptions, newOption];
    setMultipleOptions(newOptions);
    setOptions(newOptions);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    if (optionsType === "multiple") {
      setMultipleOptions(newOptions);
    }
  };

  const saveOption = async (index) => {
    const option = options[index];
    try {
      let response;
      if (option.id) {
        // PUT request to update the existing option
        response = await childSubserviceServices.editSubservice(option.id, {
          name: option.name,
          duration:
            option.durationType.value === "minute"
              ? parseInt(option.duration)
              : parseInt(option.duration) * 60,
          price: parseInt(option.price),
          subserviceId: option.subserviceId ? option.subserviceId : null,
        });
        const newOptions = [...options];
        newOptions[index] = response.subservice;
        newOptions[index].durationType = {
          value: "minute",
          label: "Minute",
        };
        setOptions(newOptions);
      } else {
        // POST request to create a new option
        response = await childSubserviceServices.addSubservice({
          name: option.name,
          duration:
            option.durationType.value === "minute"
              ? parseInt(option.duration)
              : parseInt(option.duration) * 60,
          price: parseInt(option.price),
        });
        const newOptions = [...options];
        newOptions[index] = {
          ...newOptions[index],
          ...response.subservice,
        };
        newOptions[index].durationType = {
          value: "minute",
          label: "Minute",
        };
        setOptions(newOptions);
      }
      setEditingIndices(editingIndices.filter((i) => i !== index));
    } catch (error) {
      showError(error);
    }
  };

  const cancelEdit = (index) => {
    const newOptions = [...backupOptions];
    setOptions(newOptions);
    setEditingIndices(editingIndices.filter((i) => i !== index));
    if (optionsType === "multiple") {
      setMultipleOptions(newOptions);
    }
  };

  const startEdit = (index) => {
    setBackupOptions([...options]);
    setEditingIndices([...editingIndices, index]);
  };

  const renderOptionFields = (index) => {
    const isEditing = editingIndices.includes(index);
    if (!options[index]) {
      return null; // or render a fallback UI
    }
    return (
      <div key={index} className="mb-4">
        <div className="flex mb-2">
          <p className="text-14 min-w-[200px] font-normal text-[#000000D9]">
            Option name: <span className="text-red-500 text-16">*</span>
          </p>
          <div className="flex flex-col w-full space-y-2">
            <InputFieldWithLanguage
              placeholder="Enter the name"
              value={
                options[index].name &&
                Object.keys(options[index].name).length > 0
                  ? options[index].name?.en
                  : ""
              }
              onChange={(e) =>
                handleOptionChange(index, "name", {
                  ...options[index].name,
                  en: e.target.value,
                })
              }
              language="En"
              className="w-1/2"
              isDisabled={!isEditing || disabled}
            />
            <InputFieldWithLanguage
              placeholder="Enter the name"
              value={options[index].name.ar ? options[index].name.ar : ""}
              onChange={(e) =>
                handleOptionChange(index, "name", {
                  ...options[index].name,
                  ar: e.target.value,
                })
              }
              language="Ar"
              dir="rtl"
              className="w-1/2"
              isDisabled={!isEditing || disabled}
            />
          </div>
          <div className="ml-2 w-fit">
            <Button
              icon={<CiEdit size={20} color="#DE0607" />}
              className={"w-10 h-10 border-none shadow-none"}
              onClick={() => {
                !disabled && startEdit(index);
              }}
              isDisabled={isEditing}
            />
            <Button
              icon={
                <LuTrash
                  size={18}
                  color={isEditing ? "#DE0607" : "#DE060740"}
                  className={`${
                    isEditing
                      ? options.length === 1
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                      : "cursor-default"
                  }`}
                />
              }
              className={`w-10 h-10 border-none shadow-none`}
              onClick={() => {
                if (!isEditing || options.length === 1) return;
                removeOption(index);
              }}
            />
          </div>
        </div>
        <div className="flex items-center mb-2">
          <p className="min-w-[200px] text-14 font-normal text-[#000000D9]">
            Duration <span className="text-red-500 text-16">*</span>
          </p>
          <InputField
            type="number"
            placeholder="Enter duration"
            value={options[index].duration}
            onChange={(e) =>
              handleOptionChange(index, "duration", e.target.value)
            }
            className="w-1/2 mr-2"
            disabled={!isEditing || disabled}
          />
          <SelectInput
            options={[
              { value: "minute", label: "Minute" },
              { value: "hour", label: "Hour" },
            ]}
            selected={options[index].durationType}
            onSelect={(e) => handleOptionChange(index, "durationType", e)}
            className="w-1/2"
            isDisabled={!isEditing || disabled}
          />
        </div>
        <div className="flex items-center mb-2">
          <p className="min-w-[200px] text-14 font-normal text-[#000000D9]">
            Price <span className="text-red-500 text-16">*</span>
          </p>
          <InputFieldWithLanguage
            placeholder="Enter the price"
            value={options[index].price}
            onChange={(e) => handleOptionChange(index, "price", e.target.value)}
            language={"KWD"}
            className="w-full"
            labelClassName={"!w-14"}
            isDisabled={!isEditing || disabled}
          />
        </div>
        {isEditing && (
          <div className="flex justify-end space-x-2">
            <Button onClick={() => cancelEdit(index)} label="Cancel" />
            <Button onClick={() => saveOption(index)} label="Save" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="">
      <div className="flex mb-4">
        <label className="min-w-[200px] mb-2 text-xs font-bold">
          Options only:
        </label>
        <RadioButtonGroup
          options={[
            { value: "single", label: "Single" },
            { value: "multiple", label: "Multiple" },
          ]}
          name="optionType"
          selectedValue={optionsType === undefined ? "single" : optionsType}
          onChange={(e) => {
            const newValue = e.target.value;
            setOptionsType(newValue);
          }}
          className="flex space-x-4"
          disabled={disabled}
        />
      </div>
      {optionsType === "single" ? (
        <div className="flex flex-col mb-4 space-y-2">
          <div className="flex w-full">
            <p className="min-w-[200px] text-14 font-normal text-[#000000D9]">
              Duration <span className="text-red-500 text-16">*</span>
            </p>
            <InputField
              type="number"
              placeholder="Enter duration"
              value={singleOption.duration}
              onChange={(e) =>
                handleOptionChange(0, "duration", e.target.value)
              }
              className="w-1/2 mr-2"
              disabled={disabled}
            />
            <SelectInput
              options={[
                { value: "minute", label: "Minute" },
                { value: "hour", label: "Hour" },
              ]}
              selected={singleOption.durationType}
              onSelect={(e) => handleOptionChange(0, "durationType", e)}
              className="w-1/2"
              isDisabled={disabled}
            />
          </div>
          <div className="flex w-full">
            <p className="min-w-[200px] text-14 font-normal text-[#000000D9]">
              Price <span className="text-red-500 text-16">*</span>
            </p>
            <InputFieldWithLanguage
              placeholder="Enter the price"
              value={singleOption.price}
              onChange={(e) => handleOptionChange(0, "price", e.target.value)}
              language={"KWD"}
              className="w-full"
              labelClassName={"!w-14"}
              isDisabled={disabled}
            />
          </div>
        </div>
      ) : (
        multipleOptions.map((_, index) => renderOptionFields(index))
      )}
      {optionsType === "multiple" && (
        <button className="text-red-500" onClick={addOption}>
          + Add new option
        </button>
      )}
    </div>
  );
};

export default OptionSelector;
