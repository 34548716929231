import React from "react";
import "./LoaderWrapper.css"; // Assuming the CSS is in a separate file

const LoaderWrapper = ({ isLoading, children }) => {
  return isLoading ? (
    <div className="grid py-24 place-items-center">
      <div className="flex justify-center my-20">
        <div className="spinner"></div>
      </div>
    </div>
  ) : (
    children
  );
};

export default LoaderWrapper;
