import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { IoLocationOutline } from "react-icons/io5";
import InputField from "./inputField";

const AddressSelector = ({
  apiKey,
  className = "",
  value = "",
  onAddressSelect,
  disabled = false,
  isRequired = false,
}) => {
  const [address, setAddress] = useState(value);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  useEffect(() => {
    if (value) {
      setAddress({ label: value, value: { place_id: placeId } });
      fetchPlaceDetailsByAddress(value);
    }
  }, [value, placeId]);

  const handlePlaceChanged = (place) => {
    if (!disabled) {
      setAddress(place);
      setPlaceId(place.value.place_id);
      fetchPlaceDetails(place.value.place_id);
    }
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const location = place.geometry.location;
        const newPlace = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setSelectedPlace(newPlace);
        if (onAddressSelect) {
          onAddressSelect(place.formatted_address, newPlace.lat, newPlace.lng);
        }
      }
    });
  };

  const fetchPlaceDetailsByAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        const newPlace = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setSelectedPlace(newPlace);
        setAddress({
          label: results[0].formatted_address,
          value: { place_id: results[0].place_id },
        });
        if (onAddressSelect) {
          onAddressSelect(
            results[0].formatted_address,
            newPlace.lat,
            newPlace.lng
          );
        }
      }
    });
  };

  const fetchAddressByLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        const formattedAddress = results[0].formatted_address;
        setAddress({
          label: formattedAddress,
          value: { place_id: results[0].place_id },
        });
        if (onAddressSelect) {
          onAddressSelect(formattedAddress, lat, lng);
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleLocationButtonClick = () => {
    if (!disabled && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const newPlace = { lat: latitude, lng: longitude };
        setSelectedPlace(newPlace);
        fetchAddressByLatLng(latitude, longitude);
      });
    }
  };

  const handleMarkerDragEnd = (event) => {
    if (!disabled) {
      const newPlace = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setSelectedPlace(newPlace);
      fetchAddressByLatLng(newPlace.lat, newPlace.lng);
    }
  };

  return (
    <div className={`container ${className}`}>
      <div className="flex flex-col items-start space-y-2 md:space-y-0 md:space-x-2 md:items-start md:flex-row">
        <span className="justify-start w-1/5 text-sm font-bold text-left text-gray-700 md:mt-2 min-w-32">
          Address: {isRequired && <span className="text-red-500">*</span>}
        </span>
        <div className="flex flex-col w-full ">
          <div className="flex flex-row w-full space-x-1">
            {apiKey === "" ? (
              <InputField
                value={address?.label || ""}
                onChange={(e) => setAddress({ label: e.target.value })}
                className="w-full rounded-md focus:outline-none focus:ring-2 focus:ring-red-300"
                disabled={disabled}
              />
            ) : (
              <GooglePlacesAutocomplete
                apiKey={apiKey}
                selectProps={{
                  value: address,
                  onChange: handlePlaceChanged,
                  className:
                    "w-full rounded-md focus:outline-none focus:ring-2 focus:ring-red-300",
                  isDisabled: disabled,
                }}
              />
            )}
            <button
              className="p-2 text-red-500 border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={handleLocationButtonClick}
              disabled={disabled}
            >
              <IoLocationOutline size={25} />
            </button>
          </div>
          <LoadScriptNext googleMapsApiKey={apiKey}>
            <GoogleMap
              center={selectedPlace || { lat: 0, lng: 0 }}
              zoom={selectedPlace ? 15 : 2}
              mapContainerStyle={{ height: "400px", width: "100%" }}
              onClick={(e) => {
                if (!disabled) {
                  const newPlace = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                  setSelectedPlace(newPlace);
                  fetchAddressByLatLng(newPlace.lat, newPlace.lng);
                }
              }}
              options={{
                draggable: !disabled,
                zoomControl: !disabled,
                scrollwheel: !disabled,
                disableDoubleClickZoom: disabled,
              }}
            >
              {selectedPlace && (
                <Marker
                  position={selectedPlace}
                  draggable={!disabled}
                  onDragEnd={handleMarkerDragEnd}
                />
              )}
            </GoogleMap>
          </LoadScriptNext>
        </div>
      </div>
      <div className="flex w-full mt-4"></div>
    </div>
  );
};

export default AddressSelector;
