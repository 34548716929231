import DateRangePicker from "./Components/DateRangePicker";
import React, { useEffect, useState } from "react";
import StatCard from "./Components/StatCard";
import analyticsService from "services/analytics/analyticsService";
import { showError } from "utils/helpers";
import ProgressBarCard from "./Components/ProgressBarCard";
import Table from "components/core/Table/TableComponent";

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState();
  const [userAge, setUserAge] = useState([]);
  const [userGender, setUserGender] = useState([]);
  const [channelOfBookings, setChannelOfBookings] = useState([]);
  const [listingPageViews, setListingPageViews] = useState([]);
  const [lastBookings, setLastBookings] = useState([]);
  const [stats, setStats] = useState([]);

  const convertObjectToArray = (obj) => {
    if (!obj) return;
    return Object.keys(obj).map((key) => ({
      label: key,
      value: obj[key],
    }));
  };

  const fetchAnalytics = async (dates) => {
    try {
      const data = await analyticsService.getDashboardAnalytics(dates);
      switch (data.statusCode) {
        case 200:
          setAnalytics(data.data);
          setStats([
            {
              title: "Vendors",
              value: data.data.totalVendorsCount,
            },
            {
              title: "Listings",
              value: data.data.totalLocationsCount,
            },
            {
              title: "Users",
              value: data.data.totalUsersCount,
            },
            {
              title: "Total Revenue",
              value: data.data.totalRevenue,
            },
            {
              title: "Bookings",
              value: data.data.totalBookingsCount,
            },
          ]);
          setLastBookings(
            data.data.lastBookings.map((booking) => ({
              name: booking.customerName,
              vendor: booking.vendor,
              channelOfBooking: booking.channelOfBooking,
              bookingDate: booking.bookingDate,
              price: booking.price,
            }))
          );

          // Reset listingPageViews before setting new values
          setListingPageViews([]);
          let listingViews = data.data.listingPageView.map((listing) => ({
            listing: `${listing.name.en ? listing.name.en : " "} - ${
              listing.name.ar ? listing.name.ar : " "
            }`,
            views: listing.views,
          }));
          setListingPageViews(listingViews);
          setLoading(false);
          break;
        default:
          showError(data.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.message);
      setLoading(false);
      return;
    }
  };

  const convertDate = (date) => {
    let newDate = new Date(date);
    return `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : newDate.getMonth() + 1
    }-${newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()}`;
  };
  useEffect(() => {
    if (dates.length > 0 && startDate !== undefined && endDate !== undefined) {
      fetchAnalytics(dates);
    }
  }, [dates]);

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      let newStartDate = convertDate(startDate);
      let newendDate = convertDate(endDate);
      setDates([newStartDate, newendDate]);
    } else {
      const now = new Date();
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      setStartDate(convertDate(firstDayOfMonth));
      setEndDate(convertDate(lastDayOfMonth));
      setDates([convertDate(firstDayOfMonth), convertDate(lastDayOfMonth)]);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (analytics) {
      let userAges = convertObjectToArray(analytics.userAgeDemographic);
      let userGenders = convertObjectToArray(analytics.userGenders);
      setUserAge(
        userAges.map((age) => {
          if (age.label === "<18") {
            return {
              label: "Up to 18",
              count: age.value.count,
              percentage: age.value.percentage,
              max: age.value.count * 2,
            };
          }
          if (age.label === ">60") {
            return {
              label: "60+",
              count: age.value.count,
              percentage: age.value.percentage,
              max: age.value.count * 2,
            };
          }
          return {
            label: age.label,
            count: age.value.count,
            percentage: age.value.percentage,
            max: age.value.count * 2,
          };
        })
      );
      setUserGender(
        userGenders.map((gender) => ({
          label:
            gender.label.charAt(0).toUpperCase() +
            gender.label.slice(1).toLowerCase(),
          count: gender.value.count,
          percentage: gender.value.percentage,
          max: gender.value.count * 2,
        }))
      );
      setChannelOfBookings([
        {
          label: "App",
          count: analytics.channelOfBookings.appCount,
          max: analytics.channelOfBookings.appCount,
        },
        {
          label: "Link",
          count: analytics.channelOfBookings.linkCount,
          max: analytics.channelOfBookings.linkCount,
        },
        {
          label: "Offline",
          count: analytics.channelOfBookings.offlineCount,
          max: analytics.channelOfBookings.offlineCount,
        },
      ]);
    }
  }, [analytics]);

  return (
    <div className="flex flex-col px-4 pt-6 mt-4 bg-white">
      {/* Date Range Picker */}
      <div className="flex justify-end w-full ">
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>

      {/* Stats */}
      <div className="flex justify-between mt-8 space-x-4">
        {stats.map((stat, index) => (
          <StatCard key={index} title={stat.title} value={stat.value} />
        ))}
      </div>

      <div className="flex justify-between w-full h-full my-8 space-x-4">
        <div className="flex flex-col w-1/2 space-y-5 bg-white lg:w-1/3 max-lg:w-1/3">
          <ProgressBarCard
            title="User Age Demographic"
            progressbarValues={userAge}
          />

          <ProgressBarCard
            title="User Genders"
            progressbarValues={userGender}
          />

          <ProgressBarCard
            title="Channel of Bookings"
            progressbarValues={channelOfBookings}
          />
        </div>
        <div className="flex flex-col w-1/2 h-full px-3 mb-5 space-y-10 bg-white lg:w-2/3 max-lg:w-2/3">
          <div className="flex flex-col w-full px-3 pb-5 space-y-3 border rounded-md h-1/2">
            <p className="text-16 mt-3 leading-6 font-medium text-[#000000D9]">
              Listing Page Views
            </p>
            {Array.isArray(listingPageViews) && listingPageViews.length > 0 ? (
              <Table
                headers={[
                  {
                    key: "listing",
                    value: "Listing",
                  },
                  {
                    key: "views",
                    value: "Page Views",
                  },
                ]}
                data={listingPageViews}
                numberOfRows={5}
                sm
              />
            ) : (
              <p className="text-16 mt-3 leading-6 font-medium text-[#000000D9]">
                No Listing Page Views
              </p>
            )}
          </div>
          <div className="flex flex-col w-full px-3 space-y-2 border rounded-md h-1/2">
            <p className="text-16 mt-3 leading-6 font-medium text-[#000000D9]">
              Latest Bookings
            </p>
            <Table
              headers={[
                {
                  key: "name",
                  value: "Customer Name",
                },
                {
                  key: "vendor",
                  value: "Vendor",
                },
                {
                  key: "channelOfBooking",
                  value: "Channel of Booking",
                },
                {
                  key: "bookingDate",
                  value: "Booking Date",
                },
                {
                  key: "price",
                  value: "Price",
                },
              ]}
              data={lastBookings}
              numberOfRows={5}
              sm
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
