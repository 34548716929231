import React, { useEffect, useState } from "react";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import categoryService from "../../services/category/categoryService";
import { showError, showSuccess } from "../../utils/helpers";
import subcategoryServices from "../../services/subcategory/subcategoryServices";
import useCategory from "../../utils/hooks/useCategory";
import CategoryList from "./Category/CategoryList";
import CategoryFormWrapper from "./Category/CategoryFormWrapper";
import photoService from "../../services/photoService";
import {
  AddNewCategorySchema,
  EditCategorySchema,
} from "utils/schemas/Categories/CategoriesSchemas";
import locationService from "services/location/locationService";

function Category() {
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    icon: "",
    subcategories: [],
  });
  const [formMode, setFormMode] = useState("");

  const fetchCategories = async () => {
    try {
      const data = await categoryService.getCategories();
      switch (data.statusCode) {
        case 200:
          setFetchedCategories(data.categories);
          break;
        default:
          showError(data.message);
          setFetchedCategories([]);
          break;
      }
    } catch (error) {
      showError(`Failed to fetch categories: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSave = async (category) => {
    try {
      await EditCategorySchema.validate(category).then(async () => {
        const categoryId = await saveCategory(category);
        if (categoryId) {
          const deletedSubcategories = selectedCategory.subcategories.filter(
            (sc) =>
              !category.subCategories.some(
                (updatedSc) => updatedSc.id === sc.id
              )
          );
          // Handle deletion of multiple subcategories
          await Promise.all(
            deletedSubcategories.map(async (subCategory) => {
              try {
                const deleteResponse =
                  await subcategoryServices.deleteSubcategory(subCategory.id);
                switch (deleteResponse.statusCode) {
                  case 400:
                    showError(deleteResponse.message);
                    break;
                  case 404:
                    showError(deleteResponse.message);
                    break;
                  default:
                    showSuccess(
                      `Subcategory ${
                        subCategory.name.en || subCategory.name.ar
                      } has been successfully deleted!`
                    );
                    break;
                }
              } catch (error) {
                showError(
                  `Failed to delete subcategory ${
                    subCategory.name.en || subCategory.name.ar
                  }: ${error.message}`
                );
              }
            })
          );

          await saveSubcategory(category.subCategories);

          // Fetch updated categories
          const data = await categoryService.getCategories();
          switch (data.statusCode) {
            case 200:
              setFetchedCategories(data.categories);
              break;
            default:
              showError(data.message);
              setFetchedCategories([]);
              break;
          }

          setFormMode("");
          setSelectedCategory(null);
        } else {
          showError("Failed to save category");
        }
      });
    } catch (error) {
      showError(error.message);
      return;
    }
  };

  const saveCategory = async (category) => {
    let { name, icon } = category;
    let originalIcon = selectedCategory.images
      ? selectedCategory.images[0]
      : null;
    const hasNameChanged = name !== selectedCategory.name;
    const hasIconChanged = icon !== originalIcon;

    if (!hasNameChanged && !hasIconChanged) {
      return selectedCategory.id;
    }

    if (!hasIconChanged) {
      let newCategory = {
        name: name,
        updatedAt: new Date(),
      };
      try {
        const response = await categoryService.updateCategory(
          selectedCategory.id,
          newCategory
        );
        showSuccess("Category updated successfully");
        return response.category.id;
      } catch (error) {
        showError(error.message);
        return;
      }
    }

    if (!hasNameChanged) {
      let formData = new FormData();
      formData.append("files", icon);
      try {
        const imageUrl = await photoService.uploadPhoto(formData);
        let newCategory = {
          name: name,
          images: imageUrl,
          updatedAt: new Date(),
        };
        const response = await categoryService.updateCategory(
          selectedCategory.id,
          newCategory
        );
        showSuccess("Category updated successfully");
        return response.category.id;
      } catch (error) {
        showError(error.message);
        return;
      }
    }

    // Both name and icon have changed
    let formData = new FormData();
    formData.append("files", icon);

    try {
      const imageUrl = await photoService.uploadPhoto(formData);
      let newCategory = {
        name: name,
        images: imageUrl,
        updatedAt: new Date(),
      };

      const response = await categoryService.updateCategory(
        selectedCategory.id,
        newCategory
      );
      showSuccess("Category updated successfully");
      return response.category.id;
    } catch (error) {
      showError(error.message);
      return;
    }
  };

  const saveSubcategory = async (subCategories) => {
    for (const subCategory of subCategories) {
      let { name, images, id } = subCategory;
      let categoryId = selectedCategory.id;

      if (id) {
        const originalSubCategory = selectedCategory.subcategories.find(
          (sc) => sc.id === id
        );
        const hasNameChanged = name !== originalSubCategory?.name;
        const hasImagesChanged =
          images &&
          JSON.stringify(images[0]) !==
            JSON.stringify(originalSubCategory?.images[0]);

        if (hasNameChanged || hasImagesChanged) {
          if (!hasImagesChanged) {
            let newSubCategory = {
              name: name,
              images: originalSubCategory.images,
              categoryId: categoryId,
            };
            try {
              const response = await subcategoryServices.updateSubcategory(
                id,
                newSubCategory
              );
              switch (response.statusCode) {
                case 200:
                  showSuccess("Subcategory updated successfully");
                  break;
                default:
                  showError(response.message);
                  break;
              }
            } catch (error) {
              showError(error.message);
            }
          } else if (!hasNameChanged) {
            let formData = new FormData();
            formData.append("files", images[0]);
            try {
              const imageUrl = await photoService.uploadPhoto(formData);
              let newSubCategory = {
                name: name,
                images: imageUrl,
              };
              await subcategoryServices.updateSubcategory(id, newSubCategory);
              showSuccess("Subcategory updated successfully");
            } catch (error) {
              showError(error.message);
            }
          } else {
            let formData = new FormData();
            formData.append("files", images[0]);
            try {
              const imageUrl = await photoService.uploadPhoto(formData);
              let newSubCategory = {
                name: name,
                images: imageUrl,
                categoryId: selectedCategory.id,
              };
              await subcategoryServices.updateSubcategory(id, newSubCategory);
              showSuccess("Subcategory updated successfully");
            } catch (error) {
              showError(error.message);
            }
          }
        }
      } else {
        if (images) {
          let formData = new FormData();
          formData.append("files", images[0]);
          try {
            const response = await photoService.uploadPhoto(formData);
            images = response;
          } catch (error) {
            showError(error.message);
          }
        } else {
          throw new Error("Please input subcategory image");
        }

        let newSubCategory = {
          name: name,
          images: images,
          categoryId: categoryId,
        };
        try {
          await subcategoryServices.addSubcategory(newSubCategory);
          showSuccess("Subcategory added successfully");
        } catch (error) {
          showError(error.message);
        }
      }
    }
  };

  const addNewCategory = async (category) => {
    let { name, icon } = category;
    let formData = new FormData();
    formData.append("files", icon);
    try {
      const imageUrl = await photoService.uploadPhoto(formData);
      let newCategory = {
        name: name,
        images: imageUrl,
      };

      const response = await categoryService.addCategory(newCategory);
      switch (response.statusCode) {
        case 201:
          showSuccess(response.message);
          return response.category.id;
        default:
          showError(response.message);
          return null;
      }
    } catch (error) {
      showError(error.message);
      return null;
    }
  };

  const addNewSubcategory = async (data) => {
    let { subCategories, categoryId } = data;
    for (const subCategory of subCategories) {
      try {
        let formData = new FormData();
        let imageUrl = "";
        if (subCategory.images) {
          formData.append("files", subCategory.images[0]);
          await photoService
            .uploadPhoto(formData)
            .then((response) => {
              imageUrl = response;
            })
            .catch((error) => {
              throw error;
            });
        }

        let newSubCategory = {
          name: subCategory.name,
          images: imageUrl,
          categoryId: categoryId,
        };
        await subcategoryServices.addSubcategory(newSubCategory);
        showSuccess("Subcategory added successfully");
      } catch (error) {
        showError(error.message);
        break; // Optional: Stop processing further if one fails. Remove this if you want to attempt adding all subcategories regardless of individual failures.
      }
    }
  };

  const handleAdd = async (category) => {
    try {
      await AddNewCategorySchema.validate(category).then(async () => {
        const categoryId = await addNewCategory(category);
        if (categoryId) {
          await addNewSubcategory({
            subCategories: category.subCategories,
            categoryId,
          });
          setFormMode("");
          const data = await categoryService.getCategories();
          switch (data.statusCode) {
            case 200:
              setFetchedCategories(data.categories);
              break;
            default:
              showError(data.message);
              setFetchedCategories([]);
              break;
          }
          setSelectedCategory(null);
        } else {
          showError("Failed to add new category");
        }
      });
    } catch (error) {
      showError(error.message);
      return;
    }
  };

  const onDelete = async (category) => {
    let categoryId = category.id;
    try {
      await categoryService.deleteCategory(categoryId).then((response) => {
        switch (response.statusCode) {
          case 400:
            showError(response.message);
            break;
          case 404:
            showError(response.message);
            break;
          default:
            showSuccess("Category deleted successfully");
            break;
        }
      });

      setFormMode("");
      const data = await categoryService.getCategories();
      setFetchedCategories(data.categories);
      setSelectedCategory(null);
    } catch (error) {
      showError(error.response.data.message || error.message);
    }
  };

  return (
    <div className="flex flex-row mt-4 bg-white">
      <div className="w-2/5 m-4 h-lvh">
        <header className="flex flex-row items-center justify-between px-4">
          <p className="text-16 font-medium leading-6 text-[#000000D9]">
            {fetchedCategories.length} Parent Category
          </p>

          <Button
            label={"Add Category"}
            icon={<HiOutlinePlusSm />}
            onClick={() => {
              setSelectedCategory(null);
              setFormMode("add");
            }}
          />
        </header>
        <div className="w-full mt-6 border-b" />
        {fetchedCategories.length > 0 ? (
          <CategoryList
            fetchedCategories={fetchedCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setFormMode={setFormMode}
            onDelete={onDelete}
          />
        ) : (
          <p className="text-16 font-medium leading-6 text-[#000000D9]">
            No Parent Category found
          </p>
        )}
      </div>

      <div className="w-3/5">
        <CategoryFormWrapper
          formMode={formMode}
          selectedCategory={selectedCategory}
          handleSave={handleSave}
          handleAdd={handleAdd}
          handleCancel={() => {
            setFormMode("");
            setSelectedCategory(null);
          }}
        />
      </div>
    </div>
  );
}

export default Category;
