import React, { useEffect, useState } from "react";
import InputField from "../core/inputField";
import { CiSearch } from "react-icons/ci";
import Button from "../core/Button";
import { HiOutlinePlusSm } from "react-icons/hi";
import listingData from "../../constants/data/listingData";
import TableListings from "./components/TableListings";
import { useNavigate } from "react-router-dom";
import useLocations from "utils/hooks/useLocations";
import Table from "components/core/Table/TableComponent";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { clearListingData } from "../../redux/listings/listingSlice";
import Modal from "components/core/Modal";
import { showError, showSuccess } from "utils/helpers";
import locationService from "services/location/locationService";

function Listings() {
  const { superadminLocations } = useLocations();
  const [filteredListings, setFilteredListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!superadminLocations) return;
    setFilteredListings(
      superadminLocations.map((location) => {
        return {
          id: location?.id,
          name: location?.name?.en || location?.name?.ar,
          category:
            location?.category?.name?.en || location?.category?.name?.ar,
          subcategory: location?.subcategories
            .map((sub) => sub?.name?.en || sub?.name?.ar)
            .join(", "),
          city: location?.city
            ? location?.city?.name?.en || location?.city?.name?.ar
            : "No city name",
          pageViews:
            location.visits && Array.isArray(location.visits)
              ? location.visits.length
              : 0,
          dateCreated: new Date(location?.createdAt)
            .toUTCString()
            .split(" ")
            .slice(1, 4)
            .join(" "),
        };
      })
    );
  }, [superadminLocations]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredData = superadminLocations.filter(
      (location) =>
        location.name.en.toLowerCase().includes(value) ||
        location?.category?.name?.en.toLowerCase().includes(value) ||
        location.subcategories
          .map((sub) => sub.name)
          .join(", ")
          .toLowerCase()
          .includes(value) ||
        location?.city?.name.en.toLowerCase().includes(value) ||
        location?.city?.name.ar.toLowerCase().includes(value)
    );
    setFilteredListings(
      filteredData.map((location) => {
        return {
          id: location.id,
          name: location?.name?.en || location?.name?.ar,
          category: location?.category?.name.en || location?.category?.name.ar,
          subcategory: location.subcategories
            .map((sub) => sub?.name?.en)
            .join(", "),
          city: location?.city.name.en || location?.city.name.ar,
          pageViews: 2,
          dateCreated: new Date(location.createdAt)
            .toUTCString()
            .split(" ")
            .slice(1, 4)
            .join(" "),
        };
      })
    );
  };

  const handleDelete = async () => {
    try {
      const response = await locationService.deleteLocation(selectedListing.id);
      switch (response.statusCode) {
        case 400:
          showError(response.message);
          break;
        case 404:
          showError(response.message);
          break;
        default:
          showSuccess(response.message);
          setFilteredListings((prev) =>
            prev.filter((location) => location.id !== selectedListing.id)
          );
          setIsOpen(false);
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="flex-1 mt-4 bg-white">
      <div className="p-4">
        <div className="flex items-center justify-between w-full p-4">
          <InputField
            placeholder="Search"
            onChange={handleSearch}
            icon={<CiSearch size={20} color="#00000099" />}
          />
          <Button
            label="Add Listing"
            icon={<HiOutlinePlusSm />}
            onClick={() => {
              dispatch(clearListingData());
              navigate("/listings/add");
            }}
          />
        </div>
        <Table
          data={filteredListings}
          headers={[
            {
              key: "name",
              value: "Business name",
            },
            {
              key: "category",
              value: "Category",
            },
            {
              key: "subcategory",
              value: "Subcategory",
            },
            {
              key: "city",
              value: "City",
            },
            {
              key: "pageViews",
              value: "Page views",
            },
            {
              key: "dateCreated",
              value: "Date created",
            },
          ]}
          numberOfRows={10}
          setData={setFilteredListings}
          usePopover
          popoverActions={{
            onEdit: (item, index) => {
              navigate(`/listings/${item.id}`);
            },
            onDelete: (item, index) => {
              setSelectedListing(item);
              setIsOpen(true);
            },
          }}
        />
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        headerText={"Delete Location"}
      >
        <div className="p-5">
          <h2 className="text-16 text-[#000000D9] font-medium">
            Are you sure you want to delete this location?
          </h2>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              className="px-4 py-2 text-black rounded hover:bg-red-600"
              style={{ borderWidth: 1, borderColor: "#DC2626" }}
              onClick={() => setIsOpen(false)}
              variant="outline"
            >
              <p>Cancel</p>
            </button>
            <button
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-gray-400"
              onClick={handleDelete}
            >
              <p>Delete</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Listings;
