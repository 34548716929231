import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RadioButtonGroup from "components/core/Button/RadioButtonGroup";
import DateRangePicker from "components/core/Input/DateRangePicker";
import SelectInput from "components/core/SelectInput";
import InputField from "components/core/inputField";
import {
  selectBookings,
  selectLocationId,
  selectProperties,
  selectTotalPrice,
  setSpecificBookingData,
} from "../../../../../redux/bookings/bookingsSlice";
import locationService from "services/location/locationService";
import providersService from "services/providers/providersService";
import useCity from "utils/hooks/useCity";
import Button from "components/core/Button";
import { showError } from "utils/helpers";
import LoaderWrapper from "components/core/Loader/LoaderWrapper";
import moment from "moment-timezone";
import { FaTrash } from "react-icons/fa";
import useServices from "utils/hooks/useServices";

const ServicesTab = ({ hide }) => {
  const { fetchService } = useServices();
  const [services, setServices] = useState([]);
  const [newServiceOptions, setNewServiceOptions] = useState([]);
  const [groupedServiceOptions, setGroupedServiceOptions] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);
  const [city, setCity] = useState({});
  const [blockNumber, setBlockNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const dispatch = useDispatch();
  const selectedReduxLocationId = useSelector(selectLocationId);
  const bookingsReduxData = useSelector(selectBookings);
  const properties = useSelector(selectProperties);
  const totalPrice = useSelector(selectTotalPrice);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const checkTimeOverlap = (service1, service2) => {
    if (!service1.date || !service2.date) {
      return false;
    }
    const serviceBeginDate = moment(service1.date)
      .add(3, "hours")
      .toISOString();
    const serviceEndDate = moment(service1.date)
      .add(3, "hours")
      .add(service1.service.duration, "minutes")
      .toISOString();
    const hourMoment = moment(service2.date).add(3, "hours").toISOString();
    const hourEndDate = moment(service2.date)
      .add(3, "hours")
      .add(service2.service.duration, "minutes")
      .toISOString();

    if (
      moment(hourMoment).isBetween(serviceBeginDate, serviceEndDate) ||
      moment(hourEndDate).isBetween(serviceBeginDate, serviceEndDate) ||
      moment(serviceBeginDate).isBetween(hourMoment, hourEndDate) ||
      moment(serviceEndDate).isBetween(hourMoment, hourEndDate) ||
      moment(hourMoment).isSame(serviceBeginDate) ||
      moment(hourEndDate).isSame(serviceEndDate)
    ) {
      return true;
    }

    return false;
  };

  const checkProviderTimeConflicts = (
    services,
    updatedService,
    index,
    serviceType
  ) => {
    if (serviceType === "vendor") {
      return services.some((service, i) => {
        if (
          i !== index &&
          service.staff &&
          updatedService.staff &&
          service.staff.value === updatedService.staff.value
        ) {
          const serviceEndTime = moment(service.date)
            .add(service.service.duration, "minutes")
            .toDate()
            .getTime();

          const serviceStartTime = moment(service.date).toDate().getTime();

          const updatedServiceStartTime = moment(updatedService.date)
            .toDate()
            .getTime();

          const updatedServiceEndTime = moment(updatedService.date)
            .add(updatedService.service.duration, "minutes")
            .toDate()
            .getTime();

          if (
            updatedService.date &&
            updatedServiceStartTime < serviceEndTime &&
            updatedServiceStartTime > serviceStartTime
          ) {
            return true;
          }
          if (
            updatedService.date &&
            updatedServiceEndTime > serviceStartTime &&
            updatedServiceEndTime < serviceEndTime
          ) {
            return true;
          }
          if (
            updatedService.date &&
            updatedServiceStartTime < serviceStartTime &&
            updatedServiceEndTime > serviceEndTime
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (
      bookingsReduxData.locationData.category.slug === "sports" &&
      updatedService.date &&
      updatedService.service
    ) {
      const bookingDay = moment(updatedService.date)
        .format("dddd")
        .toUpperCase();
      const locationDay = bookingsReduxData.locationData.availability.days.find(
        (day) => day.name === bookingDay
      );

      if (locationDay) {
        const locationClosingTime = moment(updatedService.date)
          .set({
            hour: parseInt(locationDay.schedule[0].endTime.split(":")[0], 10),
            minute: parseInt(locationDay.schedule[0].endTime.split(":")[1], 10),
          })
          .toDate();

        const serviceEndTime = moment(updatedService.date)
          .add(updatedService.service.duration, "minutes")
          .toDate();

        if (serviceEndTime > locationClosingTime) {
          showError(
            `Service duration exceeds the location's working hours. Please select an earlier time slot or a shorter service.`
          );
          return true;
        }
      }
    }

    return false;
  };

  const checkServiceRules = (services, updatedService, index, serviceType) => {
    if (serviceType === "vendor") {
      const hasConflict = services.some((service, i) => {
        if (i !== index) {
          if (
            service.service &&
            updatedService.service &&
            service.staff &&
            updatedService.staff &&
            service.service.id !== updatedService.service.id &&
            service.staff.value !== updatedService.staff.value
          ) {
            return checkTimeOverlap(service, updatedService);
          }

          if (
            service.service &&
            updatedService.service &&
            service.staff &&
            updatedService.staff &&
            service.service.id === updatedService.service.id &&
            service.staff.value !== updatedService.staff.value
          ) {
            return checkTimeOverlap(service, updatedService);
          }
        }
        return false;
      });

      if (hasConflict) {
        showError(
          `Time conflict: Two different services must be provided by different providers in different time slots at the vendor.`
        );

        return false;
      }
    } else if (serviceType === "home") {
      const sameProviderSameTime = services.some((service, i) => {
        if (
          i !== index &&
          service.staff &&
          updatedService.staff &&
          service.staff.value === updatedService.staff.value &&
          service.service.childSubserviceId ===
            updatedService.service.childSubserviceId &&
          service.service.subserviceId ===
            updatedService.service.subserviceId &&
          checkTimeOverlap(service, updatedService)
        ) {
          return true;
        }
        return false;
      });

      if (sameProviderSameTime) {
        showError(
          `Time conflict: For home services, two different services must be provided by the same provider at the same time or by different providers at the same time.`
        );
        return false;
      }
    }
    return true;
  };

  const calculatePriceForTime = (service, date) => {
    if (service && service.timeBasedPrices) {
      const selectedTime = date.getHours() * 60 + date.getMinutes();
      const pricingRange = service.timeBasedPrices.find((range) => {
        const [startHour, startMinute] = range.startTime.split(":").map(Number);
        const [endHour, endMinute] = range.endTime.split(":").map(Number);
        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        return selectedTime >= startTime && selectedTime < endTime;
      });
      return pricingRange ? pricingRange.price : service.price;
    }
    return service.price;
  };

  const handleServiceChange = (index, field, value) => {
    const newServices = [...services];
    const updatedService = { ...newServices[index] };

    if (field === "date" && value) {
      updatedService[field] = value.toISOString();
      updatedService.price = calculatePriceForTime(
        updatedService.service,
        value
      );

      if (
        checkProviderTimeConflicts(
          newServices,
          updatedService,
          index,
          serviceType
        )
      ) {
        showError(
          `Time conflict: Another services with the same provider already has a booking at this time.`
        );

        const nextAvailableHour =
          updatedService.availableHours &&
          updatedService.availableHours.find((hour) =>
            newServices.every((service, i) => {
              if (i !== index && service.date) {
                const serviceBeginDate = moment(service.date)
                  .add(3, "hours")
                  .toISOString();
                const serviceEndDate = moment(service.date)
                  .add(3, "hours")
                  .add(service.service.duration, "minutes")
                  .toISOString();
                const hourMoment = moment(hour).toISOString();
                const hourEndDate = moment(hour)
                  .add(updatedService.service.duration, "minutes")
                  .toISOString();

                if (
                  moment(hourMoment).isBetween(
                    serviceBeginDate,
                    serviceEndDate
                  ) ||
                  moment(hourEndDate).isBetween(
                    serviceBeginDate,
                    serviceEndDate
                  ) ||
                  moment(serviceBeginDate).isBetween(hourMoment, hourEndDate) ||
                  moment(serviceEndDate).isBetween(hourMoment, hourEndDate)
                ) {
                  return false;
                }
              }
              return true;
            })
          );

        if (nextAvailableHour) {
          let filterOutAvailableHours = updatedService.availableHours.filter(
            (hour) =>
              newServices.every((service, i) => {
                if (i !== index) {
                  return hour !== service.date;
                }
                return true;
              })
          );

          updatedService["availableHours"] = filterOutAvailableHours;
          updatedService[field] = moment(nextAvailableHour)
            .add(-3, "hours")
            .toISOString();
          newServices[index] = updatedService;
          setServices(newServices);
        } else {
          updatedService["date"] = null; // Reset when any error occur
          updatedService["availableHours"] = [];
          newServices[index] = updatedService;
          setServices(newServices);
          return;
        }
      }
    } else {
      updatedService[field] = value;
    }

    if (field === "service") {
      updatedService["staff"] = null;
      updatedService["date"] = null;
      updatedService["availability"] = [];

      getStaffForService();
    }

    if (field === "staff" && value) {
      updatedService["date"] = null;
      updatedService["availability"] = [];

      const selectedStaff = staffOptions.find(
        (staff) => staff.value === value.value
      );
      if (selectedStaff) {
        updatedService["availability"] = selectedStaff.availability || [];
      }

      if (
        checkProviderTimeConflicts(
          newServices,
          updatedService,
          index,
          serviceType
        )
      ) {
        showError(
          `Time conflict: Another service with the same provider already has a booking at this time.`
        );
        updatedService["date"] = null; // Reset when any error occur
        newServices[index] = updatedService;
        setServices(newServices);
        return;
      }
    }

    if (!checkServiceRules(newServices, updatedService, index, serviceType)) {
      const nextAvailableHour = updatedService.availableHours.find((hour) =>
        newServices.every((service, i) => {
          if (i !== index && service.date) {
            const serviceBeginDate = moment(service.date)
              .add(3, "hours")
              .toISOString();
            const serviceEndDate = moment(service.date)
              .add(3, "hours")
              .add(service.service.duration, "minutes")
              .toISOString();
            const hourMoment = moment(hour).toISOString();
            const hourEndDate = moment(hour)
              .add(updatedService.service.duration, "minutes")
              .toISOString();

            if (
              moment(hourMoment).isBetween(serviceBeginDate, serviceEndDate) ||
              moment(hourEndDate).isBetween(serviceBeginDate, serviceEndDate) ||
              moment(serviceBeginDate).isBetween(hourMoment, hourEndDate) ||
              moment(serviceEndDate).isBetween(hourMoment, hourEndDate) ||
              moment(hourMoment).isSame(serviceBeginDate) ||
              moment(hourEndDate).isSame(serviceEndDate)
            ) {
              return false;
            }
          }
          return true;
        })
      );

      if (nextAvailableHour) {
        let filterOutAvailableHours = updatedService.availableHours.filter(
          (hour) => {
            const hourMoment = moment(hour).add(3, "hours").toISOString();
            const hourEndDate = moment(hour)
              .add(3, "hours")
              .add(updatedService.service.duration, "minutes")
              .toISOString();
            return (
              moment(hourMoment).isAfter(nextAvailableHour) ||
              moment(hourEndDate).isAfter(nextAvailableHour)
            );
          }
        );

        updatedService["availableHours"] = filterOutAvailableHours;
        updatedService[field] = moment(nextAvailableHour)
          .add(-3, "hours")
          .toISOString();
        newServices[index] = updatedService;
        setServices(newServices);
      } else {
        updatedService["date"] = null;
        updatedService["availableHours"] = [];
        newServices[index] = updatedService;
        setServices(newServices);
        return;
      }
    }

    newServices[index] = updatedService;

    dispatch(
      setSpecificBookingData({ field: "properties", value: newServices })
    );

    let totalPrice = 0;
    newServices.forEach((service) => {
      if (service.service) {
        totalPrice += service.service.price;
      }
    });
    const serviceCount = newServices.filter(
      (service) => service.service
    ).length;
    if (serviceCount === newServices.length) {
      dispatch(
        setSpecificBookingData({ field: "serviceCount", value: serviceCount })
      );
    }

    dispatch(
      setSpecificBookingData({ field: "totalPrice", value: totalPrice })
    );
    setServices(newServices);
  };

  const addService = () => {
    setServices([
      ...services,
      { service: null, date: null, staff: null, availableTimes: [] },
    ]);
  };

  const removeService = (index) => {
    const newServices = services.filter((_, i) => i !== index);
    setServices(newServices);
    dispatch(
      setSpecificBookingData({ field: "properties", value: newServices })
    );

    let totalPrice = 0;
    newServices.forEach((service) => {
      if (service.service) {
        totalPrice += service.price || service.service.price;
      }
    });

    dispatch(
      setSpecificBookingData({ field: "totalPrice", value: totalPrice })
    );
  };

  const handleChange = (state, value, field) => {
    if (
      field === "blockNumber" ||
      field === "streetNumber" ||
      field === "houseNumber"
    ) {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value) || value === null) {
        state(value);
        dispatch(setSpecificBookingData({ field, value }));
      } else {
        showError(
          `Please enter only numbers for ${field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()}`
        );
      }
    } else {
      state(value);
      dispatch(setSpecificBookingData({ field, value }));
    }
  };

  const getSubservices = async () => {
    setLoading(true);
    const response = await locationService.getLocation(selectedReduxLocationId);
    switch (response.statusCode) {
      case 200:
        let formattedSubservices = [];
        response.location.business.homeServiceEnabled
          ? setServicesOptions([
              { value: "home", label: "Home service" },
              { value: "vendor", label: "At the vendor" },
            ])
          : setServicesOptions([{ value: "vendor", label: "At the vendor" }]);
        setCategory({
          id: response.location.category.id,
          label: response.location.category.name.en,
          value: response.location.category.slug,
        });
        const subservicePromises = response.location.subservices.map(
          async (subservice) => {
            if (subservice.businessId !== response.location.business.id) {
              return null;
            }

            if (!subservice.enabledOnlineBooking) {
              return null;
            }

            if (
              response.location.category &&
              response.location.category.slug === "health-and-beauty" &&
              serviceType === "home" &&
              !subservice.enabledHomeService
            ) {
              return null;
            }

            if (
              subservice.childSubservices &&
              subservice.childSubservices.length > 0
            ) {
              let serviceOfChildsubservice = subservice.serviceId
                ? await fetchService(subservice.serviceId)
                : null;
              const childSubservices = subservice.childSubservices.map(
                (childSubservice) => {
                  return {
                    ...childSubservice,
                    id: childSubservice.subserviceId,
                    childSubserviceId: childSubservice.id,
                    serviceName: serviceOfChildsubservice
                      ? `${serviceOfChildsubservice.name.en} ${
                          serviceOfChildsubservice.name.ar
                            ? ` - ${serviceOfChildsubservice.name.ar}`
                            : ""
                        }`
                      : "All",
                    subserviceName: childSubservice.name.en,
                    subserviceDuration: `${childSubservice.duration} min`,
                    subservicePrice: `KWD ${childSubservice.price}`,
                    value: childSubservice.id,
                    label: `${childSubservice.name.en} - ${childSubservice.duration} min - ${childSubservice.price} KWD`,
                  };
                }
              );
              return childSubservices;
            }

            let serviceOfSubservice = subservice.serviceId
              ? await fetchService(subservice.serviceId)
              : null;
            return {
              ...subservice,
              subserviceId: subservice.id,
              serviceName: serviceOfSubservice
                ? `${serviceOfSubservice.name.en} ${
                    serviceOfSubservice.name.ar
                      ? ` - ${serviceOfSubservice.name.ar}`
                      : ""
                  }`
                : "All",
              subserviceName: subservice.name.en,
              subserviceDuration: `${subservice.duration} min`,
              subservicePrice: `KWD ${subservice.price}`,
              value: subservice.id,
              label: `${subservice.name.en} - ${subservice.duration} min - ${subservice.price} KWD`,
            };
          }
        );

        const resolvedSubservices = await Promise.all(subservicePromises);

        formattedSubservices = resolvedSubservices.filter(Boolean).flat();

        setNewServiceOptions(formattedSubservices);
        setLoading(false);
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const getStaffForService = async () => {
    try {
      const response = await providersService.getProvider(
        selectedReduxLocationId
      );
      let formattedStaff = [];
      response.providers.forEach((provider) => {
        if (serviceType === "home") {
          if (
            !provider.providerSettings ||
            !provider.providerSettings.homeService
          ) {
            return;
          }
        }
        if (
          !provider.providerSettings ||
          !provider.providerSettings.onlineBooking
        ) {
          return;
        }

        let formattedProvider = {
          value: provider.id,
          label: provider.name,
          subservicesIds: provider?.subservices?.map(
            (subservice) => subservice.id
          ),
          availability: provider.availability.filter(
            (availability) =>
              availability.locationId === selectedReduxLocationId
          ),
        };
        formattedStaff.push(formattedProvider);
      });
      setStaffOptions(formattedStaff);
    } catch (error) {
      return [];
    }
  };

  const validateForm = () => {
    if (serviceType === "home") {
      if (!city || !city.value) {
        showError("Please input City");
        return false;
      }
      if (!blockNumber) {
        showError("Please input Block Number");
        return false;
      }
      if (!streetNumber) {
        showError("Please input Street Number");
        return false;
      }
      if (!houseNumber) {
        showError("Please input House Number");
        return false;
      }
    }

    if (
      serviceType === "vendor" ||
      (category && category.value !== "healthbeauty")
    ) {
      for (let i = 0; i < services.length; i++) {
        if (!services[i].service) {
          showError(`Please select a service for service #${i + 1}`);
          return false;
        }
        if (!services[i].staff) {
          showError(`Please select a staff member for service #${i + 1}`);
          return false;
        }
        if (!services[i].date) {
          showError(`Please select a date for service #${i + 1}`);
          return false;
        }
      }

      return true;
    }

    showError("Please select a service type");
    return false;
  };

  const groupServiceOptions = (services) => {
    const groupedServices = services.reduce((acc, service) => {
      if (!acc[service.serviceName]) {
        acc[service.serviceName] = [];
      }
      acc[service.serviceName].push(service);
      return acc;
    }, {});

    const formattedGroupedServices = Object.keys(groupedServices).map(
      (key) => ({
        label: key,
        options: groupedServices[key],
      })
    );
    return formattedGroupedServices;
  };

  const getCityOptions = async (locationId) => {
    const response = await locationService.getLocation(locationId);
    switch (response.statusCode) {
      case 200:
        // there is a settings array in the location object. It contains the cities, locationId, deliveryFee options. We need to extract the cities from it.
        const cities = response.location.settings.find(
          (setting) => setting.locationId === selectedReduxLocationId
        ).cities;
        const formattedCities = cities.map((city) => ({
          ...city,
          value: city.id,
          label: `
                  ${city.name.en ? city.name.en : ""}
                  ${city.name.en ? " - " : ""}
                  ${city.name.ar ? city.name.ar : ""}
                `,
        }));
        setCityOptions(formattedCities);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (newServiceOptions.length > 0) {
      const formattedGroupedServices = groupServiceOptions(newServiceOptions);
      setGroupedServiceOptions(formattedGroupedServices);
    }
  }, [newServiceOptions, serviceType]);

  useEffect(() => {
    if (bookingsReduxData) {
      setServiceType(bookingsReduxData.bookingDestination);
      setCity(bookingsReduxData.city);
      bookingsReduxData.blockNumber &&
        setBlockNumber(bookingsReduxData.blockNumber);
      bookingsReduxData.streetNumber &&
        setStreetNumber(bookingsReduxData.streetNumber);
      bookingsReduxData.houseNumber &&
        setHouseNumber(bookingsReduxData.houseNumber);
    }

    if (properties.length) {
      setServices(
        properties?.map((property) => ({
          service: property.service,
          date: property.date,
          staff: property.staff,
          price: property?.price || property?.service?.price,
          availableHours: property.availableHours,
        }))
      );
    }
  }, [bookingsReduxData, properties]);

  useEffect(() => {
    if (serviceType) {
      const resetServices = services.map((service) => ({
        ...service,
        service: null,
        staff: null,
        date: null,
        availability: [],
        availableHours: [],
      }));
      setServices(resetServices);
      dispatch(
        setSpecificBookingData({ field: "properties", value: resetServices })
      );
    }
  }, [serviceType]);

  useEffect(() => {
    if (selectedReduxLocationId) {
      getSubservices();
      getStaffForService();
      getCityOptions(selectedReduxLocationId);
    }
  }, [selectedReduxLocationId, serviceType]);

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="flex flex-col h-full">
        {category && category.value === "health-and-beauty" && (
          <div className="flex w-full space-x-2 ">
            <p className="!text-12 min-w-[200px] font-bold !text-[#00000073] justify-start">
              Services
            </p>
            <RadioButtonGroup
              options={servicesOptions}
              name={"serviceType"}
              selectedValue={serviceType}
              onChange={(e) => {
                handleChange(
                  setServiceType,
                  e.target.value,
                  "bookingDestination"
                );
              }}
              className="w-full space-x-4"
            />
          </div>
        )}
        {serviceType === "home" && (
          <div className="mt-4 space-y-2">
            <SelectInput
              id="city"
              label="Address"
              selected={city}
              onSelect={(value) => handleChange(setCity, value, "city")}
              options={cityOptions}
              placeholder="Select or Enter a city"
              labelClassName="!text-12 min-w-[200px] font-bold !text-[#00000073] justify-start"
              inputClassName="!text-14 font-normal !text-[#000000D9]"
              formatOptionLabel={true}
            />
            <div className="flex w-full space-x-2">
              <div className="flex items-center justify-start !text-12 min-w-[200px] font-bold !text-[#00000073]"></div>
              <InputField
                placeholder="Enter block number"
                value={blockNumber}
                onChange={(e) =>
                  handleChange(setBlockNumber, e.target.value, "blockNumber")
                }
                min={1}
                max={30}
                inputClassName={"!text-14 font-normal text-[#000000D9]"}
                className="w-full"
              />
            </div>
            <div className="flex w-full space-x-2">
              <div className="flex items-center justify-start !text-12 min-w-[200px] font-bold !text-[#00000073]"></div>
              <InputField
                placeholder="Enter street number"
                value={streetNumber}
                onChange={(e) =>
                  handleChange(setStreetNumber, e.target.value, "streetNumber")
                }
                inputClassName={"!text-14 font-normal text-[#000000D9]"}
                min={1}
                max={30}
                className="w-full"
              />
            </div>
            <div className="flex w-full space-x-2">
              <div className="flex items-center justify-start !text-12 min-w-[200px] font-bold !text-[#00000073]"></div>
              <InputField
                placeholder="Enter house number"
                value={houseNumber}
                onChange={(e) =>
                  handleChange(setHouseNumber, e.target.value, "houseNumber")
                }
                min={1}
                max={30}
                inputClassName={"!text-14 font-normal text-[#000000D9]"}
                className="w-full"
              />
            </div>
          </div>
        )}
        <div
          className={`flex flex-col h-full mt-4 space-y-4 overflow-y-auto
        ${serviceType === "home" ? "max-h-[40vh]" : "max-h-[60vh] min-h-[40vh]"}
      `}
        >
          {services?.map((service, index) => (
            <div key={index} className="mt-8 space-y-3">
              <div className="flex items-center w-full space-x-2">
                <SelectInput
                  id={`service-${index}`}
                  label="Select a service"
                  selected={service.service}
                  onSelect={(value) => {
                    handleServiceChange(index, "service", value);
                  }}
                  options={groupedServiceOptions}
                  formatOptionLabel={(option) => {
                    return (
                      <div className="flex justify-between w-full px-2">
                        <div className="flex flex-col items-start space-y-1">
                          <p className="!text-12 font-medium text-[#000000D9]">
                            {option.subserviceName}
                          </p>
                          <p className="!text-12 font-normal text-[#00000073]">
                            {option.subserviceDuration}
                          </p>
                        </div>
                        <p className="!text-12 font-medium text-[#000000D9]">
                          {option.subservicePrice}
                        </p>
                      </div>
                    );
                  }}
                  placeholder="Select a service"
                  labelClassName="!text-12 min-w-[150px] xl:min-w-[200px] font-bold !text-[#00000073] justify-start transition-all duration-300 ease-in-out transform"
                  inputClassName="!text-14 w-full font-normal text-[#00000040]"
                  optionsBackgroundColor={"#FFF"}
                  className="w-full"
                  isDisabled={
                    category &&
                    category.value === "health-and-beauty" &&
                    !serviceType
                  }
                />
                <button
                  onClick={() => removeService(index)}
                  className="text-red-500"
                >
                  <FaTrash />
                </button>
              </div>
              <SelectInput
                id={`staff-${index}`}
                label="Select staff member"
                selected={service.staff}
                onSelect={(value) => handleServiceChange(index, "staff", value)}
                options={staffOptions.filter((staff) => {
                  return staff.subservicesIds.includes(service.service?.id);
                })}
                placeholder="Select staff member"
                labelClassName="!text-12 min-w-[150px] xl:min-w-[200px] font-bold !text-[#00000073] justify-start transition-all duration-300 ease-in-out transform"
                inputClassName="!text-14 font-normal text-[#00000040]"
                isDisabled={
                  category &&
                  category.value === "health-and-beauty" &&
                  !serviceType
                }
              />
              <div className="flex w-full space-x-2">
                <p
                  className="!text-12 min-w-[150px] xl:min-w-[200px] font-bold !text-[#00000073] justify-start
                  transition-all duration-300 ease-in-out transform
                "
                >
                  Booking date and time
                </p>
                <DateRangePicker
                  type={serviceType}
                  startDate={service.date ? new Date(service.date) : null}
                  setStartDate={(date) => {
                    handleServiceChange(index, "date", date);
                  }}
                  className={
                    !service.service || !service.staff
                      ? "bg-gray-100 cursor-not-allowed w-full"
                      : "w-full"
                  }
                  showTimeSelect={true}
                  showEndDate={false}
                  startDateDisabled={!service.service || !service.staff}
                  endDateDisabled={true}
                  providerId={service?.staff?.value}
                  serviceDuration={service?.service?.duration}
                  locationId={selectedReduxLocationId}
                  availability={
                    service?.staff?.availability?.map((availability) => ({
                      days: availability.days,
                    })) || []
                  }
                  onAvailableHoursChanged={(availableTimes) => {
                    let newServices = [...services];
                    let updatedService = newServices[index];
                    updatedService["availableHours"] = availableTimes;
                    newServices[index] = updatedService;
                    dispatch(
                      setSpecificBookingData({
                        field: "properties",
                        value: newServices,
                      })
                    );
                  }}
                />
              </div>
            </div>
          ))}
          <div className="flex items-end justify-end w-full">
            <button
              onClick={addService}
              className="w-24 w-f h-4 mt-4 text-[#DE0607] text-12 font-normal"
            >
              + Add services
            </button>
          </div>
        </div>
        <div className="mt-4">
          <h2>
            Total price: KWD
            {totalPrice}
          </h2>
        </div>
        <div className="absolute bottom-0 flex justify-end w-full h-20 mt-4 space-x-2 right-6 ">
          <Button
            label="Cancel"
            className={
              "w-40 bg-white text-black border-[#DE0607] border rounded-lg"
            }
            textColor="text-black"
            onClick={hide}
          />
          <Button
            label="Next"
            className={"w-40 bg-[#DE0607] text-white rounded-lg"}
            textColor="text-white"
            onClick={() => {
              if (validateForm()) {
                dispatch(
                  setSpecificBookingData({
                    field: "activeTab",
                    value: "Notes",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default ServicesTab;
