import React, { useEffect } from "react";
import ServiceCategoryListItem from "./ServiceListItem";
import TitleComponent from "./TitleComponent";
import subserviceServices from "services/service/subserviceServices";
import { showError } from "utils/helpers";

const ServiceCategoryList = ({
  fetchedCategories,
  selectedService,
  setSelectedService,
  setFormMode,
  onDelete,
}) => {
  const handleSelect = async (subserviceId) => {
    try {
      const response = await subserviceServices.getSubservice(subserviceId);
      switch (response.statusCode) {
        case 200:
          setSelectedService(response.subservice);
          setFormMode("edit");
          break;
        default:
          showError(response.message);
          setFormMode("");
          break;
      }
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="mt-6 space-y-4">
      {fetchedCategories.map((service) => {
        return (
          <div key={service.id}>
            <TitleComponent title={service.serviceCategoryName.en} />
            {service.subservices.length > 0 ? (
              service.subservices.map((subservice) => {
                return (
                  <ServiceCategoryListItem
                    key={subservice.id}
                    service={subservice}
                    isSelected={selectedService?.id === subservice.id}
                    onSelect={() => {
                      handleSelect(subservice.id);
                    }}
                    onDelete={onDelete}
                  />
                );
              })
            ) : (
              <p className="text-16 font-medium text-center opacity-50 leading-6 text-[#000000D9]">
                No Subservice found
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ServiceCategoryList;
