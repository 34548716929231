import React, { useEffect, useState } from "react";
import WeeklySchedule from "components/Locations/components/WeeklySchedule";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListingAvailability,
  setListingAvailability,
} from "../../../../redux/listings/listingSlice";

const Availability = () => {
  const availabilityData = useSelector(
    (state) => state.listings.listingAvailability
  );
  const [schedule, setSchedule] = useState([]);
  const [isDayEnabledData, setIsDayEnabledData] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setListingAvailability(schedule));
  }, [schedule]);

  useEffect(() => {
    if (!availabilityData) return;
    if (Array.isArray(availabilityData) && availabilityData.length > 0) {
      setSchedule(availabilityData);
      availabilityData.forEach((day) => {
        setIsDayEnabledData((prev) => {
          return {
            ...prev,
            [day.day]: true,
          };
        });
      });
    }
  }, []);

  return (
    <div>
      <div className="w-full xl:w-1/2 ">
        <WeeklySchedule
          scheduleData={schedule}
          setScheduleData={setSchedule}
          isDayEnabledData={isDayEnabledData}
          setIsDayEnabledData={setIsDayEnabledData}
        />
      </div>
    </div>
  );
};

export default Availability;
