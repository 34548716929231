import React, { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import Button from "../core/Button";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";
import FormField from "./FormField";
import { showError, showSuccess } from "utils/helpers";
import authService from "services/auth/authService";

const RestorePassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setLoading(true);
    if (!email) {
      showError("Email is required");
      setLoading(false);
      return;
    }
    try {
      const response = await authService.forgetPasswordAdmin(email);
      switch (response.statusCode) {
        case 200:
          showSuccess(response.message);
          setLoading(false);
          navigate("/login");
          break;
        case 400:
          showError(response.message);
          setLoading(false);
          break;
        default:
          showError(response.message);
          setLoading(false);
          break;
      }
    } catch (error) {
      showError(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  return (
    <div
      className="w-full
     px-10 md:px-10 xl:px-10 2xl:px-[58px]
     md:w-[400px] lg:w-[450px] xl:w-[480px] 2xl:w-[500px]
     rounded-md border-[#00000026] shadow-2xl h-[680px] flex flex-col relative
      transition-all duration-300 ease-in-out
     "
    >
      <div
        onClick={() => navigate("/login")}
        className="absolute flex items-center space-x-2 cursor-pointer top-6 left-6"
      >
        <FaArrowLeft size={14} className="text-[#000000D9]" />
        <p className="font-medium text-center text-[#000000D9] text-16">Back</p>
      </div>
      <h2 className="mt-20 font-medium text-center text-38 leading-[46px] text-[#000000D9]">
        Restore password
      </h2>
      <form
        className="mt-[90px] space-y-4 "
        onSubmit={(e) => {
          e.preventDefault();
          handleResetPassword();
        }}
      >
        <p className="text-left text-14 leading-[22px] text-[#000000D9] font-medium">
          Enter your email address and we will send a link to reset your
          password.
        </p>
        <FormField
          label="Enter your email"
          icon={<HiOutlineMail size={16} />}
          name="email"
          placeholder="Email"
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          label="Reset password"
          className="w-full mt-5 text-white bg-[#DE0607] rounded-xl"
          textColor="text-white"
          loading={loading}
        />
      </form>
    </div>
  );
};

export default RestorePassword;
